import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import { IconData, CompanyAvatar } from 'components'
import {
  NAME,
  DESCRIPTION,
  ID,
  SUBSCRIPTION,
  IMAGE,
  PHOTO,
  CARD_INFO_FIELDS,
  CARD_ADVISOR_INFO_FIELDS,
  HIDE_IDENTITY,
} from 'entities/companyFields'
import { TYPE } from 'entities/formFields'
import { useHistory } from 'react-router-dom'
import { COMPANY, ADVISOR } from 'entities/routes'
import { COMPANIES, ADVISORS } from 'entities/feed'
import SUBSCRIPTIONS from 'utils/subscriptions'
import { ModalUpgradePackage } from 'components'
import '../../component/annualpackage/annualpackage.css'

const CardHeaderComponent = (props) => {
  let history = useHistory()
  const { t } = props

  const [visibleModalUpgrade, setVisibleModalUpgrade] = useState(false)
  const [dataModalUpgrade, setDataModalUpgrade] = useState({
    data: '',
    linkUpgrade: '',
  })
  const [dataModalCurrent, setDataModalCurrent] = useState({
    data: '',
    linkUpgrade: '',
  })
  const userCurrent = props.userDetail
  const subscriptionCurrent = SUBSCRIPTIONS[userCurrent.subscription]

  function handleCloseModal() {
    setVisibleModalUpgrade(false)
  }

  const handleRedirectToUserProfile = () => {
    let found = subscriptionCurrent.available_card_btn.find(
      (element) => element == props.user[TYPE]
    )
    let access = true

    if (found !== undefined || (userCurrent.subscription === 5 && props.subscription === 5)) {
      //Tiene acceso
      setVisibleModalUpgrade(false)
    } else {
      let upgrade =
        userCurrent.subscription == 1 || userCurrent.subscription == 5
          ? 2
          : userCurrent.subscription + 1
      access = false
      setVisibleModalUpgrade(true)
      setDataModalUpgrade({
        data: SUBSCRIPTIONS[upgrade],
        linkUpgrade: userCurrent,
      })
      setDataModalCurrent({
        data: subscriptionCurrent,
        linknowBenefits: '',
      })
      //No tiene acceso y levanta modal de upgrade
    }

    if (props.user[TYPE] === COMPANIES) {
      if (access == true) history.push(`${COMPANY}/${props.user[ID]}`)
    } else if (props.user[TYPE] === ADVISORS) {
      if (access == true) history.push(`${ADVISOR}/${props.user[ID]}`)
    }
  }

  const [displayShowMoreDescriptionBtn, setDisplayShowMoreDescriptionBtn] = useState(false)
  const [displayShowLessDescriptionBtn, setDisplayShowLessDescriptionBtn] = useState(false)
  const LIMIT_DESCRIPTION = 80
  useEffect(() => {
    if ((props.user[DESCRIPTION] || '').length > LIMIT_DESCRIPTION)
      setDisplayShowMoreDescriptionBtn(true)
  }, [props.user])

  const handleShowMoreDescription = () => {
    setDisplayShowMoreDescriptionBtn(false)
    setDisplayShowLessDescriptionBtn(true)
  }
  const handleShowLessDescription = () => {
    setDisplayShowMoreDescriptionBtn(true)
    setDisplayShowLessDescriptionBtn(false)
  }

  let cardHeaderInfoFields = CARD_INFO_FIELDS
  if (props.user[TYPE] === ADVISORS) {
    cardHeaderInfoFields = CARD_ADVISOR_INFO_FIELDS
  }

  const companyDefaultImage = require('assets/icons/company.svg')
  const advisorDefaultImage = require('assets/icons/advisor.svg')
  let userImage = props.user[TYPE] === ADVISORS ? advisorDefaultImage : companyDefaultImage
  if (props.user[IMAGE] && !props.user[HIDE_IDENTITY]) userImage = props.user[IMAGE]

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <div onClick={handleRedirectToUserProfile}>
          <CompanyAvatar
            subscription={props.user[SUBSCRIPTION]}
            name={props.user[NAME]}
            image={userImage}
            variant="rounded"
            userId={props.user[ID]}
            customSx={{ mr: 2, '& img': { padding: 0.1 } }}
          />
        </div>
        <Box>
          {props.user[NAME] && (
            <Typography
              variant="f16"
              onClick={handleRedirectToUserProfile}
              color={colorsByKey.defaultText}
              sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            >
              {props.user[NAME]}
            </Typography>
          )}
          {props.user[DESCRIPTION] && (
            <Typography variant="f12" color={colorsByKey.defaultText}>
              {displayShowMoreDescriptionBtn && props.user[DESCRIPTION].slice(0, LIMIT_DESCRIPTION)}
              {!displayShowMoreDescriptionBtn && props.user[DESCRIPTION]}
              {displayShowMoreDescriptionBtn && (
                <Typography component="span" variant="f12" sx={{ display: 'inline-flex' }}>
                  ...
                  <Typography
                    onClick={handleShowMoreDescription}
                    component="span"
                    variant="link"
                    sx={{
                      color: colorsByKey.accentText,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      ml: 1,
                      display: 'inline-flex',
                    }}
                  >
                    {t('view more')}
                  </Typography>
                </Typography>
              )}
              {displayShowLessDescriptionBtn && (
                <Typography
                  onClick={handleShowLessDescription}
                  variant="span"
                  sx={{
                    color: colorsByKey.accentText,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    ml: 1,
                  }}
                >
                  {t('view less')}
                </Typography>
              )}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
        {cardHeaderInfoFields.map(({ key, icon, suffix, getText }, idx) => {
          if (!props.user[key] || !(props.user[key] || []).length) return
          else {
            return (
              <Box key={`${props.user[ID]}-${key}`} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconData
                  type={key}
                  title={getText ? getText(props.user[key]) : props.user[key]}
                  icon={icon}
                  suffix={suffix}
                  margin={1}
                />
              </Box>
            )
          }
        })}
      </Box>
      <ModalUpgradePackage
        visible={visibleModalUpgrade}
        dataUpgrade={dataModalUpgrade}
        dataCurrent={dataModalCurrent}
        closeModal={() => handleCloseModal()}
      />
    </Box>
  )
}
export const CardHeader = withTranslation()(CardHeaderComponent)
