import React from 'react'
import axios from 'axios'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

import AuthenticatedFile from '../component/general/AuthenticatedFile'

class SinglePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: null,
      loading: true,
      notfound: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.pageDetail(nextProps.match.params.slug)
  }

  componentDidMount() {
    this.pageDetail(this.props.match.params.slug)
  }

  pageDetail(slug) {
    this.setState({ loading: true })
    const api_url = process.env.REACT_APP_API_URL + '/api/page/' + slug
    axios
      .get(api_url)
      .then((response) => {
        // handle success
        const { data } = response.data
        this.setState({ detail: data, loading: false })
      })
      .catch((error) => {
        this.setState({ notfound: true, loading: false })
      })
  }

  render() {
    const { detail, loading } = this.state

    if (loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div className="registration-min pb-5">
        <Helmet>
          <title>{detail.title} | BridgeWhat: Let's Grow!</title>
          <body className="singlepage-content" />
        </Helmet>
        <Container className="mb-4">
          <h6>{detail.title}</h6>
        </Container>
        <Container>
          <div className="boxshadow">
            <Row>
              <Col md="12" className="singleblog">
                <div className="card border-0">
                  <div className="card-body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: detail.content,
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="1"></Col>
              <Col md="10">
                <div className="table-responsive registrat">
                  <Table bordered responsive className="needtable need-table">
                    <thead>
                      <tr>
                        <th>
                          <img
                            style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                            className="en"
                            src={require('assets/images/en_flag.jpg')}
                            alt="#"
                          />
                          <span>EN</span>
                        </th>
                        <th>
                          <img
                            style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                            className="en"
                            src={require('assets/images/pt_flag.jpg')}
                            alt="#"
                          />
                          <span>PT</span>
                        </th>
                        <th>
                          <img
                            style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                            className="en"
                            src={require('assets/images/es_flag.jpg')}
                            alt="#"
                          />
                          <span>ES</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail.id === 2 && (
                        <tr>
                          <td>
                            {detail.upload_pdf ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf}`}
                                filename={detail.upload_pdf.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Privacy Policy.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {detail.upload_pdf_pr ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf_pr}`}
                                filename={detail.upload_pdf_pr.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Politica privacidade.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {detail.upload_pdf_es ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf_es}`}
                                filename={detail.upload_pdf_es.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Politica privacidad.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                        </tr>
                      )}
                      {detail.id === 3 && (
                        <tr>
                          <td>
                            {detail.upload_pdf ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf}`}
                                filename={detail.upload_pdf.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Terms and Conditions.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {detail.upload_pdf_pr ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf_pr}`}
                                filename={detail.upload_pdf_pr.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Condições gerais de utilização.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {detail.upload_pdf_es ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf_es}`}
                                filename={detail.upload_pdf_es.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Términos y condiciones.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                        </tr>
                      )}
                      {detail.id === 4 && (
                        <tr>
                          <td>
                            {detail.upload_pdf ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf}`}
                                filename={detail.upload_pdf.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Cookie policy.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {detail.upload_pdf_pr ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf_pr}`}
                                filename={detail.upload_pdf_pr.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Politica cookies.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {detail.upload_pdf_es ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf_es}`}
                                filename={detail.upload_pdf_es.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Politica cookies.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                        </tr>
                      )}
                      {detail.id === 5 && (
                        <tr>
                          <td>
                            {detail.upload_pdf ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf}`}
                                filename={detail.upload_pdf.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Participant's contract.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {detail.upload_pdf_pr ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf_pr}`}
                                filename={detail.upload_pdf_pr.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Contrato Participante.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {detail.upload_pdf_es ? (
                              <AuthenticatedFile
                                url={`/${detail.upload_pdf_es}`}
                                filename={detail.upload_pdf_es.split(/[/]+/).pop()}
                                linklabel={
                                  <div>
                                    <img
                                      style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                      className="en"
                                      src={require('assets/images/pdf.png')}
                                      alt="#"
                                    />
                                    <span>Contrato Participante.pdf</span>
                                  </div>
                                }
                              />
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}
export default SinglePage
