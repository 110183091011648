export const REGISTER_FEATURE = 'REGISTER_FEATURE'
export const REVIEWS_FEATURE = 'REVIEWS_FEATURE'
export const SAMPLING_FEATURE = 'SAMPLING_FEATURE'
export const SCORING_BOARD_FEATURE = 'SCORING_BOARD_FEATURE'
export const PROTOCOLS_FEATURE = 'PROTOCOLS_FEATURE'
export const CONTENTS_BLOG_FEATURE = 'CONTENTS_BLOG_FEATURE'
export const CONTENTS_PUBLICATIONS_FEATURE = 'CONTENTS_PUBLICATIONS_FEATURE'
export const NEEDS_AND_LEADS_FEATURE = 'NEEDS_AND_LEADS_FEATURE'
export const CAMPAIGNS_FEATURE = 'CAMPAIGNS_FEATURE'
export const ADVISOR_FEATURE = 'ADVISOR_FEATURE'
export const LOG_MARKET_FEATURE = 'LOG_MARKET_FEATURE'

export const OFFERS_FILTER = 'offers'
export const OPPORTUNITIES_FILTER = 'opportunities'
export const CAMPAIGNS_FILTER = 'campaigns'
export const PROTOCOLS_FILTER = 'protocols'
export const SAMPLING_FILTER = 'samplings'
export const POST_FILTER = 'posts'
export const COMPANIES_FILTER = 'companies'
export const ADVISOR_FILTER = 'advisors'
export const PARTNERSHIPS_FILTER = 'partnerships'
export const NEW_PARTNERSHIPS_FILTER = 'newpartnership'
export const ACCELERATORS_FILTER = 'accelerators'
