import { Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { colorsByKey } from 'theme/colors'
import PublishDialog from './PublishDialog'
import { FEED_ACTION_TABS } from 'entities/feed'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import SUBSCRIPTIONS from 'utils/subscriptions'
import { ModalUpgradePackage } from 'components'
import '../../../component/annualpackage/annualpackage.css'
import PartnershipModal from 'components/Modal/PartnershipModal'
import { NEW_PARTNERSHIPS_ACTION, PARTNERSHIPS_ACTION } from 'utils/actions'

const CreateItemsBar = ({ t, props }) => {
  let history = useHistory()

  const [selectedKey, setSelectedKey] = useState(null)
  const [visibleModalUpgrade, setVisibleModalUpgrade] = useState(false)
  const [partnershipOpen, setPartnershipOpen] = useState(false)

  const [dataModalUpgrade, setDataModalUpgrade] = useState({
    data: '',
    linkUpgrade: '',
  })
  const [dataModalCurrent, setDataModalCurrent] = useState({
    data: '',
    linkUpgrade: '',
  })

  const userDetail = props.userDetail
  const subscriptionCurrent = SUBSCRIPTIONS[userDetail.subscription]

  function handleCloseModal() {
    setVisibleModalUpgrade(false)
  }

  const onSelect = (key) => {
    let found = subscriptionCurrent.available_actions.find((element) => element == key)
    /*
    Object.values(FEED_ACTION_TABS).forEach((tab) => {
      if (tab.key === key) history.push(`/create/${tab.path}`)
    })*/

    //MODAL UPGRADE
    if (found != undefined) {
      //Tiene acceso
      setVisibleModalUpgrade(false)
      for (let i = 0; i < FEED_ACTION_TABS.length; i++) {
        const tab = FEED_ACTION_TABS[i]
        if (tab.key === key && key === 'partnerships') {
          setPartnershipOpen(true)
          break
        } else if (tab.key === key) {
          history.push(`/create/${tab.path}`)
          break
        }
      }
    } else {
      //Si la suscripcion es 1 (Crystal) o 5 (Free) debe migrar a 2 (Silver)
      let upgrade
      if (key === NEW_PARTNERSHIPS_ACTION || key === PARTNERSHIPS_ACTION) {
        upgrade = 3
      } else {
        upgrade =
          userDetail.subscription == 1 || userDetail.subscription == 5
            ? 2
            : userDetail.subscription + 1
      }

      setVisibleModalUpgrade(true)
      setDataModalUpgrade({
        data: SUBSCRIPTIONS[upgrade],
        linkUpgrade: userDetail,
      })
      setDataModalCurrent({
        data: subscriptionCurrent,
        linknowBenefits: '',
      })

      //No tiene acceso y levanta modal de upgrade
    }
  }

  const onClose = () => {
    setSelectedKey(null)
  }

  return (
    <Stack spacing={0.5}>
      <Typography color={colorsByKey.blue} variant="f12">
        {t('Create ...')}
      </Typography>
      <Stack
        direction={'row'}
        spacing={2}
        bgcolor={colorsByKey.defaultBg}
        boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.15)'}
        paddingX={2}
        paddingY={1}
        borderRadius={3}
      >
        {FEED_ACTION_TABS.map(({ key, btn, icon }) => (
          <Button key={key} size="small" onClick={() => onSelect(key)}>
            <>
              <img alt={`entity ${key} icon`} src={icon} style={{ marginRight: 4 }} />
              <Typography variant="f12" color={colorsByKey.defaultText} sx={{ ml: 1 }}>
                {btn}
              </Typography>
            </>
          </Button>
        ))}
      </Stack>
      <PublishDialog action={selectedKey} onClose={onClose} />
      <ModalUpgradePackage
        visible={visibleModalUpgrade}
        dataUpgrade={dataModalUpgrade}
        dataCurrent={dataModalCurrent}
        closeModal={() => handleCloseModal()}
      />

      <PartnershipModal open={partnershipOpen} close={() => setPartnershipOpen(false)} />
    </Stack>
  )
}

export default withTranslation()(CreateItemsBar)
