export default [
  { id: 'description', label: 'Description', type: 'text', isHTML: true },
  { id: 'service', label: 'BridgeWhat 20Log', type: 'object', valueKey: 'service_name' },
  { id: 'sectors', label: 'Target industry sectors', type: 'array', valueArrayKey: 'name' },
  { id: 'countries', label: 'Target countries', type: 'array', valueArrayKey: 'name' },
  { id: 'targetCompanySize', label: 'Target company employees', type: 'array', simpleArray: true },
  {
    id: 'targetCompanyRevenue',
    label: 'Target company revenue (M)',
    type: 'array',
    simpleArray: true,
  },
  { id: 'need', label: 'Service or product', type: 'text', onlyShowOn: 'iwb' },
  { id: 'innovation_areas', label: 'Areas of innovation', type: 'text', onlyShowOn: 'iws' },
  { id: 'strengths', label: 'Strengths', type: 'text', onlyShowOn: 'iws' },
  {
    id: 'what_brand_famous',
    label: 'What is your brand famous for?',
    type: 'text',
    onlyShowOn: 'iws',
  },
  {
    id: 'proposalFactors',
    label: 'Proposal differentiator factors',
    type: 'array',
    valueArrayKey: 'factorName',
    isSecured: true,
  },
  {
    id: 'scoring_board_reviews',
    label: 'Reviews',
    type: 'boolean',
    valuesIwtb: ['Required', 'Not required'],
    valuesIwts: ['Yes', 'No'],
    isSecured: true,
  },
  {
    id: 'scoring_board_testimonials',
    label: 'Testimonials',
    type: 'boolean',
    valuesIwtb: ['Required', 'Not required'],
    valuesIwts: ['Yes', 'No'],
    isSecured: true,
  },
  {
    id: 'scoring_board_recommendations',
    label: 'Recommendations',
    type: 'boolean',
    valuesIwtb: ['Required', 'Not required'],
    valuesIwts: ['Yes', 'No'],
    isSecured: true,
  },
  {
    id: 'scoring_board_certificates',
    label: 'Certificates',
    type: 'boolean',
    valuesIwtb: ['Required', 'Not required'],
    valuesIwts: ['Yes', 'No'],
    isSecured: true,
  },
  {
    id: 'scoring_board_awards',
    label: 'Awards',
    type: 'boolean',
    valuesIwtb: ['Required', 'Not required'],
    valuesIwts: ['Yes', 'No'],
    isSecured: true,
  },
  {
    id: 'scoring_board_publications',
    label: 'Publications',
    type: 'boolean',
    valuesIwtb: ['Required', 'Not required'],
    valuesIwts: ['Yes', 'No'],
    isSecured: true,
  },
  { id: 'createdAt', label: 'Publication date', type: 'date' },
  { id: 'deadline', label: 'Closing date', type: 'date' },
]
