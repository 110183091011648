import { apiDelete, apiGet, apiPost, apiPut } from 'api'
import { CLOSE_CHAT, CREATE_NEW_CHAT, DELETE_CHAT, DELETE_MESSAGE, EDIT_MESSAGE, GET_CHAT, GET_CHAT_LIST, OPEN_CHAT, SEND_CHAT_MESSAGE, VIEW_MESSAGES } from 'store/types'

const apiUrl = '/api/chat'

export const getChatList = () => {
  return async (dispatch) => {
    new Promise((resolve, reject) => {
      apiGet(apiUrl).then((response) => {
        dispatch({
          type: GET_CHAT_LIST,
          payload: response.data
        })
        resolve(response)
      }).catch(error => reject(error))
    })
  }
}

export const openChat = (id) => {
  return async (dispatch) => {

    apiGet(`${apiUrl}/${id}/view`);

    dispatch({
      type: OPEN_CHAT,
      payload: id
    })
  }
}

export const closeChat = () => {
  return async (dispatch) => {
    dispatch({
      type: CLOSE_CHAT
    })
  }
}

export const getChat = (id) => {
  return async (dispatch) => {
    new Promise((resolve, reject) => {
      apiGet(`${apiUrl}/${id}`).then((response) => {
        dispatch({
          type: GET_CHAT,
          payload: response.data
        })
        resolve(response)
      }).catch(error => reject(error))
    })
  }
}

export const viewMessages = (id) => {
  return async (dispatch) => {
    new Promise((resolve, reject) => {
      apiGet(`${apiUrl}/${id}/view`).then((response) => {
        dispatch({
          type: VIEW_MESSAGES,
          payload: response.data
        })
        resolve(response)
      }).catch(error => reject(error))
    })
  }
}

export const createNewChat = (payload) => {
  return async (dispatch) => {
    new Promise((resolve, reject) => {
      apiPost(apiUrl, payload).then((response) => {
        dispatch({
          type: CREATE_NEW_CHAT,
          payload: response.data
        })
        resolve(response)
      }).catch(error => reject(error))
    })
  }
}

export const sendChatMessage = (payload) => {
  return async (dispatch) => {
    new Promise((resolve, reject) => {
      apiPost(`${apiUrl}/messages`, payload).then((response) => {
        dispatch({
          type: SEND_CHAT_MESSAGE,
          payload: response.data
        })
        resolve(response)
      }).catch(error => reject(error))
    })
  }
}

export const deleteMessage = (id) => {
  return async (dispatch) => {
    new Promise((resolve, reject) => {
      apiDelete(`${apiUrl}/messages/${id}`).then((response) => {
        dispatch({
          type: DELETE_MESSAGE,
          payload: response.data
        })
        resolve(response)
      }).catch(error => reject(error))
    })
  }
}

export const deleteChat = (id) => {
  return async (dispatch) => {
    new Promise((resolve, reject) => {
      apiDelete(`${apiUrl}/${id}`).then((response) => {
        dispatch({
          type: DELETE_CHAT,
          payload: response.data
        })
        resolve(response)
      }).catch(error => reject(error))
    })
  }
}

export const editMessage = (payload) => {
  return async (dispatch) => {
    new Promise((resolve, reject) => {
      apiPut(`${apiUrl}/messages/${payload.id}`, payload).then((response) => {
        dispatch({
          type: EDIT_MESSAGE,
          payload: response.data
        })
        resolve(response)
      }).catch(error => reject(error))
    })
  }
}