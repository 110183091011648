import styled from '@emotion/styled'
import { Stack } from '@mui/material'
import React, { useState } from 'react'

// fileExtensions example: ".jpg, .png"
function FileUpload({ label, buttonText, fileExtensions, file, onChange, required = false }) {
  const [error, setError] = useState(null)

  const handleFileInput = (e) => {
    const newFile = e.target.files[0]
    onChange(null)
    setError(null)

    if (newFile.size > 10240 * 1024) {
      setError('File size cannot exceed more than 10MB')
      return
    }

    onChange(newFile)
  }

  return (
    <FileInput>
      <p>
        {label}
        {required && '*'}
      </p>
      <Stack useFlexGap gap={1} flexDirection={'row'}>
        <label>
          <ActionButton>
            {!file && (
              <>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4.33594 5.01562L7.5625 2.17188V10.8125C7.5625 11.0586 7.75391 11.25 8 11.25C8.21875 11.25 8.4375 11.0586 8.4375 10.8125V2.17188L11.6367 5.01562C11.7188 5.09766 11.8281 5.125 11.9375 5.125C12.0469 5.125 12.1562 5.09766 12.2383 4.98828C12.4023 4.82422 12.4023 4.52344 12.2109 4.38672L8.27344 0.886719C8.10938 0.722656 7.86328 0.722656 7.69922 0.886719L3.76172 4.38672C3.57031 4.52344 3.57031 4.79688 3.73438 4.98828C3.87109 5.17969 4.17188 5.17969 4.33594 5.01562ZM13.25 9.5H10.1875C9.94141 9.5 9.75 9.71875 9.75 9.9375C9.75 10.1836 9.94141 10.375 10.1875 10.375H13.25C13.7148 10.375 14.125 10.7852 14.125 11.25V13C14.125 13.4922 13.7148 13.875 13.25 13.875H2.75C2.25781 13.875 1.875 13.4922 1.875 13V11.25C1.875 10.7852 2.25781 10.375 2.75 10.375H5.8125C6.03125 10.375 6.25 10.1836 6.25 9.9375C6.25 9.71875 6.03125 9.5 5.8125 9.5H2.75C1.76562 9.5 1 10.293 1 11.25V13C1 13.9844 1.76562 14.75 2.75 14.75H13.25C14.207 14.75 15 13.9844 15 13V11.25C15 10.293 14.207 9.5 13.25 9.5ZM13.0312 12.125C13.0312 11.7695 12.7305 11.4688 12.375 11.4688C11.9922 11.4688 11.7188 11.7695 11.7188 12.125C11.7188 12.5078 11.9922 12.7812 12.375 12.7812C12.7305 12.7812 13.0312 12.5078 13.0312 12.125Z" />
                </svg>

                <span>{buttonText}</span>
              </>
            )}
            {file && <span>{file.name}</span>}
          </ActionButton>
          <input type="file" onChange={handleFileInput} accept={fileExtensions} />
        </label>
        {file && !required && (
          <ClearButton onClick={() => onChange(null)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443a40.83 40.83 0 0 0-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022l.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52l.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                clip-rule="evenodd"
              />
            </svg>
          </ClearButton>
        )}
      </Stack>
      {fileExtensions && (
        <InfoError error={error}>
          {error && error}
          {!error && `File format (${fileExtensions})`}
        </InfoError>
      )}
    </FileInput>
  )
}

const FileInput = styled.div`
  display: flex;
  flex-direction: column;
  & > input {
    display: none;
  }

  & > p {
    color: #132332;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`

const InfoError = styled.span`
  color: ${({ error }) => (error ? '#ff0000' : '#6e798c')};
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  margin-top: 8px;
`

const ActionButton = styled.div`
  border-radius: 4px;
  border: 1px solid #315a80;
  color: #315a80;
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  align-items: center;
  width: fit-content;
  min-width: 160px;
  max-width: 180px;
  overflow: hidden;
  transition: 200ms;

  &:hover {
    border: 1px solid #6589bc;
    color: #6589bc;
  }

  & > svg {
    width: 16px;
    height: 16px;
  }

  & > span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const ClearButton = styled.button`
  all: unset;
  background: #ef4444;
  color: white;
  width: fit-content;
  height: fit-content;
  transition: all 200ms;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f87171;
  }

  & > svg {
    width: 20px;
  }
`

export default FileUpload
