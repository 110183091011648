import React from 'react'
import { Box } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { IconData } from './IconData'

export const IconDataBadge = ({ name, icon, color }) => {
  return (
    <Box
      sx={{
        mr: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: color,
        borderRadius: '24px',
        py: 0.5,
        px: 2,
        mt: 1,
      }}
    >
      <IconData
        title={name}
        color={colorsByKey.utility}
        icon={icon}
        margin={1}
        useCustomIcon={true}
      />
    </Box>
  )
}
