export const GET_CHAT_LIST = 'GET_CHAT_LIST'
export const OPEN_CHAT = 'OPEN_CHAT'
export const CLOSE_CHAT = 'CLOSE_CHAT'
export const GET_CHAT = 'GET_CHAT'
export const VIEW_MESSAGES = 'VIEW_MESSAGES'
export const CREATE_NEW_CHAT = 'CREATE_NEW_CHAT'
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const DELETE_CHAT = 'DELETE_CHAT'
export const EDIT_MESSAGE = 'EDIT_MESSAGE'
