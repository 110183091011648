import styled from '@emotion/styled'
import { Skeleton } from '@mui/material'
import axiosInstance from 'api/axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'

function PaymentModal({
  open,
  subname,
  subPeriod,
  close,
  goNext,
  callbackPayment,
  clearCallbackPayment,
  source,
  openRenew,
  plusOption
}) {
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState(null)
  const [callbackDetails, setCallbackDetails] = useState({
    packageName: subname,
    period: subPeriod,
  })
  const transactionId = useRef(null)

  const timer = useRef(null)

  function thinkpayLoaded() {
    setLoading(false)
  }

  const handleClose = useCallback(() => {
    transactionId.current = null
    setCallbackDetails({})
    clearCallbackPayment()
    close()
  }, [clearCallbackPayment, close])

  const isPaid = useCallback(() => {
    axiosInstance
      .post(
        '/api/checkPaymentStatus',
        { transaction_id: transactionId.current },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        }
      )
      .then(({ data }) => {
        if (data.status === 'Paid') {
          clearTimeout(timer.current)
          timer.current = null
          if(source !== 'renewal') {
            goNext()
          }
          handleClose()
          return
        } else if (data.status === 'Pending') {
            const params = new URLSearchParams(window.location.search)
            const callbackPayment = params.get('callbackpayment')
            
            if (callbackPayment) {
              openRenew()
            }
        
          timer.current = setTimeout(isPaid, 5000)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [goNext, handleClose])

  const generateLink = useCallback(() => {
    const form = new FormData()
    form.append('package', subname)
    form.append('subscriptionPeriod', subPeriod)
    form.append('source', source)
    form.append('option', plusOption)

    axiosInstance
      .post('/api/thinkPayStart', form, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setUrl(data.url)
        transactionId.current = data.reference
        timer.current = setTimeout(isPaid, 5000)
      })
      .catch(() => {
        handleClose()
      })
  }, [handleClose, isPaid, subPeriod, subname])

  const getCallbackLink = useCallback(() => {
    transactionId.current = callbackPayment
    axiosInstance
      .post(
        '/api/getPaymentURL',
        { transaction_id: callbackPayment },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        }
      )
      .then(({ data }) => {
        setUrl(data.url)
        setCallbackDetails({
          packageName: data.package_name,
          period: data.frequency,
        })
        timer.current = setTimeout(isPaid, 5000)
      })
      .catch(() => {
        handleClose()
      })
  }, [callbackPayment, handleClose, isPaid])

  useEffect(() => {
    if (callbackPayment) {
      getCallbackLink()
    } else if (!callbackPayment && transactionId.current === null) {
      generateLink()
    }

    return () => {
      clearTimeout(timer.current)
      timer.current = null
      transactionId.current = null
    }
  }, [callbackPayment, generateLink, getCallbackLink])

  return (
    <Modal
      className={`package-min  ${callbackDetails.packageName}-pack`}
      show={open}
      onHide={handleClose}
      style={{ zIndex: 1500 }}
    >
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title>{callbackDetails.packageName}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading && (
          <SkeletonWrapper>
            <SkeletonTitle>
              <CustomSkeleton style={{ marginBottom: 10 }} variant="rect" width={120} height={40} />
              <CustomSkeleton style={{ alignSelf: 'end' }} variant="rect" width={65} height={27} />
            </SkeletonTitle>
            <SkeletonBody>
              <CustomSkeleton variant="rect" height={60} />
              <CustomSkeleton variant="rect" height={60} />
              <CustomSkeleton variant="rect" height={60} />
              <CustomSkeleton variant="rect" height={60} />
            </SkeletonBody>
          </SkeletonWrapper>
        )}
        {url && (
          <Iframe
            loading={loading}
            title="payment"
            className="border-0 w-100"
            onLoad={thinkpayLoaded}
            style={{ minHeight: 840 }}
            src={url}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

const Iframe = styled.iframe`
  display: ${({ loading }) => (loading ? 'none' : 'unset')};
`

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const SkeletonTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const SkeletonBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const CustomSkeleton = styled(Skeleton)`
  border-radius: 8px;
`

export default PaymentModal
