import React, { useState, useEffect } from 'react'
import { createContext } from 'react'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { PROFILE, companyProfile, advisorProfile } from 'entities/company'
import { apiGet } from 'api'
// import { normalizeCompanyData } from 'mixins/dataNormalizers'
import { FEED, COMPANY } from 'entities/routes'
import history from '../history'

const defaultValues = {
  functionValue: () => {},
  booleanValue: false,
  numberValue: 0,
  objectValue: {},
  arrayValue: [],
  dateValue: [null, null],
  stringValue: '',
}

const UserContext = createContext({
  isMyProfile: defaultValues.booleanValue,
  loadingUserData: defaultValues.booleanValue,
  userData: defaultValues.objectValue,
  sectionTab: defaultValues.stringValue,
  setSectionTab: defaultValues.functionValue,
  fetchUserData: defaultValues.functionValue,
})

export const UserContextProvider = (props) => {
  const { userDetail } = props
  let { id } = useParams()
  const profile = window.location.pathname.includes(COMPANY) ? companyProfile : advisorProfile

  const [sectionTab, setSectionTab] = useQueryParam('section', StringParam)

  const [userData, setUserData] = useState(defaultValues.objectValue)
  const [loadingUserData, setLoadingUserData] = useState(defaultValues.booleanValue)
  const [isMyProfile, setIsMyProfile] = useState(defaultValues.booleanValue)

  useEffect(() => {
    if (sectionTab == null) {
      setSectionTab(PROFILE)
    } else {
      setSectionTab(sectionTab)
    }
  }, [sectionTab, setSectionTab])

  const fetchUserData = async (id) => {
    setLoadingUserData(true)
    try {
      const { data } = await apiGet(`${profile.endpoint}/${id}`)
      const normalizedData = profile.dataNormalizer(data)
      setUserData(normalizedData)
      if (Number(id) === userDetail.id) setIsMyProfile(true)
    } catch (err) {
      history.push(FEED)
    } finally {
      setLoadingUserData(false)
    }
  }

  useEffect(() => {
    const fetchUserData = async (id) => {
      setLoadingUserData(true)
      try {
        const { data } = await apiGet(`${profile.endpoint}/${id}`)
        const normalizedData = profile.dataNormalizer(data)
        setUserData(normalizedData)
        if (Number(id) === userDetail.id) setIsMyProfile(true)
      } catch (err) {
        history.push(FEED)
      } finally {
        setLoadingUserData(false)
      }
    }
    fetchUserData(id)
  }, [id, profile, profile.endpoint, userDetail.id])

  return (
    <UserContext.Provider
      value={{
        isMyProfile,
        loadingUserData,
        userData,
        sectionTab,
        setSectionTab,
        fetchUserData,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
export default UserContext
