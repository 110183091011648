import React, { createRef } from 'react'
import axios from 'axios'

export default function AuthenticatedFile({ url, filename, linklabel }) {
  const link = createRef()

  const handleAction = async () => {
    if (link.current.href) {
      return
    }

    if (url.replace('show/', '') === '') {
      return
    }

    const file_api_url = process.env.REACT_APP_API_URL + '/api/protected' + url.replace('show/', '')
    const config = {
      responseType: 'blob',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
    }
    await axios.get(file_api_url, config).then((response) => {
      const blob = new File([response.data], filename)
      const href = window.URL.createObjectURL(blob)

      link.current.download = filename
      link.current.href = href

      link.current.click()
      link.current.removeAttribute('href')
    })
  }

  return (
    <>
      <a role="button" ref={link} target="_blank" onClick={handleAction}>
        {linklabel}
      </a>
    </>
  )
}
