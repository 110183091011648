import React from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import AskReview from '../component/review/askReview'
import GiveReview from '../component/review/giveReview'
import ListReview from '../component/review/listReviews'
import { withTranslation } from 'react-i18next'

class Review extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      participants: [],
      successmsg: null,
      error: null,
    }

    this.success_notify = React.createRef()
    this.error_notify = React.createRef()

    this.showSuccessMessage = this.showSuccessMessage.bind(this)
    this.showErrorMessage = this.showErrorMessage.bind(this)
  }

  showSuccessMessage(msg) {
    this.setState({ successmsg: msg })
    this.success_notify.current.handleOpen()
  }

  showErrorMessage(msg) {
    this.setState({ error: msg })
    this.error_notify.current.handleOpen()
  }

  async componentDidMount() {
    const api_url = process.env.REACT_APP_API_URL + '/api/listOfParticipants'
    await axios
      .get(api_url, { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
      .then((response) => {
        // handle success
        const { success } = response.data
        this.setState({
          participants: success,
          loading: false,
          referpage: this.props.match.params.type,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    const { participants, loading } = this.state

    if (loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div className="registration-min seller-details-min w-100 minheight-100 pb-5">
        <Helmet>
          <title>Reviews | BridgeWhat: Let's Grow!</title>
        </Helmet>
        <Container>
          <div className="boxshadow p-3 pt-1 pb-4">
            <GiveReview participants={participants} />
            <hr className="mb-4"></hr>
            <AskReview
              participants={participants}
              showSuccessMessage={(msg) => this.showSuccessMessage(msg)}
              showErrorMessage={(msg) => this.showErrorMessage(msg)}
            />
            <hr className="mb-4"></hr>
            <ListReview />
          </div>
        </Container>
        {/* TODO: Add notification error and successmsg */}
      </div>
    )
  }
}

export default withTranslation()(Review)
