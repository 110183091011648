import React from 'react'
import { Box, Typography } from '@mui/material'
import { colorsByKey } from 'theme/colors'

export const Tag = ({ text, customSx = {} }) => {
  return (
    <Box
      className="Tag"
      sx={{
        pb: 0.5,
        px: 1.5,
        pt: 0.5,
        mt: 0,
        mb: 2,
        background: colorsByKey.primaryBgWeak,
        width: 'fit-content',
        height: 'fit-content',
        textTransform: 'uppercase',
        borderRadius: '0 0 8px 8px',
        ...customSx,
      }}
    >
      {text && (
        <Typography
          variant="f10"
          sx={{
            color: colorsByKey.accentTextStrong,
            fontWeight: 'bold',
          }}
        >
          {text}
        </Typography>
      )}
    </Box>
  )
}
