import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Grid from '@material-ui/core/Grid'
import { confirmAlert } from 'react-confirm-alert'
import MuiPhoneNumberValidatorElement from './ErrorComponent/MuiPhoneNumberValidatorElement'
import countries from 'utils/countries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Textarea from '@material-ui/core/TextareaAutosize'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { withTranslation } from 'react-i18next'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { faCloudUploadAlt, faLink } from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  display: 'flex',
}

class SBAProfilepage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        company_legal_name: '',
        company_name: '',
        first_name: '',
        country: null,
        job_function: '',
        code: '',
        email: '',
        phone: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        youtube: '',
        website: '',
        bio: '',
        company_sector: null,
        payment_account_swift: '',
        payment_account_iban: '',
        state: '',
        city: '',
        postal_code: '',
        address1: '',
        services: [],
        vatnumber: '',
      },
      online_presence_name: '',
      services_selected: '',
      country_selected: '',
      company_sector_selected: '',
      policypage: '',
      submitted: false,
      showHide: false,
      countries: countries(),
      companysectors: [],
      growth: [],
      loading: true,
    }

    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onChangePhoto = this.onChangePhoto.bind(this)
    this.success_notify = React.createRef()
    this.error_notify = React.createRef()
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.removeValidationRule('isPhone')
    }
  }

  async componentDidMount() {
    if (!ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.addValidationRule('isPhone', (value) => {
        if (value === null || value.trim() === '' || value.trim() === '+') {
          return false
        }
        return true
      })
    }

    if (this.props.userDetail) {
      const success = this.props.userDetail
      const { formData } = this.state

      if ('user_services' in success) {
        if (success.user_services) {
          formData['services'] = success.user_services
          this.setState({ services_selected: '1' })
        }
      }

      if (success.company_sector) {
        formData['company_sector'] = success.company_sector
        this.setState({ company_sector_selected: '1' })
      }

      if (success.country) {
        formData['country'] = success.country
        this.setState({ country_selected: '1' })
      }

      formData['company_legal_name'] = success.company_legal_name
      formData['company_name'] = success.company_name
      formData['first_name'] = success.name
      formData['job_function'] = success.job_function
      formData['email'] = success.email
      formData['phone'] = success.phone_number
      formData['facebook'] = success.facebook
      formData['twitter'] = success.twitter
      formData['linkedin'] = success.linkedin
      formData['instagram'] = success.instagram
      formData['youtube'] = success.youtube
      formData['website'] = success.website
      formData['bio'] = success.bio
      formData['payment_account_swift'] = success.payment_account_swift
      formData['payment_account_iban'] = success.payment_account_iban
      formData['state'] = success.state
      formData['city'] = success.city
      formData['postal_code'] = success.postal_code
      formData['address1'] = success.address1
      formData['vatnumber'] = success.vatnumber

      if (success.photo !== null && success.photo !== '/show/') {
        formData['photo'] = success.photo
      }
      if (success.cv !== null && success.cv !== '/show/') {
        formData['cv'] = success.cv
      }

      this.setState({ formData })
    }

    const growth_api_url = process.env.REACT_APP_API_URL + '/api/services'
    await axios
      .get(growth_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ growth: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const sector_api_url = process.env.REACT_APP_API_URL + '/api/sectors'
    await axios
      .get(sector_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ companysectors: data, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })

    if (!ValidatorForm.hasValidationRule('isUrl')) {
      ValidatorForm.addValidationRule('isUrl', (value) => {
        if (value != null && value !== '') {
          var pattern = new RegExp(
            '((http|https)://)(www.)?' +
              '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
          )
          return !!pattern.test(value)
        }
        return true
      })
    }
  }

  onChange(e) {
    var files = e.target.files

    if (!files.length) return

    var currentfile = files[0]

    if (currentfile.type === 'image/png' || currentfile.type === 'image/jpeg') {
      let reader = new FileReader()
      reader.onload = (e) => {
        const { formData } = this.state
        formData['cv'] = e.target.result
        formData['cv_local'] = true
        this.setState({ formData })
      }
      reader.readAsDataURL(currentfile)
    } else {
      alert('Only image can be uploaded')
    }
  }

  confirmremoveCV() {
    const { formData } = this.state
    formData['cv'] = ''
    formData['remove_cv'] = 1
    this.setState({ formData })
  }

  removeCV() {
    confirmAlert({
      title: null,
      message: this.props.t('Are you sure you want to remove cv?'),
      buttons: [
        {
          label: this.props.t('No'),
          className: 'btn_no',
        },
        {
          label: this.props.t('Yes'),
          className: 'btn_yes',
          onClick: () => this.confirmremoveCV(),
        },
      ],
    })
  }

  onChangePhoto(e) {
    var files = e.target.files

    if (!files.length) return

    var currentfile = files[0]

    if (currentfile.type === 'image/png' || currentfile.type === 'image/jpeg') {
      let reader = new FileReader()
      reader.onload = (e) => {
        const { formData } = this.state
        formData['photo'] = e.target.result
        formData['photo_local'] = true
        this.setState({ formData })
      }
      reader.readAsDataURL(currentfile)
    } else {
      alert('Only image can be uploaded')
    }
  }

  confirmremovePhoto() {
    const { formData } = this.state
    formData['photo'] = ''
    formData['remove_photo'] = 1
    this.setState({ formData })
  }

  removePhoto() {
    confirmAlert({
      title: null,
      message: this.props.t('Are you sure you want to remove photo?'),
      buttons: [
        {
          label: this.props.t('No'),
          className: 'btn_no',
        },
        {
          label: this.props.t('Yes'),
          className: 'btn_yes',
          onClick: () => this.confirmremovePhoto(),
        },
      ],
    })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }
  handleCompanySector = (event, value) => {
    const { formData } = this.state
    formData['company_sector'] = value
    if (value !== null) {
      this.setState({ company_sector_selected: '1' })
    } else {
      this.setState({ company_sector_selected: '' })
    }
    this.setState({ formData })
  }

  handlePhoneChange(value) {
    const { formData } = this.state
    formData['phone'] = value
    this.setState({ formData })
  }

  handleCountry = (event, value) => {
    const { formData } = this.state
    formData['country'] = value
    if (value !== null) {
      this.setState({ country_selected: '1' })
    } else {
      this.setState({ country_selected: '' })
    }
    this.setState({ formData })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/updateSbaProfileInformation'

      axios
        .post(sector_api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          // handle success
          this.props.updateProfile()
          this.setState({
            submitted: false,
            successmsg: this.props.t('Changes updated successfully'),
          })
          this.success_notify.current.handleOpen()
        })
        .catch((error) => {
          if (error.response.status === 401) {
            const { data } = error.response
            if (data.error) {
              this.setState({
                submitted: false,
                error: data.error,
                errormsg: this.props.t('Something went wrong, please try again'),
              })
              this.error_notify.current.handleOpen()
            }
          } else {
            this.setState({
              submitted: false,
              errormsg: this.props.t('Something went wrong, please try again'),
            })
            this.error_notify.current.handleOpen()
          }
        })
    })
  }
  render() {
    const { t } = this.props
    const { formData, submitted, loading } = this.state

    if (loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
        <Row>
          <Col md="4">
            <div className="form-group">
              <label>
                {t('Contact Name')} <sup>*</sup>
              </label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="first_name"
                onChange={this.handleChange}
                value={formData.first_name}
                validators={['required']}
                errorMessages={t('This field is required')}
              />
            </div>
          </Col>
          <Col md="4">
            <div className="form-group">
              <label>
                {t('Email')} <sup>*</sup>
              </label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="email"
                onChange={this.handleChange}
                value={formData.email}
                validators={['required', 'isEmail']}
                errorMessages={[t('This field is required'), t('email is not valid')]}
              />
              {this.state.erroremail && <div className="error-msg">{this.state.erroremail}</div>}
            </div>
          </Col>
          <Col md="4">
            <div className="form-group">
              <label>
                {t('Phone Number')} <sup>*</sup>
              </label>
              <div className="w-100 phone_number_f">
                <MuiPhoneNumberValidatorElement
                  name="phone"
                  className="phone_number"
                  data-cy="user-phone"
                  value={formData.phone}
                  onChange={this.handlePhoneChange}
                  fullWidth
                  variant="outlined"
                  validators={['isPhone']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label>{t('Company Commercial Name')}</label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="company_name"
                onChange={this.handleChange}
                value={formData.company_name}
              />
            </div>
          </Col>

          <Col md="6">
            <div className="form-group">
              <label>
                {t('Job Function')} <sup>*</sup>
              </label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="job_function"
                onChange={this.handleChange}
                value={formData.job_function}
                validators={['required']}
                errorMessages={t('This field is required')}
              />
            </div>
          </Col>

          <Col md="12">
            <div className="form-group">
              <div className="border p-3 logoimg_insert">
                {formData.photo ? (
                  <>
                    <div className="removeFile" onClick={() => this.removePhoto()}>
                      X
                    </div>
                    {formData.photo_local ? (
                      <img src={formData.photo} alt="#" className="img-fluid" />
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: '250px',
                        }}
                      >
                        <Avatar
                          variant="square"
                          src={formData.photo}
                          style={{ width: 'auto', height: '100%', flex: 1, zIndex: 1 }}
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                    <span>{t('Insert photo')}</span>
                  </>
                )}
              </div>
              <div style={styles} className="form-group">
                <label className="custom-file-upload">
                  <input type="file" name="photo" accept="image/*" onChange={this.onChangePhoto} />
                  {t('Upload Photo')}&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faCloudUploadAlt} />
                </label>
              </div>
            </div>
          </Col>
          <Col md="12">
            <label>{t('Online Presence')}</label>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>
                Linkedin <sup>*</sup>
              </label>
              <Grid className="d-flex align-items-center online-presence-min">
                <Grid item className="online-presence-icon">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </Grid>
                <Grid item className="w-100">
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="linkedin"
                    onChange={this.handleChange}
                    placeholder={`Linkedin (${t('Insert full url')})`}
                    value={formData.linkedin}
                    validators={['required', 'isUrl']}
                    errorMessages={['This field is required', 'Invalid url']}
                  />
                </Grid>
              </Grid>
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>Facebook (optional)</label>
              <Grid className="d-flex align-items-center online-presence-min">
                <Grid item className="online-presence-icon">
                  <FontAwesomeIcon icon={faFacebookF} />
                </Grid>
                <Grid item className="w-100">
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="facebook"
                    onChange={this.handleChange}
                    placeholder={`Facebook (${t('Insert full url')})`}
                    value={formData.facebook}
                    validators={['isUrl']}
                    errorMessages={t('Invalid url')}
                  />
                </Grid>
              </Grid>
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>Youtube (optional)</label>
              <Grid className="d-flex align-items-center online-presence-min">
                <Grid item className="online-presence-icon">
                  <FontAwesomeIcon icon={faYoutube} />
                </Grid>
                <Grid item className="w-100">
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="youtube"
                    onChange={this.handleChange}
                    placeholder={`Youtube (${t('Insert full url')})`}
                    value={formData.youtube}
                    validators={['isUrl']}
                    errorMessages={t('Invalid url')}
                  />
                </Grid>
              </Grid>
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>Instagram (optional)</label>
              <Grid className="d-flex align-items-center online-presence-min">
                <Grid item className="online-presence-icon">
                  <FontAwesomeIcon icon={faInstagram} />
                </Grid>
                <Grid item className="w-100">
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="instagram"
                    onChange={this.handleChange}
                    placeholder={`Instagram (${t('Insert full url')})`}
                    value={formData.instagram}
                    validators={['isUrl']}
                    errorMessages={t('Invalid url')}
                  />
                </Grid>
              </Grid>
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>Twitter (optional)</label>
              <Grid className="d-flex align-items-center online-presence-min">
                <Grid item className="online-presence-icon">
                  <FontAwesomeIcon icon={faTwitter} />
                </Grid>
                <Grid item className="w-100">
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="twitter"
                    onChange={this.handleChange}
                    placeholder={`Twitter (${t('Insert full url')})`}
                    value={formData.twitter}
                    validators={['isUrl']}
                    errorMessages={t('Invalid url')}
                  />
                </Grid>
              </Grid>
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>Website (optional)</label>
              <Grid className="d-flex align-items-center online-presence-min">
                <Grid item className="online-presence-icon">
                  <FontAwesomeIcon icon={faLink} />
                </Grid>
                <Grid item className="w-100">
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    type="text"
                    name="website"
                    onChange={this.handleChange}
                    placeholder={`Website (${t('Insert full url')})`}
                    value={formData.website}
                    validators={['isUrl']}
                    errorMessages={t('Invalid url')}
                  />
                </Grid>
              </Grid>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="w-100">{t('BIO')}</label>
              <Textarea
                fullWidth
                variant="outlined"
                rowsMax={1}
                aria-label="maximum height"
                placeholder=""
                name="bio"
                onChange={this.handleChange}
                value={formData.bio}
                validators={['required']}
                errorMessages={t('This field is required')}
              />
            </div>
          </Col>
          <Col lg="12">
            <div className="form-group">
              <div className="border p-3 logoimg_insert">
                {formData.cv ? (
                  <>
                    <div className="removeFile" onClick={() => this.removeCV()}>
                      X
                    </div>
                    {formData.cv_local ? (
                      <img src={formData.cv} alt="#" className="img-fluid" />
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: '250px',
                        }}
                      >
                        <Avatar
                          variant="square"
                          src={formData.cv}
                          style={{ width: 'auto', height: '100%', flex: 1, zIndex: 1 }}
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                    <span>{t('Insert cv')}</span>
                  </>
                )}
              </div>

              <div style={styles} className="form-group">
                <label className="custom-file-upload">
                  <input type="file" name="cv" accept="image/*" onChange={this.onChange} />
                  {t('Upload CV')}&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faCloudUploadAlt} />
                </label>
              </div>
            </div>
          </Col>
          <Col lg="12">
            <div className="form-group">
              <label className="w-100">
                {t('Expertese Sector')} <sup>*</sup>
              </label>
              <Autocomplete
                options={this.state.companysectors}
                getOptionLabel={(option) => option.name}
                name="company_sector"
                selected={formData.company_sector}
                defaultValue={formData.company_sector}
                onChange={this.handleCompanySector}
                renderInput={(params) => (
                  <TextValidator
                    Select
                    {...params}
                    fullWidth
                    variant="outlined"
                    name="company_sector_selected"
                    placeholder="Company's sector"
                    value={this.state.company_sector_selected}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                )}
              />
            </div>
          </Col>
          <Col lg="12">
            <div className="form-group">
              <label className="w-100">
                {t('Experience in the list of Bridgewhat')} <sup>*</sup>
              </label>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={this.state.growth}
                name="services"
                selected={formData.services}
                defaultValue={formData.services}
                onChange={this.handleServices}
                validators={['required']}
                errorMessages={t('This field is required')}
                disableCloseOnSelect
                getOptionLabel={(option) => option.service_name}
                getOptionSelected={(option) => {
                  return formData.services.some((el) => el.service_name === option.service_name)
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.service_name}
                  </React.Fragment>
                )}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextValidator
                    Select
                    {...params}
                    variant="outlined"
                    name="services_selected"
                    placeholder="Select services"
                    value={this.state.services_selected}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                )}
              />
            </div>
          </Col>
          <Col lg="12">
            <h6>{t('Datailed individual/company Information for billing purposes')}</h6>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>
                {t('Company Legal Name')} <sup>*</sup>
              </label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="company_legal_name"
                onChange={this.handleChange}
                value={formData.company_legal_name}
                validators={['required']}
                errorMessages={t('This field is required')}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>{t('VAT Number')}</label>
              <TextValidator
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                margin="0"
                fullWidth
                type="text"
                name="vatnumber"
                value={formData.vatnumber}
              />
            </div>
          </Col>
          <Col lg="12">
            <div className="Form-group">
              <h6>{t('Payment Account Details')}</h6>
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>
                {t('IBAN')} <sup>*</sup>
              </label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="payment_account_iban"
                onChange={this.handleChange}
                value={formData.payment_account_iban}
                validators={['required']}
                errorMessages={t('This field is required')}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>
                {t('BIC/SWIFT')} <sup>*</sup>
              </label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="payment_account_swift"
                onChange={this.handleChange}
                value={formData.payment_account_swift}
                validators={['required']}
                errorMessages={t('This field is required')}
              />
            </div>
          </Col>
          <Col lg="12">
            <div className="Form-group">
              <h6>{t('Billing Address')}</h6>
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>
                {t('Address 1')} <sup>*</sup>
              </label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="address1"
                onChange={this.handleChange}
                value={formData.address1}
                validators={['required']}
                errorMessages={t('This field is required')}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>{t('Address 2 (Optional)')}</label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="address2"
                onChange={this.handleChange}
                value={formData.address2}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>
                {t('Postal Code')} <sup>*</sup>
              </label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="postal_code"
                onChange={this.handleChange}
                value={formData.postal_code}
                validators={['required']}
                errorMessages={t('This field is required')}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>{t('State (Optional)')}</label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="state"
                onChange={this.handleChange}
                value={formData.state}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>
                {t('City')} <sup>*</sup>
              </label>
              <TextValidator
                variant="outlined"
                margin="0"
                fullWidth
                type="text"
                name="city"
                onChange={this.handleChange}
                value={formData.city}
                validators={['required']}
                errorMessages={t('This field is required')}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form-group">
              <label>
                {t('Country')} <sup>*</sup>
              </label>
              <Autocomplete
                options={this.state.countries}
                getOptionLabel={(option) => option.name}
                name="country"
                defaultValue={formData.country}
                selected={formData.country}
                onChange={this.handleCountry}
                renderInput={(params) => (
                  <TextValidator
                    Select
                    {...params}
                    fullWidth
                    variant="outlined"
                    name="country_selected"
                    placeholder="Country"
                    value={this.state.country_selected}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <div className="col-md-12">
            <div className="form-group text-center">
              <Button type="submit" variant="contained" className="submit-btn" disabled={submitted}>
                {(submitted && t('Your form is submitted!')) || (!submitted && t('Save'))}
              </Button>
            </div>
          </div>
          {/* TODO: Add notification errormsg and successmsg */}
        </Row>
      </ValidatorForm>
    )
  }
}
export default withTranslation()(SBAProfilepage)
