import React from 'react'
import Container from 'react-bootstrap/Container'
import history from '../../history'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
class Welcome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  async componentDidMount() {
    const detail_api_url = process.env.REACT_APP_API_URL + '/api/paymentSuccessTaffiliate'
    await axios
      .get(detail_api_url, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      })
      .then((response) => {
        const { data } = response
        if (data.status === 'payment-done') {
          window.tap_purchasing(
            data.subscription_id,
            data.stripe_order_amount,
            data.stripe_customer_id
          )
        } else {
          window.tap_nopurchasing(data.stripe_customer_id)
        }

        this.setState({ loading: false })

        // setTimeout(function () {
        //   history.push('/profile')
        // }, 5000)
      })
      .catch((error) => {
        console.log(error)
        history.push('/')
      })
  }

  gotoprofile() {
    history.push('/profile')
  }

  render() {
    const { loading } = this.state
    const { t } = this.props

    if (loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div>
        <Helmet>
          <title>Welcome | BridgeWhat: Let's Grow!</title>
          <body className="welcomepage" />
        </Helmet>

        <div>
          <Container className="text-center pt-1 pb-1">
            <div className="welcome-div text-center">
              <img
                className="mb-4"
                style={{ maxWidth: '300px', width: '100%' }}
                src={require('assets/logo.png')}
                alt="BWS Logo"
              />
              <h1>{t('Registration Completed Successfully !')}</h1>
              <span className="pt-2">
                {t('You are now a BridgeWhat participant. Our growth journey starts now.')}
              </span>
              <div className="text-center">
                <Button
                  onClick={() => this.gotoprofile()}
                  type="button"
                  className="submit-btn ml-auto mr-auto mt-5 mb-0 border-0 MuiButton-root MuiButton-contained"
                >
                  {t('Let’s Grow')}
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Welcome)
