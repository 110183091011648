import React from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Textarea from '@material-ui/core/TextareaAutosize'
import MuiPhoneNumberValidatorElement from './ErrorComponent/MuiPhoneNumberValidatorElement'
import { withTranslation } from 'react-i18next'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCloudDownloadAlt,
  faPlusCircle,
  faMinusCircle,
  faAward,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons'
library.add(faCloudDownloadAlt, faPlusCircle, faAward, faTrophy)

class Profileformthree extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        user_id: 0,
        headline: '',
        headlines: '',
        about_company: '',
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        contact_job_function: '',
        company_another_email: '',
        company_another_phone: '',
        company_another_address: '',
        company_email_share_status: false,
        company_phone_share_status: false,
        company_address_share_status: false,
        contact_another_information: [],
      },
      submitted: false,
      loaded: false,
      error: null,
      successmsg: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.handleAnotherPhoneChange = this.handleAnotherPhoneChange.bind(this)

    this.success_notify = React.createRef()
    this.error_notify = React.createRef()
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.removeValidationRule('isPhone')
    }
  }

  async componentDidMount() {
    if (!ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.addValidationRule('isPhone', (value) => {
        if (value === null || value.trim() === '' || value.trim() === '+') {
          return false
        }
        return true
      })
    }

    if (this.props.userDetail) {
      const success = this.props.userDetail

      const formData = {
        user_id: success.id,
        headline: success.headline,
        headlines: success.headline,
        about_company: success.about_company,
        contact_name: success.contact_name,
        contact_email: success.contact_email,
        contact_phone: success.contact_phone,
        contact_job_function: success.contact_job_function,
      }

      if (success.contact_another_information !== null) {
        if ((success.contact_another_information || []).length > 0) {
          formData['contact_another_information'] = success.contact_another_information
        } else {
          formData['contact_another_information'] = []
        }
      } else {
        formData['contact_another_information'] = []
      }
      this.setState({ formData, loaded: true })
    }
  }

  handlePhoneChange(value) {
    const { formData } = this.state
    formData['contact_phone'] = value
    this.setState({ formData })
  }

  handleAnotherPhoneChange(value) {
    const { formData } = this.state
    formData['company_another_phone'] = value
    this.setState({ formData })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handleChecked = (event) => {
    if (event.target.checked) {
      const { formData } = this.state
      formData[event.target.name] = 1
      this.setState({ formData })
    } else {
      const { formData } = this.state
      formData[event.target.name] = 0
      this.setState({ formData })
    }
  }

  addOnlinePresense() {
    const { formData } = this.state
    const { contact_another_information } = formData
    formData['contact_another_information'] = [
      ...contact_another_information,
      { another_name: '', another_job_function: '', another_email: '', another_phone: '' },
    ]
    this.setState({ formData })
  }

  removeOnlinePresense(i) {
    const { formData } = this.state
    const { contact_another_information } = formData
    const contact_another_information_list = [...contact_another_information]
    contact_another_information_list.splice(i, 1)

    formData['contact_another_information'] = contact_another_information_list

    this.setState({ formData })
  }

  handleChangePresence(e, i) {
    const { name, value } = e.target
    const { formData } = this.state
    formData['contact_another_information'][i][name] = value
    this.setState({ formData })
  }
  handleChangePresencePhone(value, i) {
    const { formData } = this.state
    formData['contact_another_information'][i]['another_phone'] = value
    this.setState({ formData })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/updateCompanyInformation2'
      const { formData } = this.state

      if (formData['company_another_phone'] === '+') {
        formData['company_another_phone'] = ''
      }

      formData['headlines'] = formData['headline']

      axios
        .post(sector_api_url, formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          this.props.updateProfile()
          this.setState({
            submitted: false,
            successmsg: this.props.t('Changes updated successfully'),
          })
          this.success_notify.current.handleOpen()
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({
                  submitted: false,
                  error: this.props.t('Something went wrong, please try again'),
                })
                this.error_notify.current.handleOpen()
              }
            } else {
              this.setState({
                submitted: false,
                error: this.props.t('Something went wrong, please try again'),
              })
              this.error_notify.current.handleOpen()
            }
          }
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state

    if (!this.state.loaded) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div>
        <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
          <Row>
            <Col md="12">
              <div className="form-group">
                <label className="w-100">{t('Headline')}</label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="headline"
                  onChange={this.handleChange}
                  value={formData.headline}
                  validators={['required', 'maxStringLength:120']}
                  errorMessages={[t('This field is required'), t('Max length allowed') + ': 120']}
                  maxLength={120}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label className="w-100">{t('About your company')}</label>
                <Textarea
                  variant="outlined"
                  onChange={this.handleChange}
                  name="about_company"
                  value={formData.about_company}
                  aria-label="textarea"
                />
              </div>
            </Col>
            <Col md="12" className="pb-4 pt-4">
              <strong className="font-weight-normal">
                {t(
                  'Contact information - To be shared with all BridgeWhat Silver and up Participants, in the Scoring Board'
                )}
              </strong>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Name')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="contact_name"
                  onChange={this.handleChange}
                  value={formData.contact_name}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Job Function')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="contact_job_function"
                  onChange={this.handleChange}
                  value={formData.contact_job_function}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Email')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="contact_email"
                  onChange={this.handleChange}
                  value={formData.contact_email}
                  validators={['required', 'isEmail']}
                  errorMessages={[t('This field is required'), t('email is not valid')]}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Phone')} <sup>*</sup>
                </label>
                <div className="w-100 phone_number_f">
                  <MuiPhoneNumberValidatorElement
                    name="contact_phone"
                    className="phone_number"
                    data-cy="user-phone"
                    value={formData.contact_phone}
                    onChange={this.handlePhoneChange}
                    fullWidth
                    variant="outlined"
                    validators={['isPhone']}
                    errorMessages={t('This field is required')}
                  />
                </div>
              </div>
            </Col>
          </Row>

          {formData.contact_another_information &&
            formData.contact_another_information.map((anothercontact, index) => (
              <Row
                className="align-items-center"
                style={{ borderTop: '1px solid #ccc', padding: '15px 0', margin: '0' }}
              >
                <Col md="6" style={{ paddingLeft: 0 }}>
                  <div className="form-group">
                    <label>
                      {t('Name')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      onChange={(e) => this.handleChangePresence(e, index)}
                      value={anothercontact.another_name}
                      name="another_name"
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </Col>
                <Col md="6" style={{ paddingRight: 0 }}>
                  <div className="form-group">
                    <label>
                      {t('Job Function')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      onChange={(e) => this.handleChangePresence(e, index)}
                      value={anothercontact.another_job_function}
                      name="another_job_function"
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </Col>
                <Col md="6" style={{ paddingLeft: 0 }}>
                  <div className="form-group">
                    <label>
                      {t('Email')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      onChange={(e) => this.handleChangePresence(e, index)}
                      value={anothercontact.another_email}
                      name="another_email"
                      validators={['required', 'isEmail']}
                      errorMessages={[t('This field is required'), t('email is not valid')]}
                    />
                  </div>
                </Col>
                <Col md="5" style={{ paddingRight: 0 }}>
                  <div className="form-group">
                    <label>
                      {t('Phone')} <sup>*</sup>
                    </label>
                    <div className="w-100 phone_number_f">
                      <MuiPhoneNumberValidatorElement
                        className="phone_number"
                        data-cy="user-phone"
                        onChange={(value) => this.handleChangePresencePhone(value, index)}
                        value={anothercontact.another_phone}
                        fullWidth
                        variant="outlined"
                        validators={['isPhone']}
                        errorMessages={t('This field is required')}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="1">
                  <div className="form-group remove-presence-p">
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      onClick={() => this.removeOnlinePresense(index)}
                    />
                  </div>
                </Col>
              </Row>
            ))}

          <Row>
            <Col md="12" className="person-presence-min d-flex mb-2 mt-3">
              <FontAwesomeIcon icon={faPlusCircle} onClick={() => this.addOnlinePresense()} />
            </Col>
          </Row>

          <Row>
            <div className="col-12 text-center">
              <Button type="submit" variant="contained" className="submit-btn" disabled={submitted}>
                {(submitted && t('Your form is submitted!')) || (!submitted && t('Save'))}
              </Button>
            </div>
          </Row>
        </ValidatorForm>
        {/* TODO: Add notification error and successmsg */}
      </div>
    )
  }
}
export default withTranslation()(Profileformthree)
