import React from 'react'
import history from '../history'
import { LOGOUT, DETAILS } from 'entities/routes'
import { apiGet } from 'api'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import RegistrationStep1 from '../component/registrationform/registrationstep1/registrationstep1'
import RegistrationStep2 from '../component/registrationform/registrationstep2/registrationstep2'
import SubscriptionPackage from '../component/registrationform/subscriptionpackage/subscriptionpackage'
import { normalizeUserData } from 'mixins/dataNormalizers'

class CompleteUserRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userDetail: null,
      registerStep: 0,
      loading: true,
      subscription: 0,
    }

    this.nextStep = this.nextStep.bind(this)
    this.prevStep = this.prevStep.bind(this)
    this.cancelProcess = this.cancelProcess.bind(this)
  }

  prevStep(value) {
    if (this.state.registerStep > 1) {
      this.setState({ registerStep: value })
    }
  }

  cancelProcess() {
    history.push('/logout')
  }
  async nextStep(value) {
    if (value) {
      await apiGet(DETAILS)
        .then(({ data }) => {
          this.setState({ userDetail: normalizeUserData(data), registerStep: value })
        })
        .catch((error) => {
          history.push(LOGOUT)
        })
    }
  }

  componentWillMount() {
    let query = queryString.parse(this.props.location.search)

    if (query.subscription) {
      this.setState({ subscription: query.subscription })
    }
  }

  componentDidMount() {
    if (this.props.userDetail) {
      this.setState({
        registerStep: this.props.userDetail.step || 1,
        userDetail: this.props.userDetail,
      })
    }
  }

  render() {
    const { registerStep, userDetail } = this.state

    return (
      <div className="registration-min w-100 pb-2 pt-5">
        <Helmet>
          <title>Registration Form | BridgeWhat: Let's Grow!</title>
        </Helmet>
        <div className="formstep col-12  pb-5">
          <ul>
            <li>{registerStep === 1 ? <span className="active-step">1</span> : <span>1</span>}</li>
            <li>{registerStep === 2 ? <span className="active-step">2</span> : <span>2</span>}</li>
            <li>{registerStep === 3 ? <span className="active-step">3</span> : <span>3</span>}</li>
          </ul>
        </div>
        <div className="d-flex">
          <Container>
            {registerStep === 1 && (
              <SubscriptionPackage
                userDetail={userDetail}
                prevStep={(val) => this.prevStep(val)}
                nextStep={(val) => this.nextStep(val)}
                subscription={this.state.subscription}
                isUpdateSubscription={false}
              />
            )}
            {registerStep === 2 && (
              <RegistrationStep1
                userDetail={userDetail}
                prevStep={(val) => this.prevStep(val)}
                nextStep={(val) => this.nextStep(val)}
                cancelProcess={() => this.cancelProcess()}
              />
            )}
            {registerStep === 3 && (
              <RegistrationStep2
                userDetail={userDetail}
                prevStep={(val) => this.prevStep(val)}
                nextStep={(val) => this.nextStep(val)}
              />
            )}
          </Container>
        </div>
      </div>
    )
  }
}
export default CompleteUserRegister
