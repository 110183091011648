import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import store from './store'
import './assets/fonts/JennaSue.ttf'
import 'bootstrap/dist/css/bootstrap.css'
import './App.css'
import { Switch } from 'react-router-dom'
import './services/i18n/i18n'

/*== Utils ==*/
import { theme } from './theme/theme'
import { ThemeProvider } from '@mui/material/styles'
import { Helmet } from 'react-helmet'

/***************************************/
/*== New Development ==*/

/*== Layouts ==*/
import NewLayout from './layouts/NewLayout'
import NewAppRoute from './layouts/NewAppRoute'
/*== Screens ==*/
import FeedScreen from 'screens/Feed/FeedScreen'
import User from './screens/User/UserScreen'
import PostEditor from './screens/PostEditor/PostEditorScreen'
import BlogDetailScreen from './screens/Blog/BlogDetailScreen'
import IWSDetailScreen from './screens/iwts/IWSDetailScreen'
import CredentialsScreen from './screens/Credentials/CredentialsScreen'
import CompleteRegistrationScreen from './screens/Auth/CompleteRegistrationScreen'
import CampaignDetailScreen from './screens/Campaign/CampaignDetailScreen'

/***************************************/
/*== Old Development ==*/

/*== Layouts ==*/
// import AppRoute from './layouts/AppRoute'
import GuestOnlyRoute from './layouts/GuestOnlyRoute'
import PrivateUserRoute from './layouts/PrivateUserRoute'
import RegisterSbaRoute from './layouts/RegisterSbaRoute'
import RegisterUserRoute from './layouts/RegisterUserRoute'
import NewLayoutForPublic from './layouts/NewLayoutForPublic'
/*== Screens ==*/
import Profile from './pages/profile'
import CreateAccount from './pages/createaccount/createaccount'
import Forgotpassword from './pages/forgot-password'
import Login from './pages/login'
import Updatepassword from './pages/update-password'
import Changepassword from './pages/change-password'
import Welcome from './pages/welcome/welcome'
import Awardspage from './pages/awards'
import Certificationspage from './pages/certifications'
import ReferralPage from './pages/referral'
import Givetestimonial from './pages/give-testimonial'
import Review from './pages/review'
import Givereview from './pages/give-review'
import SinglePage from './pages/singlepage'
import Logout from './pages/logout'
import Banktransfer from './pages/bank-transfer'
import Sbapaymentinformation from './pages/sba-payment-information'
import CompleteUserRegister from './pages/CompleteUserRegister'
import Paymentnotsusscess from './pages/payment-not-susscess/payment-not-susscess'
import CompleteSbaRegister from './pages/CompleteSbaRegister'

import appVersion from './app.version'

import { FEED, PROFILE, COMPANY, ADVISOR } from 'entities/routes'
import CreateParnership from 'screens/Partnerships/create'
import PartnershipView from 'screens/Partnerships/view'
import CreateExpo from 'screens/Expos/create'
import ExpoView from 'screens/Expos/view'
import CreateAccelerator from 'screens/Accelerators/create'
import AcceleratorView from 'screens/Accelerators/view'

const NoMatchPage = () => {
  return (
    <div className="text-center minheight-100 mt-3">
      <h1>Page not found</h1>
    </div>
  )
}
const InactivatedPage = () => {
  return (
    <div className="text-center minheight-100 mt-3">
      <h1>Ups...</h1>
      <p>It seems that there is a problem with your subscription.</p>
      <p>Please contact via client@bridgewhat.com</p>
    </div>
  )
}

function App() {
  console.log(`%c App version: ${appVersion} `, 'background: #222; color: #bada55')

  return (
    <Provider store={store}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Helmet>
            <title>{"BridgeWhat: Let's Grow! "}</title>
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content={'post'} />
            <meta property="og:title" content={"BridgeWhat: Let's Grow!"} />
            <meta
              property="og:description"
              content={
                'BridgeWhat offers you a community, credentials, connections and counselling to accelerate your sales growth!'
              }
            />
            <NewLayout path={`/campaign/:id`} component={CampaignDetailScreen} />
            <NewLayout path={`/partnership/:id`} component={PartnershipView} />
            <NewLayout path={`/accelerator/:id`} component={AcceleratorView} />
            <NewLayout path={`/expo/:id`} component={ExpoView} />
            <meta property="og:image" content={'%PUBLIC_URL%/logo.png'} />
          </Helmet>
          <Suspense fallback={null}>
            <Switch>
              {/* New Development */}
              <NewLayout exact path={FEED} component={FeedScreen} />
              <NewLayout exact path={`${COMPANY}/:id`} component={User} />
              <NewLayout exact path={`${ADVISOR}/:id`} component={User} />
              <NewLayout path={`/create/partnerships`} component={CreateParnership} />
              <NewLayout path={`/create/accelerators`} component={CreateAccelerator} />
              <NewLayout path={`/create/expos`} component={CreateExpo} />
              <NewLayout path={`/create/:entity`} component={PostEditor} />
              <NewLayout path={`/edit/expo/:id`} component={CreateExpo} />
              <NewLayout path={`/edit/:entity/:id`} component={PostEditor} />
              <NewLayoutForPublic path={`/post/:id`} component={BlogDetailScreen} />
              <NewLayoutForPublic path={`/iwsb/:type/:id`} component={IWSDetailScreen} />
              <NewLayoutForPublic path={`/iwsb/:type/:id`} component={IWSDetailScreen} />
              <NewLayout exact path="/credentials/:section" component={CredentialsScreen} />
              <NewLayout
                path={`/complete-registration/:entity/:id`}
                component={CompleteRegistrationScreen}
              />
              <NewLayout path={`/campaign/:id`} component={CampaignDetailScreen} />
              <NewLayout path={`/partnership/:id`} component={PartnershipView} />
              <NewLayout path={`/expo/:id`} component={ExpoView} />

              {/* Old Development */}
              <NewLayout path={PROFILE} component={Profile} />
              <GuestOnlyRoute exact path="/createaccount" component={CreateAccount} />
              <GuestOnlyRoute exact path="/forgot-password" component={Forgotpassword} />
              <GuestOnlyRoute exact path="/" component={Login} />
              <GuestOnlyRoute exact path="/update-password" component={Updatepassword} />
              <PrivateUserRoute
                exact
                path="/change-password"
                accessRole="1"
                component={Changepassword}
              />
              <NewAppRoute exact path="/welcome" component={Welcome} hideInnerHeader />
              <NewLayout exact path="/awards" component={Awardspage} />
              <NewLayout exact path="/certifications" component={Certificationspage} />
              <NewLayout exact path="/referrals" accessRole="1" component={ReferralPage} />
              <NewLayout exact path="/give/guest-referral/:refercode" component={Givetestimonial} />
              <NewLayout
                exact
                path="/give/referral/:refercode"
                accessRole="1"
                component={Givetestimonial}
              />
              <NewLayout exact path="/reviews" accessRole="1" component={Review} />
              <NewLayout exact path="/give/review/:refercode" accessRole="1" component={Givereview} />
              <NewAppRoute exact path="/page/:slug" component={SinglePage} hideInnerHeader />
              <NewAppRoute exact path="/logout" component={Logout} hideInnerHeader />
              <NewLayout exact path="/bank-transfer" hideInnerHeader component={Banktransfer} />
              <NewAppRoute
                exact
                path="/account-inactive"
                hideInnerHeader
                component={InactivatedPage}
              />

              {/* IS THIS ON USE? */}
              <PrivateUserRoute
                exact
                path="/sba-payment-information"
                accessRole="2"
                component={Sbapaymentinformation}
              />
              <RegisterSbaRoute
                exact
                path="/complete-sba-registration"
                component={CompleteSbaRegister}
              />
              <RegisterUserRoute
                exact
                path="/complete-participant-registration"
                component={CompleteUserRegister}
              />
              <RegisterUserRoute exact path="/payment-not-success" component={Paymentnotsusscess} />
              <NewAppRoute component={NoMatchPage} hideInnerHeader />
            </Switch>
          </Suspense>
        </ThemeProvider>
      </div>
    </Provider>
  )
}

export default App
