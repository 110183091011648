import React from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { confirmAlert } from 'react-confirm-alert'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MuiPhoneNumberValidatorElement from './ErrorComponent/MuiPhoneNumberValidatorElement'
import { library } from '@fortawesome/fontawesome-svg-core'
import countries from 'utils/countries'
import { withTranslation } from 'react-i18next'
import {
  faEuroSign,
  faCloudUploadAlt,
  faPlusCircle,
  faAward,
  faTrophy,
  faLink,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import { COMPANY_SIZES, COMPANY_REVENUES } from 'mixins/filters'
import { Avatar } from '@mui/material'

library.add(
  faEuroSign,
  faCloudUploadAlt,
  faPlusCircle,
  faAward,
  faTrophy,
  faLink,
  faLink,
  faPlusCircle,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faInstagram
)
const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  display: 'flex',
}

class Profileform extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        user_id: 0,
        last_year_turnover: '',
        no_of_emplyees: '',
        company_name: '',
        company_email: '',
        company_phone: '',
        company_logo: '',
        country_of_operation: [],
        company_sector: '',
        company_legal_name: '',
        payment_account_swift: '',
        payment_account_iban: '',
        state: '',
        postal_code: '',
        address1: '',
        address2: '',
        city: '',
        vatnumber: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        country: '',
        youtube: '',
        website: '',
      },
      getcompany_sector: null,
      country_of_selected: '',
      submitted: false,
      country_of_operation_selected: '',
      companySizes: COMPANY_SIZES,
      companyRevenues: COMPANY_REVENUES,
      company_sector_selected: '',
      country_selected: '',
      countries: countries(),
      companysectors: [],
      files: [],
      loaded: false,
      error: [],
      errormsg: null,
      successmsg: null,
    }

    this.onChange = this.onChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.confirmremovefile = this.confirmremovefile.bind(this)
    this.removefile = this.removefile.bind(this)

    this.success_notify = React.createRef()
    this.error_notify = React.createRef()
  }
  onChange(e) {
    var files = e.target.files

    if (!files.length) return

    var currentfile = files[0]

    if (currentfile.type === 'image/png' || currentfile.type === 'image/jpeg') {
      let reader = new FileReader()
      reader.onload = (e) => {
        const { formData } = this.state
        formData['company_logo'] = e.target.result
        formData['company_logo_local'] = true
        this.setState({ formData })
      }
      reader.readAsDataURL(currentfile)
    } else {
      alert('Only image can be uploaded')
    }
  }

  confirmremovefile() {
    const { formData } = this.state
    formData['company_logo'] = ''
    formData['remove_logo'] = 1
    this.setState({ formData })
  }

  removefile() {
    confirmAlert({
      title: null,
      message: this.props.t('Are you sure you want to remove logo?'),
      buttons: [
        {
          label: this.props.t('No'),
          className: 'btn_no',
        },
        {
          label: this.props.t('Yes'),
          className: 'btn_yes',
          onClick: () => this.confirmremovefile(),
        },
      ],
    })
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule('isUrl')) {
      ValidatorForm.removeValidationRule('isUrl')
    }
    if (ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.removeValidationRule('isPhone')
    }
    if (ValidatorForm.hasValidationRule('isTurnover')) {
      ValidatorForm.removeValidationRule('isTurnover')
    }
  }

  async componentDidMount() {
    if (!ValidatorForm.hasValidationRule('isUrl')) {
      ValidatorForm.addValidationRule('isUrl', (value) => {
        if (value != null && value !== '') {
          var pattern = new RegExp(
            '((http|https)://)(www.)?' +
              '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
          )
          return !!pattern.test(value)
        }
        return true
      })
    }

    if (!ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.addValidationRule('isPhone', (value) => {
        if (value === null || value.trim() === '' || value.trim() === '+') {
          return false
        }
        return true
      })
    }

    if (!ValidatorForm.hasValidationRule('isTurnover')) {
      ValidatorForm.addValidationRule('isTurnover', (value) => {
        if (value === null || value.trim() === '') {
          return false
        }
        return true
      })
    }

    if (this.props.userDetail) {
      const success = this.props.userDetail
      const formData = {
        user_id: success.id,
        last_year_turnover: success.last_year_turnover,
        no_of_emplyees: success.no_of_emplyees,
        company_name: success.company_name,
        company_email: success.company_email,
        company_phone: success.company_phone,
        company_logo: success.company_logo,
        country_of_operation: [],
        company_sector: success.company_sector,
        company_legal_name: success.company_legal_name,
        payment_account_swift: success.payment_account_swift,
        payment_account_iban: success.payment_account_iban,
        state: success.state,
        postal_code: success.postal_code,
        address1: success.address1,
        address2: success.address2,
        city: success.city,
        country: success.country,
        vatnumber: success.vatnumber,
        facebook: success.facebook,
        twitter: success.twitter,
        linkedin: success.linkedin,
        instagram: success.instagram,
        youtube: success.youtube,
        website: success.website,
      }

      if ('user_country_of_operations' in success) {
        formData['country_of_operation'] = success.user_country_of_operations
        this.setState({ country_of_operation_selected: '1' })
      }

      this.setState({ formData })
    }

    const sector_api_url = process.env.REACT_APP_API_URL + '/api/sectors'
    await axios
      .get(sector_api_url)
      .then(async (response) => {
        const { data } = response.data
        this.setState({ companysectors: data, loaded: true })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handlePhoneChange(value) {
    const { formData } = this.state
    formData['company_phone'] = value
    this.setState({ formData })
  }
  handleCountryOperation = (event, value) => {
    const { formData } = this.state
    formData['country_of_operation'] = value
    if (value.length > 0) {
      this.setState({ country_of_operation_selected: '1' })
    } else {
      this.setState({ country_of_operation_selected: '' })
    }
    this.setState({ formData })
  }
  handleCompanySize = (event, value) => {
    const { formData } = this.state
    formData['no_of_emplyees'] = value.key
    this.setState({ formData })
  }
  handleCompanyRevenue = (event, value) => {
    const { formData } = this.state
    formData['last_year_turnover'] = value.key
    this.setState({ formData })
  }
  handleCompanyIndustrySector = (event, value) => {
    const { formData } = this.state
    formData['company_sector'] = value.id
    this.setState({ formData })
  }
  handleCountry = (event, value) => {
    const { formData } = this.state
    formData['country'] = value.id
    this.setState({ formData })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  addOnlinePresense() {
    const { formData } = this.state
    const { online_presence } = formData
    formData['online_presence'] = [...online_presence, { level: '', value: '' }]
    this.setState({ formData })
  }

  removeOnlinePresense(i) {
    console.log(i)
    const { formData } = this.state
    const { online_presence } = formData
    const online_presence_list = [...online_presence]
    online_presence_list.splice(i, 1)

    if (online_presence_list.length === 0) {
      formData['online_presence'] = [{ level: '', value: '' }]
    } else {
      formData['online_presence'] = online_presence_list
    }

    this.setState({ formData })
  }

  handleChangePresence(e, i) {
    const { name, value } = e.target
    const { formData } = this.state
    formData['online_presence'][i][name] = value
    this.setState({ formData })
  }

  getOptionLabel = (item, options, key) => {
    const value =
      typeof item === 'string' || typeof item === 'number'
        ? options.find((s) => s[key] == item)
        : item
    return (value || {}).name || ''
  }

  getOptionSelected = (item, option, options, key) => {
    const valueId = typeof item === 'string' || typeof item === 'number' ? item : (item || {})[key]
    return valueId == option[key]
  }

  getSelection = (value, options, key) => {
    return options.find((s) => s[key] == value)
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/updateCompanyInformation'

      const { formData } = this.state
      formData['company_sector'] = this.getSelection(
        formData.company_sector,
        this.state.companysectors,
        'id'
      )
      formData['country'] = this.getSelection(formData.country, this.state.countries, 'id')

      axios
        .post(sector_api_url, formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          // handle success
          this.props.updateProfile()
          this.setState({
            submitted: false,
            successmsg: this.props.t('Changes updated successfully'),
          })
          this.success_notify.current.handleOpen()
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({
                  submitted: false,
                  error: data.error,
                  errormsg: this.props.t('Something went wrong, please try again'),
                })
                this.error_notify.current.handleOpen()
              }
            } else {
              this.setState({
                submitted: false,
                errormsg: this.props.t('Something went wrong, please try again'),
              })
              this.error_notify.current.handleOpen()
            }
          }
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state

    if (!this.state.loaded) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div>
        <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
          <Row>
            <Col md="12">
              <div className="form-group">
                <label>
                  {t('Company Commercial Name')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="company_name"
                  onChange={this.handleChange}
                  value={formData.company_name}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Company Email')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="company_email"
                  onChange={this.handleChange}
                  value={formData.company_email}
                  validators={['required', 'isEmail']}
                  errorMessages={[t('This field is required'), t('email is not valid')]}
                />
                {this.state.error.company_email && (
                  <div className="error-msg">{this.state.error.company_email}</div>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Company Phone')} <sup>*</sup>
                </label>
                <div className="w-100 phone_number_f">
                  <MuiPhoneNumberValidatorElement
                    name="company_phone"
                    className="phone_number"
                    data-cy="user-phone"
                    value={formData.company_phone}
                    onChange={this.handlePhoneChange}
                    fullWidth
                    variant="outlined"
                    validators={['isPhone']}
                    errorMessages={t('This field is required')}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="form-group">
                <label>{t('Logo Upload')}</label>
                <div className="border p-3 logoimg_insert">
                  {this.state.formData.company_logo ? (
                    <>
                      <div
                        className="removeFile"
                        style={{ zIndex: 2 }}
                        onClick={() => this.removefile()}
                      >
                        X
                      </div>
                      {this.state.formData.company_logo_local ? (
                        <img src={this.state.formData.company_logo} alt="#" className="img-fluid" />
                      ) : (
                        <Avatar
                          alt="company logo"
                          variant="square"
                          src={`${process.env.REACT_APP_API_URL}${this.state.formData.company_logo}`}
                          sx={{
                            width: '100%',
                            height: '100%',
                            zIndex: 1,
                            '& img': { maxWidth: '100% !important', height: 'auto !important' },
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faCloudUploadAlt} />
                      <span>{t('Insert Logo')}</span>
                    </div>
                  )}
                </div>
                <div style={styles} className="form-group">
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      name="company_logo"
                      accept="image/*"
                      onChange={this.onChange}
                    />
                    {t('Logo Upload')}&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                  </label>
                </div>
              </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label>
                  {t('Company Sector')} <sup>*</sup>
                </label>
                <Autocomplete
                  options={this.state.companysectors}
                  getOptionLabel={(option) =>
                    this.getOptionLabel(option, this.state.companysectors, 'id')
                  }
                  getOptionSelected={(option) =>
                    this.getOptionSelected(
                      formData.company_sector,
                      option,
                      this.state.companysectors,
                      'id'
                    )
                  }
                  name="company_sector"
                  selected={formData.company_sector}
                  defaultValue={formData.company_sector}
                  onChange={this.handleCompanyIndustrySector}
                  renderInput={(params) => (
                    <TextValidator
                      Select
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="company_sector"
                      placeholder="Company sector"
                      value={formData.company_sector}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="pt-2">
              <h6>{t('Detailed Company Information')}</h6>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label>{t('VAT Number')} (e.g. PT51... or ESB...)</label>
                <TextValidator
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="0"
                  fullWidth
                  type="text"
                  name="vatnumber"
                  value={formData.vatnumber}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label>
                  {t('Company Legal Name')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="company_legal_name"
                  onChange={this.handleChange}
                  value={formData.company_legal_name}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label>
                  {t('Last Year Turnover')} <sup>*</sup>
                </label>
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faEuroSign} />
                  </Grid>
                  <Grid item className="w-100">
                    <Autocomplete
                      options={this.state.companyRevenues}
                      getOptionLabel={(option) =>
                        this.getOptionLabel(option, this.state.companyRevenues, 'key')
                      }
                      getOptionSelected={(option) =>
                        this.getOptionSelected(
                          formData.last_year_turnover,
                          option,
                          this.state.companyRevenues,
                          'key'
                        )
                      }
                      name="last_year_turnover"
                      selected={formData.last_year_turnover}
                      defaultValue={formData.last_year_turnover}
                      onChange={this.handleCompanyRevenue}
                      renderInput={(params) => (
                        <TextValidator
                          Select
                          {...params}
                          fullWidth
                          variant="outlined"
                          name="last_year_turnover"
                          placeholder="Company revenue"
                          value={formData.last_year_turnover}
                          validators={['required']}
                          errorMessages={t('This field is required')}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label>
                  {t('Number of employees')} <sup>*</sup>
                </label>
                <Autocomplete
                  options={this.state.companySizes}
                  getOptionLabel={(option) =>
                    this.getOptionLabel(option, this.state.companySizes, 'key')
                  }
                  getOptionSelected={(option) =>
                    this.getOptionSelected(
                      formData.no_of_emplyees,
                      option,
                      this.state.companySizes,
                      'key'
                    )
                  }
                  name="no_of_emplyees"
                  selected={formData.no_of_emplyees}
                  defaultValue={formData.no_of_emplyees}
                  onChange={this.handleCompanySize}
                  renderInput={(params) => (
                    <TextValidator
                      Select
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="no_of_emplyees"
                      placeholder="Company size"
                      value={formData.no_of_emplyees}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  )}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label>
                  {t('Country of Operation')} <sup>*</sup>
                </label>
                <Autocomplete
                  multiple
                  options={this.state.countries}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option) => {
                    return formData.country_of_operation.some((el) => el.name === option.name)
                  }}
                  name="country_of_operation"
                  selected={formData.country_of_operation}
                  defaultValue={formData.country_of_operation}
                  onChange={this.handleCountryOperation}
                  renderInput={(params) => (
                    <TextValidator
                      Select
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="country_of_operation_selected"
                      placeholder="Country of Operation"
                      value={this.state.country_of_operation_selected}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <div className="col-12">
              <h6 className="pb-3 mt-1">{t('Company Address')}</h6>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  {t('Address 1')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="address1"
                  onChange={this.handleChange}
                  value={formData.address1}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>{t('Address 2 (optional)')}</label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="address2"
                  onChange={this.handleChange}
                  value={formData.address2}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  {t('Postal Code')} <sup>*</sup>
                </label>
                {formData.country ? (
                  <>
                    {formData.country.name === 'Portugal' && (
                      <TextValidator
                        variant="outlined"
                        margin="0"
                        fullWidth
                        type="text"
                        name="postal_code"
                        onChange={this.handleChange}
                        value={formData.postal_code}
                        validators={['required', 'matchRegexp:^[0-9]{4}-[0-9]{3}$']}
                        errorMessages={[
                          t('This field is required'),
                          t('invalid postal code format'),
                        ]}
                      />
                    )}
                    {formData.country.name === 'Spain' && (
                      <TextValidator
                        variant="outlined"
                        margin="0"
                        fullWidth
                        type="text"
                        name="postal_code"
                        onChange={this.handleChange}
                        value={formData.postal_code}
                        validators={[
                          'required',
                          'matchRegexp:^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$',
                        ]}
                        errorMessages={[
                          t('This field is required'),
                          t('invalid postal code format'),
                        ]}
                      />
                    )}
                    {formData.country.name !== 'Spain' && formData.country.name !== 'Portugal' && (
                      <TextValidator
                        variant="outlined"
                        margin="0"
                        fullWidth
                        type="text"
                        name="postal_code"
                        onChange={this.handleChange}
                        value={formData.postal_code}
                        validators={['required']}
                        errorMessages={[t('This field is required')]}
                      />
                    )}
                  </>
                ) : (
                  <TextValidator
                    variant="outlined"
                    margin="0"
                    fullWidth
                    type="text"
                    name="postal_code"
                    onChange={this.handleChange}
                    value={formData.postal_code}
                    validators={['required']}
                    errorMessages={[t('This field is required')]}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>{t('State (Optional)')}</label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="state"
                  onChange={this.handleChange}
                  value={formData.state}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  {t('City')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="city"
                  onChange={this.handleChange}
                  value={formData.city}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  {t('Country')} <sup>*</sup>
                </label>
                <Autocomplete
                  options={this.state.countries}
                  getOptionLabel={(option) =>
                    this.getOptionLabel(option, this.state.countries, 'id')
                  }
                  getOptionSelected={(option) =>
                    this.getOptionSelected(formData.country, option, this.state.countries, 'id')
                  }
                  selected={formData.country}
                  defaultValue={formData.country}
                  name="country"
                  onChange={this.handleCountry}
                  renderInput={(params) => (
                    <TextValidator
                      Select
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="country"
                      placeholder="Country"
                      value={formData.country}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  )}
                />
              </div>
            </div>
          </Row>

          <Row>
            <Col md="12" className="person-presence-min mb-2 mt-1">
              <h6>{t('Personal Online Presence')}</h6>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="linkedin"
                      onChange={this.handleChange}
                      placeholder={`Linkedin (${t('Insert full url')})`}
                      value={formData.linkedin}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="facebook"
                      onChange={this.handleChange}
                      placeholder={`Facebook (${t('Insert full url')})`}
                      value={formData.facebook}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faYoutube} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="youtube"
                      onChange={this.handleChange}
                      placeholder={`Youtube (${t('Insert full url')})`}
                      value={formData.youtube}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faInstagram} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="instagram"
                      onChange={this.handleChange}
                      placeholder={`Instagram (${t('Insert full url')})`}
                      value={formData.instagram}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="twitter"
                      onChange={this.handleChange}
                      placeholder={`Twitter (${t('Insert full url')})`}
                      value={formData.twitter}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faLink} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="website"
                      onChange={this.handleChange}
                      placeholder={`Website (${t('Insert full url')})`}
                      value={formData.website}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="col-12 text-center">
              <Button type="submit" variant="contained" className="submit-btn" disabled={submitted}>
                {(submitted && t('Your form is submitted!')) || (!submitted && t('Save'))}
              </Button>
            </div>
          </Row>
        </ValidatorForm>
        {/* TODO: Add notification errormsg and successmsg */}
      </div>
    )
  }
}
export default withTranslation()(Profileform)
