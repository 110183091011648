import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './annualpackage.css'
import { withTranslation } from 'react-i18next'
import PaymentModal from './payment-modal'
import classnames from 'classnames';

class AnnualPackage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showHide: false,
      subname: '',
      subfulldesc: '',
      subyearprice: 0,
      submonthprice: 0,
      subsid: 0,
      getstarted: false,
      submitted: false,
      payoption: 'Credit',
      callbackPayment: null,
    }

    this.buynow = this.buynow.bind(this)
    this.handleModalShow = this.handleModalShow.bind(this)
    this.handleModalHide = this.handleModalHide.bind(this)
    this.handleChecked = this.handleChecked.bind(this)
  }

  componentDidMount() {
    if (this.props.subs_detail) {
      this.setState({
        showHide: true,
        subname: this.props.subs_detail.name,
        subfulldesc: this.props.subs_detail.full_description,
        subyearprice: this.props.subs_detail.yearly_cost,
        submonthprice: this.props.subs_detail.monthly_cost,
        subsid: this.props.subs_detail.id,
        getstarted: false,
      })
    }

    const url = new URL(window.location.href)
    const callbackPayment = url.searchParams.get('callbackpayment')
    if (callbackPayment) {
      this.setState({ callbackPayment: callbackPayment, showHide: true })
    }
    console.log(this.props.yearly)
  }

  gettingstarted() {
    this.setState({ getstarted: true })
    if (this.state.subsid > 0) {
      this.setState({ submitted: true })
      this.props.selSubscription(this.state.subsid, this.state.payoption)
    }
  }

  buynow() {
    if (this.state.subsid > 0) {
      this.setState({ submitted: true })
      this.props.selSubscription(this.state.subsid, this.state.payoption)
    }
  }

  clearCallbackPayment = () => {
    this.setState({ callbackPayment: null })
    window.history.replaceState(null, '', window.location.pathname)
  }

  calculatePriceWithPlus = (cost) => {
    if (this.props.plusChecked) {
      var priceWithPlus = (Number.parseInt(cost) + Number.parseInt(this.props.plusPrice)).toFixed(2)

      return priceWithPlus
    } 
    var price = Number.parseInt(cost).toFixed(2)
    return price
  }

  handleModalShow(name, full_description, yearlycost, monthlycost, id) {
    console.log(this.props.plusChecked)
    if (name === 'Crystal' && !this.props.plusChecked) {
      this.props.onPickCrystal()
      // this.props.selSubscriptionFree(id)
      return
    }

    // alert('This package is temporarily inactive. It will be available in the near future.')
    // return
    this.setState({
      showHide: true,
      subname: name,
      subfulldesc: full_description,
      subyearprice: yearlycost,
      submonthprice: monthlycost,
      subsid: id,
      getstarted: false,
      payoption: 'Credit',
    })
  }

  handleModalHide(name, full_description, yearlycost, monthlycost, id) {
    this.setState({
      showHide: false,
      subname: '',
      subfulldesc: '',
      subyearprice: 0,
      submonthprice: 0,
      subsid: 0,
    })

    if (this.props.close) {
      this.props.close()
    }
  }

  handleChecked(e) {
    this.setState({ payoption: e.target.value })
  }

  render() {
    const { t } = this.props

    let lng = 'en'
    if (localStorage.getItem('lang')) {
      lng = localStorage.getItem('lang')
    }

    return (
      <div>
        {!this.props.close && (
          <div className="row justify-content-between pl-4 pr-4 pack_min">
            {this.props.subscriptions && (
              <>
                {this.props.subscriptions.map((sub) => (
                  <div className={`packageplan flex-container ${sub.name}-pack`}>
                    <div className="packagetext flex-item fill text-center pt-3">
                      {lng === 'en' && <h4>{sub.name}</h4>}
                      {lng === 'pt' && <h4>{sub.name_prt}</h4>}
                      {lng === 'es' && <h4>{sub.name_spn}</h4>}
                      {lng === 'en' && <div dangerouslySetInnerHTML={{ __html: sub.services }} />}
                      {lng === 'pt' && (
                        <div dangerouslySetInnerHTML={{ __html: sub.services_prt }} />
                      )}
                      {lng === 'es' && (
                        <div dangerouslySetInnerHTML={{ __html: sub.services_spn }} />
                      )}
                    </div>
                    <div className="flex-item">
                      {/* {sub.name === 'Crystal' && <span className="packageprice">{t('free')}</span>} */}

                      {sub.name === 'Crystal' && this.props.plusChecked ? (
                        <span className="packageprice">
                            {t('Yearly Subscription')}: <br />
                          €{this.calculatePriceWithPlus('0')}
                        </span>
                      ) : sub.name === 'Crystal' && !this.props.plusChecked ? (
                        <span className="packageprice">{t('free')}</span>
                      ) : null}

                      {sub.name !== 'Silver' && sub.name !== 'Crystal' && this.props.plusChecked ? (
                        <span className="packageprice">
                          {t('Yearly Subscription')}: <br />
                          €{this.calculatePriceWithPlus(sub.yearly_cost)}
                        </span>
                      ) : (
                        sub.name !== 'Silver' &&
                        sub.name !== 'Crystal' &&
                        !this.props.plusChecked && (
                          <span className="packageprice">
                            {t('Yearly Subscription')}: <br /> €{sub.yearly_cost}
                          </span>
                        )
                      )}

                      {this.props.yearly && sub.name === 'Silver' && this.props.plusChecked ? (
                        <span className="packageprice">
                          {t('Yearly Subscription')}: <br />
                          €{this.calculatePriceWithPlus(sub.yearly_cost)}
                        </span>
                      ) : (
                        this.props.yearly &&
                        sub.name === 'Silver' &&
                        !this.props.plusChecked && (
                          <span className="packageprice">
                            {t('Yearly Subscription')}:<br /> €{sub.yearly_cost}
                          </span>
                        )
                      )}
                      {!this.props.yearly && sub.name === 'Silver' && this.props.plusChecked ? (
                        <span className="packageprice">
                          {t('Monthly Subscription')}: <br />
                          €{this.calculatePriceWithPlus(sub.monthly_cost)}
                        </span>
                      ) : (
                        !this.props.yearly &&
                        sub.name === 'Silver' &&
                        !this.props.plusChecked && (
                          <span className="packageprice">
                            {t('Monthly Subscription')}:<br /> €
                            {this.calculatePriceWithPlus(sub.monthly_cost)}
                          </span>
                        )
                      )}
                      {!this.props.plusChecked && (<div className="button_bg">
                        {lng === 'en' && (
                          <button
                            typle="button"
                            className="btn-white"
                            // disabled={!sub.name === 'Crystal'}
                            disabled={sub.name != 'Silver' && this.props.yearly === false}
                            onClick={() =>
                              this.handleModalShow(
                                sub.name,
                                sub.full_description,
                                sub.yearly_cost,
                                sub.monthly_cost,
                                sub.id
                              )
                            }
                          >
                            {t('Get Started')}
                          </button>
                        )}
                        {lng === 'es' && (
                          <button
                            typle="button"
                            className="btn-white"
                            disabled={sub.name != 'Silver' && this.props.yearly === false}
                            onClick={() =>
                              this.handleModalShow(
                                sub.name_spn,
                                sub.full_description_spn,
                                sub.yearly_cost,
                                sub.monthly_cost,
                                sub.id
                              )
                            }
                          >
                            {t('Get Started')}
                          </button>
                        )}
                        {lng === 'pt' && (
                          <button
                            typle="button"
                            className="btn-white"
                            disabled={sub.name != 'Silver' && this.props.yearly === false}
                            onClick={() =>
                              this.handleModalShow(
                                sub.name_prt,
                                sub.full_description_prt,
                                sub.yearly_cost,
                                sub.monthly_cost,
                                sub.id
                              )
                            }
                          >
                            {t('Get Started')}
                          </button>
                        )}
                      </div>)}
                      {this.props.plusChecked && (<div className={classnames('button_bg', `${sub.name}-pack-plus`)}>
                      {lng === 'en' && (
                          <button
                            typle="button"
                            className={`${sub.name}-plus-btn`}
                            // disabled={!sub.name === 'Crystal'}
                            disabled={sub.name != 'Silver' && this.props.yearly === false}
                            onClick={() =>
                              this.handleModalShow(
                                sub.name,
                                // sub.full_description,
                                // sub.yearly_cost,
                                // sub.monthly_cost,
                                // sub.id
                              )
                            }
                          >
                            {t('Get Plus')}
                          </button>
                        )}
                           {lng === 'es' && (
                          <button
                            typle="button"
                            className={`${sub.name}-plus-btn`}
                            // disabled={!sub.name === 'Crystal'}
                            disabled={sub.name != 'Silver' && this.props.yearly === false}
                            onClick={() =>
                              this.handleModalShow(
                                sub.name,
                                // sub.full_description,
                                // sub.yearly_cost,
                                // sub.monthly_cost,
                                // sub.id
                              )
                            }
                          >
                            {t('Get Plus')}
                          </button>
                        )}
                        {lng === 'pt' && (
                          <button
                            typle="button"
                            className={`${sub.name}-plus-btn`}
                            // disabled={!sub.name === 'Crystal'}
                            disabled={sub.name != 'Silver' && this.props.yearly === false}
                            onClick={() =>
                              this.handleModalShow(
                                sub.name,
                                // sub.full_description,
                                // sub.yearly_cost,
                                // sub.monthly_cost,
                                // sub.id
                              )
                            }
                          >
                            {t('Get Plus')}
                          </button>
                        )}
                      </div>)}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        )}

        {this.state.showHide && (
          <PaymentModal
            open={this.state.showHide}
            subname={this.state.subname}
            close={this.handleModalHide}
            subPeriod={this.props.yearly ? 'yearly' : 'monthly'}
            goNext={this.props.nextStep}
            callbackPayment={this.state.callbackPayment}
            clearCallbackPayment={this.clearCallbackPayment}
            source={this.props?.close ? 'renewal' : 'subscription'}
            openRenew={this.props?.open}
            plusOption={this.props.plusChecked ? 'plus' : ''}
          />
        )}
      </div>
    )
  }
}

export default withTranslation()(AnnualPackage)
