import React from 'react'
import { Tooltip, Typography, Button } from '@mui/material'
// import { styled } from '@mui/material/styles'
// import { tooltipClasses } from '@mui/material/Tooltip'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import { FinancialReportBox } from 'components'
import { financialReportFields } from 'entities/companyFields'
const IconInfoFinanciero = require('assets/images/iconInfoFinanciero.svg')

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//   },
// }))

const FinancialReportButtonComponent = (props) => {
  const { t } = props
  const isDataAvailable = financialReportFields.some(({ key }) => props[key] != null)

  if (!isDataAvailable) return null
  else
    return (
      <Tooltip
        sx={{
          backgroundColor: `${colorsByKey.transparent} !important`,
          p: 0,
          m: 0,
          boxShadow: 'none',
        }}
        title={<FinancialReportBox {...props} />}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: colorsByKey.defaultBgMedium,
            mt: 2,
            '&:hover': {
              backgroundColor: colorsByKey.defaultBgMedium,
              boxShadow: 'none',
            },
            boxShadow: 'none',
          }}
        >
          <img src={IconInfoFinanciero} alt="Financial report icon" />
          <Typography noWrap variant="body2" sx={{ ml: 1 }} color={colorsByKey.defaultText}>
            {t('Financial report')}
          </Typography>
        </Button>
      </Tooltip>
    )
}
export const FinancialReportButton = withTranslation()(FinancialReportButtonComponent)
