import React from 'react'
import history from '../history'
import Changepasswordpage from '../component/changepassword/changepasswordpage'

class Changepassword extends React.Component {
  componentDidMount() {
    if (this.props.userDetail._provider !== null) {
      history.push('/profile')
    }
  }

  render() {
    return (
      <div>
        <Changepasswordpage></Changepasswordpage>
      </div>
    )
  }
}
export default Changepassword
