import React from 'react'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import history from '../../history'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
library.add(faCheckCircle, faExclamationCircle)
class Paymentnotsusscess extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    setTimeout(function () {
      history.push('/complete-participant-registration')
    }, 1000)
  }

  render() {
    return (
      <div className="registration-min ">
        <Helmet>
          <title>Payment Declined | BridgeWhat: Let's Grow!</title>
        </Helmet>
        <div className="welcome-min w-bg border-radius d-flex align-items-center">
          <Container className="text-center ">
            <h2 className="pb-5">Payment Declined</h2>
            <span className="pt-4">Redirecting you to Subscription page to try again...</span>
          </Container>
        </div>
      </div>
    )
  }
}
export default Paymentnotsusscess
