import React from 'react'
import axios from 'axios'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import './registrationstep1.css'
import MuiPhoneNumberValidatorElement from '../../profile/ErrorComponent/MuiPhoneNumberValidatorElement'

import 'bootstrap/dist/css/bootstrap.min.css'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { withTranslation } from 'react-i18next'
import Autocomplete from '@material-ui/lab/Autocomplete'
import countries from 'utils/countries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

class RegistrationStep1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        user_id: 0,
        is_seller: false,
        services: [],
        company_name: '',
        company_sector: null,
        country_of_operation: [],
        code: '',
        first_name: '',
        phone: '',
        job_function: '',
      },
      services_selected: '',
      company_sector_selected: '',
      country_of_operation_selected: '',
      submitted: false,
      error: [],
      companysectors: [],
      countries: countries(),
      growth: [],
      loading: true,
      isServicesDisabled: false,
      maxOptions: 3,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
  }

  handleChange(value) {
    if (value) {
      this.setState({ phone: value })
    }
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.removeValidationRule('isPhone')
    }
  }

  async componentDidMount() {
    if (!ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.addValidationRule('isPhone', (value) => {
        if (value === null || value.trim() === '' || value.trim() === '+') {
          return false
        }
        return true
      })
    }

    if (this.props.userDetail) {
      const success = this.props.userDetail
      const { formData } = this.state
      formData['user_id'] = success.id

      if (success.is_seller === 1) {
        formData['is_seller'] = true
        if ('user_services' in success) {
          if (success.user_services) {
            formData['services'] = success.user_services
            if (formData['services'].length >= this.props.maxOptions) {
              this.setState({ isServicesDisabled: true })
            }
            this.setState({ services_selected: '1' })
          }
        }
      }

      if ('user_country_of_operations' in success) {
        formData['country_of_operation'] = success.user_country_of_operations
        this.setState({ country_of_operation_selected: '1' })
      }

      if (success.company_sector) {
        formData['company_sector'] = success.company_sector
        this.setState({ company_sector_selected: '1' })
      }

      formData['company_name'] = success.company_name
      formData['first_name'] = success.name
      formData['job_function'] = success.job_function
      formData['phone'] = success.phone_number

      this.setState({ formData })
    }

    const sector_api_url = process.env.REACT_APP_API_URL + '/api/sectors'
    await axios
      .get(sector_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ companysectors: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const growth_api_url = process.env.REACT_APP_API_URL + '/api/services'
    await axios
      .get(growth_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ growth: data, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handlePhoneChange(value) {
    const { formData } = this.state
    formData['phone'] = value
    this.setState({ formData })
  }

  handleServices = (event, value) => {
    const { formData, maxOptions } = this.state

    if (value.length >= maxOptions) {
      // If so, update the state to disable the Autocomplete
      this.setState({
        formData: { ...formData, services: value },
        isServicesDisabled: true,
        services_selected: '1',
      })
    } else {
      // If not, update the state with the selected services
      this.setState({
        formData: { ...formData, services: value },
        isServicesDisabled: false,
        services_selected: value.length > 0 ? '1' : '',
      })
    }
  }

  handleOnChange(value, data, event, formattedValue) {
    this.setState({ rawPhone: value.slice(data.dialCode.length) })
  }

  handleCompanySector = (event, value) => {
    const { formData } = this.state
    formData['company_sector'] = value
    if (value !== null) {
      this.setState({ company_sector_selected: '1' })
    } else {
      this.setState({ company_sector_selected: '' })
    }
    this.setState({ formData })
  }

  handleCountryOperation = (event, value) => {
    const { formData } = this.state
    formData['country_of_operation'] = value
    if (value.length > 0) {
      this.setState({ country_of_operation_selected: '1' })
    } else {
      this.setState({ country_of_operation_selected: '' })
    }
    this.setState({ formData })
  }

  handleChecked = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.checked
    this.setState({ formData })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/adduserinfo'
      const { formData } = this.state

      if (formData.is_seller) formData['is_seller'] = 1
      else formData['is_seller'] = 0

      axios
        .post(sector_api_url, this.state.formData, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        })
        .then((response) => {
          this.setState({ submitted: false })
          this.props.nextStep(3)
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, error: data.error })
              }
            } else {
              alert(this.props.t('Something went wrong, please try again'))
            }
          }
        })
    })
  }

  render() {
    const { t, cancelProcess } = this.props
    const { formData, submitted } = this.state
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div>
        <div className="boxshadow p-3 pt-1 mb-5">
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <label className="mb-lg-0 label-w">
              {t('Does your company want to become a seller')}
            </label>
            <div className="d-flex seller-sect justify-content-md-start align-items-center pt-3 pb-3">
              <div className="custom-control seller-check custom-checkbox ">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck"
                  name="is_seller"
                  checked={formData.is_seller}
                  onChange={this.handleChecked}
                />
                <label className="custom-control-label" htmlFor="customCheck">
                  {t('Seller')}
                </label>
              </div>
              {formData.is_seller && (
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={this.state.growth}
                  name="services"
                  selected={formData.services}
                  defaultValue={formData.services}
                  onChange={this.handleServices}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                  disableCloseOnSelect
                  getOptionDisabled={() => this.state.isServicesDisabled}
                  getOptionLabel={(option) => option.service_name}
                  getOptionSelected={(option) => {
                    return formData.services.some((el) => el.service_name === option.service_name)
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                      />
                      {option.service_name}
                    </React.Fragment>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextValidator
                      Select
                      {...params}
                      variant="outlined"
                      name="services_selected"
                      placeholder="Select services"
                      value={this.state.services_selected}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                      disabled={this.state.isServicesDisabled}
                    />
                  )}
                />
              )}
            </div>

            <div className="row pb-1">
              <div className="col-md-12 pb-3">
                <h6>{t('Company Information')}</h6>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label>
                    {t('Company Commercial Name')} <sup>*</sup>
                  </label>
                  <TextValidator
                    variant="outlined"
                    margin="0"
                    fullWidth
                    type="text"
                    name="company_name"
                    onChange={this.handleChange}
                    value={formData.company_name}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label>
                    {t('Companys sector')} <sup>*</sup>
                  </label>
                  <Autocomplete
                    options={this.state.companysectors}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option) => {
                      return option.name === formData.company_sector.name
                    }}
                    name="company_sector"
                    selected={formData.company_sector}
                    defaultValue={formData.company_sector}
                    onChange={this.handleCompanySector}
                    renderInput={(params) => (
                      <TextValidator
                        Select
                        {...params}
                        fullWidth
                        variant="outlined"
                        name="company_sector_selected"
                        placeholder="Company's sector"
                        value={this.state.company_sector_selected}
                        validators={['required']}
                        errorMessages={t('This field is required')}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label>{t('Country of Operation')}</label>
                  <Autocomplete
                    multiple
                    options={this.state.countries}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option) => {
                      return formData.country_of_operation.some((el) => el.name === option.name)
                    }}
                    name="country_of_operation"
                    selected={formData.country_of_operation}
                    defaultValue={formData.country_of_operation}
                    onChange={this.handleCountryOperation}
                    renderInput={(params) => (
                      <TextValidator
                        Select
                        {...params}
                        fullWidth
                        variant="outlined"
                        name="country_of_operation_selected"
                        placeholder="Country of Operation"
                        value={this.state.country_of_operation_selected}
                        validators={['required']}
                        errorMessages={t('This field is required')}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col-md-12">
                <h6 className="pb-4">{t('User Information - BridgeWhat contact')}</h6>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {t('Name')} <sup>*</sup>
                  </label>
                  <TextValidator
                    variant="outlined"
                    margin="0"
                    fullWidth
                    type="text"
                    name="first_name"
                    onChange={this.handleChange}
                    value={formData.first_name}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {t('Job Function')} <sup>*</sup>
                  </label>
                  <TextValidator
                    variant="outlined"
                    margin="0"
                    fullWidth
                    type="text"
                    name="job_function"
                    onChange={this.handleChange}
                    value={formData.job_function}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {t('Phone Number')} <sup>*</sup>
                  </label>
                  <div className="w-100 phone_number_f">
                    <MuiPhoneNumberValidatorElement
                      name="phone"
                      className="phone_number"
                      data-cy="user-phone"
                      value={formData.phone}
                      onChange={this.handlePhoneChange}
                      fullWidth
                      variant="outlined"
                      validators={['isPhone']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group text-center d-flex justify-content-center">
                  <Button
                    type="button"
                    variant="contained"
                    className="submit-btn ml-0 mr-3"
                    onClick={() => cancelProcess()}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="submit-btn ml-3 mr-0"
                    disabled={submitted}
                  >
                    {(submitted && t('Your form is submitted!')) || (!submitted && t('Next Step'))}
                    &nbsp;
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </div>
      </div>
    )
  }
}
export default withTranslation()(RegistrationStep1)
