import React from 'react'
import { Box, Typography } from '@mui/material'
import { Spinner, IconData } from 'components'
import { colorsByKey } from 'theme/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenAlt } from '@fortawesome/pro-light-svg-icons'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const CredentialsCardComponent = ({
  t,
  title,
  icon,
  children,
  isLoading,
  isEditable = false,
  linkLabel = '',
  linkIcon = '',
  redirectLink = '',
  sx = {},
}) => {
  let history = useHistory()

  const handleRedirectTo = () => {
    history.push(redirectLink)
  }
  return (
    <Box className="CredentialsCard" sx={sx}>
      <Box
        className="CredentialsCardHeader"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={icon} />
          <Typography
            variant="f20"
            color={colorsByKey.defaultText}
            sx={{ fontWeight: 'bold', ml: 2 }}
          >
            {title}
          </Typography>
        </Box>

        {isEditable && redirectLink && (
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={handleRedirectTo}
          >
            <IconData
              title={linkLabel}
              icon={linkIcon || faPenAlt}
              margin={1}
              color={colorsByKey.accentTextStrong}
            />
          </Box>
        )}
      </Box>
      <Box className="CredentialsCardBody" sx={{ py: 3 }}>
        {isLoading && <Spinner />}
        {children}
      </Box>
    </Box>
  )
}
export const CredentialsCard = withTranslation()(CredentialsCardComponent)
