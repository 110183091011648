import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import HeaderMenuItem from '../headerMenuItem'

const HeaderDropdown = ({ menuItem: { title, subItems }, userDetail }) => {
  const { t } = useTranslation()

  const printMenuItem = (menuItem, index) => {
    switch (menuItem.type) {
      case 'divider':
        return <NavDropdown.Divider key={index} />

      case 'group-title':
        return (
          <strong key={index} className="pl-3 d-block pt-2">
            {t(menuItem.title)}
          </strong>
        )

      default:
        return (
          <HeaderMenuItem key={index} dropdownSubItem menuItem={menuItem} userDetail={userDetail} />
        )
    }
  }

  return <NavDropdown title={t(title)}>{subItems.map(printMenuItem)}</NavDropdown>
}

export default HeaderDropdown
