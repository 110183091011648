import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Stack } from '@mui/material'
import { Header } from 'components'
import Footer from '../component/footer/footer'
import { FEED } from 'entities/routes'

const GuestOnlyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem('accessToken')) {
          return (
            <>
              <Header hideInnerHeader />
              <Stack sx={{ marginTop: '110px' }}>
                <Component {...props} />
              </Stack>
              <Footer></Footer>
            </>
          )
        }
        return <Redirect to={{ pathname: FEED, state: { from: props.location } }} />
      }}
    />
  )
}

export default GuestOnlyRoute
