import React, { useMemo } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import getHeaderMenuItems from 'utils/headerMenu'

import HeaderDropdown from './headerDropdown'
import HeaderMenuItem from './headerMenuItem'

const HeaderMenu = ({ userDetail }) => {
  const isTouchDevice = useMemo(() => {
    return (
      'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    )
  }, [])

  const headerMenuItems = useMemo(() => getHeaderMenuItems(userDetail), [userDetail])

  if (!userDetail) return null

  const printMenuItem = (menuItem, index) => {
    switch (menuItem.type) {
      case 'dropdown':
        return <HeaderDropdown key={index} userDetail={userDetail} menuItem={menuItem} />

      default:
        return <HeaderMenuItem key={index} userDetail={userDetail} menuItem={menuItem} />
    }
  }

  return (
    <>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav style={{ marginTop: '0px' }} className="justify-content-end ml-auto" activeKey="1">
          {headerMenuItems.map(printMenuItem)}
        </Nav>
      </Navbar.Collapse>
      <ReactTooltip
        delayHide={!isTouchDevice && 200}
        place="bottom"
        clickable
        html
        backgroundColor="#ededed"
        borderColor="#c7c7c7"
        textColor="#929597"
      />
    </>
  )
}

export default HeaderMenu
