/* FEED */
import { colorsByKey } from 'theme/colors'

import OpportunitiesIcon from '../assets/icons/entities/opportunities.svg'
import CampaignsIcon from '../assets/icons/entities/campaigns.svg'
import PostsIcon from '../assets/icons/entities/posts.svg'
import PartnershipsIcon from '../assets/icons/entities/partnerships.svg'
import AcceleratorsIcon from '../assets/icons/entities/accelerators.svg'
import SamplingsIcon from '../assets/icons/entities/samplings.svg'
import ProtocolsIcon from '../assets/icons/entities/protocols.svg'
import ExposIcon from '../assets/icons/entities/expos.svg'

import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { ADVISORS_FIELDS, EDITABLE_ADVISOR_FIELDS, EMAIL, PASSWORD } from 'entities/companyFields'
import {
  COMPANY_SIZE,
  REVENUE,
  SERVICE_CATEGORIES,
  SERVICES,
  COUNTRIES,
  SECTORS,
  DATES,
  SEARCH,
  PAGE,
  PLUS,
  COMPANY_PACKAGE
} from 'entities/filterFields'
import {
  BUY_FIELDS,
  CAMPAIGNS_FIELDS,
  POSTS_FIELDS,
  SAMPLINGS_FIELDS,
  PROTOCOLS_FIELDS,
  TITLE,
  DESCRIPTION,
  BUSINESS_SERVICE,
  BW20LOG_SERVICES,
  BW20LOGS_SERVICES,
  BUSINESS_SERVICE_CATEGORIES,
  TARGET_INDUSTRY_SECTORS,
  TARGET_COUNTRIES,
  CLOSING_DATE,
  NUMBER_OF_SAMPLES,
  HEADER_IMAGE,
  // IMAGE,
  PUBLICATION_DATE,
  PREFERRED_DATE,
  CONTENT_WYSIWYG,
  THUMBNAIL_IMAGE,
  NEW_PARTNERSHIP_FIELDS,
  DISCOUNTS
} from 'entities/formFields'
import {
  PRODUCT,
  ADVANTAGE,
  PAIN_PROBLEMS,
  BUY_CONTEXT,
  GAIN_DESIRE,
  PAIN_RELIEVERS,
  GAIN_CREATORS,
  AREAS_OF_INNOVATION,
  CAMPAIGN_STRENGHTS,
  BRAND_FAME,
  BUSINESS_CASE,
  IMPROVES_CLIENT_COMPETITIVENESS,
  IMPROVES_CLIENT_RESULTS,
  REDUCES_CLIENT_RISKS,
  POTENTIAL_OBJECTIONS,
  POTENTIAL_COMPETITORS,
} from 'entities/campaignExtraFields'

export const ALL = 'all'
export const SELL = 'offers'
export const BUY = 'opportunities'
export const CAMPAIGNS = 'campaigns'
export const POSTS = 'posts'
export const SAMPLINGS = 'samplings'
export const PROTOCOLS = 'protocols'
export const COMPANIES = 'companies'
export const ADVISORS = 'advisors'
export const PARTNERSHIPS = 'partnerships'
export const ACCELERATORS = 'accelerators'
export const NEW_PARTNERSHIPS = 'newpartnership'
export const SUBSCRIPTION_OPTION = 'subscription_option'
export const SUBSCRIPTION_OPTION_EXPIRES_AT = 'subscription_option_expires_at'

export const POST_STATUS = {
  published: {
    key: 'published',
    color: colorsByKey.success,
    label: 'Published',
  },
  approved: {
    key: 'published',
    color: colorsByKey.success,
    label: 'Published',
  },
  pending: {
    key: 'pending',
    color: colorsByKey.warning,
    label: 'Pending',
  },
  denied: {
    key: 'denied',
    color: colorsByKey.error,
    label: 'Denied',
  },
  expired: {
    key: 'expired',
    color: colorsByKey.defaultTextDisabled,
    label: 'Expired',
  },
  draft: {
    key: 'draft',
    color: colorsByKey.defaultTextDisabled,
    label: 'Draft',
  },
}
export const postStatus = (key) => POST_STATUS[key.toLowerCase()] || {}

export const interestedInWant = {
  endpoint: `/api/sendInterestedNotification`,
  method: 'POST',
}

export const interestedUpgrade = {
  endpoint: `/api/sendUpgradePackageNotification`,
  method: 'POST',
}

export const all = {
  key: ALL,
  label: 'All',
  path: 'all',
  icon: faChevronDown,
  list: {
    endpoint: `/api/feed`,
    method: 'GET',
    availableFilters: [SERVICES, SECTORS, COMPANY_SIZE, REVENUE, COUNTRIES, PAGE, DATES, SEARCH, PLUS, COMPANY_PACKAGE],
  },
}
export const offers = {
  key: SELL,
  label: 'EXPO',
  btn: 'EXPO',
  icon: ExposIcon,
  reference: 'offers',
  alterLabel: 'Offer',
  path: 'expos',
  list: {
    endpoint: `/api/market/offers`,
    method: 'GET',
    companyDefaultFilterParam: 'user',
    availableFilters: [SEARCH, SERVICES, SECTORS, COMPANY_SIZE, PAGE],
  },
  detail: {
    endpoint: (offerId) => `/api/market/offers/${offerId}`,
  },
  edit: {
    endpoint: (offerId) => `/api/market/offers/${offerId}`,
    requiresApproval: false,
  },
  delete: {
    endpoint: `/api/market/offers`,
    paramIdKey: 'offerId',
    method: 'DELETE',
  },
}
export const opportunities = {
  key: BUY,
  label: 'I want to buy',
  btn: 'I want to buy',
  icon: OpportunitiesIcon,
  reference: 'Opportunities',
  alterLabel: 'Opportunity',
  path: 'i-want-to-buy',
  list: {
    endpoint: `/api/market/opportunities`,
    method: 'GET',
    companyDefaultFilterParam: 'user',
    availableFilters: [SEARCH, SERVICES, SECTORS, COUNTRIES, COMPANY_SIZE, PAGE],
  },
  detail: {
    endpoint: (opportunityId) => `/api/market/opportunities/${opportunityId}`,
  },
  create: {
    endpoint: `/api/market/opportunities`,
    method: 'POST',
    formFields: BUY_FIELDS,
    requiresApproval: true,
    requiredFields: [
      TITLE,
      DESCRIPTION,
      BUSINESS_SERVICE,
      TARGET_INDUSTRY_SECTORS,
      TARGET_COUNTRIES,
      CLOSING_DATE,
    ],
  },
  edit: {
    endpoint: (opportunityId) => `/api/market/opportunities/${opportunityId}`,
    requiresApproval: false,
  },
  delete: {
    endpoint: `/api/market/opportunities`,
    paramIdKey: 'opportunityId',
    method: 'DELETE',
  },
}
export const campaigns = {
  key: CAMPAIGNS,
  label: 'Campaigns',
  btn: 'Campaign',
  icon: CampaignsIcon,
  path: 'campaigns',
  list: {
    endpoint: `/api/campaigns`,
    method: 'GET',
    companyDefaultFilterParam: 'user',
    availableFilters: [SEARCH, SECTORS, SERVICES, COUNTRIES, PAGE, DATES],
    defaultFilterParams: {
      public: true,
    },
  },
  detail: {
    endpoint: (campaignId) => `/api/campaigns/${campaignId}`,
  },
  create: {
    endpoint: `/api/campaigns`,
    method: 'POST',
    formFields: CAMPAIGNS_FIELDS,
    requiresApproval: true,
    requiredFields: [
      TITLE,
      TARGET_INDUSTRY_SECTORS,
      TARGET_COUNTRIES,
      PRODUCT,
      // ADVANTAGE,
      PAIN_PROBLEMS,
      BUY_CONTEXT,
      // GAIN_DESIRE,
      PAIN_RELIEVERS,
      GAIN_CREATORS,
      // AREAS_OF_INNOVATION,
      // CAMPAIGN_STRENGHTS,
      // BRAND_FAME,
      // BUSINESS_CASE,
      IMPROVES_CLIENT_COMPETITIVENESS,
      IMPROVES_CLIENT_RESULTS,
      REDUCES_CLIENT_RISKS,
      POTENTIAL_OBJECTIONS,
      POTENTIAL_COMPETITORS,
      DISCOUNTS,
      BW20LOGS_SERVICES,
      PUBLICATION_DATE,
      CLOSING_DATE,
    ],
  },
  edit: {
    endpoint: (campaignId) => `/api/campaigns/${campaignId}`,
    requiresApproval: false,
  },
  delete: {
    endpoint: (campaignId) => `/api/campaigns/${campaignId}`,
    paramIdKey: 'campaignId',
    method: 'DELETE',
  },
}
const advisors = {
  key: ADVISORS,
  label: 'Advisors',
  btn: 'Advisors',
  // disabled: true,
  displayAppart: true,
  icon: CampaignsIcon,
  path: 'advisors',
  list: {
    endpoint: `/api/advisors`,
    method: 'GET',
    availableFilters: [SEARCH, SECTORS, SERVICES, COUNTRIES, PAGE],
  },
  detail: {
    endpoint: (id) => `/api/advisors/${id}`,
    method: 'GET',
  },
  create: {
    endpoint: `/api/advisors`,
    method: 'POST',
    formFields: ADVISORS_FIELDS,
    requiresApproval: true,
    requiredFields: [EMAIL, PASSWORD],
  },
  edit: {
    endpoint: (id) => `/api/advisors/${id}`,
    method: 'PUT',
    formFields: EDITABLE_ADVISOR_FIELDS,
    requiresApproval: true,
    requiredFields: [],
  },
  delete: {
    endpoint: (id) => `/api/advisors/${id}`,
    paramIdKey: 'id',
    method: 'DELETE',
  },
}

export const posts = {
  key: POSTS,
  label: 'Posts',
  btn: 'Post',
  icon: PostsIcon,
  path: 'posts',
  list: {
    endpoint: `/api/blogs`,
    method: 'GET',
    companyDefaultFilterParam: 'author',
    availableFilters: [SEARCH, SERVICES, PAGE],
  },
  detail: {
    endpoint: (blogId) => `/api/blogDetail/${blogId}`,
  },
  create: {
    endpoint: `/api/addBlog`,
    method: 'POST',
    formFields: POSTS_FIELDS,
    requiresApproval: true,
    requiredFields: [TITLE, BW20LOG_SERVICES, CONTENT_WYSIWYG, HEADER_IMAGE, THUMBNAIL_IMAGE],
    // hiddenFields: {
    //   status: 'Review',
    //   published: 'Yes',
    // },
  },
  edit: {
    endpoint: (blogId) => `/api/editBlog/${blogId}`,
    requiresApproval: false,
  },
  delete: {
    endpoint: `/api/deleteBlog`,
    paramIdKey: 'blogId',
    method: 'DELETE',
  },
  sidebarTitle: 'The BW Posts',
}
export const samplings = {
  key: SAMPLINGS,
  label: 'Samplings',
  btn: 'Sampling',
  icon: SamplingsIcon,
  path: 'samplings',
  list: {
    endpoint: `/api/listOtherSurpriseBox`,
    method: 'GET',
    companyDefaultFilterParam: 'user',
    availableFilters: [SEARCH, PAGE],
  },
  detail: {
    endpoint: (surpriseBoxId) => `/api/surpriseBoxDetail/${surpriseBoxId}`,
  },
  create: {
    endpoint: `/api/addSurpriseBox`,
    method: 'POST',
    formFields: SAMPLINGS_FIELDS,
    requiresApproval: true,
    requiredFields: [TITLE, DESCRIPTION, NUMBER_OF_SAMPLES, PREFERRED_DATE],
  },
  applyto: {
    endpoint: `/api/surpriseBoxAdd`,
    method: 'POST',
  },
  edit: {
    endpoint: (surpriseBoxId) => `/api/editSurpriseBox/${surpriseBoxId}`,
    requiresApproval: false,
  },
  delete: {
    endpoint: `/api/deleteSurpriseBox`,
    paramIdKey: 'surpriseBoxId',
    method: 'DELETE',
  },
}
export const protocols = {
  key: PROTOCOLS,
  label: 'Protocols',
  btn: 'Protocol',
  icon: ProtocolsIcon,
  path: 'protocols',
  list: {
    endpoint: `/api/listPOMServicesOthers`,
    method: 'GET',
    companyDefaultFilterParam: 'user_id',
    availableFilters: [SEARCH, PAGE, SERVICE_CATEGORIES],
  },
  detail: {
    endpoint: (protocolId) => `/api/POMServiceDetail/${protocolId}`,
  },
  create: {
    endpoint: `/api/addPOMService`,
    method: 'POST',
    formFields: PROTOCOLS_FIELDS,
    requiresApproval: true,
    requiredFields: [
      TITLE,
      BUSINESS_SERVICE_CATEGORIES,
      TARGET_INDUSTRY_SECTORS,
      TARGET_COUNTRIES,
      CLOSING_DATE,
      PUBLICATION_DATE,
    ],
    hiddenFields: {
      user_status: 'Published',
    },
  },
  edit: {
    endpoint: (protocolId) => `/api/editPOMService/${protocolId}`,
    requiresApproval: false,
  },
  delete: {
    endpoint: `/api/deletePOMService`,
    paramIdKey: 'protocolId',
    method: 'DELETE',
  },
}

export const newpartnership = {
  key: NEW_PARTNERSHIPS,
  label: 'Partnerships',
  btn: 'Partnership',
  icon: PartnershipsIcon,
  path: 'newpartnership',
  list: {
    endpoint: `/api/existingPartnerships`,
    method: 'GET',
    companyDefaultFilterParam: 'user_id',
    availableFilters: [SEARCH, PAGE, SERVICE_CATEGORIES],
  },
  create: {
    endpoint: `/api/existingPartnerships/create`,
    method: 'POST',
    formFields: NEW_PARTNERSHIP_FIELDS,
    requiresApproval: true,
    requiredFields: [
      TITLE,
      DESCRIPTION,
      BUSINESS_SERVICE_CATEGORIES,
      TARGET_INDUSTRY_SECTORS,
      TARGET_COUNTRIES,
      CLOSING_DATE,
      PUBLICATION_DATE,
    ],
    hiddenFields: {
      user_status: 'Published',
    },
  },
}
export const companies = {
  key: COMPANIES,
  label: 'Companies',
  icon: faChevronDown,
  path: 'companies',
  list: {
    endpoint: `/api/companies`,
    method: 'GET',
    availableFilters: [SEARCH, SERVICES, SECTORS, COUNTRIES, COMPANY_SIZE, REVENUE, PAGE, PLUS, COMPANY_PACKAGE] ,
  },
  displayAppart: true,
  detail: {},
  sidebarTitle: 'The BW Participants',
}

export const lastCompanies = {
  key: 'companies',
  label: 'The BW Participants',
  sidebarTitle: 'The BW Participants',
  list: {
    endpoint: `/api/companies/latest`,
    method: 'GET',
    defaultFilterParams: { number: 3 },
  },
}

export const partnerships = {
  key: PARTNERSHIPS,
  label: 'Partnerships',
  btn: 'Partnership',
  icon: PartnershipsIcon,
  path: 'partnerships',
  list: {
    endpoint: `/api/partnerships`,
    method: 'GET',
    availableFilters: [],
  },
}

export const accelerators = {
  key: ACCELERATORS,
  label: 'Accelerators',
  btn: 'Accelerator',
  icon: AcceleratorsIcon,
  path: 'accelerators',
  list: {
    endpoint: `/api/accelerators`,
    method: 'GET',
    availableFilters: [SEARCH, PAGE],
  },
}

export const ALL_TABS = {
  all,
  partnerships,
  newpartnership,
  offers,
  opportunities,
  campaigns,
  advisors,
  posts,
  protocols,
  samplings,
  companies,
  accelerators,
}

export const FEED_SECTION_TABS = {
  all,
  partnerships,
  newpartnership,
  accelerators,
  offers,
  opportunities,
  campaigns,
  protocols,
  samplings,
  advisors,
  companies,
  posts,
}
export const FEED_SECTION_TABS_ARRAY = [
  all,
  newpartnership,
  offers,
  campaigns,
  protocols,
  samplings,
  posts,
  accelerators,
  companies,
  advisors,
]
export const COMPANY_FEED_TABS_ARRAY = [offers, campaigns, protocols, samplings, posts]
export const ADVISOR_FEED_TABS_ARRAY = [posts]

export const FEED_SIDEBAR_TABS = { lastCompanies, posts }
export const FEED_ACTION_TABS = [
  partnerships,
  offers,
  campaigns,
  protocols,
  samplings,
  posts,
  accelerators,
]
