import React, { useContext, useState } from 'react'
import { ItemTypeTabs } from './ItemTypeTabs'
import { withTranslation } from 'react-i18next'
import { Box, Stack, Button, Typography, TextField } from '@mui/material'
import Filters from './Filters'
import { colorsByKey } from 'theme/colors'
import QueryFiltersContext from 'context/queryFiltersContext'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SERVICES, SECTORS, OTHERS, SEARCH, FEED_HIGHLITED_FILTERS } from 'entities/filterFields'
import LookingForModal from './LookingFor/LookingForModal'
import styled from '@emotion/styled'
import { ModalUpgradePackage } from 'components/Modal/ModalUpgradePackage'
import SUBSCRIPTIONS from 'utils/subscriptions'
import { IM_LOOKING_FOR_ACTION } from 'utils/actions'

const FeedFiltersPanelComponent = ({ t, tabs, userDetail }) => {
  const ctx = useContext(QueryFiltersContext)
  const [lookingForOpen, setLookingForOpen] = useState(false)
  const [visibleModalUpgrade, setVisibleModalUpgrade] = useState(false)

  const [dataModalUpgrade, setDataModalUpgrade] = useState({
    data: '',
    linkUpgrade: '',
  })
  const [dataModalCurrent, setDataModalCurrent] = useState({
    data: '',
    linkUpgrade: '',
  })

  const subscriptionCurrent = SUBSCRIPTIONS[userDetail.subscription]

  const handleFilterInput = (value) => {
    ctx.setFilter('search', value)
    const filterValues = { ...ctx.filters, search: value }
    if (!ctx.showFilters) ctx.handleFilterData(filterValues)
  }
  const mainAvailableFilters = ctx.tabDetails.list.availableFilters

  const otherAvailableFilters = mainAvailableFilters.filter(
    (f) => !FEED_HIGHLITED_FILTERS.includes(f)
  )

  const handleOpenLookingFor = () => {
    let found = subscriptionCurrent.available_actions.find(
      (element) => element === IM_LOOKING_FOR_ACTION
    )

    //MODAL UPGRADE
    if (found !== undefined) {
      //Tiene acceso
      setVisibleModalUpgrade(false)
      setLookingForOpen(true)
    } else {
      //Si la suscripcion es 1 (Crystal) o 5 (Free) debe migrar a 2 (Silver)
      let upgrade =
        userDetail.subscription === 1 || userDetail.subscription === 5
          ? 2
          : userDetail.subscription + 1

      setVisibleModalUpgrade(true)
      setDataModalUpgrade({
        data: SUBSCRIPTIONS[upgrade],
        linkUpgrade: userDetail,
      })
      setDataModalCurrent({
        data: subscriptionCurrent,
        linknowBenefits: '',
      })

      //No tiene acceso y levanta modal de upgrade
    }
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
            borderRadius: '8px',
            background: colorsByKey.defaultBg,
          }}
        >
          <ItemTypeTabs tabs={tabs} userDetail={userDetail} />
          <Stack padding={2} spacing={2} direction={'row'} className="FeedFilters">
            <TextField
              fullWidth
              id={'feed-search'}
              size="small"
              placeholder={t('Search')}
              value={ctx.filters.search}
              disabled={!mainAvailableFilters.includes(SEARCH)}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    color={colorsByKey.accentText}
                    size="sm"
                  />
                ),
              }}
              sx={{
                borderRadius: '12px !important',
                backgroundColor: colorsByKey.defaultBgWeak,
                padding: '8px',
                '& .MuiInput-underline': {
                  padding: '0 12px',
                  '&::before': { border: 'none !important' },
                  '& input': { padding: '0 0 0 12px' },
                  '&.Mui-disabled': { cursor: 'not-allowed', '& input': { cursor: 'not-allowed' } },
                },
              }}
              onChange={(e) => handleFilterInput(e.target.value)}
            />
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => ctx.toggleShowFilters(SECTORS)}
                variant="outlined"
                disabled={!mainAvailableFilters.includes(SECTORS)}
                sx={{
                  borderRadius: '12px !important',
                  border: `1px solid ${colorsByKey.accentText}`,
                  color: colorsByKey.defaultText,
                  fontSize: '12px !important',
                  '& svg': { fontSize: '12px !important' },
                }}
                endIcon={
                  <FontAwesomeIcon icon={faChevronDown} color={colorsByKey.defaultText} size="sm" />
                }
              >
                <Typography noWrap variant="f12">
                  {t('Industry Sectors')}
                </Typography>
              </Button>
              <Button
                onClick={() => ctx.toggleShowFilters(SERVICES)}
                variant="outlined"
                disabled={!mainAvailableFilters.includes(SERVICES)}
                sx={{
                  borderRadius: '12px !important',
                  border: `1px solid ${colorsByKey.accentText}`,
                  color: colorsByKey.defaultText,
                  fontSize: '12px !important',
                  '& svg': { fontSize: '12px !important' },
                }}
                endIcon={
                  <FontAwesomeIcon icon={faChevronDown} color={colorsByKey.defaultText} size="sm" />
                }
              >
                <Typography noWrap variant="f12">
                  {t('20LOG')}
                </Typography>
              </Button>
              <Button
                onClick={() => ctx.toggleShowFilters(OTHERS)}
                variant="outlined"
                disabled={!otherAvailableFilters.length}
                sx={{
                  borderRadius: '12px !important',
                  border: `1px solid ${colorsByKey.accentText}`,
                  color: colorsByKey.defaultText,
                  fontSize: '12px !important',
                  '& svg': { fontSize: '14px !important' },
                }}
                endIcon={<FilterAltOutlinedIcon />}
              >
                <Typography noWrap variant="f12">
                  {t('Other filters')}
                </Typography>
              </Button>
              <DuckImportantStyles onClick={handleOpenLookingFor}>
                <Typography noWrap variant="f12">
                  I'm looking for
                </Typography>
              </DuckImportantStyles>
            </Stack>
          </Stack>
        </Box>
        <Filters />
      </Box>

      {lookingForOpen && (
        <LookingForModal open={lookingForOpen} close={() => setLookingForOpen(false)} />
      )}

      <ModalUpgradePackage
        visible={visibleModalUpgrade}
        dataUpgrade={dataModalUpgrade}
        dataCurrent={dataModalCurrent}
        closeModal={() => setVisibleModalUpgrade(false)}
      />
    </>
  )
}

const DuckImportantStyles = styled.button`
  all: unset;
  border-radius: 50px;
  background: #358feb;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
  color: white;
  padding: 8px 18px;
`

export const FeedFiltersPanel = withTranslation()(FeedFiltersPanelComponent)
