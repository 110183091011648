import React from 'react'
import history from '../history'

class Logout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    if (localStorage.getItem('accessToken') !== null) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('accessTokenRole')
    }

    history.push('/')
  }
  render() {
    return (
      <div className="loader-min">
        <div className="loader"></div>
      </div>
    )
  }
}
export default Logout
