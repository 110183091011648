import { Autocomplete, Stack, TextField } from '@mui/material'
import React from 'react'

function ProtocolForm({ countries, sectors, updateForm, form }) {
  const handleFormChange = (key, value) => {
    updateForm(key, value)
  }

  return (
    <Stack useFlexGap gap="36px" width="350px" paddingTop="10px">
      <Autocomplete
        multiple
        fullWidth
        disabled={sectors.length === 0}
        options={sectors}
        getOptionLabel={(option) => option.name}
        value={form.field1 ?? []}
        loading={sectors.length === 0}
        onChange={(event, newValue) => {
          handleFormChange('field1', newValue)
        }}
        size="small"
        renderInput={(params) => (
          <TextField {...params} fullWidth required label="Industry sector of origin" />
        )}
      />
      <Autocomplete
        multiple
        fullWidth
        disabled={countries.length === 0}
        options={countries}
        getOptionLabel={(option) => option.name}
        value={form.field2 ?? []}
        loading={countries.length === 0}
        onChange={(event, newValue) => {
          handleFormChange('field2', newValue)
        }}
        size="small"
        renderInput={(params) => <TextField {...params} fullWidth label="Country" />}
      />
      <TextField
        label="Brief description of what you are looking for"
        required
        onChange={(e) => handleFormChange('field3', e.target.value)}
      />
      <TextField
        label="What could make this campaign irresistible"
        required
        onChange={(e) => handleFormChange('field4', e.target.value)}
      />
    </Stack>
  )
}

export default ProtocolForm
