import React from 'react'
import { Box } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next'
import { faStar } from '@fortawesome/pro-light-svg-icons'
import { faStar as solidFaStar } from '@fortawesome/free-solid-svg-icons'

const ScoresComponent = ({ score, total }) => {
  const stars = Array.from({ length: total }, (_, i) => i + 1) || []
  return (
    <Box>
      {stars.map((star) => {
        if (score >= star)
          return (
            <FontAwesomeIcon
              key={`star-${star}`}
              icon={solidFaStar}
              size="xs"
              color={colorsByKey.warningText}
              style={{ marginRight: '4px' }}
            />
          )
        else
          return (
            <FontAwesomeIcon
              key={`star-${star}`}
              icon={faStar}
              size="xs"
              color={colorsByKey.defaultTextDisabled}
              style={{ marginRight: '4px' }}
            />
          )
      })}
    </Box>
  )
}
export const Scores = withTranslation()(ScoresComponent)
