import React, { useContext } from 'react'
import {
  UserCredentialsSectionSidebar,
  CredentialsCard,
  RecommendationsCard,
  ReviewsCard,
} from './components'

import { Box, Typography, Button, Container } from '@mui/material'
import { Spinner, ScoresBox, IconData } from 'components'
import { colorsByKey } from 'theme/colors'
import userContext from 'context/userContext'
import { withTranslation } from 'react-i18next'
import { ID } from 'entities/formFields'
import { DESCRIPTION, SCORE, ADVISOR } from 'entities/companyFields'
import { REVIEWS, TESTIMONIALS, RECOMMENDATIONS, credentials } from 'entities/company'
import { useHistory } from 'react-router-dom'
import { COMPANY } from 'entities/routes'
import filters from 'mixins/filters'

const TOTAL_SCORE = 5

const UserCredentialsSectionComponent = ({ data, t }) => {
  let history = useHistory()
  const { sectors } = filters()

  const ctx = useContext(userContext)
  const redirectTo = (link) => {
    history.push(link)
  }

  const { companyContentSections, advisorContentSections } = credentials
  const sections = window.location.pathname.includes(COMPANY)
    ? companyContentSections
    : advisorContentSections

  return (
    <Container sx={{ display: 'flex' }} maxWidth={false} disableGutters>
      <Box className="Sidebar" width={280}>
        <UserCredentialsSectionSidebar data={data} />
      </Box>
      <Box className="Content" sx={{ flex: 1, py: 4, px: 4, background: colorsByKey.utility }}>
        {!ctx.isMyProfile && (
          <Box
            sx={{
              p: 2,
              mb: 4,
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.10)',
              borderRadius: '8px',
              position: 'relative',
              overflow: 'hidden',
              minHeight: '120px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background:
                'linear-gradient(147.58deg, rgba(211, 230, 251, 0.35) 1.53%, #FFFFFF 79.49%), #FFFFFF',
            }}
          >
            <img
              src={require(`assets/images/card-default-images/feedback.svg`)}
              alt="feedback"
              style={{ position: 'absolute', bottom: 0, left: 0 }}
            />
            <Box>
              <Typography
                align="center"
                variant="f20"
                sx={{ mb: 2, fontWeight: 'bold' }}
                color={colorsByKey.primaryBgMedium}
              >
                {t('Give us some feedback')}
              </Typography>
              <Box>
                {sections.map((item, idx) => {
                  return (
                    <Button
                      key={`${item.key} - ${idx}`}
                      onClick={() => redirectTo(item[`${ctx.userData.userType}ActionPath`])}
                      type="button"
                      variant="outlined"
                      sx={{
                        color: colorsByKey.accentText,
                        ml: idx !== 0 ? 2 : 0,
                      }}
                    >
                      <IconData
                        title={t(item.btn)}
                        icon={item.icon}
                        margin={1}
                        color={colorsByKey.accentText}
                      />
                    </Button>
                  )
                })}
              </Box>
            </Box>
          </Box>
        )}

        <Box className="Content" sx={{ flex: 1, ml: 4 }}>
          {sections.map(
            ({ label, key, btn, linkBtn, advisorActionPath, companyActionPath, icon }, idx) => {
              const sectionLabel =
                key === REVIEWS ? `${t(label)}` : `${t(label)} (${(data[key] || []).length})`
              return (
                <CredentialsCard
                  title={sectionLabel}
                  linkLabel={ctx.isMyProfile ? t(linkBtn) : t(btn)}
                  icon={icon}
                  isEditable
                  redirectLink={
                    ctx.userData.userType === ADVISOR ? advisorActionPath : companyActionPath
                  }
                  key={`section-${key}`}
                  sx={{ mt: 4 }}
                >
                  {!data[key] && <Spinner />}
                  {data[key] && !(data[key] || []).length && (
                    <Typography variant="f14" color={colorsByKey.accentTextStrong}>
                      {t('no data yet!')}
                    </Typography>
                  )}
                  {data[key] && (data[key] || []).length > 0 && (
                    <>
                      {key === REVIEWS && (
                        <Box>
                          <ScoresBox reviews={data[key]} total={TOTAL_SCORE} />
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr 1fr',
                              gap: 3,
                              mt: 4,
                            }}
                          >
                            {data[key].map((review) => {
                              return (
                                <ReviewsCard
                                  key={`review-${review[ID]}`}
                                  company={review.company || {}}
                                  text={review[DESCRIPTION]}
                                  score={review[SCORE]}
                                  total={TOTAL_SCORE}
                                  localization={t}
                                />
                              )
                            })}
                          </Box>
                        </Box>
                      )}
                      {key === RECOMMENDATIONS && (
                        <Box>
                          {data[key].map((recommendation) => {
                            return (
                              <RecommendationsCard
                                key={`recommendation-${recommendation[ID]}`}
                                {...recommendation}
                                sx={{ mb: 3 }}
                                sectors={sectors}
                              />
                            )
                          })}
                        </Box>
                      )}
                      {key === TESTIMONIALS && (
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 3 }}>
                          {data[key].map((testimonial) => {
                            return (
                              <RecommendationsCard
                                key={`testimonial-${testimonial[ID]}`}
                                {...testimonial}
                                displayVertical
                                sectors={sectors}
                              />
                            )
                          })}
                        </Box>
                      )}
                    </>
                  )}
                </CredentialsCard>
              )
            }
          )}
        </Box>
      </Box>
    </Container>
  )
}
export const UserCredentialsSection = withTranslation()(UserCredentialsSectionComponent)
