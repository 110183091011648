import styled from '@emotion/styled'
import { Container, Stack, Tooltip } from '@mui/material'
import axiosInstance from 'api/axios'
import Loader from 'component/loader'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

function PartnershipView() {
  const params = useParams()

  const [details, setDetails] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    axiosInstance
      .get(`/api/partnershipsDetail/${params.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setDetails(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [params.id])

  const downloadBrochure = () => {
    const anchor = document.createElement('a')
    anchor.href = details.brochure_url
    anchor.target = '_blank'
    anchor.download = details.brochure_url.split('/').pop()
    anchor.click()
  }

  return (
    <>
      {loading && <Loader width={54} height={54} />}
      {!loading && (
        <Wrapper maxWidth={'xl'} sx={{ my: 4 }}>
          <Stack direction="row" gap="72px" justifyContent="space-between">
            <Stack spacing={2} flexGrow={1}>
              <Title>{details.title}</Title>
              <Stack>
                <AboutPart>About the partnership</AboutPart>
                <Stack marginBottom={2}>
                  <Purpose>Reasons for partnering</Purpose>
                  <PurposeText>{details.partnership_purpose}</PurposeText>
                </Stack>
                <Stack>
                  <Purpose>Partnership objectives</Purpose>
                  <PurposeText>{details.partnership_objectives}</PurposeText>
                </Stack>
              </Stack>
              <Divider />

              <Stack>
                <SecondTitle>ASSETS</SecondTitle>
                <Stack direction={'row'} spacing={3}>
                  <Stack spacing={1} flexGrow={1}>
                    <Purpose>{details.user.name}</Purpose>
                    <Stack direction={'row'} spacing={2}>
                      {details.proponent_assets.map((a) => (
                        <AssetBox>{a}</AssetBox>
                      ))}
                    </Stack>
                  </Stack>
                  <Stack spacing={1} flexGrow={1}>
                    <Purpose>PARTNER</Purpose>
                    <Stack direction={'row'} spacing={2}>
                      {details.partner_assets.map((a) => (
                        <AssetBox>{a}</AssetBox>
                      ))}
                    </Stack>
                  </Stack>
                </Stack>

                <Divider />

                {(details.partnership_reason || details.partnership_reason_context) && (
                  <>
                    <Stack useFlexGap gap="10px">
                      <SecondTitle>COMPANY</SecondTitle>
                      {details.partnership_reason && (
                        <Stack>
                          <Purpose>Why should a company want a partner?</Purpose>
                          <PurposeText>{details.partnership_reason}</PurposeText>
                        </Stack>
                      )}
                      {details.partnership_reason_context && (
                        <Stack>
                          <Purpose>
                            Why should a company want to partner in the current context?
                          </Purpose>
                          <PurposeText>{details.partnership_reason_context}</PurposeText>
                        </Stack>
                      )}
                    </Stack>
                    <Divider />
                  </>
                )}
              </Stack>

              <Stack>
                <SecondTitle>People</SecondTitle>
                <Stack direction={'row'} spacing={3}>
                  <Stack flexGrow={1}>
                    <Purpose>Decision Maker</Purpose>
                    <Stack>{details.decision_maker}</Stack>
                  </Stack>
                  <Stack flexGrow={1}>
                    <Purpose>Influencer</Purpose>
                    <Stack>{details.influencer}</Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack minWidth="200px" width="200px">
              <SecondTitle>ABOUT</SecondTitle>
              <Stack spacing={1}>
                <PartnerTag>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.9883 5.42627L10.1875 7.94189V5.97314C10.1875 5.48096 9.61328 5.15283 9.17578 5.42627L5.375 7.94189V3.56689C5.375 3.07471 4.96484 2.69189 4.5 2.69189H1.875C1.38281 2.69189 1 3.07471 1 3.56689V14.0669C1 14.5317 1.38281 14.9419 1.875 14.9419H14.125C14.5898 14.9419 15 14.5317 15 14.0669V5.97314C15 5.48096 14.4258 5.15283 13.9883 5.42627ZM12.0469 12.3169H10.9531C10.7617 12.3169 10.625 12.1802 10.625 11.9888V10.895C10.625 10.731 10.7617 10.5669 10.9531 10.5669H12.0469C12.2109 10.5669 12.375 10.731 12.375 10.895V11.9888C12.375 12.1802 12.2109 12.3169 12.0469 12.3169ZM8.54688 12.3169H7.45312C7.26172 12.3169 7.125 12.1802 7.125 11.9888V10.895C7.125 10.731 7.26172 10.5669 7.45312 10.5669H8.54688C8.71094 10.5669 8.875 10.731 8.875 10.895V11.9888C8.875 12.1802 8.71094 12.3169 8.54688 12.3169ZM5.04688 12.3169H3.95312C3.76172 12.3169 3.625 12.1802 3.625 11.9888V10.895C3.625 10.731 3.76172 10.5669 3.95312 10.5669H5.04688C5.21094 10.5669 5.375 10.731 5.375 10.895V11.9888C5.375 12.1802 5.21094 12.3169 5.04688 12.3169Z" />
                  </svg>
                  <SectorBadge>
                    <span>{details.company_sectors[0].name}</span>
                    {details.company_sectors.length > 1 && (
                      <Tooltip
                        title={details.company_sectors.map((cs) => (
                          <TooltipP>{cs.name}</TooltipP>
                        ))}
                      >
                        <SectorBadgePlus>+{details.company_sectors.length - 1}</SectorBadgePlus>
                      </Tooltip>
                    )}
                  </SectorBadge>
                </PartnerTag>
                <PartnerTag>
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 0.283203C3.07422 0.283203 0.75 2.63477 0.75 5.5332C0.75 7.66602 1.46094 8.26758 5.45312 14.0098C5.69922 14.3926 6.27344 14.3926 6.51953 14.0098C10.5117 8.24023 11.25 7.66602 11.25 5.5332C11.25 2.63477 8.89844 0.283203 6 0.283203ZM6 7.7207C4.76953 7.7207 3.8125 6.73633 3.8125 5.5332C3.8125 4.30273 4.76953 3.3457 6 3.3457C7.20312 3.3457 8.1875 4.30273 8.1875 5.5332C8.1875 6.73633 7.20312 7.7207 6 7.7207Z" />
                  </svg>

                  <span>{details.user_country.name}</span>
                </PartnerTag>
                <PartnerTag>
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.97266 10.5669C10.5586 10.5669 11.8164 9.30908 11.8164 7.72314C11.8164 6.16455 10.5312 4.87939 8.97266 4.87939C7.41406 4.87939 6.15625 6.16455 6.15625 7.72314C6.12891 9.30908 7.41406 10.5669 8.97266 10.5669ZM10.3398 11.4419H7.63281C5.47266 11.4419 3.75 13.0825 3.75 15.106C3.75 15.5161 4.07812 15.8169 4.51562 15.8169H13.457C13.8945 15.8169 14.25 15.5161 14.25 15.106C14.25 13.0825 12.5 11.4419 10.3398 11.4419ZM14.25 6.19189C15.4531 6.19189 16.4375 5.23486 16.4375 4.00439C16.4375 2.80127 15.4531 1.81689 14.25 1.81689C13.0195 1.81689 12.0625 2.80127 12.0625 4.00439C12.0625 5.23486 13.0195 6.19189 14.25 6.19189ZM5.25391 7.72314C5.25391 7.58643 5.28125 7.44971 5.30859 7.31299C5.00781 7.14893 4.67969 7.06689 4.32422 7.06689H2.65625C1.31641 7.06689 0.25 8.21533 0.25 9.63721C0.25 9.91064 0.441406 10.1294 0.714844 10.1294H6.18359C5.60938 9.50049 5.25391 8.65283 5.25391 7.72314ZM3.75 6.19189C4.95312 6.19189 5.9375 5.23486 5.9375 4.00439C5.9375 2.80127 4.95312 1.81689 3.75 1.81689C2.51953 1.81689 1.5625 2.80127 1.5625 4.00439C1.5625 5.23486 2.51953 6.19189 3.75 6.19189ZM15.3164 7.06689H13.6484C13.293 7.06689 12.9648 7.17627 12.6641 7.31299C12.6641 7.44971 12.6914 7.58643 12.6914 7.72314C12.6914 8.65283 12.3633 9.50049 11.7891 10.1294H17.2578C17.5312 10.1294 17.75 9.91064 17.75 9.63721C17.75 8.21533 16.6562 7.06689 15.3164 7.06689Z" />
                  </svg>
                  <span>{details.partner_employees}</span>
                </PartnerTag>
                <PartnerTag>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.25 4.44189H9.75L11.0352 2.50049C11.1172 2.36377 11.1445 2.19971 11.0625 2.06299C10.9805 1.92627 10.8438 1.81689 10.6797 1.81689H5.29297C5.12891 1.81689 4.96484 1.92627 4.88281 2.06299C4.82812 2.19971 4.85547 2.36377 4.9375 2.50049L6.25 4.44189ZM9.75 5.31689H6.25C0.699219 8.48877 1 12.6724 1 13.1919C1 14.6411 2.33984 15.8169 3.98047 15.8169H11.9922C13.6328 15.8169 14.9727 14.6411 14.9727 13.1919C14.9727 12.6724 15.2461 8.46143 9.75 5.31689ZM9.94141 11.8794C9.80469 12.5356 9.33984 12.9731 8.65625 13.1372V13.4106C8.65625 13.7935 8.35547 14.0669 8 14.0669C7.61719 14.0669 7.34375 13.7935 7.34375 13.4106V13.1099C7.07031 13.0552 6.82422 12.9731 6.57812 12.8911L6.46875 12.8364C6.11328 12.7271 5.92188 12.3442 6.05859 11.9888C6.16797 11.6606 6.55078 11.4692 6.90625 11.606L7.01562 11.6606C7.26172 11.7427 7.53516 11.8247 7.75391 11.8794C8.21875 11.9341 8.60156 11.8521 8.62891 11.6606C8.65625 11.5239 8.68359 11.4692 7.80859 11.2231L7.61719 11.1685C7.125 11.0044 5.8125 10.6216 6.05859 9.28174C6.16797 8.62549 6.63281 8.18799 7.34375 8.02393V7.72314C7.34375 7.36768 7.61719 7.06689 8 7.06689C8.35547 7.06689 8.65625 7.36768 8.65625 7.72314V8.05127C8.82031 8.07861 8.98438 8.1333 9.17578 8.18799C9.53125 8.32471 9.72266 8.70752 9.58594 9.03564C9.47656 9.39111 9.09375 9.55518 8.76562 9.4458C8.51953 9.36377 8.35547 9.30908 8.21875 9.28174C7.75391 9.22705 7.37109 9.30908 7.34375 9.50049C7.31641 9.60986 7.31641 9.69189 8 9.91064L8.16406 9.93799C8.875 10.1567 10.1602 10.5122 9.94141 11.8794Z" />
                  </svg>

                  <span>{details.partner_revenue} €</span>
                </PartnerTag>
              </Stack>
              <Stack>
                {/* <BrochureImage src={details.brochure_url} alt="brochure" /> */}
                <DownloadImage onClick={downloadBrochure} style={{ marginTop: '30px' }}>
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.69922 11.1406C6.78125 11.2227 6.89062 11.25 7 11.25C7.08203 11.25 7.19141 11.2227 7.27344 11.1406L11.2109 7.64062C11.4023 7.50391 11.4023 7.20312 11.2383 7.03906C11.1016 6.84766 10.8008 6.84766 10.6367 7.01172L7.4375 9.85547V1.1875C7.4375 0.96875 7.21875 0.75 7 0.75C6.75391 0.75 6.5625 0.96875 6.5625 1.1875V9.85547L3.33594 6.98438C3.17188 6.84766 2.87109 6.84766 2.73438 7.03906C2.57031 7.20312 2.57031 7.50391 2.76172 7.64062L6.69922 11.1406ZM12.25 9.5H10.9375C10.6914 9.5 10.5 9.71875 10.5 9.9375C10.5 10.1836 10.6914 10.375 10.9375 10.375H12.25C12.7148 10.375 13.125 10.7852 13.125 11.25V13C13.125 13.4922 12.7148 13.875 12.25 13.875H1.75C1.25781 13.875 0.875 13.4922 0.875 13V11.25C0.875 10.7852 1.25781 10.375 1.75 10.375H3.0625C3.28125 10.375 3.5 10.1836 3.5 9.9375C3.5 9.71875 3.28125 9.5 3.0625 9.5H1.75C0.765625 9.5 0 10.293 0 11.25V13C0 13.9844 0.765625 14.75 1.75 14.75H12.25C13.207 14.75 14 13.9844 14 13V11.25C14 10.293 13.207 9.5 12.25 9.5ZM12.0312 12.125C12.0312 11.7695 11.7305 11.4688 11.375 11.4688C10.9922 11.4688 10.7188 11.7695 10.7188 12.125C10.7188 12.5078 10.9922 12.7812 11.375 12.7812C11.7305 12.7812 12.0312 12.5078 12.0312 12.125Z" />
                  </svg>
                  <span>Download brochure</span>
                </DownloadImage>
              </Stack>
            </Stack>
          </Stack>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  /* padding: 30px 70px 60px; */
`

const AboutPart = styled.div`
  color: #358feb;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
`

const Purpose = styled.p`
  margin: 0;
  color: #358feb;
  font-size: 17px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
`
const PurposeText = styled.p`
  margin: 0;
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`

export const Divider = styled.hr`
  height: 2px;
  background: #d3e6fb;
  width: 100%;
  border: 0;
  margin-top: 45px !important;
`

export const SecondTitle = styled.h5`
  margin: 0;
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 34px;
  margin-bottom: 18px;
`

const Title = styled.h4`
  margin: 0;
  color: #132332;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 35px;
`

export const AssetBox = styled.div`
  border-radius: 5px;
  background: #d3e6fb;
  padding: 10px 16px;
  color: #358feb;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`

export const PartnerTag = styled.div`
  padding: 4px 8px;
  border-radius: 24px;
  background: #f1f2f2;
  gap: 8px;
  display: flex;
  align-items: center;

  color: #315a80;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  width: fit-content;
`

export const BrochureImage = styled.img`
  width: 352px;
  height: 235px;
  object-fit: cover;
  border-radius: 4px;
  margin: 20px 0;
`

export const DownloadImage = styled.button`
  display: flex;
  gap: 5px;
  border-radius: 4px;
  border: 1px solid #315a7f;
  padding: 8px 10px;
  align-items: center;
  width: fit-content;
  background: unset;
  color: #315a80;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`

export const SectorBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const TooltipP = styled.p`
  margin: 0;
`

export const SectorBadgePlus = styled.div`
  border-radius: 100px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: #3373b6;
  flex-grow: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
`

export default PartnershipView
