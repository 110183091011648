export const COMPANY_SIZE = 'companySize'
export const REVENUE = 'revenue'
export const SERVICES = 'services'
export const SERVICE_CATEGORIES = 'serviceCategories'
export const COUNTRIES = 'countries'
export const SECTORS = 'sectors'
export const DATES = 'dates'
export const SEARCH = 'search'
export const PAGE = 'page'
export const AUTHOR = 'author'
export const BW_PARTICIPANT = 'bwsparticipant'
export const OTHERS = 'others'
export const PLUS = 'plus'
export const COMPANY_PACKAGE = "companyPackage"

export const FEED_HIGHLITED_FILTERS = [SERVICES, SECTORS, SEARCH, PAGE]
