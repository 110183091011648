import {
  ADVISOR_FEATURE,
  CAMPAIGNS_FEATURE,
  CONTENTS_BLOG_FEATURE,
  CONTENTS_PUBLICATIONS_FEATURE,
  NEEDS_AND_LEADS_FEATURE,
  PROTOCOLS_FEATURE,
  SAMPLING_FEATURE,
  SCORING_BOARD_FEATURE,
  LOG_MARKET_FEATURE,
} from './features'
import { OPPORTUNITIES, OTHERS } from '../entities/marketplace'

const getHeaderMenuItems = (userDetail) => {
  let headerMenuItems = []

  if (userDetail) {
    if (userDetail.cb_roles_id === 1) {
      headerMenuItems = [
        ...headerMenuItems,
        {
          title: 'Feed',
          link: '/feed',
        },
        {
          type: 'dropdown',
          title: 'Directory',
          subItems: [
            {
              title: 'Sellers',
              link: '/seller-directory',
            },
            {
              title: 'Participants',
              link: '/participant-directory',
            },
          ],
        },
        {
          title: 'Sampling',
          link: '/sampling-listing',
          feature: SAMPLING_FEATURE,
        },
        {
          title: 'Blog',
          link: '/blog',
          feature: CONTENTS_BLOG_FEATURE,
        },
        {
          type: 'dropdown',
          title: 'Your Channel',
          subItems: [
            {
              title: 'Protocols',
              link: '/protocols-directory',
              feature: PROTOCOLS_FEATURE,
            },
            {
              title: 'Campaigns',
              link: '/campaigns',
              feature: CAMPAIGNS_FEATURE,
            },
          ],
        },
        // {
        //   type: "dropdown",
        //   title: "Needs & Leads",
        //   highlighted: true,
        //   subItems: [
        //     ...(userDetail.is_seller === 1
        //       ? [
        //           {
        //             type: "group-title",
        //             title: "Sellers",
        //             feature: NEEDS_AND_LEADS_FEATURE
        //           },
        //           {
        //             title: "Deals",
        //             link: "/deals",
        //             feature: NEEDS_AND_LEADS_FEATURE
        //           },
        //           {
        //             title: "Leads",
        //             link: "/leads",
        //             feature: NEEDS_AND_LEADS_FEATURE
        //           },
        //           {
        //             type: "divider",
        //           },
        //         ]
        //       : []),
        //     {
        //       type: "group-title",
        //       title: "Participants",
        //       feature: NEEDS_AND_LEADS_FEATURE
        //     },
        //     {
        //       title: "Needs",
        //       link: "/needs-area",
        //       feature: NEEDS_AND_LEADS_FEATURE
        //     },
        //     {
        //       title: "Opportunities",
        //       link: "/opportunities",
        //       feature: NEEDS_AND_LEADS_FEATURE
        //     },
        //   ],
        // },
        {
          title: '20 LOG Market',
          link: `/20-log-market/${OTHERS}/${OPPORTUNITIES}`,
          feature: LOG_MARKET_FEATURE,
        },
        {
          title: 'Your Advisor',
          link: '/your-advisor',
          disabled: true,
          feature: ADVISOR_FEATURE,
        },
        {
          type: 'dropdown',
          title: 'My Account',
          subItems: [
            {
              title: 'Profile',
              link: '/profile',
            },
            {
              title: 'Reviews',
              link: '/reviews',
              feature: SCORING_BOARD_FEATURE,
            },
            {
              title: 'Certificates',
              link: '/certifications',
            },
            {
              title: 'Awards',
              link: '/awards',
              feature: SCORING_BOARD_FEATURE,
            },
            {
              title: 'Referrals',
              link: '/referrals',
              feature: SCORING_BOARD_FEATURE,
            },
            {
              type: 'divider',
            },
            {
              title: 'Sampling',
              link: '/sampling-participating',
              feature: SAMPLING_FEATURE,
            },
            {
              title: 'Publications',
              link: '/publications',
              feature: CONTENTS_PUBLICATIONS_FEATURE,
            },
            {
              title: 'Your Channel',
              link: '/your-channel-seller-area',
            },
            {
              title: 'Needs & Leads',
              link: '/needs-and-leads-seller-area',
              feature: NEEDS_AND_LEADS_FEATURE,
            },
            {
              title: '20 LOG Market',
              link: `/20-log-market/${OTHERS}/${OPPORTUNITIES}`,
              feature: LOG_MARKET_FEATURE,
            },
            {
              title: 'Your Advisor',
              link: '/your-advisor-seller-area',
              feature: ADVISOR_FEATURE,
            },
            {
              type: 'divider',
            },
            {
              type: 'group-title',
              title: 'My BridgeWhat',
            },
            {
              title: 'Invoices',
              link: '/invoices',
            },
            {
              title: 'Messages',
              link: '/messages',
            },
            {
              type: 'divider',
            },
            ...(userDetail._provider === null
              ? [
                  {
                    title: 'Change Password',
                    link: '/change-password',
                  },
                ]
              : []),
            {
              title: 'Logout',
              link: '/logout',
            },
          ],
        },
      ]
    }

    if (userDetail.cb_roles_id === 2) {
      headerMenuItems = [
        ...headerMenuItems,
        {
          title: 'Payment Requests',
          link: '/sba-payment-information',
        },
        {
          title: 'Logout',
          link: '/logout',
        },
      ]
    }
  }

  return headerMenuItems
}

export default getHeaderMenuItems
