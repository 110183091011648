import React from 'react'
import logo from 'assets/logo.png'
import { Box, Typography, Container, Stack, Link } from '@mui/material'
import LanguageSelector from './components/LanguageSelector'
import MenuSelector from './components/MenuSelector'
import { colorsByKey } from 'theme/colors'
import { FEED } from 'entities/routes'
import CreateItemsBar from './components/CreateItemsBar'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { NAME, ID, IMAGE, USER_TYPE } from 'entities/companyFields'
import { useHistory } from 'react-router-dom'

const HeaderComponent = (props = {}) => {
  let history = useHistory()

  const {
    userDetail = {},
    displayCreateItemsBar,
    displayGoBack,
    displayExplore,
    hideInnerHeader = false,
    t,
  } = props
  const handleRedirectToFeed = () => {
    // TODO: Fix redirection to be absolute
    history.push(FEED)
  }
  const goBack = () => {
    const queryParams = history.location.query || {}
    if (!!Object.keys(queryParams || {}).length) {
      if (history.location.query && history.location.query.section == 'channel') {
        // Fix for section channel inside Company
        history.go(-6)
      } else {
        history.go(-3)
      }
    } else history.goBack()
  }
  return (
    <Container disableGutters maxWidth={false}>
      <Container
        disableGutters
        maxWidth={false}
        style={{
          position: 'fixed',
          top: 0,
          background: colorsByKey.utility,
          height: '90px',
          display: 'flex',
          alignItems: 'center',
          zIndex: 999,
        }}
      >
        <Container maxWidth={'xl'}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <img
              style={{
                maxWidth: '120px',
                cursor: 'pointer',
              }}
              src={logo}
              alt="Logo BridgeWhat"
              onClick={handleRedirectToFeed}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* {name && (
                <Box
                  sx={{
                    background: colorsByKey.lightBlue,
                    ml: 4,
                    px: 2,
                    py: 1,
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '5px',
                  }}
                >
                  <Typography sx={{ color: colorsByKey.blue, ml: 1 }} variant="f14">
                    {name}
                  </Typography>
                </Box>
              )} */}

              <>
                {(userDetail || {}).id && (
                  <Box sx={{ ml: 4 }}>
                    <MenuSelector
                      name={userDetail[NAME]}
                      id={userDetail[ID]}
                      image={userDetail[IMAGE]}
                      userType={userDetail[USER_TYPE]}
                      userDetail={userDetail}
                    />
                  </Box>
                )}
                <Box sx={{ ml: 4 }}>
                  <LanguageSelector />
                </Box>
              </>
            </Box>
          </Stack>
        </Container>
      </Container>
      {(userDetail || {}).id && !hideInnerHeader && (
        <Container
          disableGutters
          maxWidth={false}
          style={{
            position: 'fixed',
            top: '90px',
            background: colorsByKey.defaultBgWeak,
            height: '94px',
            display: 'flex',
            alignItems: 'center',
            zIndex: 998,
          }}
        >
          <Container maxWidth={'xl'}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2}>
                {displayExplore && <Typography variant="f20">{t('Explore')}</Typography>}
                {displayGoBack && (
                  <Link onClick={goBack} sx={{ cursor: 'pointer', p: 2, pl: 0 }}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Link>
                )}
              </Stack>
              {displayCreateItemsBar && <CreateItemsBar props={props} />}
            </Stack>
          </Container>
        </Container>
      )}
    </Container>
  )
}
export const Header = withTranslation()(HeaderComponent)
