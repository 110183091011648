import {
  faMapMarkerAlt,
  faUsers,
  faSackDollar,
  faIndustryAlt,
  faGiftCard,
  faDownload,
  faBriefcase,
  // faGlobe,
  // faEnvelope,
  // faPhoneAlt,
  // faSignature,
  // faCalendar,
  // faGlobeEurope,
  // faDownload,
} from '@fortawesome/pro-light-svg-icons'
// import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { COMPANY_REVENUES, REQUIRED } from 'mixins/filters'
import {
  targetIndustrySegments,
  product,
  advantage,
  painProblems,
  buyContext,
  irresistibleOffer,
  businessCaseOptions,
  irresistibleOfferDiscounts,
  bwParticipants,
  painFears,
  painFrustrations,
  gainDesire,
  gainConfidence,
  gainHapiness,
  decisionMaker,
  financier,
  purchaser,
  endUser,
  influencer,
  businessCaser,
  devilsAdvocate,
  earlyAdopter,
  friend,
  diplomat,
  campaignWill,
  problem,
  problemUseContext,
  painRelievers,
  gainCreators,
  areasOfInnovation,
  campaignStrengths,
  brandFame,
  businessCase,
  improvesClientsCompetitiveness,
  improvesClientsResults,
  reducesClientsRisks,
  scoringBoardReviews,
  scoringBoardTestimonials,
  scoringBoardRecommendations,
  scoringBoardCertificates,
  scoringBoardAwards,
  scoringBoardPublications,
  potentialObjections,
  potentialCompetitors,
  specialConditions,
  separator,
  MAIN,
  OTHERS,
  CREDENTIALS,
} from './campaignExtraFields'

/* CARD FIELDS */
export const TYPE = 'type'
export const KEY = 'key'
export const ID = 'id'
export const CREATED_AT = 'createdAt'
export const USER = 'user'

/* FORM FIELDS */
export const TITLE = 'title'
export const HEADLINE = 'headline'
export const DESCRIPTION = 'description'
export const BUSINESS_SERVICE = 'businessService'
export const BUSINESS_SERVICE_CATEGORIES = 'categories'
export const BUSINESS_SERVICE_CATEGORIES_SERVICES = 'services'
export const BW20LOG_SERVICES = 'bw20log_services'
export const BW20LOGS_SERVICES = 'bw20logs_services'
export const BUSINESS_SERVICE_ITEM = 'service'
export const TARGET_INDUSTRY_SECTORS = 'targetIndustrySectors'
export const TARGET_COUNTRIES = 'targetCountries'
export const COMPANY_EXAMPLE = 'companyExample'
export const TARGET_COMPANY_SIZE = 'targetCompanySize'
export const TARGET_COMPANY_REVENUE = 'targetCompanyRevenue'
export const BROCHURE = 'brochure'
export const BROCHURES = 'brochures'
export const FILES = 'files'
export const HEADER_IMAGE = 'headerImage'
export const THUMBNAIL_IMAGE = 'thumbnailImage'
export const WATERMARK_IMAGE = 'watermarkImage'
export const IMAGE = 'image'
export const IMAGES = 'images'
export const CLOSING_DATE = 'closingDate'
export const PUBLICATION_DATE = 'publicationDate'
export const PREFERRED_DATE = 'preferredDate'
export const LINK = 'link'
export const INSERT_LINK = 'insertlink'
export const NUMBER_OF_SAMPLES = 'numberOfSamples'
export const CONTENT = 'content'
export const STATUS = 'status'
export const PUBLISHED = 'published'
export const CONTENT_WYSIWYG = 'content'
export const RANGE_DATES = 'rangeDates'
export const DEADLINE = 'deadline'
export const PROPOSAL_DIFFERENTIATOR_FACTORS = 'proposalFactors'
export const SCORING_BOARD = 'scoringBoard'
export const DISCOUNTS = 'discounts'

const getText = (text, options) => {
  const item = options.find(({ key }) => key === text)
  return (item || {}).name || text
}

export const title = {
  key: TITLE,
  label: 'Title',
  type: 'text',
  backendKey: 'title',
}
export const description = {
  key: DESCRIPTION,
  label: 'Description',
  type: 'text',
  backendKey: 'description',
}
export const services = {
  key: BUSINESS_SERVICE,
  label: 'BridgeWhat 20Log',
  type: 'select',
  optionType: 'services',
  multiple: false,
  backendKey: 'service_id',
  toServerKeyExtractor: 'id',
}
export const proposalFactors = {
  key: PROPOSAL_DIFFERENTIATOR_FACTORS,
  label: 'Proposal Differentiator Factors (up to 3 choices)',
  type: 'select',
  optionType: 'proposalFactors',
  multiple: true,
  maxValues: 3,
  backendKey: 'proposalFactors',
  toServerKeyExtractor: 'id',
}
export const businessServiceCategory = {
  ...services,
  key: BUSINESS_SERVICE_CATEGORIES,
  label: 'Business Service Categories',
  optionType: 'serviceCategories',
  multiple: true,
  icon: faBriefcase,
  backendKey: 'categories',
}
export const businessServiceCategoryServices = {
  ...businessServiceCategory,
  key: BUSINESS_SERVICE_CATEGORIES_SERVICES,
  backendKey: 'services',
}
export const bw20log_services = {
  ...services,
  key: BW20LOG_SERVICES,
  label: 'BridgeWhat 20Log',
  backendKey: 'bw20log_services',
}
export const bw20logs_services = {
  ...services,
  key: BW20LOGS_SERVICES,
  label: 'BridgeWhat 20Logs',
  multiple: true,
  backendKey: 'bw20logs_services',
}
export const targetIndustrySectors = {
  key: TARGET_INDUSTRY_SECTORS,
  label: 'Target Industry Sectors',
  type: 'select',
  optionType: 'sectors',
  multiple: true,
  icon: faIndustryAlt,
  backendKey: 'sectors',
  toServerKeyExtractor: 'id',
}
export const targetCountries = {
  key: TARGET_COUNTRIES,
  label: 'Target Countries',
  type: 'select',
  optionType: 'countries',
  multiple: true,
  backendKey: 'countries',
  icon: faMapMarkerAlt,
  toServerKeyExtractor: 'id',
}
export const companyExample = {
  key: COMPANY_EXAMPLE,
  label: 'Company example',
  type: 'text',
  backendKey: 'company_example',
}
export const targetCompanySize = {
  key: TARGET_COMPANY_SIZE,
  label: 'Target Company Employees',
  type: 'select',
  optionType: 'companySizes',
  multiple: true,
  backendKey: 'target_company_size',
  icon: faUsers,
  toServerKeyExtractor: 'key',
}
export const targetCompanyRevenue = {
  key: TARGET_COMPANY_REVENUE,
  label: 'Target Company Revenue (M)',
  type: 'select',
  optionType: 'companyRevenues',
  multiple: true,
  backendKey: 'target_company_revenue',
  icon: faSackDollar,
  toServerKeyExtractor: 'key',
  getText: (text) => getText(text, COMPANY_REVENUES),
}
export const brochure = {
  key: BROCHURE,
  label: 'Upload brochure',
  type: 'files',
  // backendKey: 'files',
  icon: faDownload,
  toServerKeyExtractor: 'id',
  backendKey: 'brochure',
}
export const brochures = {
  key: BROCHURES,
  label: 'Download brochure',
  type: 'files',
  // backendKey: 'files',
  icon: faDownload,
  toServerKeyExtractor: 'id',
  backendKey: 'brochure',
}
export const brochuresPartner = {
  ...brochures,
  backendKey: 'brochure_url',
}
export const image = {
  key: IMAGE,
  label: 'Upload Image',
  type: 'files',
  multiple: false,
  accept: 'image',
  backendKey: 'image',
  toServerKeyExtractor: 'id',
}
export const logo = {
  ...image,
  label: 'Upload logo',
}
export const headerImage = {
  ...image,
  key: HEADER_IMAGE,
  label: 'Upload Header Image',
  backendKey: 'header_image',
  hint: 'blog_header_image_hint',
}
export const thumbnailImage = {
  ...image,
  key: THUMBNAIL_IMAGE,
  label: 'Upload Thumbnail Image',
  backendKey: 'thumbnailImage',
  hint: 'blog_thumbnail_image_hint',
  endpoint: '/api/files',
}
export const files = {
  ...brochures,
  key: FILES,
  multiple: true,
  maxFiles: 5,
  label: 'Upload brochure',
  backendKey: 'files',
  toServerKeyExtractor: 'id',
}
export const imagesGallery = {
  ...image,
  key: IMAGES,
  label: 'Upload Images',
  multiple: true,
  maxFiles: 5,
  backendKey: 'images',
  toServerKeyExtractor: 'id',
}
export const publicationDate = {
  key: PUBLICATION_DATE,
  label: 'Publication Date',
  type: 'date',
  backendKey: 'date_of_publication',
}
export const preferredDate = {
  key: PREFERRED_DATE,
  label: 'Preferred Date',
  type: 'date',
  backendKey: 'prefered_date',
}
export const closingDate = {
  key: CLOSING_DATE,
  label: 'Closing Date',
  type: 'date',
  backendKey: 'deadline',
}
export const dateOfValidity = {
  ...closingDate,
  backendKey: 'date_of_validity',
}
export const link = {
  key: LINK,
  label: 'Link',
  type: 'text',
  backendKey: 'link',
}
export const insertlink = {
  key: INSERT_LINK,
  label: 'Insert Link',
  type: 'text',
  backendKey: 'insertlink',
}
export const numberOfSamples = {
  key: NUMBER_OF_SAMPLES,
  label: 'Number Of Samples',
  type: 'number',
  backendKey: 'no_of_offers',
  icon: faGiftCard,
}
export const published = {
  key: PUBLISHED,
  label: 'Published',
  type: 'checkbox',
  defaultValue: true,
  backendKey: 'published',
  toServerBooleanYesNo: true,
}
export const content_wysiwyg = {
  key: CONTENT_WYSIWYG,
  label: 'Content',
  type: 'wysiwyg',
  backendKey: 'description',
}

//// Scoring board
const scoringBoard = {
  type: 'scoringBoard',
  label: 'Credentials',
  fields: [
    scoringBoardReviews,
    scoringBoardTestimonials,
    scoringBoardRecommendations,
    scoringBoardCertificates,
    scoringBoardAwards,
    scoringBoardPublications,
  ],
}

const scoringBoardRequired = {
  type: 'scoringBoard',
  label: 'Credentials',
  fields: [
    { ...scoringBoardReviews, options: REQUIRED },
    { ...scoringBoardTestimonials, options: REQUIRED },
    { ...scoringBoardRecommendations, options: REQUIRED },
    { ...scoringBoardCertificates, options: REQUIRED },
    { ...scoringBoardAwards, options: REQUIRED },
    { ...scoringBoardPublications, options: REQUIRED },
  ],
}

export const strengths = {
  key: 'strengths',
  label: 'Strengths',
  type: 'text',
  backendKey: 'strengths',
}
export const companies = {
  key: 'companies',
  label: 'Bridgewhat Partner Company',
  type: 'select',
  optionType: 'companies',
  backendKey: 'partner_id',
  toServerKeyExtractor: 'id',
}
export const outside_companies = {
  key: 'outside_companies',
  label: 'Other Partner Company',
  type: 'text',
  backendKey: 'outside_partner',
}
export const need = {
  key: 'need',
  label: 'Service or Product',
  type: 'text',
  backendKey: 'need',
}

export const SELL_FIELDS = [
  title,
  description,
  services,
  targetIndustrySectors,
  targetCountries,
  targetCompanySize,
  targetCompanyRevenue,
  areasOfInnovation,
  strengths,
  brandFame,
  proposalFactors,
  scoringBoard,
  { ...publicationDate, grid: 6, backendKey: 'opening_date' },
  closingDate,
  files,
]
export const BUY_FIELDS = [
  title,
  services,
  targetIndustrySectors,
  targetCountries,
  targetCompanySize,
  targetCompanyRevenue,
  need,
  description,
  proposalFactors,
  scoringBoardRequired,
  { ...publicationDate, grid: 6, backendKey: 'opening_date' },
  closingDate,
  files,
]

export const CAMPAIGNS_FIELDS = [
  { ...title, grid: 12, groupBy: MAIN },
  { ...targetIndustrySectors, grid: 6, groupBy: MAIN, backendKey: 'company_sectors' },
  // TODO: target companies per sector
  targetIndustrySegments,
  { ...targetCompanySize, grid: 6, groupBy: MAIN },
  { ...targetCompanyRevenue, grid: 6, groupBy: MAIN },
  { ...targetCountries, grid: 6, groupBy: MAIN },
  bwParticipants,
  { ...companyExample, label: 'Other Companies', grid: 6, groupBy: MAIN },
  // // Description
  product,
  // advantage,
  // // Avatar clients
  // Company pains
  painProblems,
  buyContext,
  // painFears,
  // painFrustrations,
  // Company gains
  // gainDesire,
  // gainConfidence,
  // gainHapiness,
  // People names
  decisionMaker,
  financier,
  purchaser,
  endUser,
  influencer,
  // People roles
  businessCaser,
  devilsAdvocate,
  earlyAdopter,
  friend,
  diplomat,
  // // Differentiation from competitors
  campaignWill,
  problem,
  problemUseContext,
  painRelievers,
  gainCreators,
  areasOfInnovation,
  campaignStrengths,
  brandFame,
  businessCase,
  improvesClientsCompetitiveness,
  improvesClientsResults,
  // reducesClientsRisks,
  // // Scoring board
  // scoringBoardReviews,
  // scoringBoardTestimonials,
  // scoringBoardRecommendations,
  // scoringBoardCertificates,
  // scoringBoardAwards,
  // scoringBoardPublications,
  //
  potentialObjections,
  potentialCompetitors,
  {
    ...bw20logs_services,
    label: 'BridgeWhat LOG (up to 3 choices, from droplist) to support this campaign)',
    detailsLabel: 'Bridgewhat LOG',
    groupBy: CREDENTIALS,
  },
  specialConditions,
  separator,
  { ...publicationDate, grid: 6, groupBy: OTHERS, backendKey: 'opening_date' },
  { ...closingDate, grid: 6, groupBy: OTHERS },
  { ...image, groupBy: OTHERS, hint: 'campaign_header_image_hint' },
  { ...brochure, groupBy: OTHERS },
  // Irresistible Scoring
  businessCaseOptions,
  irresistibleOffer,
  irresistibleOfferDiscounts
]

export const POSTS_FIELDS = [
  title,
  bw20log_services,
  content_wysiwyg,
  published,
  headerImage,
  thumbnailImage,
]
export const SAMPLINGS_FIELDS = [
  title,
  { ...description, label: description.label + ' (max: 580)', inputProps: { maxLength: 580 } },
  numberOfSamples,
  preferredDate,
  headerImage,
  brochure,
  imagesGallery,
  // insertlink,
  // publicationDate,
  // "prefered_date": "string",
  // "newsletter_date": "string",
  // "no_of_offers": 0,
  // "insertlink": "string",
  // "date_of_give_away": "string",
  // "winners": "string",
  // "generate_link": 0,
  // "link": "string",
  // "code": "string",
  // "winning_criteria": "string",
  // "brochure": 0,
  // "header_image": 0,
  // "images": [
  //   0
  // ]
  // "prefered_date": "string",
  // "newsletter_date": "string",
  // "date_of_give_away": "string",
  // "winners": "string",
  // "generate_link": 0,
  // "insertlink": "string",
  // "code": "string",
  // "winning_criteria": "string",
  // "images": [ 0 ]
]
export const PROTOCOLS_FIELDS = [
  title,
  description,
  businessServiceCategory, // TODO: get backend param service_id
  targetIndustrySectors, // TODO: get backend param sector_id
  targetCountries,
  publicationDate,
  dateOfValidity,
  image,
  brochure,
  // TODO: backend has extra param pom_categories
]

export const NEW_PARTNERSHIP_FIELDS = [
  title,
  description,
  businessServiceCategory, // TODO: get backend param service_id
  targetIndustrySectors, // TODO: get backend param sector_id
  targetCountries,
  companies,
  outside_companies,
  publicationDate,
  dateOfValidity,
  logo,
  brochure,
  // TODO: backend has extra param pom_categories
]

export const CARD_FOOTER_FIELDS = [
  targetIndustrySectors,
  businessServiceCategory,
  businessServiceCategoryServices,
  targetCountries,
  targetCompanyRevenue,
  targetCompanySize,
]
