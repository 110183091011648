import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Dropdown } from 'react-bootstrap'
import enFlag from 'assets/images/en_flag.jpg'
import ptFlag from 'assets/images/pt_flag.jpg'
import esFlag from 'assets/images/es_flag.jpg'

const LanguageSelector = () => {
  const { i18n } = useTranslation()

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value)
    localStorage.setItem('lang', event.target.value)
  }

  let lng = 'en'
  if (localStorage.getItem('lang')) {
    lng = localStorage.getItem('lang')
  }

  return (
    <Container className="position-relative">
      <div className="d-flex multi_language" onChange={changeLanguage}>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            {lng === 'en' && (
              <span>
                <img src={enFlag} alt="" /> EN
              </span>
            )}
            {lng === 'pt' && (
              <span>
                <img src={ptFlag} alt="" /> PT
              </span>
            )}
            {lng === 'es' && (
              <span>
                <img src={esFlag} alt="" /> ES
              </span>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="javascript void(0)">
              <div className="form-check dropdown-item">
                <input type="radio" value="en" id="en" name="language" checked={lng === 'en'} />
                <label className="form-check-label" htmlFor="en">
                  <img className="logo-img" src={enFlag} alt="" /> EN
                </label>
              </div>
            </Dropdown.Item>
            <Dropdown.Item href="javascript void(0)">
              <div className="form-check dropdown-item">
                <input type="radio" value="pt" id="pt" name="language" checked={lng === 'pt'} />
                <label className="form-check-label" htmlFor="pt">
                  <img className="logo-img" src={ptFlag} alt="" /> PT
                </label>
              </div>
            </Dropdown.Item>
            <Dropdown.Item href="javascript void(0)">
              <div className="form-check ">
                <input type="radio" value="es" id="es" name="language" checked={lng === 'es'} />
                <label className="form-check-label" htmlFor="es">
                  <img className="logo-img" src={esFlag} alt="" /> ES
                </label>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Container>
  )
}

export default LanguageSelector
