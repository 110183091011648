import React from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Row, Col } from 'react-bootstrap'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import MuiPhoneNumberValidatorElement from './ErrorComponent/MuiPhoneNumberValidatorElement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withTranslation } from 'react-i18next'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import {
  faDownload,
  faCloudDownloadAlt,
  faPlusCircle,
  faAward,
  faTrophy,
  faLink,
} from '@fortawesome/free-solid-svg-icons'
import { IconData } from 'components'
import { colorsByKey } from 'theme/colors'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
library.add(
  faDownload,
  faCloudDownloadAlt,
  faPlusCircle,
  faAward,
  faTrophy,
  faLink,
  faLink,
  faPlusCircle,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faInstagram
)

class Profileform extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        user_id: 0,
        services: [],
        is_seller: false,
        company_subscription_leavel: '',
        name: '',
        email: '',
        job_function: '',
        country_code: '',
        phone: '',
        pomservicesseller: false,
        personal_facebook: '',
        expertises: [],
        personal_twitter: '',
        personal_linkedin: '',
        personal_instagram: '',
        personal_youtube: '',
        personal_website: '',
      },
      profile_role: 0,
      services_selected: '',
      expertises: [],
      expertise_sectors_selected: '',
      online_presence_name: '',
      growth: [],
      submitted: false,
      loaded: false,
      error: null,
      erroremail: null,
      certificado: null,
      successmsg: '',
      isServicesDisabled: false,
      maxOptions: 3,
    }

    this.success_notify = React.createRef()
    this.error_notify = React.createRef()

    this.redirectTo = this.redirectTo.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule('isUrl')) {
      ValidatorForm.removeValidationRule('isUrl')
    }
    if (ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.removeValidationRule('isPhone')
    }
    if (ValidatorForm.hasValidationRule('maxLimit')) {
      ValidatorForm.removeValidationRule('maxLimit')
    }
    if (ValidatorForm.hasValidationRule('isLinkedinUrl')) {
      ValidatorForm.removeValidationRule('isLinkedinUrl')
    }
    if (ValidatorForm.hasValidationRule('isFacebookUrl')) {
      ValidatorForm.removeValidationRule('isFacebookUrl')
    }
  }

  async componentDidMount() {
    if (!ValidatorForm.hasValidationRule('isUrl')) {
      ValidatorForm.addValidationRule('isUrl', (value) => {
        if (value != null && value !== '') {
          var pattern = new RegExp(
            '((http|https)://)(www.)?' +
              '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
          )
          return !!pattern.test(value)
        }
        return true
      })
    }

    if (!ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.addValidationRule('isPhone', (value) => {
        if (value === null || value.trim() === '' || value.trim() === '+') {
          return false
        }
        return true
      })
    }

    if (!ValidatorForm.hasValidationRule('maxLimit')) {
      ValidatorForm.addValidationRule('maxLimit', (value) => {
        if (value !== null && value.length > 3) {
          return false
        }
        return true
      })
    }

    if (!ValidatorForm.hasValidationRule('isLinkedinUrl')) {
      ValidatorForm.addValidationRule('isLinkedinUrl', (value) => {
        if (value) {
          if (value.indexOf('linkedin.com') !== -1) {
            return true
          }
          return false
        } else {
          return true
        }
      })
    }

    if (!ValidatorForm.hasValidationRule('isFacebookUrl')) {
      ValidatorForm.addValidationRule('isFacebookUrl', (value) => {
        if (value) {
          if (value.indexOf('facebook.com') !== -1 || value.indexOf('fb.com') !== -1) {
            return true
          }
          return false
        } else {
          return true
        }
      })
    }

    if (this.props.userDetail) {
      const success = this.props.userDetail
      const { formData, maxOptions } = this.state
      formData['user_id'] = success.id

      if (success.is_seller === 1) {
        formData['is_seller'] = true
        if ('user_services' in success) {
          formData['services'] = success.user_services
          if (formData['services'].length >= maxOptions) {
            this.setState({ isServicesDisabled: true })
          }
          this.setState({ services_selected: '1' })
        }
        if ('expertises' in success) {
          formData['expertises'] = success.expertises
          this.setState({ expertise_sectors_selected: '1' })
        }
      }
      if (success.pomservicesseller === 1) {
        formData['pomservicesseller'] = true
      }
      formData['expertise_sector_name'] = success.expertise_sector_name
      formData['company_subscription_leavel'] = success.company_subscription_leavel
      formData['name'] = success.name
      formData['email'] = success.email
      formData['job_function'] = success.job_function
      formData['phone'] = success.phone_number
      formData['personal_facebook'] = success.personal_facebook
      formData['personal_twitter'] = success.personal_twitter
      formData['personal_linkedin'] = success.personal_linkedin
      formData['personal_instagram'] = success.personal_instagram
      formData['personal_youtube'] = success.personal_youtube
      formData['personal_website'] = success.personal_website
      if (
        success.personal_online_presence !== '[]' ||
        success.personal_online_presence !== 'null'
      ) {
        const personal_online_presence_parse = JSON.parse(success.personal_online_presence)
        if (personal_online_presence_parse !== null) {
          if (personal_online_presence_parse.length > 0)
            formData['personal_online_presence'] = personal_online_presence_parse
        }
      }

      console.log(process.env)
      this.setState({
        formData,
        profile_role: success.cb_roles_id,
        certificado:
          process && success && success.certificado
            ? `${process.env.REACT_APP_API_URL}${success.certificado.replace('show/', '')}`
            : '',
      })
    }

    const growth_api_url = process.env.REACT_APP_API_URL + '/api/services'
    await axios
      .get(growth_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ growth: data, loaded: true, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })

    const expertise_api_url = process.env.REACT_APP_API_URL + '/api/sectors'
    await axios
      .get(expertise_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ expertises: data, loaded: true, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleServices = (event, value) => {
    const { formData, maxOptions } = this.state
    if (value.length >= maxOptions) {
      // If so, update the state to disable the Autocomplete
      this.setState({
        formData: { ...formData, services: value },
        isServicesDisabled: true,
        services_selected: '1',
      })
    } else {
      // If not, update the state with the selected services
      this.setState({
        formData: { ...formData, services: value },
        isServicesDisabled: false,
        services_selected: value.length > 0 ? '1' : '',
      })
    }
  }

  handleexpertises = (event, value) => {
    const { formData } = this.state
    formData['expertises'] = value
    if (value !== null) {
      this.setState({ expertise_sectors_selected: '1' })
    } else {
      this.setState({ expertise_sectors_selected: '' })
    }
    this.setState({ formData })
  }

  handleOnlinePresence = (event, value) => {
    const { formData } = this.state
    formData['online_presence'] = value
    if (value !== null) {
      this.setState({ online_presence_name: '1' })
    } else {
      this.setState({ online_presence_name: '' })
    }
    this.setState({ formData })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handlePhoneChange(value) {
    const { formData } = this.state
    formData['phone'] = value
    this.setState({ formData })
  }

  handleChecked = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.checked
    this.setState({ formData })
  }

  addOnlinePresense() {
    const { formData } = this.state
    const { personal_online_presence } = formData
    formData['personal_online_presence'] = [...personal_online_presence, { level: '', value: '' }]
    this.setState({ formData })
  }

  removeOnlinePresense(i) {
    const { formData } = this.state
    const { personal_online_presence } = formData
    const personal_online_presence_list = [...personal_online_presence]
    personal_online_presence_list.splice(i, 1)

    if (personal_online_presence_list.length === 0) {
      formData['personal_online_presence'] = [{ level: '', value: '' }]
    } else {
      formData['personal_online_presence'] = personal_online_presence_list
    }

    this.setState({ formData })
  }
  handleCountryOperation = (event, value) => {
    const { formData } = this.state
    formData['country_code'] = value
    if (value !== null) {
      this.setState({ country_of_code: 1 })
    } else {
      this.setState({ country_of_code: 0 })
    }
    this.setState({ formData })
  }

  handleChangePresence(e, i) {
    const { name, value } = e.target
    const { formData } = this.state
    formData['personal_online_presence'][i][name] = value
    this.setState({ formData })
  }
  handleDownloadBrochure = () => {
    window.open(this.state.certificado, '_blank')
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/updateMainProfile'
      const { formData } = this.state

      if (formData.is_seller) formData['is_seller'] = 1
      else formData['is_seller'] = 0

      if (formData.pomservicesseller) formData['pomservicesseller'] = 1
      else formData['pomservicesseller'] = 0

      axios
        .post(sector_api_url, formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          this.props.updateProfile()
          this.setState({
            submitted: false,
            successmsg: this.props.t('Changes updated successfully'),
          })
          this.success_notify.current.handleOpen()
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, error: data.error, erroremail: data.error })
                this.error_notify.current.handleOpen()
              }
            } else {
              this.setState({
                submitted: false,
                error: this.props.t('Something went wrong, please try again'),
              })
              this.error_notify.current.handleOpen()
            }
          }
        })
    })
  }
  redirectTo = (web) => {
    window.open(`${process.env.REACT_APP_API_URL}${web}`, '_blank')
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state

    if (!this.state.loaded) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    if (this.state.profile_role === 2 || this.state.profile_role === '2') {
      return <div className="text-center minheight-100">SBA Profile Under Construction</div>
    }

    return (
      <div>
        {this.props.userDetail.subscription && (
          <Row>
            <Col md="12">
              <div className="form-group">
                <label>{t('Subscription')}: </label>
                &nbsp;&nbsp;
                <strong>{this.props.userDetail.subscription.name} </strong>
              </div>
            </Col>
          </Row>
        )}

        <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
          <Row>
            <Col md="12">
              <div className="form-group">
                <label className="w-100">{t('Does your company want to become a seller')}</label>
                <div className="d-flex seller-sect justify-content-md-start align-items-start">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck"
                      name="is_seller"
                      checked={formData.is_seller}
                      onChange={this.handleChecked}
                    />
                    <label className="custom-control-label" htmlFor="customCheck">
                      {t('Seller')}
                    </label>
                  </div>
                  {(formData.is_seller || formData.is_seller === 1) && (
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={this.state.growth}
                      name="services"
                      selected={formData.services}
                      defaultValue={formData.services}
                      onChange={this.handleServices}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.service_name}
                      getOptionDisabled={() => this.state.isServicesDisabled}
                      getOptionSelected={(option) => {
                        return formData.services.some(
                          (el) => el.service_name === option.service_name
                        )
                      }}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            color="primary"
                          />
                          {option.service_name}
                        </React.Fragment>
                      )}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextValidator
                          Select
                          {...params}
                          variant="outlined"
                          name="services_selected"
                          placeholder="Select services"
                          value={this.state.services_selected}
                          validators={['required']}
                          errorMessages={t('This field is required')}
                          disabled={this.state.isServicesDisabled}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </Col>
            {this.state.certificado && (
              <Col md="12">
                <Button
                  onClick={this.handleDownloadBrochure}
                  type="button"
                  className="submit-btn border-0 MuiButton-root MuiButton-contained py-2"
                >
                  <IconData
                    title={t('Download Certificado Buyer/Seller BW')}
                    icon={faDownload}
                    iconColor={colorsByKey.utility}
                    titleColor={colorsByKey.utility}
                    margin={1}
                  />
                </Button>
              </Col>
            )}
          </Row>

          {(formData.is_seller || formData.is_seller === 1) && (
            <Row>
              <hr className="w-100"></hr>
              <Col md="12">
                <div className="form-group">
                  <label>
                    {t(
                      'If your company is a seller with expertise on specific sectors, please select from the box. You can select up to three.'
                    )}
                  </label>
                  <Autocomplete
                    multiple
                    options={this.state.expertises}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option) => {
                      return formData.expertises.some((el) => el.name === option.name)
                    }}
                    name="expertises"
                    defaultValue={formData.expertises}
                    selected={formData.expertises}
                    onChange={this.handleexpertises}
                    renderInput={(params) => (
                      <TextValidator
                        Select
                        {...params}
                        fullWidth
                        variant="outlined"
                        name="expertise_sectors_selected"
                        value={this.state.expertise_sectors_selected}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
          )}
          <hr />
          <Row>
            <Col md="12">
              <label>
                {t(
                  'If your company is interested in becoming a Protocols seller, please select the box.'
                )}
              </label>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="pomservicesseller"
                  id="pomservicesseller"
                  checked={formData.pomservicesseller}
                  onChange={this.handleChecked}
                />
                <label className="custom-control-label mb-0" htmlFor="pomservicesseller">
                  {t('Protocols Seller')}
                </label>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="12" className="pt-2">
              <h6>{t('Contact Information')}</h6>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Contact Name')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  value={formData.name}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Email')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="email"
                  onChange={this.handleChange}
                  value={formData.email}
                  validators={['required', 'isEmail']}
                  errorMessages={[t('This field is required'), t('email is not valid')]}
                />
                {this.state.erroremail && <div className="error-msg">{this.state.erroremail}</div>}
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Job Function')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="job_function"
                  onChange={this.handleChange}
                  value={formData.job_function}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label>
                  {t('Phone Number')} <sup>*</sup>
                </label>

                <div className="w-100 phone_number_f">
                  <MuiPhoneNumberValidatorElement
                    name="phone"
                    className="phone_number"
                    data-cy="user-phone"
                    value={formData.phone}
                    onChange={this.handlePhoneChange}
                    fullWidth
                    variant="outlined"
                    validators={['isPhone']}
                    errorMessages={t('This field is required')}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="person-presence-min mb-2 mt-1">
              <h6>{t('Personal Online Presence')}</h6>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="personal_linkedin"
                      onChange={this.handleChange}
                      placeholder={`Linkedin (${t('Insert full url')})`}
                      value={formData.personal_linkedin}
                      validators={['isUrl', 'isLinkedinUrl']}
                      errorMessages={[t('Invalid url'), t('Invalid linkedin url')]}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="personal_facebook"
                      onChange={this.handleChange}
                      placeholder={`Facebook (${t('Insert full url')})`}
                      value={formData.personal_facebook}
                      validators={['isUrl', 'isFacebookUrl']}
                      errorMessages={[t('Invalid url'), t('Invalid facebook url')]}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faYoutube} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="personal_youtube"
                      onChange={this.handleChange}
                      placeholder={`Youtube (${t('Insert full url')})`}
                      value={formData.personal_youtube}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faInstagram} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="personal_instagram"
                      onChange={this.handleChange}
                      placeholder={`Instagram (${t('Insert full url')})`}
                      value={formData.personal_instagram}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="personal_twitter"
                      onChange={this.handleChange}
                      placeholder={`Twitter (${t('Insert full url')})`}
                      value={formData.personal_twitter}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <Grid className="d-flex align-items-center online-presence-min">
                  <Grid item className="online-presence-icon">
                    <FontAwesomeIcon icon={faLink} />
                  </Grid>
                  <Grid item className="w-100">
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="personal_website"
                      onChange={this.handleChange}
                      placeholder={`Website (${t('Insert full url')})`}
                      value={formData.personal_website}
                      validators={['isUrl']}
                      errorMessages={t('Invalid url')}
                    />
                  </Grid>
                </Grid>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="col-12 text-center">
              <Button type="submit" variant="contained" className="submit-btn" disabled={submitted}>
                {(submitted && t('Your form is submitted!')) || (!submitted && t('Save'))}
              </Button>
            </div>
          </Row>
        </ValidatorForm>
        {/* TODO: Add notification error and successmsg */}
      </div>
    )
  }
}
export default withTranslation()(Profileform)
