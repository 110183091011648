import React from 'react'
import axios from 'axios'
import history from '../../history'
import Button from '@material-ui/core/Button'
import { Helmet } from 'react-helmet'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Container, Row, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
class Updatepasswordpage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        password_token: this.props.code,
        password: '',
        c_password: '',
      },
      errormsg: '',
      successmsg: '',
      submitted: false,
      error: [],
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    if (!ValidatorForm.hasValidationRule('isPasswordMatch')) {
      ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        const { formData } = this.state
        if (value !== formData.password) {
          return false
        }
        return true
      })
    }

    if (!ValidatorForm.hasValidationRule('PasswordStrength')) {
      ValidatorForm.addValidationRule('PasswordStrength', (value) => {
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/
        return re.test(value)
      })
    }
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
      ValidatorForm.removeValidationRule('isPasswordMatch')
    }
    if (ValidatorForm.hasValidationRule('PasswordStrength')) {
      ValidatorForm.removeValidationRule('PasswordStrength')
    }
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
    this.setState({ errormsg: '', successmsg: '' })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/resetPassword'
      axios
        .post(sector_api_url, this.state.formData)
        .then((response) => {
          const { data } = response

          if (data.error) {
            this.setState({ errormsg: data.error, submitted: false })
          } else {
            history.push({
              pathname: '/',
              state: { resetmsg: data.success },
            })
          }
        })
        .catch((error) => {
          alert(this.props.t('Something went wrong, please try again'))
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }
    return (
      <div className="create-account-min forgot-password w-100 pb-5  d-flex align-items-center">
        <Helmet>
          <title>Update Password | BridgeWhat: Let's Grow!</title>
          <body className="header_s" />
        </Helmet>
        <Container className="d-flex align-items-center">
          <div className="create-account ">
            <div className="boxshadow p-3 pt-1">
              <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                <Row>
                  <Col md="12">
                    <h2 className="text-center h5 pb-3">{t('Update Password')}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="form-group">
                      <label>{t('Update Password')}</label>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        value={formData.password}
                        validators={['required', 'PasswordStrength']}
                        errorMessages={[
                          t('This field is required'),
                          t(
                            'password must be at least one number, one lowercase and one uppercase letter and min 6 character'
                          ),
                        ]}
                      />
                      {this.state.error.password && (
                        <div className="error-msg">{this.state.error.password}</div>
                      )}
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label>{t('Confirm Password')}</label>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="c_password"
                        type="password"
                        value={formData.c_password}
                        validators={['isPasswordMatch', 'required']}
                        errorMessages={[t('password mismatch'), t('This field is required')]}
                      />
                      {this.state.error.c_password && (
                        <div className="error-msg">{this.state.error.c_password}</div>
                      )}
                    </div>
                  </Col>
                  <Button
                    variant="contained"
                    type="submit"
                    className="submit-btn"
                    disabled={submitted}
                  >
                    {(submitted && t('Your form is submitted!')) ||
                      (!submitted && t('Change Password'))}
                  </Button>
                </Row>
                {this.state.errormsg && <div className="text-danger">{this.state.errormsg}</div>}
                {this.state.successmsg && (
                  <div className="text-success">{this.state.successmsg}</div>
                )}
              </ValidatorForm>
            </div>
          </div>
          <div className="w-75 site-logo">
            <img className="img-fluid" src={require('assets/images/logo.svg')} alt="logo" />
          </div>
        </Container>
      </div>
    )
  }
}
export default withTranslation()(Updatepasswordpage)
