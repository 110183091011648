import React from 'react'
import axios from 'axios'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import history from '../history'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import SocialButton from '../component/socialbutton/socialbutton'
import Login from '../component/login/login'
import { FEED } from 'entities/routes'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter, faGoogle, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
library.add(faFacebookF, faTwitter, faGoogle, faLinkedinIn)

class Loginpage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      msg: null,
    }

    this.handleSocialLogin = this.handleSocialLogin.bind(this)
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this)
    this.handleLinkedinLogin = this.handleLinkedinLogin.bind(this)
  }

  componentDidMount() {
    if ('state' in this.props.location) {
      if (this.props.location.state) {
        if ('resetmsg' in this.props.location.state) {
          this.setState({ msg: this.props.location.state.resetmsg })
        }
        setTimeout(
          function () {
            this.setState({ msg: null })
          }.bind(this),
          3000
        )
      }
    }
  }

  handleSocialLogin(user) {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/socialLogin'
      const socialData = {
        email: user._profile.email,
        providerToken: user._token.accessToken,
        provider: user._provider,
      }
      axios
        .post(sector_api_url, socialData)
        .then((response) => {
          // handle success
          const { data } = response
          localStorage.setItem('accessToken', data.success.token)
          history.push(FEED)
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                alert(data.error)
                this.setState({ submitted: false })
              }
            } else {
              this.setState({ submitted: false })
              alert('Something went wrong, please try again')
            }
          }
        })
    })
  }

  handleSocialLoginFailure(err) {
    console.error(err)
  }

  handleLinkedinLogin(user) {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/socialLogin'
      const socialData = {
        email: '',
        providerToken: user.code,
        provider: 'linkedin',
      }
      axios
        .post(sector_api_url, socialData)
        .then((response) => {
          // handle success
          const { data } = response
          localStorage.setItem('accessToken', data.success.token)
          history.push('/feed')
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                alert(data.error)
                this.setState({ submitted: false })
              }
            } else {
              this.setState({ submitted: false })
              alert('Something went wrong, please try again')
            }
          }
        })
    })
  }

  render() {
    return (
      <div>
        <Container className="d-flex  align-items-center">
          <Row>
            <Col md="12">
              {this.state.msg && <div className="text-success">{this.state.msg}</div>}
            </Col>
          </Row>
        </Container>
        <div className="create-account-min login-min ">
          <Container>
            <div className="d-flex align-items-center">
              <div className="create-account">
                <div className="boxshadow p-3 pt-1">
                  <Login></Login>
                  {/* <Row className="mt-3">
                    <Col>
                      <div className="social_icon_login" style={{ marginBottom: '10px' }}>
                        <LinkedIn
                          clientId="86d9xc0kub9l4f"
                          onFailure={this.handleSocialLoginFailure}
                          onSuccess={this.handleLinkedinLogin}
                          redirectUri={process.env.REACT_APP_FRONTEND_URL + '/linkedin'}
                        >
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </LinkedIn>
                        <SocialButton
                          className="fb-bg"
                          provider="facebook"
                          appId="1184189502014772"
                          onLoginSuccess={this.handleSocialLogin}
                          onLoginFailure={this.handleSocialLoginFailure}
                        >
                          <FontAwesomeIcon icon={faFacebookF} />
                        </SocialButton>
                        <SocialButton
                          className="g-bg"
                          provider="google"
                          appId="118706232903-ehi0jbd106kulta531f0ip5cooo7bobf.apps.googleusercontent.com"
                          onLoginSuccess={this.handleSocialLogin}
                          onLoginFailure={this.handleSocialLoginFailure}
                        >
                          <FontAwesomeIcon icon={faGoogle} />
                        </SocialButton>
                      </div>
                    </Col>
                  </Row> */}
                </div>
              </div>
              <div className="w-75 site-logo">
                <img className="img-fluid" src={require('assets/images/logo.svg')} alt="logo" />
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}
export default Loginpage
