import apiConfig from './api.config'
import stripeConfig from './stripe.config'
import businessConfig from './business.config'

const config = {
  api: apiConfig,
  business: businessConfig,
  stripe: stripeConfig,
}

export default config
