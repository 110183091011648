import React from 'react'
import { IconData } from 'components'
import { Dialog, DialogTitle, DialogContent, Avatar, Typography, Popover } from '@mui/material'
import { faXmark, faLink } from '@fortawesome/pro-light-svg-icons'
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { COMPANIES, POSTS, CAMPAIGNS } from 'entities/feed'
import {
  TYPE,
  ID,
  TITLE,
  HEADER_IMAGE,
  THUMBNAIL_IMAGE,
  WATERMARK_IMAGE,
} from 'entities/formFields'
import copy from 'copy-to-clipboard'

export const SocialCard = ({
  visible,
  onHide,
  props,
  showImage = true,
  showLinkedin = true,
  showTwitter = true,
  showFacebook = true,
  showCopy = true,
}) => {
  const socialItems = [
    {
      id: 'linkedin',
      label: 'LinkedIn',
      icon: faLinkedin,
      iconColor: 'white',
      bgColor: '#0084bf',
      url: 'https://www.linkedin.com/shareArticle?url=',
    },
    {
      id: 'facebook',
      label: 'Facebook',
      icon: faFacebook,
      iconColor: 'white',
      bgColor: '#4267b2',
      url: 'https://www.facebook.com/sharer/sharer.php?u=',
    },
    {
      id: 'twitter',
      label: 'Twitter',
      icon: faTwitter,
      iconColor: 'white',
      bgColor: '#1da1f2',
      url: 'https://twitter.com/share?via=bridge_what&url=',
    },
  ]

  const getShareUrl = () => {
    let url = ''
    if (props[TYPE] === POSTS) {
      return `${process.env.REACT_APP_API_URL.replace('/public', '')}/api/shareblog/${props[ID]}`
    } else {
      if (props[TYPE] === CAMPAIGNS) url = `campaign/${props[ID]}`
      else if (props[TYPE] === COMPANIES) url = `company/${props[ID]}`
      return `${process.env.REACT_APP_FRONTEND_URL}${url}`
    }
  }

  const [isCopiedToClipboard, setIsCopiedToClipboard] = React.useState(false)
  const handleCopyClipboard = () => {
    setIsCopiedToClipboard(true)
    copy(getShareUrl())
    setTimeout(() => setIsCopiedToClipboard(false), 2000)
  }

  const socialIcon = (item) => {
    const { id, label, icon, iconColor, bgColor, url } = item

    return (
      <span
        key={id}
        onClick={() =>
          window.open(
            `${url}${getShareUrl()}`,
            '_blank',
            `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=500,
          height=600`
          )
        }
        style={{
          width: 'fit-content',
          margin: '0 auto',
          float: 'left',
          marginRight: '5px',
        }}
      >
        <IconData
          icon={icon}
          iconSize={'xl'}
          margin={1}
          color={iconColor}
          style={{
            fontWeight: 'bold',
            cursor: 'pointer',
            borderRadius: '25px',
            width: '40px',
            height: '40px',
            paddingLeft: '9px',
            backgroundColor: bgColor,
            boxShadow: '2px 0px 8px rgba(0,0,0,0.10)',
          }}
        />
      </span>
    )
  }

  return (
    <Dialog
      open={visible}
      onClose={onHide}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
        <b>{props[TITLE]}</b>
        <span onClick={onHide}>
          <IconData
            icon={faXmark}
            iconSize={'sm'}
            margin={1}
            color={'black'}
            style={{
              float: 'right',
              marginTop: 0,
              marginRight: '-10px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          />
        </span>
      </DialogTitle>
      <DialogContent>
        {showImage && (
          <Avatar
            key={`image-`}
            alt={`post-picture`}
            src={
              props[WATERMARK_IMAGE] ||
              (props[THUMBNAIL_IMAGE] || {}).url ||
              (props[HEADER_IMAGE] || {}).url
            }
            variant="rounded"
            sx={{ width: '90%', height: 'auto', m: 1 }}
          />
        )}

        <div
          style={{
            width: 'fit-content',
            margin: '0 auto',
          }}
        >
          {socialItems.map((item) => {
            if (
              (item.id === 'twitter' && showTwitter) ||
              (item.id === 'facebook' && showFacebook) ||
              (item.id === 'linkedin' && showLinkedin)
            ) {
              return socialIcon(item)
            }
          })}
        </div>
        <br />
        <br />
        <div
          style={{
            width: 'fit-content',
            margin: '0 auto',
            marginBottom: '10px',
          }}
        >
          {showCopy && (
            <span
              onClick={() => handleCopyClipboard()}
              style={{
                width: '100%',
                margin: '0 auto',
              }}
              aria-describedby={isCopiedToClipboard ? 'simple-popover' : undefined}
            >
              <IconData
                icon={faLink}
                iconSize={'lg'}
                margin={1}
                color={'black'}
                style={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  borderRadius: '5px',
                  float: 'left',
                }}
              />
              <input
                type="text"
                value={getShareUrl()}
                size={50}
                style={{
                  float: 'left',
                }}
              />
            </span>
          )}
        </div>

        <Popover
          id={isCopiedToClipboard ? 'simple-popover' : undefined}
          open={isCopiedToClipboard}
          anchorEl={isCopiedToClipboard}
          onClose={() => setIsCopiedToClipboard(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography sx={{ p: 2 }}>{'Copied to clipboard!'}</Typography>
        </Popover>
      </DialogContent>
    </Dialog>
  )
}
