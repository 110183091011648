import React from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { Helmet } from 'react-helmet'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

class Forgotpassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        email: '',
      },
      errormsg: '',
      successmsg: '',
      submitted: false,
      error: null,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleClick = () => {
    this.setState({ open: true })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
    this.setState({ errormsg: '', successmsg: '' })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/forgotPassword'
      axios
        .post(sector_api_url, this.state.formData)
        .then((response) => {
          // handle success
          const { data } = response

          if (data.error) {
            this.setState({ errormsg: data.error, submitted: false })
          } else {
            this.setState({ successmsg: data.success, submitted: false })
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({
                  submitted: false,
                  error: this.props.t('Something went wrong, please try again'),
                })
                this.refs.error_notify.handleOpen()
              }
            } else {
              this.setState({
                submitted: false,
                error: this.props.t('Something went wrong, please try again'),
              })
              this.refs.error_notify.handleOpen()
            }
          }
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }
    return (
      <div className="create-account-min forgot-password w-100 pb-5 d-flex align-items-center">
        <Helmet>
          <title>Forgot Password | BridgeWhat: Let's Grow!</title>
          <body className="header_s" />
        </Helmet>
        <Container className="d-flex align-items-center">
          <div className="create-account ">
            <div className="boxshadow p-3 pt-1">
              <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                <Row>
                  <Col md="12">
                    <h2 className="text-center h5 pb-3">{t('Forgot Password')}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="form-group">
                      <label>{t('Email')}</label>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="email"
                        value={formData.email}
                        validators={['required', 'isEmail']}
                        errorMessages={[t('This field is required'), t('email is not valid')]}
                      />
                    </div>
                  </Col>
                  <Grid container>
                    <Grid item xs>
                      &nbsp;&nbsp;&nbsp;&nbsp;{t('Back to')}&nbsp;
                      <Link to="/">
                        <u>{t('Login')}</u>
                      </Link>
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    type="submit"
                    className="submit-btn mb-3"
                    disabled={submitted}
                  >
                    {(submitted && 'Your form is submitted!') ||
                      (!submitted && 'Retrieve Password')}
                  </Button>
                </Row>
              </ValidatorForm>
            </div>
          </div>
          <div className="w-75 site-logo">
            <img className="img-fluid" src={require('assets/images/logo.svg')} alt="logo" />
          </div>
        </Container>
        {/* TODO: Add notification error or successmsg */}
      </div>
    )
  }
}
export default withTranslation()(Forgotpassword)
