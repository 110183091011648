import React from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap'
import AskReferral from '../component/referral/askReferral'
import AskGuestReferral from '../component/referral/askGuestReferral'
import GiveReferral from '../component/referral/giveReferral'
import ListReferral from '../component/referral/listReferral'
import ReferralHeader from '../component/referral/referralHeader'
import { withTranslation } from 'react-i18next'

class ReferralPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      participants: [],
      referpage: 'testimonial',
      successmsg: null,
      error: null,
    }

    this.success_notify = React.createRef()
    this.error_notify = React.createRef()

    this.showSuccessMessage = this.showSuccessMessage.bind(this)
    this.showErrorMessage = this.showErrorMessage.bind(this)
  }

  async componentDidMount() {
    const api_url = process.env.REACT_APP_API_URL + '/api/listOfParticipants'
    await axios
      .get(api_url, { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
      .then((response) => {
        // handle success
        const { success } = response.data
        this.setState({ participants: success, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  showSuccessMessage(msg) {
    this.setState({ successmsg: msg })
    this.success_notify.current.handleOpen()
  }

  showErrorMessage(msg) {
    this.setState({ error: msg })
    this.error_notify.current.handleOpen()
  }

  changeTab(val) {
    this.setState({ referpage: val })
  }

  render() {
    const { t } = this.props
    const { participants, loading, referpage } = this.state

    if (loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div className="registration-min seller-details-min w-100 minheight-100 pb-5">
        <Helmet>
          <title>Referrals | BridgeWhat: Let's Grow!</title>
        </Helmet>
        <Container>
          <div className="boxshadow p-3 pt-1 pb-4">
            <ReferralHeader activekey={referpage} onChangeTab={(val) => this.changeTab(val)} />
            {referpage === 'testimonial' && (
              <>
                <Row>
                  <Col lg="6">
                    <h6 className="pb-3 pt-0">{t('Give a Testimonial')}</h6>
                    <GiveReferral
                      key="givetestimonial"
                      participants={participants}
                      referpage={referpage}
                    />
                  </Col>
                  <Col lg="6">
                    <h6 className="pb-3 pt-0">{t('Ask for a Testimonial')}</h6>
                    <AskReferral
                      key="asktestimonial"
                      participants={participants}
                      referpage={referpage}
                      showSuccessMessage={(msg) => this.showSuccessMessage(msg)}
                      showErrorMessage={(msg) => this.showErrorMessage(msg)}
                    />
                  </Col>
                </Row>

                <hr className="mb-4"></hr>
                <Row>
                  <Col lg="12">
                    <h6 className="pb-3">
                      {t('Ask for a Testimonial from someone outside the BWP')}
                    </h6>
                  </Col>
                  <AskGuestReferral
                    key="askguesttestimonial"
                    participants={participants}
                    referpage={referpage}
                    showSuccessMessage={(msg) => this.showSuccessMessage(msg)}
                    showErrorMessage={(msg) => this.showErrorMessage(msg)}
                  />
                </Row>
                <hr className="mb-4"></hr>

                <ListReferral referpage="testimonial" />
              </>
            )}

            {referpage === 'recommendation' && (
              <>
                <Row>
                  <Col lg="6">
                    <h6 className="pb-3 pt-0">{t('Give a Endorsement')}</h6>
                    <GiveReferral
                      key="giveendorsement"
                      participants={participants}
                      referpage={referpage}
                    />
                  </Col>
                  <Col lg="6">
                    <h6 className="pb-3 pt-0">{t('Ask for a Endorsement')}</h6>
                    <AskReferral
                      key="askendorsement"
                      participants={participants}
                      referpage={referpage}
                      showSuccessMessage={(msg) => this.showSuccessMessage(msg)}
                      showErrorMessage={(msg) => this.showErrorMessage(msg)}
                    />
                  </Col>
                </Row>

                <hr className="mb-4"></hr>
                <Row>
                  <Col lg="12">
                    <h6 className="pb-3">
                      {t('Ask for a Endorsement from someone outside the BWP')}
                    </h6>
                  </Col>
                  <AskGuestReferral
                    key="askguestendorsement"
                    participants={participants}
                    referpage={referpage}
                    showSuccessMessage={(msg) => this.showSuccessMessage(msg)}
                    showErrorMessage={(msg) => this.showErrorMessage(msg)}
                  />
                </Row>
                <hr className="mb-4"></hr>

                <ListReferral referpage="recommendation" />
              </>
            )}
          </div>
        </Container>
        {/* TODO: Add notification error and successmsg */}
      </div>
    )
  }
}

export default withTranslation()(ReferralPage)
