import React from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import { IconData } from 'components'
import { financialReportFields } from 'entities/companyFields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const FinancialReportBoxComponent = (props) => {
  const { t } = props
  const isDataAvailable = financialReportFields.some(({ key }) => props[key] != null)
  if (!isDataAvailable) return null
  else
    return (
      <Box
        sx={{
          background: colorsByKey.utility,
          p: 2,
          borderRadius: '6px',
          boxShadow: '2px 0px 8px rgba(0,0,0,0.10)',
        }}
      >
        <img src={require('assets/images/e-informa.png')} alt="Financial report logo" width={90} />
        <Box sx={{ mb: 2 }}>
          {financialReportFields.map(({ key, label, icon, tooltip }) => {
            if (props[key] == null) return undefined
            else {
              return (
                <Box
                  key={key}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    key={key}
                    sx={{
                      display: 'flex',
                      mt: 1,
                    }}
                  >
                    <IconData
                      title={t(label)}
                      color={colorsByKey.secondaryText}
                      icon={icon}
                      margin={1}
                    />
                    <Typography color={colorsByKey.accentText} sx={{ ml: 1 }}>
                      {props[key]}
                    </Typography>
                  </Box>
                  <Tooltip
                    sx={{
                      backgroundColor: `${colorsByKey.transparent} !important`,
                      p: 0,
                      m: 0,
                      boxShadow: 'none',
                    }}
                    title={
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: tooltip }} />
                      </Box>
                    }
                  >
                    <Box>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        size="sm"
                        color={colorsByKey.accentTextStrong}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              )
            }
          })}
        </Box>
      </Box>
    )
}
export const FinancialReportBox = withTranslation()(FinancialReportBoxComponent)
