import React, { useContext, useState, useEffect } from 'react'
import { UserProfileSidebar } from './components/UserProfileSectionSidebar'
import { Box, Typography, Container, Button, Input } from '@mui/material'
import { Spinner, IconData } from 'components'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import userContext from 'context/userContext'
import {
  DESCRIPTION,
  ABOUT,
  ADVISOR,
  BROCHURE,
  MAIN_CAREER_ACHIEVEMENT,
  mainCareerAchievement,
  credentialsSections,
} from 'entities/companyFields'
import { expertises, EXPERTISES } from 'entities/company'
import { useHistory } from 'react-router-dom'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { apiGet } from 'api'
import { useParams } from 'react-router-dom'
import './UserProfileSection.css'

const UserProfileSectionComponent = ({ t, userType, filters, fetchData }) => {
  const ctx = useContext(userContext)
  let history = useHistory()
  let { id } = useParams()

  const handleRedirectTo = (url) => {
    history.push(url)
  }
  const handleDownloadBrochure = () => {
    window.open(ctx.userData[BROCHURE].url, '_blank')
  }

  const expertisesEntity = credentialsSections[EXPERTISES]

  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState([])

  const setItemsData = (data) => {
    setItems(data)
  }
  useDeepCompareEffect(() => {
    const fetchList = async () => {
      setIsLoading(true)
      apiGet(`${expertisesEntity.list}/${id}`)
        .then(({ data }) => {
          expertises.list.dataNormalizer(data)
          setItemsData(expertises.list.dataNormalizer(data))
        })
        .finally(() => setIsLoading(false))
    }
    fetchList()
  }, [expertisesEntity])

  useEffect(() => {
    console.log(ctx.userData)
  }, ctx.loadingUserData)

  return (
    <Container sx={{ display: 'flex' }} maxWidth={false} disableGutters>
      <Box className="Sidebar" width={280}>
        <UserProfileSidebar userType={userType} filters={filters} fetchData={fetchData} />
      </Box>
      <Box className="Content" sx={{ flex: 1, py: 4, px: 4, background: colorsByKey.utility }}>
        <Typography
          variant="f20"
          color={colorsByKey.defaultText}
          sx={{ mb: 2, fontWeight: 'bold' }}
        >
          {userType === ADVISOR ? t('Bio') : t('About')}
        </Typography>
        {ctx.loadingUserData && <Spinner />}
        {!ctx.loadingUserData && userType === ADVISOR && (
          <>
            {ctx.userData[ABOUT] && (
              <Box>
                <Input
                  id={ABOUT}
                  value={ctx.userData[ABOUT]}
                  name={ABOUT}
                  multiline={true}
                  disabled={true}
                  type="text"
                  size="small"
                  variant="standard"
                  margin="none"
                  color="primary"
                  disableUnderline
                  fullWidth
                />
              </Box>
            )}
            {!ctx.userData[ABOUT] && (
              <Typography variant="f14" color={colorsByKey.accentTextStrong}>
                {t('no data yet!')}
              </Typography>
            )}
            {ctx.userData[BROCHURE] && userType === ADVISOR && (
              <Button
                type="button"
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={handleDownloadBrochure}
              >
                <Typography noWrap>{t('Download CV')}</Typography>
              </Button>
            )}
          </>
        )}
        {!ctx.loadingUserData && userType !== ADVISOR && (
          <>
            {ctx.userData[DESCRIPTION] && (
              <Box>
                <Typography variant="f16" color={colorsByKey.defaultText}>
                  {ctx.userData[DESCRIPTION]}
                </Typography>
              </Box>
            )}
            {!ctx.userData[DESCRIPTION] && (
              <Typography variant="f14" color={colorsByKey.accentTextStrong}>
                {t('no data yet!')}
              </Typography>
            )}
          </>
        )}
        {!ctx.loadingUserData && userType === ADVISOR && ctx.userData[MAIN_CAREER_ACHIEVEMENT] && (
          <Box sx={{ mt: 6 }}>
            <Typography
              variant="f16"
              color={colorsByKey.defaultText}
              sx={{ mb: 2, fontWeight: 'bold' }}
            >
              {t(mainCareerAchievement.label)}
            </Typography>
            <Input
              id={MAIN_CAREER_ACHIEVEMENT}
              value={ctx.userData[MAIN_CAREER_ACHIEVEMENT]}
              name={MAIN_CAREER_ACHIEVEMENT}
              multiline={true}
              disabled={true}
              type="text"
              size="small"
              variant="standard"
              margin="none"
              color="primary"
              disableUnderline
              fullWidth
            />
          </Box>
        )}

        {userType === ADVISOR && (
          <Box sx={{ borderTop: `2px solid ${colorsByKey.primaryBgWeak}`, mt: 6, pt: 6 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="f20"
                color={colorsByKey.defaultText}
                sx={{ mb: 4, fontWeight: 'bold' }}
              >
                {t(expertises.label)}
              </Typography>
              {/* TODO: RESTORE EDIT ICON */}
              {ctx.isMyProfile && (
                <Box onClick={() => handleRedirectTo(expertises[`${userType}ActionPath`])}>
                  <IconData
                    icon={faEdit}
                    clickable
                    margin={1}
                    color={colorsByKey.accentTextStrong}
                  />
                </Box>
              )}
            </Box>
            {(ctx.loadingUserData || isLoading) && <Spinner />}
            {!ctx.loadingUserData && !isLoading && !!items.length && (
              <Box>
                {items.map(
                  ({ jobFunction, company, dateRange, experienceYears, highlights }, idx) => (
                    <Box
                      key={idx}
                      sx={{
                        borderBottom:
                          idx + 1 !== items.length ? `1px solid ${colorsByKey.defaultBgWeak}` : '',
                        display: 'flex',
                        pb: 2,
                        mb: 2,
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: '250px',
                          mr: 4,
                          width: '250px',
                          overflowWrap: 'break-word',
                        }}
                      >
                        <Typography
                          variant="f16"
                          color={colorsByKey.defaultText}
                          sx={{ mb: 1, fontWeight: 'bold' }}
                        >
                          {jobFunction}
                        </Typography>
                        <Typography
                          variant="f14"
                          color={colorsByKey.accentTextStrong}
                          sx={{ mb: 1, fontWeight: 'bold' }}
                        >
                          {company}
                        </Typography>
                        {dateRange && (
                          <Typography variant="f14" color={colorsByKey.defaultText} sx={{ mb: 1 }}>
                            {dateRange}
                          </Typography>
                        )}
                        {!!experienceYears && experienceYears > 0 && (
                          <Typography
                            variant="f14"
                            color={colorsByKey.defaultTextDisabled}
                            sx={{ mb: 1 }}
                          >
                            {experienceYears} {t('years')}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          variant="f14"
                          color={colorsByKey.defaultTextDisabled}
                          sx={{ mb: 1, fontWeight: 'bold' }}
                        >
                          {t('Highlights')}
                        </Typography>
                        <Typography variant="f14" color={colorsByKey.defaultText} sx={{ mb: 1 }}>
                          {highlights}
                        </Typography>
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            )}
            {!ctx.loadingUserData && !isLoading && !items.length && (
              <Typography variant="f14" color={colorsByKey.accentTextStrong}>
                {t('no data yet!')}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Container>
  )
}
export const UserProfileSection = withTranslation()(UserProfileSectionComponent)
