import React from 'react'
import axios from 'axios'
import history from '../../../history'
import Container from 'react-bootstrap/Container'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import 'bootstrap/dist/css/bootstrap.min.css'
import AnnualPackage from '../../annualpackage/annualpackage'
import { withTranslation } from 'react-i18next'
import { loadStripe } from '@stripe/stripe-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import axiosInstance from 'api/axios'
import styled from '@emotion/styled'
import { faChevronRight, faClose } from '@fortawesome/pro-light-svg-icons'
import { colorsByKey } from 'theme/colors'
import './crystalModal.css'

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#339338',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

class SubscriptionPackage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedB: true,
      subscriptions: [],
      loading: true,
      user_id: 0,
      subscription_package_id: 0,
      subs_detail: null,
      voucher: '',
      voucherError: null,
      validatingVoucher: false,
      showCrystalModal: false,
      isOptionPlusAvailable: false,
      plusPrice: '',
      plusChecked: false,
    }

    this.selSubscription = this.selSubscription.bind(this)
    this.selSubscriptionFree = this.selSubscriptionFree.bind(this)
  }

  async selSubscription(subid, payoption) {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY)

    const formdata = {
      user_id: this.state.user_id,
      id: subid,
      substype: this.state.checkedB ? 'annual' : 'monthly',
      payoption: payoption,
    }

    this.setState({ subscription_package_id: subid })

    if (payoption === 'Bank') {
      const stripe_api_url = process.env.REACT_APP_API_URL + '/api/bankPost'
      axios
        .post(stripe_api_url, formdata, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          history.push('/bank-transfer')
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      const stripe_api_url = process.env.REACT_APP_API_URL + '/api/stripePost'
      axios
        .post(stripe_api_url, formdata, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          const { sessionid } = response.data
          if (sessionid) {
            stripe.redirectToCheckout({
              sessionId: sessionid,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  onPickCrystal = () => {
    this.setState({ showCrystalModal: true })
  }

  onCloseCrystalModal = () => {
    this.setState({ showCrystalModal: false })
  }

  async selSubscriptionFree(subid) {
    const formdata = {
      user_id: this.state.user_id,
      id_suscription: subid,
    }

    const api_url = process.env.REACT_APP_API_URL + '/api/suscriptionfree'
    axios
      .post(api_url, formdata, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      })
      .then(() => {
        this.props.nextStep(2)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  async componentDidMount() {
    if (this.props.userDetail) {
      this.setState({
        checkedB:
          (this.props.userDetail?.subscription_type ?? "annual") === ('annual' || 'free') ? true : false,
      })
      const success = this.props.userDetail
      this.setState({ user_id: success.id })
      // console.log(this.props.close)
      console.log(this.props.isUpdateSubscription)
    }

    const sector_api_url = process.env.REACT_APP_API_URL + '/api/packages'
    await axios
      .get(sector_api_url, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      })
      .then((response) => {
        const { data } = response.data
        const isOptionPlusAvailable = response.data.option_plus_available
        const plusPrice = response.data.option_plus_price

        // console.log(isOptionPlusAvailable)
        // console.log(plusPrice)

        if (this.props.subscription > 0) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].id === parseInt(this.props.subscription)) {
              this.setState({ subs_detail: data[i] })
            }
          }
        }
        this.setState({
          subscriptions: data,
          loading: false,
          isOptionPlusAvailable: isOptionPlusAvailable,
          plusPrice: plusPrice,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
    // console.log(this.state.checkedB)
  }

  handleChangePlus = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  logout = () => {
    if (localStorage.getItem('accessToken') !== null) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('accessTokenRole')
    }

    history.push('/')
  }

  handleSubmit = () => {
    this.setState({ validatingVoucher: true })
    axiosInstance
      .post(
        '/api/vouchers/validate',
        { voucher: this.state.voucher },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        }
      )
      .then(() => {
        this.setState({ validatingVoucher: false })
        this.props.nextStep(2)
      })
      .catch((err) => {
        this.setState({ validatingVoucher: false })
        this.setState({ voucherError: err.response.data.error })
      })
  }

  render() {
    const { t } = this.props
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div>
        <div>
          <Container className="w-bg border-radius pt-0 pl-5 pb-4 mb-3 pr-5">
            {!this.props?.close && (
              <>
                <div className="row">
                  <div className="col-12 text-center pt-0">
                    <h6>{t('Subscription Packages')}</h6>
                  </div>
                </div>
                <FormGroup className="text-center align-content-center subscription-div">
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>{t('Monthly')}</Grid>
                      <Grid item>
                        <IOSSwitch
                          checked={this.state.checkedB}
                          onChange={this.handleChange}
                          name="checkedB"
                        />
                      </Grid>
                      <Grid item>{t('Annual')}</Grid>
                    </Grid>
                  </Typography>

                  {this.state.isOptionPlusAvailable && (
                    <Typography component="div">
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                        alignContent="center"
                        justifyContent="center"
                      >
                        <Grid item>{t('Get Plus')}</Grid>
                        <Grid item>
                          <IOSSwitch
                            checked={this.state.plusChecked}
                            onChange={this.handleChangePlus}
                            name="plusChecked"
                          />
                        </Grid>
                      </Grid>
                    </Typography>
                  )}
                </FormGroup>
              </>
            )}
            <AnnualPackage
              subscriptions={this.state.subscriptions}
              yearly={
                // (this.props?.userDetail?.subscription_type ?? 'yearly') ||
                this.state.checkedB
              }
              subscription_package_id={this.state.subscription_package_id}
              selSubscription={(subid, payoption) => this.selSubscription(subid, payoption)}
              selSubscriptionFree={(subid) => this.selSubscriptionFree(subid)}
              subs_detail={this.state.subs_detail}
              nextStep={() => this.props.nextStep(2)}
              onPickCrystal={() => this.onPickCrystal()}
              close={this.props?.close ?? false}
              open={this.props?.open ?? false}
              plusChecked={this.state.plusChecked}
              plusPrice={this.state.plusPrice}
            ></AnnualPackage>
            {!this.props?.close &&
              !this.props.isUpdateSubscription ? (
                <div className="d-flex justify-content-between align-items-center pl-2 pt-3">
                  <div className="d-flex align-items-start">
                    <Button
                      closeButton
                      onClick={this.logout}
                      className="submit-btn m-0 mr-5 border-0 MuiButton-root MuiButton-contained"
                      style={{ height: 42 }}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                      &nbsp;&nbsp;Cancelar
                    </Button>
                    <div>
                      <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          {/* <label>Voucher</label> */}
                          <VoucherWrapper style={{ display: 'flex' }}>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={(e) => this.setState({ voucher: e.target.value })}
                              name="voucher"
                              value={this.state.voucher}
                              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                              placeholder="Voucher"
                            />
                            <VoucherSubmitButton type="submit">
                              {!this.state.validatingVoucher && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32"
                                  height="32"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    d="M5 10a.75.75 0 0 1 .75-.75h6.638L10.23 7.29a.75.75 0 1 1 1.04-1.08l3.5 3.25a.75.75 0 0 1 0 1.08l-3.5 3.25a.75.75 0 1 1-1.04-1.08l2.158-1.96H5.75A.75.75 0 0 1 5 10Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                              {this.state.validatingVoucher && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32"
                                  height="32"
                                  viewBox="0 0 24 24"
                                  style={{ padding: 4 }}
                                >
                                  <path
                                    fill="currentColor"
                                    d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                                    opacity=".25"
                                  />
                                  <path
                                    fill="currentColor"
                                    d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      dur="0.75s"
                                      repeatCount="indefinite"
                                      type="rotate"
                                      values="0 12 12;360 12 12"
                                    />
                                  </path>
                                </svg>
                              )}
                            </VoucherSubmitButton>
                          </VoucherWrapper>
                          {this.state.voucherError && (
                            <p style={{ color: 'red' }}>{this.state.voucherError}</p>
                          )}
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                  <div className="text-right alert-danger bg-white pr-2">
                    <small>Prices do not include VAT</small>
                  </div>
                </div>
              ): !this.props?.close &&
              this.props.isUpdateSubscription && (
                <div className="text-right alert-danger bg-white pr-2">
              <small>Prices do not include VAT</small>
            </div>
              )}
            
          </Container>
        </div>

        {this.state.showCrystalModal && (
          <div>
            <div
              className="crystal-modal-backdrop"
              onClick={() => this.onCloseCrystalModal()}
            ></div>
            <div className="crystal-modal">
              <Button className="closeBtn" onClick={() => this.onCloseCrystalModal()}>
                <FontAwesomeIcon icon={faClose} size="xs" color={colorsByKey.utility} />
              </Button>
              <header>
                <h1>You're almost there</h1>
                <p>
                  To get started please insert your
                  <br />
                  Crystal voucher number below.
                </p>
              </header>
              <main>
                <div className="formInput">
                  <input
                    type="text"
                    name="crystal-voucher"
                    placeholder="Voucher"
                    onChange={(e) => this.setState({ voucher: e.target.value })}
                    value={this.state.voucher}
                  />
                  <Button className="submitBtn" onClick={this.handleSubmit}>
                    {!this.state.validatingVoucher && (
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        size="xs"
                        color={colorsByKey.utility}
                      />
                    )}
                    {this.state.validatingVoucher && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        style={{ padding: 4 }}
                      >
                        <path
                          fill="currentColor"
                          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                          opacity=".25"
                        />
                        <path
                          fill="currentColor"
                          d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                        >
                          <animateTransform
                            attributeName="transform"
                            dur="0.75s"
                            repeatCount="indefinite"
                            type="rotate"
                            values="0 12 12;360 12 12"
                          />
                        </path>
                      </svg>
                    )}
                  </Button>
                  {this.state.voucherError && (
                    <p style={{ color: 'red' }}>{this.state.voucherError}</p>
                  )}
                </div>
                <span>
                  If you don't have a voucher, please contact{' '}
                  <a href="mailto:bridgewhat@bridgewhat.co" target="_blank">
                    bridgewhat@bridgewhat.com
                  </a>
                </span>
              </main>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const VoucherWrapper = styled.div`
  display: flex;
`

const VoucherSubmitButton = styled.button`
  border: 0;
  background-color: #3b5680;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  color: white;
`

export default withTranslation()(SubscriptionPackage)
