import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'

const modalContentMap = {
  // TODO: add the rest of modal contents here
  // TODO: content should be a separated form
  advisor: {
    content: <div>advisor</div>,
    title: 'I need an advisor',
  },
}

const PublishDialog = ({ action, onClose }) => {
  const isOpen = action != null
  const modalContent = modalContentMap[action]

  if (modalContent == null) {
    return null
  }

  const { content, title } = modalContent

  return (
    <Dialog open={isOpen} onClose={onClose} scroll="paper">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onClose}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PublishDialog
