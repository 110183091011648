import React, { useEffect, useContext } from 'react'
import { useState } from 'react'
import { apiGet, apiPost } from 'api'
import { PROFILE, CREDENTIALS, CHANNEL } from 'entities/company'
import { Container } from '@mui/material'
import { UserProfileSection, UserCredentialsSection, UserChannelSection } from '../sections'
import { withTranslation } from 'react-i18next'
import { Spinner } from 'components'
import userContext from 'context/userContext'
import { QueryFiltersContextProvider } from 'context/queryFiltersContext'
import { COMPANY_FEED_TABS_ARRAY, ADVISOR_FEED_TABS_ARRAY } from 'entities/feed'
import { COMPANY } from 'entities/companyFields'
import filters from 'mixins/filters'

const UserSectionComponent = ({ t, userType, sectionTabs, userDetail }) => {
  const ctx = useContext(userContext)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState({})

  const allFilters = filters()

  const channelTabs = userType === COMPANY ? COMPANY_FEED_TABS_ARRAY : ADVISOR_FEED_TABS_ARRAY

  const refreshProfileData = () => {
    if (ctx.sectionTab === PROFILE) ctx.fetchUserData(ctx.userData.id)
  }

  useEffect(() => {
    let allData = {}
    // console.log('fetch', method)
    const singleFetch = async (
      key,
      method,
      endpoint,
      paramIdKey,
      params,
      responseKey,
      dataNormalizer
    ) => {
      // console.log('method', method)
      method === 'GET'
        ? await apiGet(`${endpoint}/${ctx.userData.id}`).then((data) => {
            const normalizedData = dataNormalizer(data[responseKey])
            // allData = { ...allData, [key]: normalizedData }
            // return allData
            allData = { ...allData, [key]: normalizedData }
            return { [key]: data[responseKey] }
          })
        : await apiPost(endpoint, {
            ...params,
            [paramIdKey]: ctx.userData.id,
          }).then((response) => {
            const normalizedData = dataNormalizer(response[responseKey])
            allData = { ...allData, [key]: normalizedData }
            return { [key]: response[responseKey] }
          })
    }

    const fetchData = async () => {
      setIsLoading(true)
      setError(null)
      try {
        const section = sectionTabs.find((s) => s.key === ctx.sectionTab)
        const sections = userType === COMPANY ? section.companySections : section.advisorSections

        const actions = (sections || []).map((s) => {
          const { method, endpoint, paramIdKey, params, responseKey, dataNormalizer } = s.list
          // console.log('list', s.list, endpoint)
          return singleFetch(
            s.key,
            method,
            endpoint,
            paramIdKey,
            params,
            responseKey,
            dataNormalizer
          )
        })
        // console.log('actions', actions)
        Promise.all(actions).then(() => {
          setData(allData)
        })
      } catch (err) {
        setError(err?.message ?? 'Some error')
      } finally {
        setIsLoading(false)
      }
    }
    if (ctx.sectionTab !== PROFILE && ctx.userData.id) fetchData()
  }, [ctx.userData.id, ctx.sectionTab, sectionTabs, userType])

  if (isLoading) {
    return <Spinner />
  }

  if (ctx.sectionTab !== PROFILE && error) {
    return <div>{error}</div>
  }

  return (
    <Container maxWidth={'xl'} sx={{ flex: 1 }}>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          {ctx.sectionTab === PROFILE && (
            <UserProfileSection
              userType={userType}
              filters={allFilters}
              fetchData={refreshProfileData}
            />
          )}
          {ctx.sectionTab === CREDENTIALS && (
            <UserCredentialsSection data={data} key={`user-section-${CREDENTIALS}`} />
          )}
          {ctx.sectionTab === CHANNEL && (
            <QueryFiltersContextProvider tabs={channelTabs}>
              <UserChannelSection
                userId={ctx.userData.id}
                data={data}
                key={`user-section-${CHANNEL}`}
                tabs={channelTabs}
                userDetail={userDetail}
              />
            </QueryFiltersContextProvider>
          )}
        </>
      )}
    </Container>
  )
}
export const UserSection = withTranslation()(UserSectionComponent)
