import React, { useRef, useContext, useState } from 'react'
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import { UserInfoCard } from 'screens/User/components/UserInfoCard'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import { IconData, IconDataBadge, Spinner } from 'components'
import {
  SIDEBAR_COMPANY_INFO_FIELDS,
  SIDEBAR_ADVISOR_INFO_FIELDS,
  ADVISOR_PROFILE_FIELDS,
  ADVISOR_PROFILE_EXTRA_FIELDS,
  CONTACT_FIELDS,
  ADDRESS_FIELDS,
  SOCIAL_MEDIA_FIELDS,
  LEGAL_INFO_FIELDS,
  industrySectors,
  userIndustrySectors,
  leversOfGrowth,
  PROFILE_FIELDS,
  EXPERIENCE_YEARS,
  COMPANY,
  ADVISOR,
  brochure,
  EXPERTISE_FIELDS,
  GEOGRAPHY,
} from 'entities/companyFields'
import userContext from 'context/userContext'
import { PROFILE } from 'entities/routes'
import Form from './Form'
import { apiPut } from 'api'
import { useHistory } from 'react-router-dom'

const UserProfileSidebarComponent = ({ t, userDetail, userType, filters, fetchData }) => {
  const ctx = useContext(userContext)
  const isSocialMediaDataAvailable = SOCIAL_MEDIA_FIELDS.some(
    ({ key }) => (ctx.userData.socialMedia || {})[key] != null
  )
  const redirectTo = (web) => {
    window.open(web, '_blank')
  }
  let history = useHistory()
  const handleRedirectTo = (link) => {
    history.push(link)
  }

  const [selectedKey, setSelectedKey] = useState(null)
  const [selectedFormFields, setSelectedFormFields] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const [submitted, setSubmitted] = useState(false)

  const onClose = () => {
    setSelectedKey(null)
    setSelectedFormFields([])
  }
  const onSave = () => {
    setSubmitted(true)
    const formData = formRef.current.getFormData()
    const isValid = formRef.current.validateForm()
    // const newFormData = {
    //   ...formData,
    //   experience_start_date: moment(formData.experience_start_date),
    // }

    // console.log('formData', formData)
    // console.log('newFormData', newFormData)

    if (formData?.errorMessage) {
      // showDialog(({ close }) => ({
      //   title: 'Error',
      //   text: formData.errorMessage,
      //   actions: [{ text: 'Ok', action: () => close() }],
      // }))
      // return Promise.reject()
    }

    // console.log(userDetail, ctx.userData)

    // TODO: IS THIS OK FOR COMPANIES?
    // console.log('ctx.userData.id', ctx.userData.id)
    if (isValid) {
      const request = apiPut(`/api/advisors/${ctx.userData.id}`, formData)
      return request.then((response) => {
        setSubmitted(false)
        onClose()
        fetchData()
      })
    } else {
      setErrorMessage('Hay errores en los campos del formulario')
      setSubmitted(false)
      setTimeout(() => setErrorMessage(''), 2000)
    }
  }

  const formRef = useRef(null)

  const postData = ctx.userData || {}

  const hiddenFields = {}

  const profileFields =
    userType === COMPANY
      ? SIDEBAR_COMPANY_INFO_FIELDS
      : [...SIDEBAR_ADVISOR_INFO_FIELDS, { ...brochure, label: 'CV' }]
  const basicProfileFields = userType === COMPANY ? PROFILE_FIELDS : ADVISOR_PROFILE_FIELDS
  const sectors =
    userType === COMPANY ? { ...industrySectors, multiple: false } : userIndustrySectors

  const sections = {
    personal: {
      title: userType === COMPANY ? t('Company info') : t('Personal info'),
      fields: profileFields,
      editableFields: [
        ...basicProfileFields,
        ...ADVISOR_PROFILE_EXTRA_FIELDS,
        ...profileFields.filter((f) => f.key !== EXPERIENCE_YEARS),
      ],
      requiredFields: ['name'],
    },
    expertise: {
      title: t('Experience'),
      fields: [leversOfGrowth, sectors, ...EXPERTISE_FIELDS],
      editableFields: [
        { ...leversOfGrowth, backendKey: userType === COMPANY ? 'service_id' : 'user_services' },
        sectors,
        // {
        //   ...industrySectors,
        //   multiple: false,
        //   backendKey: userType === COMPANY ? 'sectors' : 'company_sector',
        // },
        ...EXPERTISE_FIELDS,
      ],
      requiredFields: [],
    },
    contact: {
      title: userType === COMPANY ? t('Contact with us') : t('Contact Information'),
      fields: CONTACT_FIELDS,
      editableFields: [...CONTACT_FIELDS, ...ADDRESS_FIELDS, ...SOCIAL_MEDIA_FIELDS],
      requiredFields: [],
    },
    legal: {
      title: t('Legal info'),
      fields: LEGAL_INFO_FIELDS,
      editableFields: LEGAL_INFO_FIELDS,
      requiredFields: [],
    },
  }
  const onEditUserSection = (key) => {
    setSelectedKey(key)
    const formFields = sections[key].editableFields.map((p) =>
      p.optionType ? { ...p, options: filters[p.optionType] } : p
    )
    // const formFields = [...sections[key].fields, ...sections[key].extraFields].map((p) =>
    //   p.optionType ? { ...p, options: filters[p.optionType] } : p
    // )
    setSelectedFormFields(formFields)
  }

  return (
    <Box
      className="UserProfileSidebar"
      minWidth={280}
      sx={{ background: colorsByKey.defaultBgWeaker, pr: 4, py: 4 }}
    >
      <Dialog open={selectedKey !== null} onClose={onClose} scroll="paper">
        <DialogTitle>{(sections[selectedKey] || {}).title}</DialogTitle>
        <DialogContent dividers sx={{ width: '600px', minHeight: '300px' }}>
          {!submitted && (
            <Form
              ref={formRef}
              postData={postData}
              formFields={selectedFormFields}
              requiredFields={(sections[selectedKey] || {}).requiredFields || []}
              hiddenFields={hiddenFields}
            />
          )}
          {submitted && <Spinner />}
        </DialogContent>
        <DialogActions>
          <Box sx={{ flex: 1, mx: 2 }}>
            <Typography color={colorsByKey.alertText}>{errorMessage}</Typography>
          </Box>
          <Button disabled={submitted} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button disabled={submitted} onClick={onSave}>
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ mb: 4 }}>
        <UserInfoCard
          title={sections.personal.title}
          isEditable={ctx.isMyProfile}
          isLoading={ctx.loadingUserData}
          actionOnClick={() =>
            userType === COMPANY ? handleRedirectTo(PROFILE) : onEditUserSection('personal')
          }
        >
          {sections.personal.fields.map(({ key, label, icon, suffix, getText }, idx) => {
            if (!ctx.userData[key] || !(ctx.userData[key] || []).length) return
            else
              return (
                <Box key={`${key}-${idx}`} sx={{ mb: 2 }}>
                  <IconData
                    title={label}
                    type={key}
                    allowWrap
                    text={getText ? getText(ctx.userData[key]) : ctx.userData[key]}
                    icon={icon}
                    textSuffix={suffix}
                    sx={{ alignItems: 'flex-start' }}
                    useCustomIcon={key === GEOGRAPHY}
                  />
                </Box>
              )
          })}
        </UserInfoCard>
      </Box>
      {userType === ADVISOR && (
        <Box sx={{ mb: 4 }}>
          <UserInfoCard
            title={sections.expertise.title}
            isEditable={ctx.isMyProfile}
            isLoading={ctx.loadingUserData}
            actionOnClick={() =>
              userType === COMPANY ? handleRedirectTo(PROFILE) : onEditUserSection('expertise')
            }
          >
            {!ctx.loadingUserData && (
              <>
                {!!(ctx.userData[leversOfGrowth.key] || []).length && (
                  <Box sx={{ mb: 2 }}>
                    <Typography noWrap color={colorsByKey.accentTextStrong} variant="f12">
                      {t(leversOfGrowth.label)}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {(ctx.userData[leversOfGrowth.key] || []).map((item, idx) => (
                        <IconDataBadge key={`${item.id}-${idx}`} {...item} />
                      ))}
                    </Box>
                  </Box>
                )}
                {ctx.userData[sectors.key] && (
                  <Box sx={{ mb: 2 }}>
                    <IconData
                      title={sectors.label}
                      type={sectors.key}
                      allowWrap
                      text={sectors.getText(ctx.userData[sectors.key], filters.sectors)}
                      icon={sectors.icon}
                      sx={{ alignItems: 'flex-start' }}
                    />
                  </Box>
                )}
                {EXPERTISE_FIELDS.map(({ key, label, icon, suffix, getText, multiple }, idx) => {
                  if (!ctx.userData[key] || !(ctx.userData[key] || []).length) {
                    return
                  } else {
                    const retriveText = getText ? getText(ctx.userData[key]) : ctx.userData[key]
                    let text = ''
                    if ((retriveText || '').includes('[')) {
                      text = ((getText ? getText(ctx.userData[key]) : ctx.userData[key]) + '')
                        .toString()
                        .replace(/\[/g, '')
                        .replace(/"/g, '')
                        .replace(/\]/g, '')
                    } else {
                      text = retriveText
                    }
                    return (
                      <Box key={`${key}-${idx}`} sx={{ mb: 2 }}>
                        <IconData
                          title={label}
                          type={key}
                          allowWrap
                          text={text}
                          icon={icon}
                          textSuffix={suffix}
                          sx={{ alignItems: 'flex-start' }}
                        />
                      </Box>
                    )
                  }
                })}
              </>
            )}
          </UserInfoCard>
        </Box>
      )}
      <Box sx={{ mb: 4 }}>
        <UserInfoCard
          title={sections.contact.title}
          isEditable={ctx.isMyProfile}
          isLoading={ctx.loadingUserData}
          actionOnClick={() =>
            userType === COMPANY ? handleRedirectTo(PROFILE) : onEditUserSection('contact')
          }
        >
          {sections.contact.fields.map(({ key, label, icon }) => {
            if (!ctx.userData[key] || !(ctx.userData[key] || []).length) return
            else
              return (
                <Box key={key} sx={{ mb: 2 }}>
                  <IconData
                    title={label}
                    type={key}
                    allowWrap
                    text={ctx.userData[key]}
                    icon={icon}
                    showTextTooltip
                    sx={{ alignItems: 'flex-start' }}
                  />
                </Box>
              )
          })}
          {isSocialMediaDataAvailable && !ctx.loadingUserData && (
            <>
              <Typography color={colorsByKey.accentTextStrong} variant="f12">
                {t('Follow us')}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                {SOCIAL_MEDIA_FIELDS.map((field, idx) => {
                  if (!(ctx.userData.socialMedia || {})[field.key]) return
                  else
                    return (
                      <img
                        key={`${field.key} - ${idx}`}
                        src={field.icon}
                        alt={`Social media icon - ${field.icon}`}
                        onClick={() => redirectTo((ctx.userData.socialMedia || {})[field.key])}
                        style={{
                          marginRight: '16px',
                          cursor: 'pointer',
                          width: '24px',
                          height: 'auto',
                        }}
                      />
                    )
                })}
              </Box>
            </>
          )}
        </UserInfoCard>
      </Box>
      {userType === COMPANY && (
        <Box sx={{ mb: 4 }}>
          <UserInfoCard
            title={sections.legal.title}
            isEditable={ctx.isMyProfile}
            isLoading={ctx.loadingUserData}
            actionOnClick={() =>
              userType === COMPANY ? handleRedirectTo(PROFILE) : onEditUserSection('legal')
            }
          >
            {sections.legal.fields.map(({ key, label, icon }) => {
              if (!ctx.userData[key]) return
              else
                return (
                  <Box key={key} sx={{ mb: 2 }}>
                    <IconData
                      title={label}
                      type={key}
                      allowWrap
                      text={ctx.userData[key]}
                      icon={icon}
                      sx={{ alignItems: 'flex-start' }}
                    />
                  </Box>
                )
            })}
          </UserInfoCard>
        </Box>
      )}
    </Box>
  )
}
export const UserProfileSidebar = withTranslation()(UserProfileSidebarComponent)
