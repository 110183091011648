import React, { useContext } from 'react'
import { Box, Tab, Tabs, Container } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import userContext from 'context/userContext'

const UserSectionTabsComponent = ({ t, sectionTabs }) => {
  const ctx = useContext(userContext)

  const handleSelectSectionTab = (e, value) => {
    ctx.setSectionTab(sectionTabs[value].key)
  }

  const value = ctx.sectionTab ? sectionTabs.findIndex(({ key }) => key === ctx.sectionTab) : 0

  return (
    <Box
      sx={{
        width: '100%',
        borderBottom: `2px solid ${colorsByKey.primaryBgWeak}`,
      }}
    >
      <Container maxWidth={'xl'}>
        <Tabs
          value={value}
          onChange={handleSelectSectionTab}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            backgroundColor: colorsByKey.primaryBgWeak,
            borderRadius: '8px',
            width: 'fit-content',
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          {sectionTabs.map(({ label, key }) => (
            <Tab
              label={t(label)}
              key={key}
              id={key}
              sx={{
                color: colorsByKey.primaryBgStrong,
                minHeight: 'auto',
                textTransform: 'none',
                fontSize: '14px',
                padding: '8px 16px',
                '&.Mui-selected': {
                  background: colorsByKey.accentTextStrong,
                  color: 'white !important',
                  margin: '6px',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  height: 'auto !important',
                },
              }}
            />
          ))}
        </Tabs>
      </Container>
    </Box>
  )
}
export const UserSectionTabs = withTranslation()(UserSectionTabsComponent)
