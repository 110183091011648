import React, { useContext } from 'react'
import filters from 'mixins/filters'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import QueryFiltersContext from 'context/queryFiltersContext'
import './filtersStyles.css'
import {
  TextField,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  // Slider,
  Button,
  Typography,
  Grid,
} from '@mui/material'
import { StaticDateRangePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashAlt, faSearch } from '@fortawesome/pro-light-svg-icons'
import {
  COMPANY_SIZE,
  REVENUE,
  SERVICES,
  SERVICE_CATEGORIES,
  COUNTRIES,
  SECTORS,
  DATES,
  OTHERS,
  PLUS,
  COMPANY_PACKAGE
} from 'entities/filterFields'
import { Spinner, IconData } from 'components'

const Filters = ({ t }) => {
  const ctx = useContext(QueryFiltersContext)
  const availableFilters = ctx.tabDetails.list.availableFilters
  const {
    serviceCategories,
    services,
    countries,
    sectors,
    fetchingFilters,
    companySizes,
    companyRevenues,
    companyPackages,
  } = filters()

  const groupedServices = services.reduce((group, service) => {
    const { type } = service
    group[type] = group[type] ?? []
    group[type].push(service)
    return group
  }, {})

  const getPackageNameByKey = (key) => {
    const foundPackage = companyPackages.find(option => option.key === key);
    return foundPackage ? foundPackage.name : '';
  };

  return (
    <Box
      className="Wrapper"
      sx={{
        my: 2,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '12px',
      }}
    >
      {ctx.showFilters && (
        <Box
          sx={{
            // backgroundColor: colorsByKey.lightBlue,
            my: 2,
            p: 3,
          }}
        >
          {fetchingFilters && <Spinner />}
          {!fetchingFilters &&
            availableFilters.includes(SECTORS) &&
            ctx.filterBy === SECTORS &&
            !!sectors.length && (
              <Box sx={{ mb: 4 }}>
                <Grid container>
                  {sectors.map(({ name, id: sectorId }, index) => (
                    <Grid item xs={6} lg={4} key={`sector-${sectorId}-${index}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`${sectorId}`}
                            sx={{
                              color: colorsByKey.secondaryText,
                              padding: '6px',
                              '&.Mui-checked': {
                                color: colorsByKey.accentText,
                              },
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={ctx.filters.selectedSectors.some(
                              (s) => Number(s) === sectorId
                            )}
                          />
                        }
                        label={
                          <Typography variant="f12" color={colorsByKey.defaultText}>
                            {name}
                          </Typography>
                        }
                        key={`sector-${sectorId}`}
                        onChange={(event) => ctx.handleSelectSectors(event, sectorId)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          {!fetchingFilters &&
            availableFilters.includes(SERVICES) &&
            ctx.filterBy === SERVICES &&
            !!services.length && (
              <Box sx={{ mb: 4 }}>
                <Grid container>
                  {Object.keys(groupedServices).map((it, index) => {
                    return (
                      <Grid item xs={6} lg={4} key={`service-${it}-${index}`}>
                        <Typography variant="f12" color={colorsByKey.accentText}>
                          {it}
                        </Typography>
                        <ul style={{ paddingLeft: '20px' }}>
                          {groupedServices[it].map(({ name, id: serviceId, type }, idx) => {
                            return (
                              <li key={`service-${name}-${index}`}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={`${serviceId}`}
                                      sx={{
                                        color: colorsByKey.secondaryText,
                                        padding: '6px',
                                        '&.Mui-checked': {
                                          color: colorsByKey.accentText,
                                        },
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                      checked={ctx.filters.selectedServices.some(
                                        (s) => Number(s) === serviceId
                                      )}
                                    />
                                  }
                                  label={
                                    <Typography variant="f12" color={colorsByKey.defaultText}>
                                      {name}
                                    </Typography>
                                  }
                                  onChange={(event) => ctx.handleSelectServices(event, serviceId)}
                                />
                              </li>
                            )
                          })}
                        </ul>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            )}
          {!fetchingFilters &&
            availableFilters.includes(SERVICE_CATEGORIES) &&
            ctx.filterBy === OTHERS &&
            !!serviceCategories.length && (
              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="f14"
                  color={colorsByKey.primaryBorder}
                  sx={{ mb: 1, fontWeight: 'bold' }}
                >
                  {t('Service categories')}
                </Typography>
                <Grid container>
                  {serviceCategories.map(({ name, id: serviceCategoryId }, index) => (
                    <Grid item xs={6} lg={4} key={`serviceCategory-${serviceCategoryId}-${index}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`${serviceCategoryId}`}
                            sx={{
                              color: colorsByKey.secondaryText,
                              padding: '6px',
                              '&.Mui-checked': {
                                color: colorsByKey.accentText,
                              },
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={ctx.filters.selectedServiceCategories.some(
                              (s) => Number(s) === serviceCategoryId
                            )}
                          />
                        }
                        label={
                          <Typography variant="f12" color={colorsByKey.defaultText}>
                            {name}
                          </Typography>
                        }
                        key={`serviceCategory-${serviceCategoryId}`}
                        onChange={(event) =>
                          ctx.handleSelectServiceCategories(event, serviceCategoryId)
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          {!fetchingFilters && (
            <Box sx={{ mb: 4 }}>
              <Grid container spacing={8}>
                {availableFilters.includes(COUNTRIES) &&
                  ctx.filterBy === OTHERS &&
                  !!countries.length && (
                    <Grid item xs={6}>
                      <div>
                        <Typography
                          variant="f14"
                          color={colorsByKey.primaryBorder}
                          sx={{ mb: 2, fontWeight: 'bold' }}
                        >
                          {t('Countries')}
                        </Typography>
                        <FormControl fullWidth sx={{ m: 0 }}>
                          <Select
                            value={ctx.selectedCountries}
                            multiple
                            placeholder="Search"
                            onChange={(e) => ctx.handleSelectCountries(e.target.value)}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="selectedCountries"
                                placeholder="Search"
                                startAdornment={
                                  <FontAwesomeIcon
                                    icon={faSearch}
                                    size="xs"
                                    color={colorsByKey.blue}
                                  />
                                }
                              />
                            }
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value, idx) => (
                                  <Chip
                                    key={`selected-option-${value.id}-${idx}`}
                                    label={
                                      <Typography variant="f12" color={colorsByKey.defaultText}>
                                        {value.name}
                                      </Typography>
                                    }
                                  />
                                ))}
                              </Box>
                            )}
                          >
                            {countries.map((option, idx) => {
                              return (
                                <MenuItem
                                  value={option}
                                  key={`select-option-${option.name}-${idx}`}
                                >
                                  {option.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  )}
                {(availableFilters.includes(COMPANY_SIZE) || availableFilters.includes(REVENUE)) &&
                  ctx.filterBy === OTHERS && (
                    <Grid item xs={6}>
                      <Typography
                        variant="f14"
                        color={colorsByKey.primaryBorder}
                        sx={{ mb: 2, fontWeight: 'bold' }}
                      >
                        {t('Dimension')}
                      </Typography>
                      <Grid container spacing={2}>
                        {availableFilters.includes(COMPANY_SIZE) && (
                          <>
                            <Grid item xs={4}>
                              <Typography
                                variant="f12"
                                color={colorsByKey.primaryBorder}
                                sx={{ mb: 1 }}
                              >
                                {t('Employees')}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <FormControl fullWidth sx={{ m: 0 }}>
                                <Select
                                  id={ctx.filters.companySize}
                                  labelId={`company-size-label`}
                                  label={
                                    <Typography variant="f12" color={colorsByKey.defaultText}>
                                      {ctx.filters.companySize}
                                    </Typography>
                                  }
                                  value={ctx.filters.companySize}
                                  onChange={(e, value) => {
                                    ctx.setFilter('companySize', value.props.value)
                                  }}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label={
                                        <Typography variant="f12" color={colorsByKey.defaultText}>
                                          {ctx.filters.companySize}
                                        </Typography>
                                      }
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      <Chip
                                        key={`selected-option-company-size-${selected}`}
                                        label={
                                          <Typography variant="f12" color={colorsByKey.defaultText}>
                                            {selected}
                                          </Typography>
                                        }
                                      />
                                    </Box>
                                  )}
                                >
                                  {companySizes.map((option, idx) => {
                                    return (
                                      <MenuItem value={option.key} key={option.key}>
                                        {option.name}
                                      </MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                              {/* <Slider
                              aria-label="Always visible"
                              {...companySizeFilter}
                              value={ctx.filters.companySize}
                              marks
                              sx={{
                                '&.MuiSlider-colorPrimary': {
                                  color: colorsByKey.accentText,
                                },
                              }}
                              valueLabelDisplay="auto"
                              onChange={(e, value) => ctx.setFilter('companySize', value)}
                            /> */}
                            </Grid>
                          </>
                        )}
                        {availableFilters.includes(REVENUE) && (
                          <>
                            <Grid item xs={4}>
                              <Typography
                                variant="f12"
                                color={colorsByKey.primaryBorder}
                                sx={{ mb: 1 }}
                              >
                                {t('Revenue')}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <FormControl fullWidth sx={{ m: 0 }}>
                                <Select
                                  id={ctx.filters.revenue}
                                  labelId={`company-size-label`}
                                  label={
                                    <Typography variant="f12" color={colorsByKey.defaultText}>
                                      {ctx.filters.revenue}
                                    </Typography>
                                  }
                                  value={ctx.filters.revenue}
                                  onChange={(e, value) => {
                                    ctx.setFilter('revenue', value.props.value)
                                  }}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label={
                                        <Typography variant="f12" color={colorsByKey.defaultText}>
                                          {ctx.filters.revenue}
                                        </Typography>
                                      }
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      <Chip
                                        key={`selected-option-company-size-${selected}`}
                                        label={
                                          <Typography variant="f12" color={colorsByKey.defaultText}>
                                            {selected}
                                          </Typography>
                                        }
                                      />
                                    </Box>
                                  )}
                                >
                                  {companyRevenues.map((option, idx) => {
                                    return (
                                      <MenuItem value={option.key} key={option.key}>
                                        {option.name}
                                      </MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                              {/* <Slider
                              aria-label="Always visible"
                              {...revenueFilter}
                              value={ctx.filters.revenue}
                              marks
                              sx={{
                                '&.MuiSlider-colorPrimary': {
                                  color: colorsByKey.accentText,
                                },
                              }}
                              valueLabelDisplay="auto"
                              onChange={(e, value) => ctx.setFilter('revenue', value)}
                            /> */}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Box>
          )}

          {!fetchingFilters && availableFilters.includes(DATES) && ctx.filterBy === OTHERS && (
            <Box sx={{ my: 4 }}>
              <Typography
                variant="f14"
                color={colorsByKey.primaryBorder}
                sx={{ mb: 1, fontWeight: 'bold' }}
              >
                {t('Date')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDateRangePicker
                  displayStaticWrapperAs="desktop"
                  value={ctx.filters.dates}
                  onChange={(newValue) => {
                    ctx.setFilter('dates', newValue)
                  }}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </>
                  )}
                />
              </LocalizationProvider>
            </Box>
          )}

          {/* {!fetchingFilters && availableFilters.includes(PLUS) && ctx.filterBy === OTHERS && (
            <Box
              sx={{
                maxWidth: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
            <FormControlLabel
                control={
                  <Checkbox
                    name="cccsc"
                    sx={{
                      padding: 0,
                      color: colorsByKey.primaryBorder,
                      '&.Mui-checked': {
                        color: colorsByKey.primaryBorder,
                      },
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={ctx.selectPlus} 
                  />
                }
                label={
                  <Typography 
                    variant="f14"
                    color={colorsByKey.primaryBorder}
                    sx={{ fontWeight: 'bold' }}
                  >
                    Plus
                  </Typography>
                }
                onChange={(event) =>
                  ctx.handlePlus('plus', event)
                }
              />
            </Box>
            
          )} */}
          {!fetchingFilters && availableFilters.includes(COMPANY_PACKAGE) && ctx.filterBy === OTHERS && (
            <>
            <Grid item xs={4}>
              <Typography
                variant="f14"
                color={colorsByKey.primaryBorder}
                sx={{ mb: 2, fontWeight: 'bold' }}
              >
                {t('Subscription')}
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ maxWidth: '200px' }}>
            <Typography
                variant="f12"
                color={colorsByKey.primaryBorder}
                sx={{ mb: 2 }}
              >
                {t('package')}
              </Typography>
              <FormControl fullWidth sx={{ m: 0 }}>
                <Select
                  id={ctx.filters.companyPackage}
                  labelId={`package-label`}
                  label={
                    <Typography variant="f12" color={colorsByKey.defaultText}>
                      {ctx.filters.companyPackage}
                    </Typography>
                  }
                  value={ctx.filters.companyPackage}
                  onChange={(e, value) => {
                    console.log(e)
                    ctx.setFilter('companyPackage', value.props.value)
                  }}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label={
                        <Typography variant="f12" color={colorsByKey.defaultText}>
                          {ctx.filters.companyPackage}
                        </Typography>
                      }
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      <Chip
                        key={`selected-option-package-${selected}`}
                        label={
                          <Typography variant="f12" color={colorsByKey.defaultText}>
                            {getPackageNameByKey(selected)}
                          </Typography>
                        }
                      />
                    </Box>
                  )}
                >
                  {companyPackages.map((option, idx) => {
                    // console.log(option)
                    return (
                      <MenuItem value={option.key} key={option.key}>
                        {option.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

            {availableFilters.includes(PLUS) && (
              <Box
                sx={{
                  maxWidth: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '10px'
                }}
              >
              <FormControlLabel
                  control={
                    <Checkbox
                      name="plus"
                      sx={{
                        padding: '6px',
                        color: colorsByKey.primaryBorder,
                        '&.Mui-checked': {
                          color: colorsByKey.primaryBorder,
                        },
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      checked={ctx.selectPlus} 
                    />
                  }
                  label={
                    <Typography 
                      variant="f12"
                      color={colorsByKey.primaryBorder}
                      // sx={{ fontWeight: 'bold' }}
                    >
                      Plus
                    </Typography>
                  }
                  onChange={(event) =>
                    ctx.handlePlus('plus', event)
                  }
                />
              </Box>
            )}
            </Grid>
          </>
          )}
          {!fetchingFilters && (
            <Box
              sx={{ pt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Button onClick={ctx.handleClearFilters} type="button" variant="text">
                <IconData
                  title={t('Clear filters')}
                  icon={faTrashAlt}
                  titleVariant="f14"
                  margin={1}
                  color={colorsByKey.primaryBgStrong}
                  sx={{ padding: '6px 8px' }}
                />
              </Button>
              <Box sx={{ display: 'flex' }}>
                <Button onClick={ctx.toggleShowFilters} type="button" variant="text">
                  <Typography variant="f14" color={colorsByKey.primaryBgStrong}>
                    {t('Cancel')}
                  </Typography>
                </Button>
                <Button
                  onClick={() => ctx.handleFilterData(ctx.filters)}
                  variant="contained"
                  sx={{
                    ml: 2,
                    borderRadius: '48px !important',
                    backgroundColor: colorsByKey.accentBg,
                  }}
                >
                  <IconData
                    title={t('Save')}
                    icon={faSave}
                    titleVariant="f14"
                    margin={1}
                    color={colorsByKey.utility}
                    sx={{ padding: '6px 8px' }}
                  />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
export default withTranslation()(Filters)
