import React from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import './registrationstep2.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Table } from 'react-bootstrap'
import countries from 'utils/countries'
import { withTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { library } from '@fortawesome/fontawesome-svg-core'

import AuthenticatedFile from '../../general/AuthenticatedFile'

import {
  faCheckCircle,
  faExclamationCircle,
  faAngleRight,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons'
library.add(faCheckCircle, faExclamationCircle, faAngleRight, faAngleLeft)

class RegistrationStep2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        user_id: 0,
        company_legal_name: '',
        vatnumber: '',
        address2: '',
        address1: '',
        postal_code: '',
        country: null,
        state: '',
        city: '',
      },
      isCountryInUE: true,
      country_of_selected: '',
      submitted: false,
      vatvalidation: 'nosubmit',
      policypage: '',
      termpage: '',
      cookiepage: '',
      contractpage: '',
      showHide: false, // true,
      loading: true,
      countries: countries(),
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateVatnumber = this.validateVatnumber.bind(this)
  }

  async componentDidMount() {
    if (this.props.userDetail) {
      const success = this.props.userDetail
      const formData = {
        user_id: success.id,
        company_legal_name: success.company_legal_name,
        vatnumber: success.vatnumber,
        state: success.state,
        postal_code: success.postal_code,
        address1: success.address1,
        address2: success.address2,
        city: success.city,
        country: '',
      }

      if (success.vatnumber) {
        this.setState({ vatvalidation: 'valid' })
      }

      if (success.country) {
        formData['country'] = success.country
        this.setState({ country_of_selected: '1' })
      }

      this.setState({ formData })
    }

    const page_api_url2 = process.env.REACT_APP_API_URL + '/api/pages/2'
    await axios
      .get(page_api_url2)
      .then((response) => {
        const { data } = response.data
        this.setState({ policypage: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const page_api_url3 = process.env.REACT_APP_API_URL + '/api/pages/3'
    await axios
      .get(page_api_url3)
      .then((response) => {
        const { data } = response.data
        this.setState({ termpage: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const page_api_url4 = process.env.REACT_APP_API_URL + '/api/pages/4'
    await axios
      .get(page_api_url4)
      .then((response) => {
        const { data } = response.data
        this.setState({ cookiepage: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const page_api_url5 = process.env.REACT_APP_API_URL + '/api/pages/5'
    await axios
      .get(page_api_url5)
      .then((response) => {
        const { data } = response.data
        this.setState({ contractpage: data, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  validateVatnumber() {
    if (this.state.formData.vatnumber !== '') {
      this.setState({ vatvalidation: 'checking' })

      const vat_api_url = process.env.REACT_APP_API_URL + '/api/uniqueVatnumber'
      axios
        .post(vat_api_url, this.state.formData, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        })
        .then((response) => {
          const data = response.data
          if (data.valid) {
            this.setState({
              vatvalidation: 'valid',
            })
          } else {
            if(data.type == 'optional') {
              this.setState({vatvalidation: 'optional'})
              return
            }
            this.setState({ vatvalidation: 'Notvalid' })
          }
        })
        .catch((error) => {
          this.setState({ vatvalidation: 'AlreadyExist' })
        })
    } else {
      console.log('Vat number is empty')
    }
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
    if (event.target.name === 'vatnumber') {
      this.setState({ vatvalidation: 'nosubmit' })
    }
  }

  handleCountryOperation = (event, value) => {
    this.setState({ isCountryInUE: value.UE || false })

    const { formData } = this.state
    formData['country'] = value
    if (value !== null) {
      this.setState({ country_of_selected: 1 })
    } else {
      this.setState({ country_of_selected: 0 })
    }
    this.setState({ formData })
  }
  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/adduseraddress'
      axios
        .post(sector_api_url, this.state.formData, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        })
        .then((response) => {
          window.location = '/welcome'
          this.setState({ submitted: false })
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, error: data.error })
              }
            } else {
              alert(this.props.t('Something went wrong, please try again'))
            }
          }
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted, isCountryInUE } = this.state
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }
    return (
      <div>
        <div>
          <div className="boxshadow p-3 pt-1 mb-5">
            <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <h6 className="pb-4">{t('Company Address')}</h6>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {t('Street 1')}
                      <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="address1"
                      onChange={this.handleChange}
                      value={formData.address1}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{t('Street 2 (Optional)')}</label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="address2"
                      onChange={this.handleChange}
                      value={formData.address2}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {t('Postal Code')} <sup>*</sup>
                    </label>
                    {formData.country ? (
                      <>
                        {formData.country.name === 'Portugal' && (
                          <TextValidator
                            variant="outlined"
                            margin="0"
                            fullWidth
                            type="text"
                            name="postal_code"
                            onChange={this.handleChange}
                            value={formData.postal_code}
                            validators={['required', 'matchRegexp:^[0-9]{4}-[0-9]{3}$']}
                            errorMessages={[
                              t('This field is required'),
                              t('invalid postal code format'),
                            ]}
                          />
                        )}
                        {formData.country.name === 'Spain' && (
                          <TextValidator
                            variant="outlined"
                            margin="0"
                            fullWidth
                            type="text"
                            name="postal_code"
                            onChange={this.handleChange}
                            value={formData.postal_code}
                            validators={[
                              'required',
                              'matchRegexp:^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$',
                            ]}
                            errorMessages={[
                              t('This field is required'),
                              t('invalid postal code format'),
                            ]}
                          />
                        )}
                        {formData.country.name !== 'Spain' &&
                          formData.country.name !== 'Portugal' && (
                            <TextValidator
                              variant="outlined"
                              margin="0"
                              fullWidth
                              type="text"
                              name="postal_code"
                              onChange={this.handleChange}
                              value={formData.postal_code}
                              validators={['required']}
                              errorMessages={[t('This field is required')]}
                            />
                          )}
                      </>
                    ) : (
                      <TextValidator
                        variant="outlined"
                        margin="0"
                        fullWidth
                        type="text"
                        name="postal_code"
                        onChange={this.handleChange}
                        value={formData.postal_code}
                        validators={['required']}
                        errorMessages={t('This field is required')}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{t('State (Optional)')}</label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="state"
                      onChange={this.handleChange}
                      value={formData.state}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {t('City')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="city"
                      onChange={this.handleChange}
                      value={formData.city}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {t('Country')} <sup>*</sup>
                    </label>
                    <Autocomplete
                      options={this.state.countries}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => {
                        return option.name === formData.country.name
                      }}
                      name="country"
                      selected={formData.country}
                      defaultValue={formData.country}
                      onChange={this.handleCountryOperation}
                      renderInput={(params) => (
                        <TextValidator
                          Select
                          {...params}
                          fullWidth
                          variant="outlined"
                          name="country_selected"
                          placeholder="Country"
                          value={this.state.country_of_selected}
                          validators={['required']}
                          errorMessages={t('This field is required')}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h6 className="pb-4">{t('Detailed Company Information')}</h6>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="form-group">
                            <label>
                              {t('Company Legal Name')} <sup>*</sup>
                            </label>
                            <TextValidator
                              variant="outlined"
                              margin="0"
                              fullWidth
                              type="text"
                              name="company_legal_name"
                              onChange={this.handleChange}
                              value={formData.company_legal_name}
                              validators={['required']}
                              errorMessages={t('This field is required')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {isCountryInUE && (
                      <div className="row">
                        <div className="col-md-12">
                          <label>{t('VAT Number')}</label>
                        </div>
                        <div className="col-md-7">
                          <div className="form-group">
                            <TextValidator
                              variant="outlined"
                              margin="0"
                              fullWidth
                              type="text"
                              name="vatnumber"
                              onChange={this.handleChange}
                              value={formData.vatnumber}
                              validators={['required']}
                              errorMessages={t('This field is required')}
                            />
                            {this.state.vatvalidation === 'AlreadyExist' && (
                              <p className="text-danger">Vat number already exist</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <button
                            type="button"
                            className="submit-btn btn-check mr-3"
                            onClick={this.validateVatnumber}
                          >
                            {this.state.vatvalidation === 'checking'
                              ? 'Validating'
                              : 'Validate Vat Number'}
                          </button>
                          {this.state.vatvalidation === 'valid' && (
                            <div className="valid-vat">
                              <FontAwesomeIcon icon={faCheckCircle} /> {t('Vat Number is Vaild')}
                            </div>
                          )}
                          {this.state.vatvalidation === 'Notvalid' || this.state.vatvalidation === 'optional' && (
                            <div className="notvalid-vat">
                              <FontAwesomeIcon icon={faExclamationCircle} />
                              {t('Vat Number is not valid')}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group text-center d-flex justify-content-center">
                    <Button
                      type="button"
                      variant="contained"
                      className="submit-btn ml-0 mr-3"
                      onClick={() => this.props.prevStep(1)}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                      &nbsp;{t('Prev Step')}
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      className="submit-btn ml-3 mr-0"
                      disabled={submitted ||  (!['valid', 'optional'].includes(this.state.vatvalidation) && isCountryInUE)}
                    >
                      {(submitted && t('Your form is submitted!')) ||
                        (!submitted && t('Next Step'))}
                      &nbsp;
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                  </div>
                </div>
              </div>
            </ValidatorForm>
          </div>
        </div>
        <Modal show={this.state.showHide} backdrop="static" className="policypopup">
          <Modal.Header>
            <Modal.Title
              dangerouslySetInnerHTML={{ __html: this.state.policypage.name }}
            ></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.policypage.content,
              }}
            ></div>
            <div className="row">
              <div className="page-titles">
                <h4>{this.state.termpage.name}</h4>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.termpage.content,
              }}
            ></div>

            <div className="row">
              <div className="page-titles">
                <h4>{this.state.cookiepage.name}</h4>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.cookiepage.content,
              }}
            ></div>

            <div className="row">
              <div className="page-titles">
                <h4>{this.state.contractpage.name}</h4>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.contractpage.content,
              }}
            ></div>
            <div className="table-responsive registrat">
              <Table bordered responsive className="needtable need-table">
                <thead>
                  <tr>
                    <th>
                      <img
                        style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                        className="en"
                        src={require('assets/images/en_flag.jpg')}
                        alt="#"
                      />
                      <span>EN</span>
                    </th>
                    <th>
                      <img
                        style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                        className="en"
                        src={require('assets/images/pt_flag.jpg')}
                        alt="#"
                      />
                      <span>PT</span>
                    </th>
                    <th>
                      <img
                        style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                        className="en"
                        src={require('assets/images/es_flag.jpg')}
                        alt="#"
                      />
                      <span>ES</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {this.state.policypage.upload_pdf ? (
                        <AuthenticatedFile
                          url={`/${this.state.policypage.upload_pdf}`}
                          filename={this.state.policypage.upload_pdf.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Privacy Policy.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.policypage.upload_pdf_pr ? (
                        <AuthenticatedFile
                          url={`/${this.state.policypage.upload_pdf_pr}`}
                          filename={this.state.policypage.upload_pdf_pr.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Politica privacidade.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.policypage.upload_pdf_es ? (
                        <AuthenticatedFile
                          url={`/${this.state.policypage.upload_pdf_es}`}
                          filename={this.state.policypage.upload_pdf_es.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Politica privacidad.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {this.state.termpage.upload_pdf ? (
                        <AuthenticatedFile
                          url={`/${this.state.termpage.upload_pdf}`}
                          filename={this.state.termpage.upload_pdf.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Terms and Conditions.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.termpage.upload_pdf_pr ? (
                        <AuthenticatedFile
                          url={`/${this.state.termpage.upload_pdf_pr}`}
                          filename={this.state.termpage.upload_pdf_pr.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Condições gerais de utilização.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.termpage.upload_pdf_es ? (
                        <AuthenticatedFile
                          url={`/${this.state.termpage.upload_pdf_es}`}
                          filename={this.state.termpage.upload_pdf_es.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Términos y condiciones.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {this.state.cookiepage.upload_pdf ? (
                        <AuthenticatedFile
                          url={`/${this.state.cookiepage.upload_pdf}`}
                          filename={this.state.cookiepage.upload_pdf.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Cookie policy.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.cookiepage.upload_pdf_pr ? (
                        <AuthenticatedFile
                          url={`/${this.state.cookiepage.upload_pdf_pr}`}
                          filename={this.state.cookiepage.upload_pdf_pr.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Politica cookies.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.cookiepage.upload_pdf_es ? (
                        <AuthenticatedFile
                          url={`/${this.state.cookiepage.upload_pdf_es}`}
                          filename={this.state.cookiepage.upload_pdf_es.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Politica cookies.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {this.state.contractpage.upload_pdf ? (
                        <AuthenticatedFile
                          url={`/${this.state.contractpage.upload_pdf}`}
                          filename={this.state.contractpage.upload_pdf.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Participant's contract.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.contractpage.upload_pdf_pr ? (
                        <AuthenticatedFile
                          url={`/${this.state.contractpage.upload_pdf_pr}`}
                          filename={this.state.contractpage.upload_pdf_pr.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Contrato Participante.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.contractpage.upload_pdf_es ? (
                        <AuthenticatedFile
                          url={`/${this.state.contractpage.upload_pdf_es}`}
                          filename={this.state.contractpage.upload_pdf_es.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{
                                  width: '20px',
                                  margin: '0px 10px 0px 0px',
                                }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Contrato Participante.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <Button
              closeButton
              onClick={() => this.handleModalShowHide()}
              className="submit-btn mt-1 ml-0 mr-0 mb-1 border-0 MuiButton-root MuiButton-contained"
            >
              Agree and continue
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
export default withTranslation()(RegistrationStep2)
