import './header.css'
import 'bootstrap/dist/css/bootstrap.css'

import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import LanguageSelector from '../general/LanguageSelector'
import HeaderMenu from './headerMenu/'

import logo from '../../assets/logo.png'

const Header = ({ userDetail }) => (
  <header>
    <LanguageSelector />
    <Container>
      <Navbar expand="lg" className="pl-0 pr-0">
        <Navbar.Brand>
          <Link to="/">
            <img className="logo-img" src={logo} alt="" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <HeaderMenu userDetail={userDetail} />
      </Navbar>
    </Container>
  </header>
)

export default Header
