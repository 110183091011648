import React from 'react'
import { Nav } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
class ProfileHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { t } = this.props
    return (
      <div className="profile-header">
        <Nav
          style={{ marginBottom: '20px' }}
          variant="tabs"
          defaultActiveKey={this.props.activekey}
        >
          <Nav.Item>
            <Nav.Link
              eventKey="profile"
              className="px-3 py-2"
              onClick={() => this.props.onChangeTab('profile')}
            >
              {t('Profile')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="profile-two"
              className="px-3 py-2"
              onClick={() => this.props.onChangeTab('profile-two')}
            >
              {t('Company Info')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="profile-three"
              className="px-3 py-2"
              onClick={() => this.props.onChangeTab('profile-three')}
            >
              {t('Contact Info')}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    )
  }
}

export default withTranslation()(ProfileHeader)
