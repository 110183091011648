import 'date-fns'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next'
import { apiPost } from 'api'
import './FileUploader2.css'

const FILE_STATUS = {
  NOT_UPLOADED: 'not-uploaded',
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  ERROR: 'error',
}

const FileUploader2 = forwardRef(
  (
    {
      t,
      label,
      files = [],
      endpoint = `/api/files`,
      onSetFiles,
      maxFiles = 1,
      autoUpload = true,
      accept,
      disabled = false,
      onError,
    },
    ref
  ) => {
    useImperativeHandle(ref, (id) => ({
      deleteFile(id) {
        removeFile(id)
      },
    }))
    const acceptArray = Array.isArray(accept) ? accept : [accept]
    const acceptFormats = []
    acceptArray.forEach((format) => {
      if (format === 'image') {
        acceptFormats.push('.png', '.jpg')
      } else if (format === 'pdf') {
        acceptFormats.push('.pdf')
      } else if (typeof format === 'string') {
        acceptFormats.push(format)
      }
    })
    let btnLabel = t('chooseFile')
    let formatLabel
    if (acceptArray.length > 0) {
      if (!acceptArray.some((format) => format !== 'image')) {
        btnLabel = t('chooseFileImage')
        formatLabel = t('fileFormat', { formats: '.jpg, .png' })
      } else if (!acceptArray.some((format) => format !== 'pdf')) {
        btnLabel = t('chooseFilePdf')
        formatLabel = t('fileFormat', { formats: '.pdf' })
      }
    }

    const isMultiple = maxFiles > 1

    const maxFilesReached = files.length >= maxFiles
    const canUploadMore = !(maxFilesReached || disabled)

    const updateFile = (id, data) => {
      onSetFiles(files.map((file) => (file.id === id ? { ...file, ...data } : file)))
    }
    const removeFile = (id) => {
      onSetFiles(files.filter((file) => file.id !== id))
    }

    const uploadFile = (file) => {
      updateFile(file.id, { status: FILE_STATUS.UPLOADING })
      const formData = new FormData()
      formData.append('file', file.file)
      // return Promise.resolve({ data: { id: Math.round(Math.random() * 1000), url: 'https://' } })
      return apiPost(endpoint, formData)
        .then(({ data }) => {
          updateFile(file.id, { ...data, status: FILE_STATUS.UPLOADED })
        })
        .catch((error) => {
          // updateFile(file.id, { status: FILE_STATUS.ERROR })
          removeFile(file.id)
          if (onError) {
            const { message, exception } = error?.response?.data || {}
            if (message) {
              onError(message)
            } else if (exception && exception.includes('PostTooLargeException')) {
              onError('The file size is too large')
            } else {
              onError('Unknown error')
            }
          }
        })
    }
    const uploadAllFiles = (fileList = files) => {
      const filesToUpload = fileList.filter((file) => file.status === FILE_STATUS.NOT_UPLOADED)
      filesToUpload.forEach((file) => {
        uploadFile(file)
      })
    }

    const addFiles = (event) => {
      const fileList = Object.values(event.target.files)
        .slice(0, maxFiles - files.length)
        .map((file) => ({
          id: -Math.round(Math.random() * 1e6),
          name: file.name,
          file,
          status: FILE_STATUS.NOT_UPLOADED,
        }))
      onSetFiles([...files, ...fileList])
    }

    useEffect(() => {
      if (autoUpload) {
        uploadAllFiles()
      }
    })

    return (
      <div className="form-group">
        <label>{label || t('Attachment Documents')}</label>

        <div className="form-group">
          <label className={`${!canUploadMore ? 'disabled' : ''} custom-file-upload mr-5`}>
            <input
              type="file"
              name="documents"
              multiple={isMultiple}
              accept={acceptFormats}
              disabled={!canUploadMore}
              onChange={addFiles}
            />
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            {btnLabel}
          </label>
          <div>
            {maxFiles > 1 && !canUploadMore && (
              <div className="form-error-message">
                {t('filesUploadLimit', { number: maxFiles })}
              </div>
            )}
            {formatLabel && <span className=" font-weight-light">{formatLabel}</span>}
          </div>
        </div>

        <div className="FilesList">
          {files.map(({ id, originalName, name }, index) => (
            <div key={`file-${index}`} className="FileItem bordered-bottom-item">
              <p className="FileName">{originalName || name}</p>
              <Button
                variant="outlined"
                className="ml-4"
                disabled={disabled}
                onClick={() => removeFile(id)}
              >
                {t('Delete')}
              </Button>
            </div>
          ))}
        </div>
      </div>
    )
  }
)

export default withTranslation(undefined, { withRef: true })(FileUploader2)
