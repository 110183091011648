import React, { useState, useEffect } from 'react'
import { Box, Typography, Avatar, Container } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { apiGet } from 'api'
import { useParams } from 'react-router-dom'
import { colorsByKey } from 'theme/colors'
import { useHistory } from 'react-router-dom'
import FIELDS from './entities.iwsb'
import { Spinner, IconData, CompanyAvatar } from 'components'
import { FEED } from 'entities/routes'
import { CREATED_AT, USER, TITLE, HEADER_IMAGE, DESCRIPTION, ID, IMAGE } from 'entities/formFields'
import { NAME, SUBSCRIPTION } from 'entities/companyFields'
import moment from 'moment'
import { Helmet } from 'react-helmet'

const IwsDetailScreen = ({ t }) => {
  let history = useHistory()
  const { id, type } = useParams()
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await apiGet(`/api/market/${type === 'iws' ? 'offers' : 'opportunities'}/${id}`)
        .then(({ data }) => {
          setData(data)
          console.log(data)
        })
        .catch(() => {
          history.push(FEED)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    fetchData()
  }, [history, id, type])

  const bubblesStyle = {
    backgroundColor: '#E5E5E5',
    borderRadius: '20px',
    padding: '5px 10px 5px 10px',
    textAlign: 'center',
    width: 'fit-content',
    marginLeft: '0.5rem',
    whiteSpace: 'nowrap',
    fontSize: 'small',
  }

  const showTextFromType = (field) => {
    if (field.isSecured && data[field.id] === '-') {
      return '-'
    } else if (field.type === 'array') {
      if (typeof data[field.id] === 'string') {
        if ((data[field.id] + '').includes(';')) {
          let finalRes = []
          ;(data[field.id] + '').split(';').forEach((i) => finalRes.push({ name: i }))
          return (
            <IconData
              type={'select'}
              allowWrap
              title={finalRes}
              sx={{
                alignItems: 'flex-start',
                backgroundColor: 'rgba(0,0,0,0.1)',
                borderRadius: '20px',
              }}
            />
          )
        } else {
          return <span style={bubblesStyle}>{data[field.id]}</span>
        }
      }
      //return (data[field.id] || []).map((subitem, subidx) => <><span style={bubblesStyle} key={`${field.valueArrayKey}-${subidx}`}>{field.simpleArray ? subitem : subitem[field.valueArrayKey]}</span><br/></>)
      if (data[field.id][0].name !== undefined) {
        return (
          <IconData
            type={'select'}
            allowWrap
            title={data[field.id]}
            sx={{
              alignItems: 'flex-start',
              backgroundColor: 'rgba(0,0,0,0.1)',
              borderRadius: '20px',
            }}
          />
        )
      } else {
        let finalRes = []
        data[field.id].forEach((i) => finalRes.push({ name: i.factorName }))
        return (
          <IconData
            type={'select'}
            allowWrap
            title={finalRes}
            sx={{
              alignItems: 'flex-start',
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: '20px',
            }}
          />
        )
      }
    } else if (field.type === 'date') {
      return new Date(data[field.id]).toDateString()
    } else if (field.type === 'object') {
      return data[field.id][field.valueKey]
    } else if (field.type === 'boolean') {
      if (data[field.id] !== undefined && data[field.id] !== null) {
        return data[field.id]
          ? type === 'iws'
            ? field.valuesIwts[0]
            : field.valuesIwtb[0]
          : type === 'iws'
          ? field.valuesIwts[1]
          : field.valuesIwtb[1]
      } else {
        return ''
      }
    } else {
      return data[field.id]
    }
  }

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && !!Object.keys(data).length && (
        <Container maxWidth={'xl'} sx={{ my: 4 }}>
          <Helmet>
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content={type} />
          </Helmet>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1,
            }}
          >
            {data[TITLE] && (
              <div>
                <Helmet>
                  <title>{"BridgeWhat: Let's Grow! - " + data[TITLE]}</title>
                  <meta property="og:title" content={"BridgeWhat: Let's Grow! - " + data[TITLE]} />
                </Helmet>
                <Typography variant="f24" color={colorsByKey.accentTextStrong}>
                  {data[TITLE]}
                </Typography>
              </div>
            )}
            {data[CREATED_AT] && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <Typography noWrap variant="f12" color={colorsByKey.secondaryText}>
                  {t('Publish Date')}:
                </Typography>
                <Typography noWrap variant="f12" color={colorsByKey.defaultText} sx={{ ml: 1 }}>
                  {moment(data[CREATED_AT]).format('Do MMMM YYYY, h:mm')}
                </Typography>
              </Box>
            )}
          </Box>
          {/* Print header image */}
          {(data[HEADER_IMAGE] || {}).url && (
            <div>
              <Helmet>
                <meta property="og:image" content={data[HEADER_IMAGE].url} />
              </Helmet>
              <Avatar
                alt={`${(data[HEADER_IMAGE] || {}).name} - Image`}
                src={(data[HEADER_IMAGE] || {}).url}
                variant="rounded"
                sx={{
                  width: '400px',
                  height: '400px',
                  my: 2,
                }}
              />
            </div>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              my: 3,
              pb: 3,
              borderBottom: `2px solid ${colorsByKey.primaryBgWeak}`,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CompanyAvatar
                name={data[USER][NAME]}
                subscription={data[USER][SUBSCRIPTION]}
                userId={data[USER][ID]}
                image={data[USER][IMAGE]}
                size="sm"
                variant="rounded"
                customSx={{ mr: 2 }}
              />
              <Box>
                {data[USER][NAME] && (
                  <Typography sx={{ color: colorsByKey.defaultText }} variant="f16">
                    {data[USER][NAME]}
                  </Typography>
                )}
                {data[USER][DESCRIPTION] && (
                  <div dangerouslySetInnerHTML={{ __html: data[USER][DESCRIPTION] }}></div>
                  // <Typography sx={{ color: colorsByKey.secondaryText }} variant="f12">
                  //   {data[USER][DESCRIPTION]}
                  // </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignContent: 'center',
              gap: '2em',
            }}
          >
            {' '}
            {/* sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center', gap: '2em'}} */}
            {(FIELDS || []).map((field) => {
              if (field.onlyShowOn && field.onlyShowOn !== type) return
              return (
                <div
                  style={{
                    width: 'fit-content',
                    height: 'fit-content',
                    borderRadius: '5px',
                    padding: '5px 10px 5px 10px',
                    minWidth: '150px',
                    marginBottom: '5px',
                    flex: '1 1 23%',
                  }}
                >
                  <Typography
                    sx={{ color: 'rgba(0,0,0,0.6)', width: 'fit-content' }}
                    variant="f12"
                    key={`${field.id}`}
                  >
                    {field.label}
                  </Typography>
                  {field.isHTML && (
                    <div dangerouslySetInnerHTML={{ __html: showTextFromType(field) }}></div>
                  )}
                  {!field.isHTML && (
                    <Typography
                      sx={{
                        color: colorsByKey.defaultText,
                        fontWeight: '500',
                        width: 'fit-content',
                        marginTop: '0.5rem',
                        overflowWrap: 'anywhere',
                      }}
                      variant="f16"
                      key={`${field.id}-value`}
                    >
                      {showTextFromType(field)}
                    </Typography>
                  )}
                </div>
              )
            })}
          </Box>
        </Container>
      )}
    </>
  )
}
export default withTranslation()(IwsDetailScreen)
