export const OFFERS = 'offers'
export const OPPORTUNITIES = 'opportunities'
export const TABS = [
  {
    key: OFFERS,
    label: 'Offers',
    alterLabel: 'Offer',
  },
  {
    key: OPPORTUNITIES,
    label: 'Opportunities',
    alterLabel: 'Opportunity',
  },
]

export const MINE = 'my-posts'
export const OTHERS = 'posts'
export const POST_OWNERS = [
  {
    key: MINE,
    label: 'My posts',
  },
  {
    key: OTHERS,
    label: 'Posts',
  },
]

export const ANY = 'OR'
export const ALL = 'AND'
export const FILTER_DISTINCTION_TYPES = [
  {
    key: ANY,
    label: 'Any of these',
  },
  {
    key: ALL,
    label: 'All of these',
  },
]

export const COMPANY_SIZES = [
  {
    key: '',
    label: '-',
  },
  {
    key: '1-10',
    label: '1 - 10',
  },
  {
    key: '11-50',
    label: '11 - 50',
  },
  {
    key: '51-200',
    label: '51 - 200',
  },
  {
    key: '200',
    label: '+ 200',
  },
]
