import {
  SELL,
  BUY,
  SAMPLINGS,
  CAMPAIGNS,
  PROTOCOLS,
  COMPANIES,
  ADVISORS,
  POSTS,
} from 'entities/feed'
import {
  // COMPANY_SIZE,
  // REVENUE,
  SERVICE_CATEGORIES,
  SERVICES,
  SEARCH,
  COUNTRIES,
  SECTORS,
  DATES,
  PAGE,
  PLUS
} from 'entities/filterFields'
import { BUSINESS_SERVICE_CATEGORIES } from 'entities/formFields'

/* DESIGN FILTERS */
// // search: "fff"
// // services: [{ id name }]
// // companySize: 110
// // revenue: 18950
// // countries: [{ id name }]
// // sectors: [{ id name }]
// // dates: [Tue Dec 14 2021 00:00:00 GMT+0100 (Central European Standard Time), Fri Dec 31 2021 00:00:00 GMT+0100 (Central European Standard Time)]

const normalizeMarketplaceFilters = (params) => {
  // // companySize: 11-50
  // // filterDistinction: OR
  // // services[0]: 20
  // // sectors[0]: 16
  // // countries[0]: 11
  /* Missing params: search revenue dates */
  /* Tiene adicional: filterDistinction */
  return params
}
const normalizeSamplingFilters = (params) => {
  // // newsletter_date: "2021-10-11T22:00:00.000Z"
  // // title: "test"
  /* Missing params: services companySize revenue countries sectors */

  return {
    [SEARCH]: params[SEARCH],
    [PAGE]: params[PAGE],
  }
}
const normalizeCampaignFilters = (params) => {
  // // title: fff // search input
  // // openingDate: 2021-11-21T23:00:00.000Z // created
  // // deadline: 2021-10-11T22:00:00.000Z
  // // company_sector: 53
  // // bw20logs_services: 13
  // // countries: [ id, name]
  /* Missing params: companySize revenue */
  /* Tiene adicional: openingDate */
  /* Diferente: company_sector y bw20logs_services debería ser array */
  return {
    [SEARCH]: params[SEARCH],
    company_sector: params[SECTORS],
    bw20logs_services: params[SERVICES],
    [COUNTRIES]: params[COUNTRIES],
    [PAGE]: params[PAGE],
    deadline: params[DATES], // TODO: How is this going to be?
  }
}
const normalizeProtocolFilters = (params) => {
  // // date_of_publication: "2021-12-31T14:27:00.000Z" // created
  // // date_of_validity: "2022-01-13T14:27:00.000Z" // deadline
  // // filterData: {
  // //   allcategory: true
  // //   sections: [
  // //     { name: "category", selected: [ {id, category_name, description, status }]}
  // //   ]
  // // }
  // // numofitems: 0
  // // searchValue: "jjjjj"
  /* Missing params: companySize revenue countries sectors */
  /* Tiene adicional: date_of_publication numofitems */
  return {
    // filters: {
    [SEARCH]: params[SEARCH],
    [BUSINESS_SERVICE_CATEGORIES]: params[SERVICE_CATEGORIES],
    [PAGE]: params[PAGE],
    // },
    // [PAGE]: params[PAGE],
  }
}
// const normalizeFeedFilters = (params) => {
//   console.log('normalizeFeedFilters')
//   return {
//     filters: {
//       ...params,
//       [SEARCH]: params[SEARCH],
//       [BUSINESS_SERVICE_CATEGORIES]: params[SERVICE_CATEGORIES],
//       [PAGE]: params[PAGE],
//     },
//     [PAGE]: params[PAGE],
//   }
// }
const normalizeCompanyFilters = (params) => {
  // // searchValue: ''
  // // is_seller: "1"
  // // numofitems: 0
  // // filterData: {
  // //   allb20logs: true
  // //   sections: [
  // //     { name: "sectors", selected: [{ id, name, desc}] },
  // //     { name: "b20logs", selected: [{ id, name, desc}] },
  // //     { name: "countries", selected: [{ id, name, desc}] },
  // //     // { name: "sector_of_expertise", selected: sectors },
  // //   ],
  // // },
  /* Missing params: companySize revenue dates */
  /* Tiene adicional: is_seller numofitems sector_of_expertise */
  return params
}
const normalizeAdvisorsFilters = (params) => {
  return params
}
const normalizeBlogFilters = (params) => {
  // // author: 749 // company id
  // // bwsparticipant: 755 // employee in company id
  // // searchValue: ""
  // // services: [{ id, service_name, description ... }] // palancas de crecimiento
  // // // NO numofitems: 0
  /* Missing params: companySize revenue countries sectors dates */
  /* Tiene adicional: author bwsparticipant NO numofitems */
  return params
}
export const normalizeFilters = (params, type) => {
  if (type === SELL || type === BUY) return normalizeMarketplaceFilters(params)
  if (type === CAMPAIGNS) return normalizeCampaignFilters(params)
  if (type === SAMPLINGS) return normalizeSamplingFilters(params)
  if (type === PROTOCOLS) return normalizeProtocolFilters(params)
  if (type === COMPANIES) return normalizeCompanyFilters(params)
  if (type === ADVISORS) return normalizeAdvisorsFilters(params)
  if (type === POSTS) return normalizeBlogFilters(params)
  return params
}
