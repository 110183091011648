const dateFormat = (dateString, time = 'no') => {
  var date = new Date(dateString.replace(/-/g, '/'))
  var month = parseInt(date.getMonth() + 1)
  if (month < 10) {
    month = '0' + month
  }

  var day = date.getDate()
  if (isNaN(day)) {
    return '-'
  }
  if (day < 10) {
    day = '0' + day
  }

  if (time === 'yes') {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return day + '/' + month + '/' + date.getFullYear() + ' ' + strTime
  } else {
    return day + '/' + month + '/' + date.getFullYear()
  }
}

export default dateFormat
