import styled from '@emotion/styled'
import {
  Autocomplete,
  Box,
  Container,
  Stack,
  TextField,
  Typography,
  Button as MButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import FileUpload from 'component/form/file-upload'
import { format, subDays } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import axiosInstance from 'api/axios'
import history from '../../history'
import { colorsByKey } from 'theme/colors'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { IconData } from 'components'
import { FormSection } from 'screens/Partnerships/create'
import Loader from 'component/loader'

function CreateAccelerator() {
  const schema = yup.object({
    title: yup.string().required('Required'),
    promoter: yup.string().required('Required'),
    description: yup.string().required('Required'),
    fundingProgrammes: yup.string().required('Required'),
    elegibility: yup.string().required('Required'),
    fundingBenefits: yup.string().required('Required'),
    country: yup.object().required('Required'),
    industrySectors: yup.array().of(yup.object()).required('Required'),
    publicationDate: yup
      .date()
      .min(subDays(new Date(), 1), 'Must be today or later')
      .required('Required'),
    closingDate: yup
      .date()
      .min(subDays(new Date(), 1), 'Must be today or later')
      .required('Required'),
  })

  const [form, setForm] = useState({
    promoter: '',
    title: '',
    description: '',
    fundingProgrammes: '',
    elegibility: '',
    fundingBenefits: '',
    country: null,
    industrySectors: [],
    publicationDate: null,
    closingDate: null,
    programLogo: null,
    brochure: null,
  })

  const [loading, setLoading] = useState(true)
  const [formErrors, setFormErrors] = useState({})
  const [sectors, setSectors] = useState([])
  const [countries, setCountries] = useState({})
  const [endDialog, setEndDialog] = useState(false)

  const fetchFormData = useCallback(async () => {
    return await Promise.all([
      axiosInstance.get('/api/companysector', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }),
      axiosInstance.get('/api/countries', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }),
    ])
  }, [])

  useEffect(() => {
    fetchFormData()
      .then((response) => {
        setSectors(response[0].data.data)
        setCountries(response[1].data.data)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [fetchFormData])

  const handleFormInput = (key, value) => setForm((prev) => ({ ...prev, [key]: value }))

  const handleCreateAccelerator = async () => {
    try {
      await schema.validate(form, { abortEarly: false })
      create()
    } catch (err) {
      organizeErrors(err)
    }
  }

  const create = () => {
    const formData = new FormData()
    formData.append('promoter', form.promoter)
    formData.append('title', form.title)
    formData.append('publication_date', form.publicationDate)
    formData.append('closing_date', form.closingDate)

    formData.append('title', form.title)
    formData.append('description', form.description)
    formData.append('elegibility', form.elegibility)
    formData.append('funding_programmes', form.fundingProgrammes)
    formData.append('funding_programme_benefits', form.fundingBenefits)
    formData.append('image', form.programLogo)
    if (form.brochure) {
      formData.append('brochure', form.brochure)
    }
    formData.append('country', form.country.id)
    formData.append('publication_date', form.publicationDate)
    formData.append('closing_date', form.closingDate)

    for (let i = 0; i < form.industrySectors.length; i++) {
      formData.append('partner_industry_sectors[]', form.industrySectors[i].id)
    }

    axiosInstance
      .post('/api/accelerator/create', formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(() => {
        setEndDialog(true)
      })
      .catch((err) => {
        // Do something eventually
      })
  }

  const organizeErrors = (errors) => {
    const newErrors = {}
    errors.inner.forEach((error) => {
      newErrors[error.path] = error.message
    })
    setFormErrors(newErrors)
  }

  const handleCancel = () => {
    history.push('/')
  }

  return (
    <>
      <Wrapper maxWidth={'xl'} sx={{ my: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
          <Typography variant="f20" sx={{ display: 'inline-block' }}>
            Create new
            <Typography
              variant="f20"
              component="span"
              color={colorsByKey.accentText}
              sx={{ ml: 1, display: 'inline-block' }}
            >
              Accelerator
            </Typography>
          </Typography>
          {!loading && (
            <Box>
              <MButton onClick={handleCancel}>
                <Typography noWrap>Cancel</Typography>
              </MButton>
              <MButton
                onClick={handleCreateAccelerator}
                variant="contained"
                sx={{
                  ml: 2,
                  borderRadius: '48px !important',
                  backgroundColor: colorsByKey.accentBg,
                }}
              >
                <IconData
                  title="Submit for approval"
                  icon={faSave}
                  titleVariant="f14"
                  margin={1}
                  color={colorsByKey.utility}
                  sx={{ padding: '6px 8px' }}
                />
              </MButton>
            </Box>
          )}
        </Box>

        {loading && <Loader />}
        {!loading && (
          <Form>
            <Stack useFlexGap spacing={2}>
              <TextField
                label="Promoter"
                value={form.promoter}
                onChange={(e) => handleFormInput('promoter', e.target.value)}
                required
                error={!!formErrors.promoter}
                helperText={formErrors.promoter}
              />
              <TextField
                label="Title"
                value={form.title}
                onChange={(e) => handleFormInput('title', e.target.value)}
                required
                error={!!formErrors.title}
                helperText={formErrors.title}
              />
              <TextField
                label="Description"
                value={form.description}
                onChange={(e) => handleFormInput('description', e.target.value)}
                required
                error={!!formErrors.description}
                helperText={formErrors.description}
              />
              <TextField
                label="Funding Programmes"
                value={form.fundingProgrammes}
                onChange={(e) => handleFormInput('fundingProgrammes', e.target.value)}
                required
                error={!!formErrors.fundingProgrammes}
                helperText={formErrors.fundingProgrammes}
              />
              <TextField
                label="Elegibility"
                value={form.elegibility}
                onChange={(e) => handleFormInput('elegibility', e.target.value)}
                required
                error={!!formErrors.elegibility}
                helperText={formErrors.elegibility}
                minRows={4}
                multiline
              />
              <TextField
                label="Benefits of the funding programme"
                value={form.fundingBenefits}
                onChange={(e) => handleFormInput('fundingBenefits', e.target.value)}
                required
                error={!!formErrors.fundingBenefits}
                helperText={formErrors.fundingBenefits}
                minRows={4}
                multiline
              />
              <Stack direction="row" spacing={2}>
                <Autocomplete
                  fullWidth
                  disabled={countries.length === 0}
                  options={countries}
                  getOptionLabel={(option) => option.name}
                  value={form.country}
                  onChange={(event, newValue) => {
                    handleFormInput('country', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required label="Country" />
                  )}
                />
                <Autocomplete
                  multiple
                  fullWidth
                  disabled={sectors.length === 0}
                  options={sectors}
                  getOptionLabel={(option) => option.name}
                  value={form.industrySectors}
                  onChange={(event, newValue) => {
                    handleFormInput('industrySectors', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required label="Target Industry Sectors" />
                  )}
                />
              </Stack>

              <FormSection style={{ marginTop: '30px' }}>Application Deadlines</FormSection>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="Publication date"
                  type="date"
                  variant="outlined"
                  value={form.publicationDate}
                  onChange={(e) => handleFormInput('publicationDate', e.target.value)}
                  error={!!formErrors.publicationDate}
                  helperText={formErrors.publicationDate}
                  InputProps={{
                    inputProps: {
                      min: format(new Date(), 'yyyy-MM-dd'),
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Closing date"
                  type="date"
                  variant="outlined"
                  value={form.closingDate}
                  onChange={(e) => handleFormInput('closingDate', e.target.value)}
                  error={!!formErrors.closingDate}
                  helperText={formErrors.closingDate}
                  InputProps={{
                    inputProps: {
                      min: format(new Date(), 'yyyy-MM-dd'),
                    },
                  }}
                />
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
              <Stack direction="row" spacing={4}>
                <FileUpload
                  label="Upload Programme Logo"
                  buttonText="Choose an Image"
                  fileExtensions=".jpg, .png"
                  required
                  file={form.programLogo}
                  onChange={(f) => handleFormInput('programLogo', f)}
                />
                <FileUpload
                  label="Upload Brochure"
                  buttonText="Choose an File"
                  fileExtensions=".pdf, .docx, .pptx"
                  file={form.brochure}
                  onChange={(f) => handleFormInput('brochure', f)}
                />
              </Stack>
            </Stack>
          </Form>
        )}
      </Wrapper>

      <Dialog open={endDialog} onClose={handleCancel}>
        <DialogTitle>Great!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This information has been correctly submited. Our team will review it as soon as
            possible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const Wrapper = styled(Container)`
  margin-bottom: 60px;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
`

export default CreateAccelerator
