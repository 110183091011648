export const colorsByKey = {
  transparent: 'transparent',

  // MAIN
  darkBlue: '#315A80',
  blue: '#358FEB',
  lightBlue: '#D3E6FB',
  alterBlue: '#3373B6',

  primary: '#315A80',
  secondary: '#358FEB',
  tertiary: '#808080',

  black: '#081F32',
  blueGrey: '#6E798C',
  grey: '#808080',

  // AUX
  purple: '#A300BF',
  pink: '#FD19A2',
  orange: '#FA5B0F',
  yellow: '#FFDE20',
  green: '#00A768',

  // GRADIENTS
  gradientBlue: '#87B7FE',
  gradientSylver: '#FF9992',
  gradientYellow: '#FFB704',
  gradientPurple: '#B06CE4',

  // TEXTS
  defaultText: '#132332',
  defaultTextDisabled: '#979797',
  secondaryText: '#6E798C',
  logText: '#808080',
  accentText: '#358FEB',
  warningText: '#FFB800',
  warningTextHover: '#EC991D',
  alertText: '#FA2154',
  alertTextHover: '#E20035',
  successText: '#33D343',
  successTextHover: '#12B022',
  primaryBgWeak: '#D3E6FB',
  primaryBgMedium: '#3373B6',
  primaryBgStrong: '#315A80',
  defaultBg: '#FFFFFF',
  defaultBgWeak: '#F1F2F2',
  defaultBgWeaker: '#FAFBFB',
  defaultBgMedium: '#EDEDEE',
  accentBg: '#358FEB',
  warningBgWeak: '#FFF5DD',
  warningBgStrong: '#FFB039',
  alertBgWeak: '#FCEEF1',
  alertBgStrong: '#D30032',
  successBgWeak: '#B4F4BB',
  successBgStrong: '#31AA3D',
  primaryBorder: '#132332',
  accentBorder: '#358FEB',
  warningBorder: '#FFB800',
  alertBorder: '#FA2154',
  successBorder: '#33D343',
  accentTextStrong: '#315A80',
  utility: '#FFFFFF',
  separators: '#EDEDED',

  // States
  success: '#239945',
  info: '#3377F2',
  warning: '#EFC13F',
  danger: '#F00524',
  error: '#dc3545',
}

export const pallette = {
  transparent: colorsByKey.transparent,
  darkBlue: colorsByKey.darkBlue,
  blue: colorsByKey.blue,
  lightBlue: colorsByKey.lightBlue,
  alterBlue: colorsByKey.alterBlue,
  primary: colorsByKey.primary,
  secondary: colorsByKey.secondary,
  tertiary: colorsByKey.tertiary,
  black: colorsByKey.black,
  blueGrey: colorsByKey.blueGrey,
  grey: colorsByKey.grey,
  purple: colorsByKey.purple,
  pink: colorsByKey.pink,
  orange: colorsByKey.orange,
  yellow: colorsByKey.yellow,
  green: colorsByKey.green,
  gradientBlue: colorsByKey.gradientBlue,
  gradientSylver: colorsByKey.gradientSylver,
  gradientYellow: colorsByKey.gradientYellow,
  gradientPurple: colorsByKey.gradientPurple,

  // TEXTS
  defaultText: colorsByKey.defaultText,
  defaultTextDisabled: colorsByKey.defaultTextDisabled,
  secondaryText: colorsByKey.secondaryText,
  logText: colorsByKey.logText,
  accentText: colorsByKey.accentText,
  warningText: colorsByKey.warningText,
  warningTextHover: colorsByKey.warningTextHover,
  alertText: colorsByKey.alertText,
  alertTextHover: colorsByKey.alertTextHover,
  successText: colorsByKey.successText,
  successTextHover: colorsByKey.successTextHover,
  primaryBgWeak: colorsByKey.primaryBgWeak,
  primaryBgMedium: colorsByKey.primaryBgMedium,
  primaryBgStrong: colorsByKey.primaryBgStrong,
  defaultBg: colorsByKey.defaultBg,
  defaultBgWeak: colorsByKey.defaultBgWeak,
  defaultBgWeaker: colorsByKey.defaultBgWeaker,
  defaultBgMedium: colorsByKey.defaultBgMedium,
  accentBg: colorsByKey.accentBg,
  warningBgWeak: colorsByKey.warningBgWeak,
  warningBgStrong: colorsByKey.warningBgStrong,
  alertBgWeak: colorsByKey.alertBgWeak,
  alertBgStrong: colorsByKey.alertBgStrong,
  successBgWeak: colorsByKey.successBgWeak,
  successBgStrong: colorsByKey.successBgStrong,
  primaryBorder: colorsByKey.primaryBorder,
  accentBorder: colorsByKey.accentBorder,
  warningBorder: colorsByKey.warningBorder,
  alertBorder: colorsByKey.alertBorder,
  successBorder: colorsByKey.successBorder,
  accentTextStrong: colorsByKey.accentTextStrong,
  utility: colorsByKey.utility,
  separators: colorsByKey.separators,
}
