import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import ModalImage from 'react-modal-image'

function PartnershipImages({ partnershipImage, secondaryImage = null }) {
  return (
    <Container isOnlyOne={!secondaryImage}>
      <ImageWrapper isOnlyOne={!secondaryImage}>
        <Image small={partnershipImage} large={partnershipImage} alt={'partnership image'} />
      </ImageWrapper>
      {secondaryImage && (
        <Image2Wrapper>
          <Image2 small={secondaryImage} large={secondaryImage} alt={'partner logo'} />
        </Image2Wrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  ${({ isOnlyOne }) =>
    !isOnlyOne &&
    `
    min-height: 200px;
    min-width: 272px;
  `}
`

const ImageWrapper = styled.div`
  width: 136px;
  height: 136px;
  ${({ isOnlyOne }) =>
    !isOnlyOne &&
    `
    position: absolute;
    right: 130px;
    bottom: 60px;
    z-index: 2;
  `}
`
const Image2Wrapper = styled.div`
  position: absolute;
  width: 136px;
  height: 136px;
  right: 20px;
  bottom: 10px;
`

const image = css`
  width: 136px;
  height: 136px;
  object-fit: cover;
  border-radius: 10px;
`

const Image = styled(ModalImage)`
  ${image}
`

const Image2 = styled(ModalImage)`
  ${image}
`

export default PartnershipImages
