import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Typography,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import {
  COMPANIES,
  ADVISORS,
  POSTS,
  CAMPAIGNS,
  PROTOCOLS,
  SAMPLINGS,
  SELL,
  BUY,
  samplings,
  interestedInWant,
  ACCELERATORS,
  SUBSCRIPTION_OPTION,
  SUBSCRIPTION_OPTION_EXPIRES_AT
} from 'entities/feed'
import { IconData, SocialCard } from 'components'
import { useHistory } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import {
  faEnvelope,
  faBullseye,
  faEye,
  faPenAlt,
  faShareNodes,
  faMessage,
} from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { TYPE, USER, ID, STATUS, NUMBER_OF_SAMPLES } from 'entities/formFields'
import { EMAIL } from 'entities/companyFields'
import authContext from 'context/authContext'
import { postStatus } from 'entities/feed'
import { apiPost } from 'api'
import SUBSCRIPTIONS from 'utils/subscriptions'
import { ModalUpgradePackage } from 'components'
import '../../component/annualpackage/annualpackage.css'
import { connect } from 'react-redux'
import { createNewChat } from 'store/actions/chatActions'

const CardFooterComponent = (props) => {
  let history = useHistory()
  const ctx = useContext(authContext)
  const isMyPost = ctx.userDetail.id === (props[USER] || {})[ID]

  const [visibleModalUpgrade, setVisibleModalUpgrade] = useState(false)
  const [dataModalUpgrade, setDataModalUpgrade] = useState({
    data: '',
    linkUpgrade: '',
  })
  const [dataModalCurrent, setDataModalCurrent] = useState({
    data: '',
    linkUpgrade: '',
  })
  const userCurrent = props.userDetail
  const {canTalk} = props
  const subscriptionCurrent = SUBSCRIPTIONS[userCurrent.subscription]

  const usePlusOption= props[TYPE] === COMPANIES && props[SUBSCRIPTION_OPTION]
  
  const plusOption=props[SUBSCRIPTION_OPTION]

  function handleCloseModal() {
    setVisibleModalUpgrade(false)
  }

  const { t } = props

  const DIALOG_INITIAL_DATA = {
    isOpen: false,
    title: t('Sampling apply title'),
    text: t('Sampling apply question'),
    actions: [
      { text: t('Cancel'), action: () => closeDialog() },
      { text: t('Sampling apply submit button'), action: () => handleWantItem() },
    ],
  }
  const [dialogData, setDialogData] = useState(DIALOG_INITIAL_DATA)
  const [dialogDisabled, setDialogDisabled] = useState(false)
  const closeDialog = () => setDialogData(DIALOG_INITIAL_DATA)
  const showDialog = (fn) => setDialogData({ isOpen: true, ...fn({ close: closeDialog }) })
  const showDialogSampling = () => setDialogData({ ...DIALOG_INITIAL_DATA, isOpen: true })

  const [shareDialogEnabled, setShareDialogEnabled] = useState(false)

  const handleContactByEmail = () => {
    let found = subscriptionCurrent.available_card_btn.find((element) => element == props[TYPE])

    if (props[TYPE] === COMPANIES || props[TYPE] === ADVISORS || props[TYPE] === SAMPLINGS) {
      if (found !== undefined || (userCurrent.subscription === 5 && props.subscription === 5)) {
        //Tiene acceso
        setVisibleModalUpgrade(false)
        window.location.href = `mailto:${(props[USER] || {})[EMAIL] || props[EMAIL]}`
        // window.open(`mailto:${(props[USER] || {})[EMAIL] || props[EMAIL]}`) // Esta solución crea una página en blanco en Safari
      } else {
        let upgrade =
          userCurrent.subscription == 1 || userCurrent.subscription == 5
            ? 2
            : userCurrent.subscription + 1

        setVisibleModalUpgrade(true)
        setDataModalUpgrade({
          data: SUBSCRIPTIONS[upgrade],
          linkUpgrade: userCurrent,
        })
        setDataModalCurrent({
          data: subscriptionCurrent,
          linknowBenefits: '',
        })
        //No tiene acceso y levanta modal de upgrade
      }
    } else {
      window.location.href = `mailto:${(props[USER] || {})[EMAIL] || props[EMAIL]}`
      // window.open(`mailto:${(props[USER] || {})[EMAIL] || props[EMAIL]}`) // Esta solución crea una página en blanco en Safari
    }
  }
  const handleWantClick = (type) => {
    let found = subscriptionCurrent.available_card_btn.find((element) => element == props[TYPE])

    // POST request to send notification to admin
    let item_type
    if (props[TYPE] === PROTOCOLS) {
      item_type = 'pom_services'
    } else {
      item_type = props[TYPE]
    }

    if (!(props[TYPE] === SAMPLINGS)) {
      apiPost(interestedInWant.endpoint, {
        item_id: props[ID],
        item_type: item_type,
        user_id: ctx.userDetail.id,
      }).catch((error) => {
        console.error('Error in "interestedInWant": ', error)
      })
    }

    if (type === SAMPLINGS) {
      if (found != undefined) {
        //Tiene acceso
        setVisibleModalUpgrade(false)
        showDialogSampling()
      } else {
        let upgrade =
          userCurrent.subscription == 1 || userCurrent.subscription == 5
            ? 2
            : userCurrent.subscription + 1

        setVisibleModalUpgrade(true)
        setDataModalUpgrade({
          data: SUBSCRIPTIONS[upgrade],
          linkUpgrade: userCurrent,
        })
        setDataModalCurrent({
          data: subscriptionCurrent,
          linknowBenefits: '',
        })
        //No tiene acceso y levanta modal de upgrade
      }
    } else {
      handleOpenMail()
    }
  }
  const handleWantItem = () => {
    setDialogDisabled(true)

    if (props[TYPE] === SAMPLINGS && props[NUMBER_OF_SAMPLES] > 0) {
      // POST request to send notification to admin
      apiPost(interestedInWant.endpoint, {
        item_id: props[ID],
        item_type: 'surprise_boxes',
        user_id: ctx.userDetail.id,
      })
        .then(() => {
          // POST request to apply to sampling
          apiPost(samplings.applyto.endpoint, { surprise_id: props.id })
            .then((response) => {
              if (response && response.result) {
                // handleOpenMail()
                closeDialog()
                setDialogDisabled(false)
                showDialog(({ close }) => ({
                  title: t('Samplings interested confirmation title'),
                  text: t('Samplings interested confirmation description'),
                  actions: [{ text: t('Close'), action: () => window.location.reload() }],
                }))
              } else {
                showDialog(({ close }) => ({
                  title: 'Error',
                  text: response,
                  actions: [{ text: 'Ok', action: () => close() }],
                }))
              }
            })
            .catch((error) => {
              // if errortype fill error dialog
              setDialogDisabled(false)
              showDialog(({ close }) => ({
                title: 'Error',
                text: error,
                actions: [{ text: 'Ok', action: () => close() }],
              }))
              console.error('There was an error!', error)
            })
        })
        .catch((error) => {
          setDialogDisabled(false)
          console.error('Error in "interestedInWant": ', error)
        })
    } else {
      handleOpenMail()
    }
  }

  const handleOpenMail = () => {
    const simpleTypeName =
      props.type.substring(props.type.length - 1, props.type.length) === 's'
        ? props.type.substring(0, props.type.length - 1)
        : props.type
    const subject = `I am interested in this Bridgewhat ${simpleTypeName}`
    const body = `Hello, I am interested in this Bridgewhat ${simpleTypeName} titled "${props.title}"`
    window.location.href = `mailto:${
      (props[USER] || {})[EMAIL] || props[EMAIL]
    }?subject=${subject}&body=${body}`
    // '_blank'
  }

  const handleViewMore = () => {
    let found = subscriptionCurrent.available_card_btn.find((element) => element == props[TYPE])

    if (props[TYPE] === POSTS) {
      history.push(`/post/${props[ID]}`)
    } else if (props[TYPE] === CAMPAIGNS) {
      history.push(`/campaign/${props[ID]}`)
    } else if (props[TYPE] === COMPANIES) {
      //MODAL UPGRADE
      if (found !== undefined || (userCurrent.subscription === 5 && props.subscription === 5)) {
        // Tiene acceso
        setVisibleModalUpgrade(false)
        history.push(`/company/${props[ID]}`)
      } else {
        let upgrade =
          userCurrent.subscription == 1 || userCurrent.subscription == 5
            ? 2
            : userCurrent.subscription + 1
        setVisibleModalUpgrade(true)
        setDataModalUpgrade({
          data: SUBSCRIPTIONS[upgrade],
          linkUpgrade: userCurrent,
        })
        setDataModalCurrent({
          data: subscriptionCurrent,
          linknowBenefits: '',
        })
        //No tiene acceso y levanta modal de upgrade
      }
    } else if (props[TYPE] === ADVISORS) {
      if (found !== undefined || (userCurrent.subscription === 5 && props.subscription === 5)) {
        //Tiene acceso
        setVisibleModalUpgrade(false)
        history.push(`/advisor/${props[ID]}`)
      } else {
        let upgrade =
          userCurrent.subscription == 1 || userCurrent.subscription == 5
            ? 2
            : userCurrent.subscription + 1
        setVisibleModalUpgrade(true)
        setDataModalUpgrade({
          data: SUBSCRIPTIONS[upgrade],
          linkUpgrade: userCurrent,
        })
        setDataModalCurrent({
          data: subscriptionCurrent,
          linknowBenefits: '',
        })
        //No tiene acceso y levanta modal de upgrade
      }
    } else if (props[TYPE] === SELL) {
      history.push(`/expo/${props[ID]}`)
    } else if (props[TYPE] === ACCELERATORS) {
      history.push(`/accelerator/${props[ID]}`)
    }
  }

  const handleEditPost = () => {
    if (props[TYPE] === SELL) history.push(`/edit/expo/${props[ID]}`)
    else history.push(`/edit/${props[TYPE]}/${props[ID]}`)
  }
  const canContactCompanyByEmail = props[TYPE] === COMPANIES && props[EMAIL]
  const canContactUserByEmail = (props[USER] || {})[EMAIL]
  const canContactAdvisorByEmail = props[TYPE] === ADVISORS && props[EMAIL]
  const canViewMore =
    props[TYPE] === POSTS ||
    props[TYPE] === CAMPAIGNS ||
    props[TYPE] === COMPANIES ||
    props[TYPE] === ADVISORS ||
    props[TYPE] === ACCELERATORS ||
    props[TYPE] === SELL ||
    props[TYPE] === BUY

  const chatTitle = props[TYPE] === COMPANIES || props[TYPE] === ADVISORS ? 'Chat' : 'Send message'

  return (
    <Box className="Footer">
      {(canContactCompanyByEmail ||
        canViewMore ||
        canContactUserByEmail ||
        canContactAdvisorByEmail) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
            mt: 2,
            borderTop: `2px solid ${colorsByKey.defaultBgWeak}`,
            backgroundColor: `2px solid ${colorsByKey.utility}`,
          }}
        >
        {usePlusOption && (
        <>
          <Box
            sx={{
              display: 'flex',
              flex: '0 1 75%',
              alignItems: 'center',
              justifyContent: 'flex-start',
              borderRadius: '24px',
              py: 0.5,
              // px: 2,
            }}
          >
            <span style={{ color: (SUBSCRIPTIONS[props.subscription] || {}).color, fontWeight: "900", fontSize: "1.125rem" }}>{plusOption.toUpperCase()}</span>
          </Box>
        </>
      )}
          { canTalk && (
            <Button onClick={() => {
              props.createNewChat({user: props.id})
            }} type="button" variant="text">
              <IconData
                title={t(chatTitle)}
                icon={faMessage}
                titleVariant="f14"
                margin={1}
                clickable
                color={colorsByKey.primaryBgStrong}
                sx={{
                  padding: '6px 8px',
                  '& span': { fontWeight: '300' },
                }}
              />
            </Button>
          )}
          {isMyPost && (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ ml: 1, flex: 1, display: 'flex', alignItems: 'center' }}>
                {props[STATUS] && (
                  <>
                    <Typography
                      noWrap
                      variant="f12"
                      color={colorsByKey.secondaryText}
                      sx={{ mr: 2 }}
                    >
                      {t('Status')}:
                    </Typography>
                    <IconData
                      title={postStatus(props[STATUS]).label}
                      icon={faCircle}
                      margin={1}
                      color={postStatus(props[STATUS]).color}
                    />
                  </>
                )}
              </Box>
              {props[TYPE] !== ACCELERATORS && (
                <Button
                  onClick={handleEditPost}
                  variant="contained"
                  sx={{
                    ml: 2,
                    borderRadius: '48px !important',
                    backgroundColor: colorsByKey.accentBg,
                  }}
                >
                  <IconData
                    title={t('Edit')}
                    icon={faPenAlt}
                    margin={1}
                    clickable
                    color={colorsByKey.utility}
                    sx={{
                      padding: '6px 8px',
                      '& span': { fontWeight: '300' },
                    }}
                  />
                </Button>
              )}
            </Box>
          )}

          {!isMyPost && (canContactCompanyByEmail || canContactAdvisorByEmail) && (
            <Button
              onClick={handleContactByEmail}
              variant="contained"
              sx={{
                ml: 2,
                borderRadius: '48px !important',
                backgroundColor: colorsByKey.accentBg,
              }}
            >
              <IconData
                title={t('Send an email')}
                icon={faEnvelope}
                titleVariant="f14"
                margin={1}
                clickable
                color={colorsByKey.utility}
                sx={{
                  padding: '6px 8px',
                  '& span': { fontWeight: '300' },
                }}
              />
            </Button>
          )}
          {(canContactUserByEmail || canViewMore) && (
            <Box>
              {canContactUserByEmail && props[TYPE] !== POSTS && !isMyPost && (
                <>
                  <Button onClick={handleContactByEmail} type="button" variant="text">
                    <IconData
                      title={t('Send an email')}
                      icon={faEnvelope}
                      titleVariant="f14"
                      margin={1}
                      clickable
                      color={colorsByKey.primaryBgStrong}
                      sx={{
                        padding: '6px 8px',
                        '& span': { fontWeight: '300' },
                      }}
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      handleWantClick(props[TYPE])
                    }}
                    variant="contained"
                    disabled={props[TYPE] === SAMPLINGS && !(props[NUMBER_OF_SAMPLES] > 0)}
                    sx={{
                      ml: 2,
                      borderRadius: '48px !important',
                      backgroundColor: colorsByKey.accentBg,
                    }}
                  >
                    <IconData
                      title={t('Want')}
                      icon={faBullseye}
                      titleVariant="f14"
                      margin={1}
                      clickable
                      color={colorsByKey.utility}
                      sx={{
                        padding: '6px 8px',
                        '& span': { fontWeight: '300' },
                      }}
                    />
                  </Button>
                </>
              )}
              {props[TYPE] === POSTS && (
                <>
                  <Button
                    onClick={() => setShareDialogEnabled(true)}
                    type="button"
                    variant="text"
                    sx={{ ml: 2 }}
                  >
                    <IconData
                      title={t('Share')}
                      icon={faShareNodes}
                      titleVariant="f14"
                      margin={1}
                      clickable
                      color={colorsByKey.primaryBgStrong}
                      sx={{
                        padding: '6px 8px',
                        '& span': { fontWeight: '300' },
                      }}
                    />
                  </Button>
                </>
              )}
              {canViewMore && (
                <Button
                  onClick={handleViewMore}
                  variant="contained"
                  sx={{
                    ml: 2,
                    borderRadius: '48px !important',
                    backgroundColor: colorsByKey.accentBg,
                  }}
                >
                  <IconData
                    title={t('View more')}
                    icon={faEye}
                    titleVariant="f14"
                    margin={1}
                    clickable
                    color={colorsByKey.utility}
                    sx={{
                      padding: '6px 8px',
                      '& span': { fontWeight: '300' },
                    }}
                  />
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
      {
        <div>
          <Dialog
            open={dialogData.isOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disabled={dialogDisabled}
          >
            <DialogTitle id="alert-dialog-title">{dialogData.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{dialogData.text}</DialogContentText>
            </DialogContent>
            <DialogActions>
              {dialogData.actions.map(({ text, action }, index) => (
                <Button key={index} onClick={action}>
                  {text}
                </Button>
              ))}
            </DialogActions>
          </Dialog>
          <SocialCard
            props={props}
            visible={shareDialogEnabled}
            onHide={() => setShareDialogEnabled(false)}
          />
        </div>
      }
      <ModalUpgradePackage
        visible={visibleModalUpgrade}
        dataUpgrade={dataModalUpgrade}
        dataCurrent={dataModalCurrent}
        closeModal={() => handleCloseModal()}
      />
    </Box>
  )
}

const mapStateToProps = null

const mapDispatchToProps = {
  createNewChat
}

export const CardFooter = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CardFooterComponent))
