import React from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Row, Col } from 'react-bootstrap'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle, faLink, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import MuiPhoneNumberValidatorElement from '../../profile/ErrorComponent/MuiPhoneNumberValidatorElement'
import AuthenticatedFile from '../../general/AuthenticatedFile'

import {
  faFacebookF,
  faTwitter,
  faGoogle,
  faLinkedinIn,
  faYoutube,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
library.add(
  faLink,
  faPlusCircle,
  faFacebookF,
  faTwitter,
  faGoogle,
  faLinkedinIn,
  faYoutube,
  faInstagram,
  faAngleRight
)

class SBAregistrationsteponeform extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        company_name: '',
        first_name: '',
        country: null,
        job_function: '',
        code: '',
        phone: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        youtube: '',
        website: '',
      },
      online_presence_name: '',
      policypage: '',
      termpage: '',
      cookiepage: '',
      contractpage: '',
      submitted: false,
      showHide: true,
      countries: [],
      loading: true,
      loaded: false,
    }

    this.handlePhoneChange = this.handlePhoneChange.bind(this)
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule('isUrl')) {
      ValidatorForm.removeValidationRule('isUrl')
    }
    if (ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.removeValidationRule('isPhone')
    }
  }

  async componentDidMount() {
    if (!ValidatorForm.hasValidationRule('isUrl')) {
      ValidatorForm.addValidationRule('isUrl', (value) => {
        if (value != null && value !== '') {
          var pattern = new RegExp(
            '((http|https)://)(www.)?' +
              '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
          )
          return !!pattern.test(value)
        }
        return true
      })
    }

    if (!ValidatorForm.hasValidationRule('isPhone')) {
      ValidatorForm.addValidationRule('isPhone', (value) => {
        if (value === null || value.trim() === '' || value.trim() === '+') {
          return false
        }
        return true
      })
    }

    if (this.props.userDetail) {
      const success = this.props.userDetail
      const formData = {
        company_name: success.company_name,
        first_name: success.name,
        job_function: success.job_function,
        phone: success.phone_number,
        facebook: success.facebook,
        twitter: success.twitter,
        linkedin: success.linkedin,
        instagram: success.instagram,
        youtube: success.youtube,
        website: success.website,
      }
      this.setState({ formData })
    }

    const page_api_url2 = process.env.REACT_APP_API_URL + '/api/pages/2'
    await axios
      .get(page_api_url2)
      .then((response) => {
        const { data } = response.data
        this.setState({ policypage: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const page_api_url3 = process.env.REACT_APP_API_URL + '/api/pages/3'
    await axios
      .get(page_api_url3)
      .then((response) => {
        const { data } = response.data
        this.setState({ termpage: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const page_api_url4 = process.env.REACT_APP_API_URL + '/api/pages/4'
    await axios
      .get(page_api_url4)
      .then((response) => {
        const { data } = response.data
        this.setState({ cookiepage: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const page_api_url5 = process.env.REACT_APP_API_URL + '/api/pages/5'
    await axios
      .get(page_api_url5)
      .then((response) => {
        const { data } = response.data
        this.setState({ contractpage: data, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
  }

  handleOnlinePresence = (event, value) => {
    const { formData } = this.state
    formData['online_presence'] = value
    if (value !== null) {
      this.setState({ online_presence_name: '1' })
    } else {
      this.setState({ online_presence_name: '' })
    }
    this.setState({ formData })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handlePhoneChange(value) {
    const { formData } = this.state
    formData['phone'] = value
    this.setState({ formData })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/addSbaRegistrationForm1'

      axios
        .post(sector_api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          // handle success
          this.props.nextStep(2)
          this.setState({ submitted: false })
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, error: data.error })
              }
            } else {
              alert(this.props.t('Something went wrong, please try again'))
            }
          }
        })
    })
  }
  render() {
    const { t } = this.props
    const { formData, submitted } = this.state
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }
    return (
      <div>
        <div className="boxshadow p-3 pt-1 mb-5">
          <h6 className="pb-3">{t('User Information')}</h6>
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <Row>
              <Col lg="6">
                <div className="form-group">
                  <label>
                    {t('Contact Name')} <sup>*</sup>
                  </label>
                  <TextValidator
                    variant="outlined"
                    margin="0"
                    fullWidth
                    type="text"
                    name="first_name"
                    onChange={this.handleChange}
                    value={formData.first_name}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label>
                    {t('Job Function')} <sup>*</sup>
                  </label>
                  <TextValidator
                    variant="outlined"
                    margin="0"
                    fullWidth
                    type="text"
                    name="job_function"
                    onChange={this.handleChange}
                    value={formData.job_function}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label>
                    {t('Phone Number')} <sup>*</sup>
                  </label>
                  <div className="w-100 phone_number_f">
                    <MuiPhoneNumberValidatorElement
                      name="phone"
                      className="phone_number"
                      data-cy="user-phone"
                      value={formData.phone}
                      onChange={this.handlePhoneChange}
                      fullWidth
                      variant="outlined"
                      validators={['isPhone']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </div>
              </Col>
              <div className="col-12">
                <label>{t('Online Presence')}:</label>
              </div>
              <Col lg="6">
                <div className="form-group">
                  <label>
                    {t('Linkedin')} <sup>*</sup>
                  </label>
                  <Grid className="d-flex align-items-center online-presence-min">
                    <Grid item className="online-presence-icon">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </Grid>
                    <Grid item className="w-100">
                      <TextValidator
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="linkedin"
                        onChange={this.handleChange}
                        placeholder={`Linkedin (${t('Insert full url')})`}
                        value={formData.linkedin}
                        validators={['required', 'isUrl']}
                        errorMessages={['This field is required', 'Invalid url']}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label>{t('Facebook (optional)')}</label>
                  <Grid className="d-flex align-items-center online-presence-min">
                    <Grid item className="online-presence-icon">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Grid>
                    <Grid item className="w-100">
                      <TextValidator
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="facebook"
                        onChange={this.handleChange}
                        placeholder={`Facebook (${t('Insert full url')})`}
                        value={formData.facebook}
                        validators={['isUrl']}
                        errorMessages={t('Invalid url')}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label>{t('Youtube (optional)')}</label>
                  <Grid className="d-flex align-items-center online-presence-min">
                    <Grid item className="online-presence-icon">
                      <FontAwesomeIcon icon={faYoutube} />
                    </Grid>
                    <Grid item className="w-100">
                      <TextValidator
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="youtube"
                        onChange={this.handleChange}
                        placeholder={`Youtube (${t('Insert full url')})`}
                        value={formData.youtube}
                        validators={['isUrl']}
                        errorMessages={t('Invalid url')}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label>{t('Instagram (optional)')}</label>
                  <Grid className="d-flex align-items-center online-presence-min">
                    <Grid item className="online-presence-icon">
                      <FontAwesomeIcon icon={faInstagram} />
                    </Grid>
                    <Grid item className="w-100">
                      <TextValidator
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="instagram"
                        onChange={this.handleChange}
                        placeholder={`Instagram (${t('Insert full url')})`}
                        value={formData.instagram}
                        validators={['isUrl']}
                        errorMessages={t('Invalid url')}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label>{t('Twitter (optional)')}</label>
                  <Grid className="d-flex align-items-center online-presence-min">
                    <Grid item className="online-presence-icon">
                      <FontAwesomeIcon icon={faTwitter} />
                    </Grid>
                    <Grid item className="w-100">
                      <TextValidator
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="twitter"
                        onChange={this.handleChange}
                        placeholder={`Twitter (${t('Insert full url')})`}
                        value={formData.twitter}
                        validators={['isUrl']}
                        errorMessages={t('Invalid url')}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Col>
              <Col lg="6">
                <div className="form-group">
                  <label>{t('Website (optional)')}</label>
                  <Grid className="d-flex align-items-center online-presence-min">
                    <Grid item className="online-presence-icon">
                      <FontAwesomeIcon icon={faLink} />
                    </Grid>
                    <Grid item className="w-100">
                      <TextValidator
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="website"
                        onChange={this.handleChange}
                        placeholder={`Website (${t('Insert full url')})`}
                        value={formData.website}
                        validators={['isUrl']}
                        errorMessages={t('Invalid url')}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Col>
            </Row>
            <hr className="mt-4"></hr>
            <Row>
              <Col lg="12">
                <h6 className="pb-3">{t('Company information (optional)')}</h6>
                <div className="form-group">
                  <label>{t('Company Commercial Name')}</label>
                  <TextValidator
                    variant="outlined"
                    margin="0"
                    fullWidth
                    type="text"
                    name="company_name"
                    onChange={this.handleChange}
                    value={formData.company_name}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div className="col-md-12">
                <div className="form-group text-center">
                  <Button
                    type="submit"
                    variant="contained"
                    className="submit-btn"
                    disabled={submitted}
                  >
                    {(submitted && t('Your form is submitted!')) || (!submitted && t('Next Step'))}
                    &nbsp;
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                </div>
              </div>
            </Row>
          </ValidatorForm>
        </div>
        <Modal show={this.state.showHide} backdrop="static" className="policypopup">
          <Modal.Header>
            <Modal.Title
              dangerouslySetInnerHTML={{ __html: this.state.policypage.name }}
            ></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: this.state.policypage.content }}></div>
            <div className="row">
              <div className="page-titles">
                <h4>{this.state.termpage.name}</h4>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.termpage.content,
              }}
            ></div>

            <div className="row">
              <div className="page-titles">
                <h4>{this.state.cookiepage.name}</h4>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.cookiepage.content,
              }}
            ></div>

            <div className="row">
              <div className="page-titles">
                <h4>{this.state.contractpage.name}</h4>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.contractpage.content,
              }}
            ></div>
            <div className="table-responsive registrat">
              <Table bordered responsive className="needtable need-table">
                <thead>
                  <tr>
                    <th>
                      <img
                        style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                        className="en"
                        src={require('assets/images/en_flag.jpg')}
                        alt="#"
                      />
                      <span>EN</span>
                    </th>
                    <th>
                      <img
                        style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                        className="en"
                        src={require('assets/images/pt_flag.jpg')}
                        alt="#"
                      />
                      <span>PT</span>
                    </th>
                    <th>
                      <img
                        style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                        className="en"
                        src={require('assets/images/es_flag.jpg')}
                        alt="#"
                      />
                      <span>ES</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {this.state.policypage.upload_pdf ? (
                        <AuthenticatedFile
                          url={`/${this.state.policypage.upload_pdf}`}
                          filename={this.state.policypage.upload_pdf.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Privacy Policy.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.policypage.upload_pdf_pr ? (
                        <AuthenticatedFile
                          url={`/${this.state.policypage.upload_pdf_pr}`}
                          filename={this.state.policypage.upload_pdf_pr.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Politica privacidade.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.policypage.upload_pdf_es ? (
                        <AuthenticatedFile
                          url={`/${this.state.policypage.upload_pdf_es}`}
                          filename={this.state.policypage.upload_pdf_es.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Politica privacidad.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {this.state.termpage.upload_pdf ? (
                        <AuthenticatedFile
                          url={`/${this.state.termpage.upload_pdf}`}
                          filename={this.state.termpage.upload_pdf.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Terms and Conditions.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.termpage.upload_pdf_pr ? (
                        <AuthenticatedFile
                          url={`/${this.state.termpage.upload_pdf_pr}`}
                          filename={this.state.termpage.upload_pdf_pr.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Condições gerais de utilização.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.termpage.upload_pdf_es ? (
                        <AuthenticatedFile
                          url={`/${this.state.termpage.upload_pdf_es}`}
                          filename={this.state.termpage.upload_pdf_es.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Términos y condiciones.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {this.state.cookiepage.upload_pdf ? (
                        <AuthenticatedFile
                          url={`/${this.state.cookiepage.upload_pdf}`}
                          filename={this.state.cookiepage.upload_pdf.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Cookie policy.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.cookiepage.upload_pdf_pr ? (
                        <AuthenticatedFile
                          url={`/${this.state.cookiepage.upload_pdf_pr}`}
                          filename={this.state.cookiepage.upload_pdf_pr.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Politica cookies.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.cookiepage.upload_pdf_es ? (
                        <AuthenticatedFile
                          url={`/${this.state.cookiepage.upload_pdf_es}`}
                          filename={this.state.cookiepage.upload_pdf_es.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Politica cookies.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {this.state.contractpage.upload_pdf ? (
                        <AuthenticatedFile
                          url={`/${this.state.contractpage.upload_pdf}`}
                          filename={this.state.contractpage.upload_pdf.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Participant's contract.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.contractpage.upload_pdf_pr ? (
                        <AuthenticatedFile
                          url={`/${this.state.contractpage.upload_pdf_pr}`}
                          filename={this.state.contractpage.upload_pdf_pr.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Contrato Participante.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                    <td>
                      {this.state.contractpage.upload_pdf_es ? (
                        <AuthenticatedFile
                          url={`/${this.state.contractpage.upload_pdf_es}`}
                          filename={this.state.contractpage.upload_pdf_es.split(/[/]+/).pop()}
                          linklabel={
                            <div>
                              <img
                                style={{ width: '20px', margin: '0px 10px 0px 0px' }}
                                className="en"
                                src={require('assets/images/pdf.png')}
                                alt="#"
                              />
                              <span>Contrato Participante.pdf</span>
                            </div>
                          }
                        />
                      ) : (
                        <span>--</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <Button
              closeButton
              onClick={() => this.handleModalShowHide()}
              className="submit-btn mt-1 ml-0 mr-0 mb-1 border-0 MuiButton-root MuiButton-contained"
            >
              Agree and continue
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
export default withTranslation()(SBAregistrationsteponeform)
