import { CLOSE_CHAT, CREATE_NEW_CHAT, DELETE_CHAT, DELETE_MESSAGE, EDIT_MESSAGE, GET_CHAT, GET_CHAT_LIST, OPEN_CHAT, SEND_CHAT_MESSAGE, VIEW_MESSAGES } from 'store/types'
const initialState = {
  chats: [],
  chat: {},

};

const chatReducer = (state = initialState, action) => {
  let chatIndex = null
  switch (action.type) {
    case GET_CHAT_LIST:
      if(Object.values(state.chat).length) {
        let index = action.payload.findIndex(chat => chat.id === state.chat.id)
        state.chat.messages = [...action.payload[index].chat.messages, ...state.chat.messages]
      }
      return {
        ...state,
        chats: action.payload,
        chat: state.chat.id ? action.payload.find(chat => chat.id === state.chat.id).chat : state.chat
      }
    case GET_CHAT:
      return {
        ...state,
        chat: {...action.payload, ...state.chat},
      }
    case OPEN_CHAT:
      chatIndex = state.chats.findIndex(chat => chat.id === action.payload)
      state.chats[chatIndex].unreadMessages = []
      if(state.chats[chatIndex].lastMessage)
        state.chats[chatIndex].lastMessage.status = 'viewed'
      return {
        ...state,
        chats: [...state.chats],
        chat: state.chats.find(chat => chat.id === action.payload).chat,
      }
    case CLOSE_CHAT:
      return {
        ...state,
        chat: {}
      }
    case VIEW_MESSAGES:
      return {
        ...state,
        chat: action.payload,
      }
    case CREATE_NEW_CHAT:
      return {
        ...state,
        chat: action.payload,
        chats: [...state.chats, action.payload]
      }
    case SEND_CHAT_MESSAGE:
      chatIndex = state.chats.findIndex(chat => chat.id === state.chat.id)
      state.chats[chatIndex].chat.messages = action.payload
      state.chats[chatIndex].lastMessage = {...action.payload[action.payload.length -1], status: 'viewed'}
      state.chats[chatIndex].unreadMessages = []
      const chat = {...state.chats[chatIndex]}
      state.chats.splice(chatIndex, 1)
      state.chats.unshift(chat)

      return {
        ...state,
        chats: [...state.chats],
        chat: {...state.chat, messages: action.payload},
      }
    case DELETE_MESSAGE:
      chatIndex = state.chats.findIndex(chat => chat.id === action.payload.id)
      state.chats[chatIndex].chat.messages = state.chats[chatIndex].chat.messages.filter(message => message.id !== action.payload.id)
      state.chat.messages = state.chats[chatIndex].chat.messages
      return state
    case DELETE_CHAT:
      state.chats = state.chats.filter(chat => chat.id !== action.payload.id)
      if(state.chat.id === action.payload.id)
        state.chat = {}
      return state
    case EDIT_MESSAGE:
      chatIndex = state.chats.findIndex(chat => chat.id === state.chat.id)
      const messageIndex = state.chats[chatIndex].chat.messages.findIndex(message => message.id === action.payload.id)
      state.chats[chatIndex].chat.messages[messageIndex] = action.payload
      return state
    default:
      return state;
  }
};

export default chatReducer;
