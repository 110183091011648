import React from 'react'
import { Box, Typography } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import { Scores } from './Scores'
import { SCORE } from 'entities/companyFields'

const ScoresBoxComponent = ({ reviews, total, t }) => {
  const reviewsQt = reviews.length
  let allScores = 0
  reviews.forEach((review) => {
    allScores += review[SCORE]
  })
  const averageScore = allScores / reviewsQt

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: colorsByKey.warningBgWeak,
        borderRadius: '6px',
        boxShadow: '2px 0px 8px rgba(0,0,0,0.10)',
        width: 'fit-content',
        alignItems: 'center',
        p: 2,
      }}
    >
      <Scores total={total} score={averageScore} />
      <Typography
        variant="f16"
        color={colorsByKey.accentText}
        sx={{ ml: 1, fontWeight: 'bold', display: 'flex' }}
      >
        {averageScore}
        <Typography
          variant="f16"
          component="span"
          color={colorsByKey.defaultText}
          sx={{ ml: 1, fontWeight: 'bold' }}
        >
          / {total}
        </Typography>
      </Typography>
      <Typography
        variant="f14"
        color={colorsByKey.defaultText}
        sx={{ ml: 2, textTransform: 'lowercase' }}
      >
        ({reviewsQt} {t('Reviews')})
      </Typography>
    </Box>
  )
}
export const ScoresBox = withTranslation()(ScoresBoxComponent)
