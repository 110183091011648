import React, { useState } from 'react'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { Box } from '@mui/material'
import { TYPE } from 'entities/formFields'
import { COMPANIES, ADVISORS } from 'entities/feed'
import { Container, Row, Modal, Col } from 'react-bootstrap'
import { apiPost } from 'api'
import { interestedUpgrade } from 'entities/feed'

export const ModalUpgradePackage = ({ visible, dataUpgrade, dataCurrent, closeModal }) => {
  const handleWantClickUpgrade = (userCurrent, packageTo, type) => {
    console.log(userCurrent, packageTo, type)

    apiPost(interestedUpgrade.endpoint, {
      user_id: userCurrent,
      new_package: packageTo,
      type,
      type,
    })
      .then((data) => {
        console.error(data)
        closeModal()
      })
      .catch((error) => {
        console.error('Error in "interestedUpgrade": ', error)
      })
  }

  return (
    <Box>
      <Modal className={`modal-upgrade`} show={visible} animation={false} backdrop="static">
        <Modal.Body>
          <div className={`modal-upgrade-header ${dataCurrent?.data?.pname}-upgrade-pack`}>
            <div className={`modal-btn-upgrade-close`} onClick={() => closeModal()}>
              <a href="#">x</a>
            </div>
            <div>Your suscription</div>
            <div className={`title-modal-current`}>{dataCurrent.data.name}</div>
            <div className={`link-modal-benefits`}>
              <a href="https://bridgewhat.com/#Packages" target="_blank">
                Know all my benefits
              </a>
            </div>
          </div>
          <div className={`modal-upgrade-body`}>
            Upgrade to{' '}
            <span className={`title-modal-upgrade ${dataUpgrade.data.name}-pack`}>
              {dataUpgrade.data.name}
            </span>
          </div>
          <div
            className={`modal-btn-upgrade`}
            onClick={() => {
              handleWantClickUpgrade(dataUpgrade.linkUpgrade.id, dataUpgrade.data.id, 'upgrade')
            }}
          >
            <a>Upgrade now</a>
          </div>
          <div
            className={`link-modal-knowmore`}
            onClick={() => {
              handleWantClickUpgrade(dataUpgrade.linkUpgrade.id, dataUpgrade.data.id, 'know')
            }}
          >
            <a>I want to know more</a>
          </div>
        </Modal.Body>
      </Modal>
    </Box>
  )
}
