/* COMPANY FIELDS */

import {
  faMapMarkerAlt,
  faUsers,
  faSackDollar,
  faIndustryAlt,
  faGlobe,
  faEnvelope,
  faPhoneAlt,
  faSignature,
  faCalendar,
  faGlobeEurope,
  faDownload,
  faMask,
  faHeartBroken,
  faTag,
  faPrayingHands,
  faBriefcase,
  faLanguage,
  faUserCircle,
} from '@fortawesome/pro-light-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {
  COMPANY_REVENUES,
  COMPANY_SIZES,
  BUSINESS_AREAS_OPTIONS,
  EXPERIENCE_PREVIOUS_FUNCIONS_OPTIONS,
} from 'mixins/filters'
import { FIELDS_OF_STUDY, TYPES_OF_DEGREE } from 'mixins/educationFilters'
import {
  EXPERTISES,
  EDUCATION,
  CERTIFICATES,
  AWARDS,
  education,
  expertises,
} from 'entities/company'

const getNameOrString = (property, key) => {
  return typeof property === 'object' ? property[key] : property
}

const getTextByKey = (text, options = [], isMultiple) => {
  if (!text || text.toString() === '[null]') return
  if (isMultiple && text.includes('['))
    return (
      text.includes('"') ? JSON.parse(text) : typeof text === 'string' ? [text] : text || []
    ).join(', ')
  if (isMultiple && Array.isArray(text)) {
    let finalArray = []
    text.forEach((i) => {
      if (i) {
        const item = options.find(({ key, name }) => key == i.key || name == i.key)
        finalArray.push({ name: getNameOrString(item || {}, 'name') || i.name || i.key })
      }
    })
    return finalArray
  } else {
    const item = options.find(({ key, name }) => key == text || name == text)
    return getNameOrString(item || {}, 'name') || text
  }
}
const getTextById = (text, options = []) => {
  const item = options.find(({ id }) => id == text)
  return (item || {}).name || text
}

export const ADVISOR = 'advisor'
export const COMPANY = 'company'

export const USER_PROFILE_TYPES = [
  {
    key: COMPANY,
    label: 'Company',
    info: '',
  },
  {
    key: ADVISOR,
    label: 'Advisor',
    info: 'Advisor info',
  },
]

export const ID = 'id'
export const NAME = 'name'
export const DESCRIPTION = 'description'
export const ABOUT = 'about'
export const OPERATION_COUNTRIES = 'operationCountries' // countryOfOperation // operationCountry // country
export const INDUSTRY_SECTORS = 'industrySectors' // sector
export const USER_INDUSTRY_SECTORS = 'userIndustrySectors'
export const SIZE = 'size'
export const REVENUE = 'revenue'
export const LEVERS_OF_GROWTH = 'leversOfGrowth'
export const IMAGE = 'image'
export const PHOTO = 'photo'
export const AVATAR = 'avatar'
export const EMAIL = 'email'
export const PASSWORD = 'password'
export const PHONE = 'phone'
export const WEBSITE = 'website'
export const SUBSCRIPTION = 'subscription'
export const IS_SELLER = 'isSeller'
export const SOCIAL_MEDIA = 'socialMedia'
export const LINKEDIN = 'linkedin'
export const INSTAGRAM = 'instagram'
export const FACEBOOK = 'facebook'
export const TWITTER = 'twitter'
export const YOUTUBE = 'youtube'
export const LEGAL_NAME = 'legalName'
export const FOUNDATION_YEAR = 'foundationYear'
export const FISCAL_NUMBER = 'fiscalNumber'
export const BROCHURE = 'brochure'
/*  financialReport */
export const FINANTIAL_REPORT = 'financialReport'
export const DELIQUENCY_RATING = 'delinquencyRating'
export const FAILURE_RATING = 'failureRating'
export const TAX = 'tax'
export const SOCIAL_SECURITY = 'socialSecurity'
export const SCORE = 'score'
export const JOB_FUNCTION = 'jobFunction'
export const LANGUAGES = 'languages'
export const EXPERIENCE_START_DATE = 'experienceStartDate'
export const EXPERIENCE_YEARS = 'experienceYears'
export const HIDE_IDENTITY = 'hideIdentity'
export const PUBLISH = 'publish'

export const FULL_ADDRESS = 'fullAddress'
export const ADDRESS = 'address'
export const POSTAL_CODE = 'postalCode'
export const CITY = 'city'
export const COUNTRY = 'country'
export const STATE = 'state'

export const END_DATE = 'endDate'
export const START_DATE = 'startDate'
export const DATE_RANGE = 'dateRange'

export const CURRENT_JOB = 'currentJob'
export const MAIN_CAREER_ACHIEVEMENT = 'mainCareerAchievement'
export const GEOGRAPHY = 'geography'
export const FIELD_OF_STUDY = 'fieldOfStudy'
export const TYPE_OF_DEGREE = 'typeOfDegree'
export const BUSINESS_AREAS = 'businessAreas'
export const EXPERIENCE_PREVIOUS_FUNCIONS = 'experiencePreviousFunctions'

export const USER_TYPE = 'userType'
export const INVITATION_TYPE = 'invitation_type'
export const SUBSCRIPTION_OPTION = 'subscription_option'
export const SUBSCRIPTION_OPTION_EXPIRES_AT = 'subscription_option_expires_at'

export const id = {
  key: ID,
}
export const name = {
  key: NAME,
  label: 'Name',
  icon: undefined,
  type: 'text',
  grid: 12,
  backendKey: 'name',
}
export const description = {
  key: DESCRIPTION,
  label: 'Description',
  icon: undefined,
  type: 'text',
  multiline: true,
  grid: 12,
  backendKey: 'description',
}
export const operationCountries = {
  key: OPERATION_COUNTRIES,
  label: 'Country of operation',
  icon: faMapMarkerAlt,
  type: 'select',
  optionType: 'countries',
  multiple: true,
  backendKey: 'countries',
  toServerKeyExtractor: 'id',
}
export const industrySectors = {
  key: INDUSTRY_SECTORS,
  label: 'Industry sector',
  icon: faIndustryAlt,
  optionsKey: 'sectors',
  getText: (text, options) => getTextById(text, options),
  type: 'select',
  optionType: 'sectors',
  multiple: true,
  backendKey: 'sectors',
  toServerKeyExtractor: 'id',
}
export const userIndustrySectors = {
  key: USER_INDUSTRY_SECTORS,
  label: 'Industry sectors',
  icon: faIndustryAlt,
  optionsKey: 'sectors',
  getText: (text, options) => getTextById(text, options),
  type: 'select',
  optionType: 'sectors',
  multiple: true,
  backendKey: 'user_industry_sectors',
  toServerKeyExtractor: 'id',
}
export const size = {
  key: SIZE,
  label: 'Size',
  icon: faUsers,
  suffix: 'people',
  getText: (text) => getTextByKey(text, COMPANY_SIZES, true),
  type: 'select',
  optionType: 'companySizes',
  multiple: true,
  backendKey: 'company_size',
  toServerKeyExtractor: 'key',
}
export const revenue = {
  key: REVENUE,
  label: 'Revenue',
  icon: faSackDollar,
  getText: (text) => getTextByKey(text, COMPANY_REVENUES, true),
  type: 'select',
  optionType: 'companyRevenues',
  multiple: true,
  backendKey: 'company_revenue',
  toServerKeyExtractor: 'key',
}
export const experience = {
  key: EXPERIENCE_YEARS, // EXPERIENCE_START_DATE,
  label: 'Experience',
  icon: faBriefcase,
  type: 'date',
  backendKey: 'experience_start_date',
}
export const experienceStartDate = {
  key: EXPERIENCE_START_DATE,
  label: 'Experience start date',
  icon: faBriefcase,
  type: 'date',
  backendKey: 'experience_start_date',
}
export const date = {
  key: 'date',
  label: 'Date',
  icon: faBriefcase,
  type: 'date',
  backendKey: 'date',
}
export const jobFunction = {
  key: JOB_FUNCTION,
  label: 'Job function',
  icon: faUserCircle,
  type: 'text',
  backendKey: 'job_function',
}
export const languages = {
  key: LANGUAGES,
  label: 'Languages',
  icon: faLanguage,
  type: 'select',
  optionType: 'languages',
  multiple: true,
  backendKey: 'languages',
  toServerKeyExtractor: 'id', // is it?
}
export const leversOfGrowth = {
  key: LEVERS_OF_GROWTH,
  label: 'Levers Of Growth',
  icon: undefined,
  type: 'select',
  maxOptions: 3,
  optionType: 'services',
  multiple: true,
  backendKey: 'service_id', // is it?
  toServerKeyExtractor: 'id',
}
export const image = {
  key: IMAGE,
  label: 'Image',
  icon: undefined,
  type: 'files',
  multiple: false,
  accept: 'image',
  backendKey: 'image',
  toServerKeyExtractor: 'id',
}
export const address = {
  key: ADDRESS,
  label: 'Address',
  icon: faMapMarkerAlt,
  type: 'text',
  backendKey: 'address1',
}
export const fullAddress = {
  key: FULL_ADDRESS,
  label: 'Address',
  icon: faMapMarkerAlt,
  type: 'text',
  backendKey: 'address1',
}
export const postalCode = {
  key: POSTAL_CODE,
  label: 'Postal code',
  icon: faMapMarkerAlt,
  type: 'text',
  backendKey: 'postal_code',
}
export const city = {
  key: CITY,
  label: 'City',
  icon: faMapMarkerAlt,
  type: 'text',
  backendKey: 'city',
}
export const country = {
  key: COUNTRY,
  label: 'Country',
  icon: faMapMarkerAlt,
  backendKey: 'country',
  type: 'select',
  optionType: 'countries',
  multiple: false,
  toServerKeyExtractor: 'id',
}
export const state = {
  key: STATE,
  label: 'State',
  icon: faMapMarkerAlt,
  type: 'text',
  backendKey: 'state',
}

export const email = {
  key: EMAIL,
  label: 'Email',
  icon: faEnvelope,
  type: 'text',
  backendKey: 'email',
}
export const password = {
  key: PASSWORD,
  label: 'Password',
  type: 'text',
  backendKey: 'password',
}
export const phone = {
  key: PHONE,
  label: 'Phone',
  icon: faPhoneAlt,
  type: 'text',
  backendKey: 'phone',
}
export const website = {
  key: WEBSITE,
  label: 'Website',
  btn: 'See website',
  icon: faGlobe,
  type: 'text',
  backendKey: 'website',
  validate: 'isUrl',
}
export const subscription = {
  key: SUBSCRIPTION,
  label: 'Subscription',
  icon: undefined,
}
export const isSeller = {
  key: IS_SELLER,
  label: 'Is seller',
  icon: undefined,
}
export const linkedin = {
  key: LINKEDIN,
  label: 'Linkedin',
  color: '#327CC0',
  icon: require(`assets/icons/socialMedia/linkedin.svg`),
  fortawesomeIcon: faLinkedin,
  type: 'text',
  backendKey: 'linkedin',
  validate: 'isUrl',
}
export const instagram = {
  key: INSTAGRAM,
  label: 'Instagram',
  icon: require(`assets/icons/socialMedia/instagram.svg`),
  type: 'text',
  backendKey: 'instagram',
  validate: 'isUrl',
}
export const facebook = {
  key: FACEBOOK,
  label: 'Facebook',
  icon: require(`assets/icons/socialMedia/facebook.svg`),
  type: 'text',
  backendKey: 'facebook',
  validate: 'isUrl',
}
export const twitter = {
  key: TWITTER,
  label: 'Twitter',
  icon: require(`assets/icons/socialMedia/twitter.svg`),
  type: 'text',
  backendKey: 'twitter',
  validate: 'isUrl',
}
export const youtube = {
  key: YOUTUBE,
  label: 'Youtube',
  icon: require(`assets/icons/socialMedia/youtube.svg`),
  type: 'text',
  backendKey: 'youtube',
  validate: 'isUrl',
}
export const legalName = {
  key: LEGAL_NAME,
  label: 'Legal name',
  type: 'text',
  backendKey: 'legal_name',
  icon: faSignature,
}
export const foundationYear = {
  key: FOUNDATION_YEAR,
  label: 'Foundation year',
  icon: faCalendar,
}
export const fiscalNumber = {
  key: FISCAL_NUMBER,
  label: 'Fiscal number',
  icon: faGlobeEurope,
}
export const brochure = {
  key: BROCHURE,
  label: 'Brochure',
  icon: faDownload,
  type: 'files',
  multiple: false,
  accept: 'image/*,.pdf',
  backendKey: 'brochure',
  toServerKeyExtractor: 'id',
}
export const hideIdentity = {
  key: HIDE_IDENTITY,
  label: 'Hide Identity',
  type: 'checkbox',
  // type: 'boolean',
  defaultValue: true,
  backendKey: 'hide_identity',
}
export const publish = {
  key: PUBLISH,
  label: 'publish',
  type: 'checkbox',
  defaultValue: false,
  backendKey: 'publish',
}
export const currentJob = {
  key: CURRENT_JOB,
  label: 'Current job',
  type: 'checkbox',
  defaultValue: false,
  backendKey: 'current_job',
}
export const mainCareerAchievement = {
  key: MAIN_CAREER_ACHIEVEMENT,
  label: 'Main Career Achievements',
  helper:
    'Please write up to 3 of your main career achievements that can be of relevance for a company searching for an advisor',
  type: 'text',
  multiline: true,
  backendKey: 'main_career_achievement',
}
export const geography = {
  key: GEOGRAPHY,
  label: 'Past Countries Experience',
  icon: require(`assets/icons/IconGeography.svg`),
  type: 'select',
  optionType: 'countries',
  multiple: true,
  backendKey: 'geography',
  toServerKeyExtractor: 'id',
}
export const fieldOfStudy = {
  key: FIELD_OF_STUDY,
  label: 'Field of study',
  type: 'select',
  options: FIELDS_OF_STUDY,
  getText: (text) => getTextByKey(text, FIELDS_OF_STUDY),
  multiple: false,
  backendKey: 'field_of_study',
  toServerKeyExtractor: 'key',
}
export const typeOfDegree = {
  key: TYPE_OF_DEGREE,
  label: 'Type of degree',
  type: 'select',
  options: TYPES_OF_DEGREE,
  getText: (text) => getTextByKey(text, TYPES_OF_DEGREE),
  multiple: false,
  backendKey: 'type_of_degree',
  toServerKeyExtractor: 'key',
}
export const currentCountries = {
  ...operationCountries,
  label: 'Current Countries',
  backendKey: 'user_country_of_operations',
}
export const businessAreas = {
  key: BUSINESS_AREAS,
  label: 'Business Areas',
  type: 'select',
  options: BUSINESS_AREAS_OPTIONS,
  getText: (text) => getTextByKey(text, BUSINESS_AREAS_OPTIONS, true),
  multiple: true,
  icon: faIndustryAlt,
  backendKey: 'business_areas',
  toServerKeyExtractor: 'key',
}
export const experiencePreviousFunctions = {
  key: EXPERIENCE_PREVIOUS_FUNCIONS,
  label: 'Experience Previous Functions',
  type: 'select',
  options: EXPERIENCE_PREVIOUS_FUNCIONS_OPTIONS,
  getText: (text) => getTextByKey(text, EXPERIENCE_PREVIOUS_FUNCIONS_OPTIONS, true),
  multiple: true,
  icon: faBriefcase,
  backendKey: 'experience_previous_functions',
  toServerKeyExtractor: 'key',
}

export const financialReportFields = [
  {
    key: DELIQUENCY_RATING,
    label: 'Delincuency rating',
    icon: faMask,
    tooltip: `
      <p>This is a system that uses statistical probabilities to classify entities that have the obligation to deposit their accounts with five risk of delinquency notations.</p>
      <p>The Informa D&B Delinquency Scoring system allows to determine the probability of a company to register payment delays in excess of 90 days to at least one of its creditors.</p>
      <ul>
      <li>1 - Low Risk</li>
      <li>2 - Moderate Low Risk</li>
      <li>3 - Moderate Risk</li>
      <li>4 - Moderate High Risk</li>
      <li>5 - High Risk</li>
      </ul>
    `,
  },
  {
    key: FAILURE_RATING,
    label: 'Failure score',
    icon: faHeartBroken,
    tooltip: `
      <p>Is a system that uses statistical probabilities to classify companies and entrepreneurs under four risk failure notations.</p>
      <p>The Informa D&B Failure Scoring allows to determine the risk of an entity to ceasse its activity with debts in the following 12 months.</p>
      <ul>
      <li>1 - Minimal Risk</li>
      <li>2 - Low Risk</li>
      <li>3 - Moderate Risk</li>
      <li>4 - High Risk</li>
      </ul>
    `,
  },
  {
    key: TAX,
    label: 'Tax',
    icon: faTag,
    tooltip: `
      <p>Tax and social security.</p>
      <ul>
      <li>OK.</li>
      <li>NOK.</li>
      <li>No information available</li>
      </ul>
    `,
  },
  {
    key: SOCIAL_SECURITY,
    label: 'Social security',
    icon: faPrayingHands,
    tooltip: `
      <p>Tax and social security.</p>
      <ul>
      <li>OK.</li>
      <li>NOK.</li>
      <li>No information available</li>
      </ul>
    `,
  },
]

export const INFO_FIELDS = [
  name,
  description,
  operationCountries,
  industrySectors,
  size,
  revenue,
  leversOfGrowth,
  image,
]

export const HEADER_COMPANY_INFO_FIELDS = [operationCountries, industrySectors, size, revenue]
export const HEADER_ADVISOR_INFO_FIELDS = [currentCountries, experience, jobFunction, languages]

export const SIDEBAR_COMPANY_INFO_FIELDS = [operationCountries, size, revenue, leversOfGrowth]
export const SIDEBAR_ADVISOR_INFO_FIELDS = [...HEADER_ADVISOR_INFO_FIELDS, geography] // [operationCountries, experience, jobFunction, languages]

export const CARD_INFO_FIELDS = [operationCountries, size, revenue]
export const CARD_ADVISOR_INFO_FIELDS = [operationCountries]
export const PROFILE_FIELDS = [name, description, experienceStartDate, hideIdentity]

export const ADVISOR_PROFILE_EXTRA_FIELDS = [mainCareerAchievement, geography]
export const ADVISOR_PROFILE_FIELDS = [
  { ...image, grid: 12, key: AVATAR, backendKey: AVATAR, label: 'Upload Image' },
  hideIdentity,
  { ...name, label: 'Name and Surname' },
  { ...description, label: 'Headline (max 250 carac.)', backendKey: 'bio' },
  { ...description, key: ABOUT, label: 'Bio', backendKey: 'about_company' },
  experienceStartDate,
]

export const COMPANY_CARD_FIELDS = [operationCountries, size, revenue, industrySectors]
export const ADVISOR_CARD_FIELDS = [
  { ...experience, backendKey: 'experience_start_date' },
  jobFunction,
  currentCountries,
  languages,
  geography,
]

export const EXPERTISE_FIELDS = [
  { ...size, backendKey: 'no_of_emplyees' },
  { ...revenue, backendKey: 'last_year_turnover' },
  businessAreas,
  experiencePreviousFunctions,
]

export const ADDRESS_FIELDS = [
  { ...address, grid: 12 },
  { ...postalCode, grid: 3 },
  { ...city, grid: 3 },
  { ...country, grid: 3 },
  { ...state, grid: 3 },
  // city, country, state
]
export const CONTACT_FIELDS = [email, phone, website]
export const SOCIAL_MEDIA_FIELDS = [linkedin, instagram, facebook, twitter, youtube]

export const LEGAL_INFO_FIELDS = [legalName, foundationYear, fiscalNumber]

export const REGISTRATION_FIELDS = [email, password]
export const ADVISORS_REGISTRATION_FIELDS = [
  ...ADVISOR_PROFILE_FIELDS,
  jobFunction,
  currentCountries,
  languages,
  { ...phone, backendKey: 'phone_number' },
  website,
  { ...leversOfGrowth, backendKey: 'user_services' },
  userIndustrySectors,
  // { ...industrySectors, backendKey: 'company_sector', multiple: false },
  ...EXPERTISE_FIELDS,
  //     "expertises": [1,4,11],
  ...ADDRESS_FIELDS,
  ...SOCIAL_MEDIA_FIELDS,
]
export const EDITABLE_ADVISOR_FIELDS = [...ADVISORS_REGISTRATION_FIELDS, ...SOCIAL_MEDIA_FIELDS]
export const ADVISORS_FIELDS = [
  ...REGISTRATION_FIELDS,
  ...ADVISORS_REGISTRATION_FIELDS,
  ...SOCIAL_MEDIA_FIELDS,
]

export const CREDENTIALS_FIELDS = [
  { ...name, key: 'title', backendKey: 'title' },
  description,
  { ...image, backendKey: AVATAR, accept: 'image/*,.pdf' },
  { ...publish, backendKey: 'user_status' },
]
export const EDUCATION_FIELDS = [
  { ...image, accept: 'image/*,.pdf' },
  typeOfDegree,
  { ...name, key: 'degree', backendKey: 'degree', label: 'Degree' },
  { ...name, key: 'academy', backendKey: 'academy', label: 'Institution' },
  fieldOfStudy,
  description,
  { ...date, key: 'startDate', backendKey: 'init_date', label: 'Init date' },
  { ...date, key: 'endDate', backendKey: 'end_date', label: 'End date' },
]
export const EXPERTISES_FIELDS = [
  { ...name, key: 'company_name', backendKey: 'company_name', label: 'Company name' },
  { ...jobFunction, key: 'position', backendKey: 'position' },
  { ...description, key: 'highlights', backendKey: 'highlights', label: 'Highlights' },
  currentJob,
  { ...date, key: 'startDate', backendKey: 'init_date', label: 'Init date' },
  { ...date, key: 'endDate', backendKey: 'end_date', label: 'End date', disableIf: 'currentJob' },
]

export const ALL_COMPANY_FIELDS = {
  id,
  legalName,
  foundationYear,
  fiscalNumber,
  contact: CONTACT_FIELDS,
  socialMedia: SOCIAL_MEDIA_FIELDS,
  legalInfo: LEGAL_INFO_FIELDS,
}

const defaultCredentialItem = {
  documentfile: null,
  title: '',
  description: '',
  user_status: 'unpublish',
  admin_status: 'approved',
}
const defaultEducationItem = {
  degree: '',
  academy: '',
  description: '',
  startDate: null,
  endDate: null,
}
const defaultExpertiseItem = {
  company_name: '',
  position: '',
  highlights: '',
  startDate: null,
  endDate: null,
}

export const credentialsSections = {
  [CERTIFICATES]: {
    title: 'Certificates',
    list: '/api/listCertificates',
    create: '/api/addCertificate',
    edit: '/api/editCertificate',
    delete: '/api/deleteCertificate',
    fields: CREDENTIALS_FIELDS,
    defaultItem: defaultCredentialItem,
  },
  [AWARDS]: {
    title: 'Awards',
    list: '/api/listAwards',
    create: '/api/addAward',
    edit: '/api/editAward',
    delete: '/api/deleteAward',
    fields: CREDENTIALS_FIELDS,
    defaultItem: defaultCredentialItem,
  },
  [EDUCATION]: {
    title: 'Education',
    list: '/api/advisors/educations',
    create: '/api/advisors/educations',
    edit: '/api/advisors/educations',
    delete: '/api/advisors/educations',
    fields: EDUCATION_FIELDS,
    defaultItem: defaultEducationItem,
    requiredFields: ['academy', 'degree'],
    dataNormalizer: education.list.dataNormalizer,
  },
  [EXPERTISES]: {
    title: 'Experiences',
    list: '/api/advisors/expertises',
    create: '/api/advisors/expertises',
    edit: '/api/advisors/expertises',
    delete: '/api/advisors/expertises',
    fields: EXPERTISES_FIELDS,
    requiredFields: ['company_name', 'position', 'startDate'],
    defaultItem: defaultExpertiseItem,
    dataNormalizer: expertises.list.dataNormalizer,
  },
}
