import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorComponent } from 'react-material-ui-form-validator'

class MuiPickersUtilsProviderValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      withRequiredValidator,
      containerProps,
      ...rest
    } = this.props
    const { isValid } = this.state
    const disableAreaCodes = true
    const disableDropdown = false
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...rest}
          error={!isValid || error}
          disableAreaCodes={disableAreaCodes}
          disableDropdown={disableDropdown}
          helperText={(!isValid && this.getErrorMessage()) || helperText}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

export default MuiPickersUtilsProviderValidator
