import React from 'react'
import { Stack, Container, Box } from '@mui/material'
import { Feed } from 'components'
import FeedSidebar from './Sidebar/FeedSidebar'
import RGPDBox from './RGPDBox'
import { withTranslation } from 'react-i18next'
import { QueryFiltersContextProvider } from 'context/queryFiltersContext'
import { FEED_SECTION_TABS_ARRAY } from 'entities/feed'
import { colorsByKey } from 'theme/colors'

const FeedScreen = (props) => {
  return (
    <>
      <Container
        sx={{ pt: 3, background: colorsByKey.defaultBgWeaker }}
        maxWidth={false}
        disableGutters
      >
        <QueryFiltersContextProvider tabs={FEED_SECTION_TABS_ARRAY}>
          <Container maxWidth={'xl'}>
            <Stack spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
              <FeedSidebar props={props} />
              <Box sx={{ width: 'calc(100% - 372px)' }}>
                <Feed userDetail={props.userDetail} tabs={FEED_SECTION_TABS_ARRAY} />
              </Box>
            </Stack>
          </Container>
        </QueryFiltersContextProvider>
      </Container>
      <RGPDBox {...props} />
    </>
  )
}

export default withTranslation()(FeedScreen)
