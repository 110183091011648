import React from 'react'
import axios from 'axios'
import history from '../../history'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import { Helmet } from 'react-helmet'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Row, Col } from 'react-bootstrap'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import queryString from 'query-string'
import { Snackbar } from '@mui/material'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        email: '',
        password: '',
      },
      emailErr: '',
      passwordErr: '',
      formErrors: {},
      submitted: false,
      error: null,
      openSnackbar: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFormValidation = this.handleFormValidation.bind(this)

    if (localStorage.getItem('accessToken') !== null) {
      history.push('/feed')
    }
  }

  handleFormValidation = () => {
    const email = this.state.formData.email
    const password = this.state.formData.password
    let formErrors = this.state.formErrors
    let formIsValid = true

    if (!email) {
      formIsValid = false
      formErrors['emailErr'] = 'This field is required'
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
      formIsValid = false
      formErrors['emailErr'] = 'email is not valid'
    }

    if (!password) {
      formIsValid = false
      formErrors['passwordErr'] = 'This field is required'
    }
    this.setState({ [this.state.formErrors]: formErrors })
    return formIsValid
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handleSubmit = () => {
    if (this.handleFormValidation()) {
      this.setState({ submitted: true, error: null }, () => {
        const sector_api_url = process.env.REACT_APP_API_URL + '/api/login'
        axios
          .post(sector_api_url, this.state.formData)
          .then((response) => {
            const { data } = response
            this.setState({ submitted: false })
            localStorage.setItem('accessToken', data.success.token)
            history.push('/feed')
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                const { data } = error.response
                if (data.error) {
                  this.setState({
                    submitted: false,
                    error: this.props.t('Invalid username and password'),
                    openSnackbar: true,
                  })
                  setTimeout(() => {
                    this.setState(() => ({
                      openSnackbar: false,
                    }))
                  }, 6000)
                  // this.refs.error_notify.handleOpen()
                }
              } else {
                this.setState({
                  submitted: false,
                  error: this.props.t('Something went wrong, please try again'),
                  openSnackbar: true,
                })
                setTimeout(() => {
                  this.setState(() => ({
                    openSnackbar: false,
                  }))
                }, 6000)
                // this.refs.error_notify.handleOpen()
              }
            }
          })
      })
    }
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state
    const { emailErr, passwordErr } = this.state.formErrors
    let query = queryString.parse(window.location.search)
    let signup = '/createaccount'
    if (query.ref) {
      signup = signup + '/?ref=' + query.ref
    }

    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div className="w-100">
        <Helmet>
          <title>Login | BridgeWhat: Let's Grow!</title>
          <body className="header_s" />
        </Helmet>

        <div>
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <Row>
              <Col md="12">
                <div className="form-group">
                  <label>{t('Email Address')}</label>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    name="email"
                    value={formData.email}
                    className={emailErr ? 'showError' : ''}
                  />
                  {emailErr && <div style={{ color: 'red', paddingBottom: 5 }}>{emailErr}</div>}
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label>{t('Password')}</label>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    className={passwordErr ? 'showError' : ''}
                  />
                  {passwordErr && (
                    <div style={{ color: 'red', paddingBottom: 5 }}>{passwordErr}</div>
                  )}
                </div>
              </Col>
              <Col md="12" className="text-right forgot-link">
                <Grid item xs>
                  <Link to="/forgot-password">
                    <u>{t('Forgot password')}?</u>
                  </Link>
                </Grid>
              </Col>
              <Col md="12" className="mt-3 remember-me">
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={t('Remember me')}
                />
              </Col>
              <Button variant="contained" type="submit" className="submit-btn" disabled={submitted}>
                {(submitted && t('Your form is submitted!')) || (!submitted && t('Sign in'))}
              </Button>
              <Col md="12" className="forgot-link text-center">
                <Grid item>
                  <Link to={signup}>
                    {t("Don't have an account?")} <u>{t('Sign Up')}</u>
                  </Link>
                </Grid>
              </Col>
            </Row>
          </ValidatorForm>
        </div>
        <Snackbar
          open={this.state.openSnackbar}
          message={this.state.error}
          className={['error-snackbar']}
        />
      </div>
    )
  }
}

export default withTranslation()(Login)
