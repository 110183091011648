import { useState } from 'react'
import { apiGet, apiPost } from '../api'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { PROTOCOLS, ALL } from 'entities/feed'
const PER_PAGE_DEFAULT = 10

const useList = ({ url, method = 'GET', filters, defaultFilterParams, currentPage = 1, type }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const [meta, setMeta] = useState({
    currentPage,
    lastPage: 1,
    total: 0,
  })

  const setListData = (oldData, newData, currentPage, response = {}) => {
    // const responseCurrentPage = response.current_page || (response.meta || {}).current_page || 1
    const responseCurrentPage = currentPage
    setIsLoaded(true)
    if (responseCurrentPage === 1) setData(newData)
    else setData([...oldData, ...newData])
  }

  useDeepCompareEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (currentPage === 1) setIsLoaded(false)
      setError(null)

      const filterParams = { ...defaultFilterParams, ...filters }
      const { page, ...restFilterParams } = filterParams

      const normalizedFilterParams =
        type === PROTOCOLS || type === ALL
          ? { filters: restFilterParams, page: currentPage, perPage: PER_PAGE_DEFAULT }
          : { ...restFilterParams, page: currentPage, perPage: PER_PAGE_DEFAULT }

      try {
        const response =
          method === 'GET'
            ? await apiGet(url, normalizedFilterParams)
            : await apiPost(url, defaultFilterParams)

        // FIX: protocols fails if gets a param "page"
        // : await apiPost(url, protocolsFilterParams); // FIX: protocols fails if gets a param "page"

        if (Array.isArray(response.data)) {
          // NEW backend enpoints return this
          const typedData = response.data.map((i) => ({ ...i, type }))
          setListData(data, typedData, currentPage, response)
        } else {
          // OLD backend enpoints return this
          const typedData = response.data.data.map((i) => ({ ...i, type }))
          setListData(data, typedData, currentPage, response)
        }
        if (response.meta) {
          // NEW backend enpoints return this
          setMeta({
            currentPage: response.meta.current_page,
            lastPage: response.meta.last_page,
            total: response.meta.total,
          })
        } else {
          // OLD backend enpoints return this
          setMeta({
            currentPage: response.data.current_page,
            lastPage: response.data.last_page,
          })
        }
      } catch (err) {
        setError(err?.message ?? 'Some error')
      } finally {
        setIsLoading(false)
        !isLoaded && setIsLoaded(true)
      }
    }

    fetchData()
  }, [url, filters, currentPage])

  const isFirstFetch = isLoading && !isLoaded

  const showMore = meta.lastPage > meta.currentPage

  return { isLoaded, isLoading, error, data, meta, isFirstFetch, showMore }
}

export default useList
