import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@mui/material'
import SubscriptionPackage from '../../../component/registrationform/subscriptionpackage/subscriptionpackage'

const ModalWrapper = ({ open, handleClose, userDetails }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Subscription Packages</DialogTitle>
      <DialogContent>
        <SubscriptionPackage userDetail={userDetails} isUpdateSubscription={open} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalWrapper
