export const OFFERS_ACTION = 'offers'
export const OPPORTUNITIES_ACTION = 'opportunities'
export const CAMPAIGNS_ACTION = 'campaigns'
export const POST_ACTION = 'posts'
export const PROTOCOLS_ACTION = 'protocols'
export const SAMPLING_ACTION = 'samplings'
export const PARTNERSHIPS_ACTION = 'partnerships'
export const ACCELERATOR_ACTION = 'accelerators'
export const NEW_PARTNERSHIPS_ACTION = 'newpartnership'
export const COMPANIES_ACTION = 'companies'
export const IM_LOOKING_FOR_ACTION = 'lookingfor'
