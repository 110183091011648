import React from 'react'
import axios from 'axios'
import { Row, Col, Container } from 'react-bootstrap'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Textarea from '@material-ui/core/TextareaAutosize'

class AskGuestReferral extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        participant: 0,
        request_type: 'ask',
        public_request: 'yes',
        reffer_type: this.props.referpage,
        name: '',
        email: '',
        email_content: '',
      },
      submitted: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData, error: [] })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const api_url = process.env.REACT_APP_API_URL + '/api/addReferralPermit'
      axios
        .post(api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          this.setState({ submitted: false })
          this.props.showSuccessMessage(this.props.t('Request successfully send!'))
        })
        .catch((error) => {
          this.setState({ submitted: false })
          if (error.response.status === 403) {
            const { data } = error.response
            if (data.error) {
              this.props.showErrorMessage(data.message)
            } else {
              this.props.showErrorMessage(this.props.t('Something went wrong, please try again'))
            }
          } else {
            this.props.showErrorMessage(this.props.t('Something went wrong, please try again'))
          }
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state

    var nameLabel = 'Name'
    if (formData.reffer_type === 'testimonial') {
      if (formData.request_type === 'ask') {
        nameLabel = t('Name of the person to ask for a testimonial')
      } else {
        nameLabel = t('Name of the person to give a testimonial')
      }
    } else if (formData.reffer_type === 'recommendation') {
      if (formData.request_type === 'ask') {
        nameLabel = t('Name of the person to ask for a recommendation')
      } else {
        nameLabel = t('Name of the person to give a recommendation')
      }
    }

    return (
      <ValidatorForm ref="form" onSubmit={this.handleSubmit} className="w-100">
        <Container>
          <Row>
            <Col lg="6">
              <div className="form-group">
                <label>
                  {nameLabel} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  value={formData.name}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <label>
                  {t('Email')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="email"
                  onChange={this.handleChange}
                  value={formData.email}
                  validators={['required', 'isEmail']}
                  errorMessages={[t('This field is required'), t('email is not valid')]}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label className="w-100">{t('Your Message')}</label>
                <Textarea
                  fullWidth
                  variant="outlined"
                  rowsMax={1}
                  aria-label="maximum height"
                  placeholder=""
                  name="email_content"
                  onChange={this.handleChange}
                  value={formData.email_content}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="d-flex align-items-center justify-content-center">
              <Button
                variant="contained"
                type="submit"
                className="submit-btn ml-2 mr-2"
                disabled={submitted}
              >
                {(submitted && 'Your form is submitted!') || (!submitted && t('Send'))}
              </Button>
            </Col>
          </Row>
        </Container>
      </ValidatorForm>
    )
  }
}

export default withTranslation()(AskGuestReferral)
