import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormControl, Select, MenuItem, Typography } from '@mui/material'
import enFlag from 'assets/images/en_flag.jpg'
import ptFlag from 'assets/images/pt_flag.jpg'
import esFlag from 'assets/images/es_flag.jpg'
import { colorsByKey } from 'theme/colors'

const languages = {
  en: { key: 'en', image: enFlag, label: 'EN' },
  pt: { key: 'pt', image: ptFlag, label: 'PT' },
  es: { key: 'es', image: esFlag, label: 'ES' },
}

const LanguageSelector = () => {
  const { i18n } = useTranslation()

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value)
    localStorage.setItem('lang', event.target.value)
  }

  let lng = 'en'
  if (localStorage.getItem('lang')) {
    lng = localStorage.getItem('lang')
  }

  return (
    <Box className="LanguageSelector">
      <FormControl
        sx={{ m: 0, p: 0, border: 'none' }}
        margin="none"
        size="small"
        variant="standard"
      >
        <Select
          value={lng}
          IconComponent=""
          autoWidth
          displayEmpty
          sx={{
            m: 0,
            p: 0,
            '&:before': { border: 'none !important', '&:hover': { border: 'none !important' } },
            '&::after': { border: 'none', '&:hover': { border: 'none !important' } },
            '& .MuiSelect-select': { padding: '0px !important' },
          }}
          onChange={changeLanguage}
          renderValue={() => (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={languages[lng].image}
                alt={`flag-${languages[lng].label}`}
                style={{ width: '20px' }}
              />
              <Typography variant="f12" color={colorsByKey.defaultText} sx={{ ml: 1 }}>
                {languages[lng].label}
              </Typography>
            </Box>
          )}
        >
          {Object.values(languages).map(({ key, image, label }, idx) => {
            return (
              <MenuItem value={key} key={`select-option-${key}-${label}-${idx}`}>
                <img src={image} alt={`flag-${label}`} style={{ width: '20px' }} />
                <Typography variant="f12" color={colorsByKey.defaultText} sx={{ ml: 1 }}>
                  {label}
                </Typography>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default LanguageSelector
