import styled from '@emotion/styled'
import {
  Autocomplete,
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button as MButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import FileUpload from 'component/form/file-upload'
import { addDays, format, subDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import axiosInstance from 'api/axios'
import history from '../../history'
import { colorsByKey } from 'theme/colors'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { IconData } from 'components'

function CreateParnership() {
  const partnershipSchema = yup.object({
    // bridgewhatGuarantee: yup.boolean(),
    title: yup.string().required('Required'),
    partnerSector: yup.array().of(yup.object()).required('Required'),
    partnerSegment: yup.string(),
    partnerEmployees: yup.array().of(yup.string()).required('Required'),
    partnerRevenue: yup.array().of(yup.string()).required('Required'),
    // Company/Partner that exists in Bridgewhat
    partnerBridgewhat: yup.array().of(yup.object()).nullable(),
    // Company/Partner that doesn't exists in Bridgewhat
    partnerOutside: yup.string(),
    partnershipPurpose: yup.string().required('Required'),
    partnershipGoal: yup.string().required('Required'),
    assetPartnership: yup
      .array()
      .min(1, 'Must select at least one')
      .of(yup.string().required())
      .required(),
    assetPartner: yup
      .array()
      .min(1, 'Must select at least one')
      .of(yup.string().required())
      .required(),
    whyPartner: yup.string(),
    whyPartnerContext: yup.string().required('Required'),
    decisionMaker: yup.string().required('Required'),
    influencer: yup.string().required('Required'),
    publicationDate: yup.date().min(subDays(new Date(), 1), 'Must be today or later').required(),
    closingDate: yup.date().required().min(subDays(new Date(), 1), 'Must be today or later'),
  })

  const [partnershipForm, setPartnershipForm] = useState({
    // bridgewhatGuarantee: false,
    title: '',
    partnerSector: [],
    partnerSegment: '',
    partnerEmployees: [],
    partnerRevenue: [],
    partnerBridgewhat: [],
    partnerOutside: '',
    partnershipPurpose: '',
    partnershipGoal: '',
    assetPartnership: [],
    assetPartner: [],
    decisionMaker: '',
    influencer: '',
    whyPartner: '',
    whyPartnerContext: '',
    publicationDate: new Date(),
    closingDate: addDays(new Date(), 1),
    image: null,
    brochure: null,
  })

  const [formErrors, setFormErrors] = useState({})
  const [companySectors, setCompanySectors] = useState([])
  const [formFields, setFormFields] = useState({})
  const [endDialog, setEndDialog] = useState(false)

  useEffect(() => {
    axiosInstance
      .get('/api/companysector', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setCompanySectors(data.data)
      })

    axiosInstance
      .get('/api/partnershipsFormParameters', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setFormFields(data.success)
      })
  }, [])

  const handleFormInput = (key, value) => setPartnershipForm((prev) => ({ ...prev, [key]: value }))

  const handleAssetCheckboxes = (mainKey, value, checked) => {
    const aux = [...partnershipForm[mainKey]]
    if (checked) {
      aux.push(value)
    } else {
      const index = aux.indexOf(value)
      aux.splice(index, 1)
    }
    setPartnershipForm((prev) => ({ ...prev, [mainKey]: aux }))
  }

  const handleCreatePartnership = async () => {
    try {
      await partnershipSchema.validate(partnershipForm, { abortEarly: false })
      create()
    } catch (err) {
      organizeErrors(err)
    }
  }

  const create = () => {
    const formData = new FormData()
    formData.append('title', partnershipForm.title)

    // formData.append('use_guarantee', partnershipForm.bridgewhatGuarantee)
    formData.append('partnership_purpose', partnershipForm.partnershipPurpose)
    formData.append('partnership_objectives', partnershipForm.partnershipGoal)
    formData.append('partnership_reason_context', partnershipForm.whyPartnerContext)
    formData.append('partnership_reason', partnershipForm.whyPartner)
    formData.append('partner_revenue', partnershipForm.partnerRevenue.join(';'))
    formData.append('partner_employees', partnershipForm.partnerEmployees.join(';'))
    formData.append('decision_maker', partnershipForm.decisionMaker)
    formData.append('influencer', partnershipForm.influencer)
    formData.append('partner_industry_segments', partnershipForm.partnerSegment)
    formData.append('image', partnershipForm.image)
    formData.append('brochure', partnershipForm.brochure)

    if (partnershipForm.partnerBridgewhat?.length > 0)
      formData.append(
        'bridgewhat_partner_examples',
        partnershipForm.partnerBridgewhat.map((pb) => pb.name).join(';')
      )

    formData.append('outside_partner', partnershipForm.partnerOutside)
    formData.append('publication_date', partnershipForm.publicationDate)
    formData.append('closing_date', partnershipForm.closingDate)

    for (let i = 0; i < partnershipForm.partnerSector.length; i++) {
      formData.append('partner_industry_sectors[]', partnershipForm.partnerSector[i].id)
    }

    for (let i = 0; i < partnershipForm.assetPartnership.length; i++) {
      formData.append('proponent_assets[]', partnershipForm.assetPartnership[i])
    }
    for (let i = 0; i < partnershipForm.assetPartner.length; i++) {
      formData.append('partner_assets[]', partnershipForm.assetPartner[i])
    }

    axiosInstance
      .post('/api/partnerships/create', formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(() => {
        setEndDialog(true)
      })
  }

  const organizeErrors = (errors) => {
    const newErrors = {}
    errors.inner.forEach((error) => {
      newErrors[error.path] = error.message
    })
    setFormErrors(newErrors)
  }

  const handleCancel = () => {
    history.push('/')
  }

  return (
    <>
      <Wrapper maxWidth={'xl'} sx={{ my: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
          <Typography variant="f20" sx={{ display: 'inline-block' }}>
            Create new
            <Typography
              variant="f20"
              component="span"
              color={colorsByKey.accentText}
              sx={{ ml: 1, display: 'inline-block' }}
            >
              Partnership
            </Typography>
          </Typography>
          <Box>
            <MButton onClick={handleCancel}>
              <Typography noWrap>Cancel</Typography>
            </MButton>
            <MButton
              onClick={handleCreatePartnership}
              variant="contained"
              sx={{
                ml: 2,
                borderRadius: '48px !important',
                backgroundColor: colorsByKey.accentBg,
              }}
            >
              <IconData
                title="Submit for approval"
                icon={faSave}
                titleVariant="f14"
                margin={1}
                color={colorsByKey.utility}
                sx={{ padding: '6px 8px' }}
              />
            </MButton>
          </Box>
        </Box>
        {/* <GuaranteedPartnership>
        <p>Total number of guaranteed Partnerships *during* the current subscription period:</p>
        <div>
          <p>
            Total: <em>2</em>
          </p>
          <p>
            Used: <em>{formFields?.Partnerships_used_with_guarantee}</em>
          </p>
        </div>
      </GuaranteedPartnership> */}

        <Form>
          <Stack useFlexGap spacing={2}>
            <Stack direction="row" spacing={2} justifyContent="flex-start">
              {/* <TextField
              value={format(new Date(), 'yyyy/MM/dd')}
              fullWidth
              label="Available on"
              type="text"
              variant="outlined"
              disabled
            /> */}
              <TextField
                // fullWidth
                label="Deadline for submission approval"
                variant="outlined"
                value={
                  formFields.submission_aproval_dealine_in_date
                    ? format(new Date(formFields.submission_aproval_dealine_in_date), 'yyyy/MM/dd')
                    : '...'
                }
                disabled
              />
            </Stack>
            {/* <FormControlLabel
            control={
              <Checkbox
                checked={partnershipForm.bridgewhatGuarantee}
                onChange={(e) => handleFormInput('bridgewhatGuarantee', e.target.checked)}
              />
            }
            label="Use an available Bridgewhat guarantee for this Partnership?"
          /> */}
            <TextField
              label="Title"
              value={partnershipForm.title}
              onChange={(e) => handleFormInput('title', e.target.value)}
              required
              error={!!formErrors.title}
              helperText={formErrors.title}
              minRows={1}
            />
            <Stack direction="row" spacing={2}>
              <Autocomplete
                multiple
                fullWidth
                disabled={companySectors.length === 0}
                options={companySectors}
                getOptionLabel={(option) => option.name}
                value={partnershipForm.partnerSector}
                onChange={(event, newValue) => {
                  handleFormInput('partnerSector', newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    label="Target partner industry sectors"
                    error={!!formErrors.partnerSector}
                    helperText={formErrors.partnerSector}
                  />
                )}
              />
              <TextField
                fullWidth
                label="Target partner industry segments"
                value={partnershipForm.partnerSegment}
                onChange={(e) => handleFormInput('partnerSegment', e.target.value)}
                error={!!formErrors.partnerSegment}
                helperText={formErrors.partnerSegment}
                multiline
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel required>Target partner Company employees</InputLabel>
                <Select
                  label="Target partner Company employees"
                  value={partnershipForm.partnerEmployees}
                  onChange={(e) => handleFormInput('partnerEmployees', e.target.value)}
                  required
                  multiple
                >
                  <MenuItem value="1 - 10">1 - 10</MenuItem>
                  <MenuItem value="11 - 50">11 - 50</MenuItem>
                  <MenuItem value="51 - 200">51 - 200</MenuItem>
                  <MenuItem value="+200">+200</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel required>Target partner Company Revenue (M)</InputLabel>
                <Select
                  fullWidth
                  multiple
                  label="Target partner Company Revenue (M)"
                  value={partnershipForm.partnerRevenue}
                  onChange={(e) => handleFormInput('partnerRevenue', e.target.value)}
                  required
                >
                  <MenuItem value="0 - 2M">0 - 2M €</MenuItem>
                  <MenuItem value="2 - 10M">2 - 10M €</MenuItem>
                  <MenuItem value="10 - 50M">10 - 50M €</MenuItem>
                  <MenuItem value="&gt; 50M">&gt; 50M €</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <FormSectionText>Target partner company(ies) examples:*</FormSectionText>
            <Stack direction="row" spacing={2}>
              <Autocomplete
                fullWidth
                multiple
                disabled={formFields?.companies_list?.length === 0}
                options={formFields?.companies_list || []}
                getOptionLabel={(option) => option?.company_name}
                value={partnershipForm.partnerBridgewhat}
                onChange={(event, newValue) => {
                  handleFormInput('partnerBridgewhat', newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    label="Bridgewhat Participant"
                    error={!!formErrors.partnerBridgewhat}
                    helperText={formErrors.partnerBridgewhat}
                  />
                )}
              />
              <TextField
                fullWidth
                label="Other participants"
                value={partnershipForm.partnerOutside}
                onChange={(e) => handleFormInput('partnerOutside', e.target.value)}
                error={!!formErrors.partnerOutside}
                helperText={formErrors.partnerOutside}
              />
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <FormSection>About the Partnership</FormSection>
            <TextField
              fullWidth
              label="Reasons for partnering (why)"
              required
              value={partnershipForm.partnershipPurpose}
              onChange={(e) => handleFormInput('partnershipPurpose', e.target.value)}
              error={!!formErrors.partnershipPurpose}
              helperText={formErrors.partnershipPurpose}
              multiline
            />
            <TextField
              fullWidth
              label="Partnership objectives (what)"
              required
              value={partnershipForm.partnershipGoal}
              onChange={(e) => handleFormInput('partnershipGoal', e.target.value)}
              error={!!formErrors.partnershipGoal}
              helperText={formErrors.partnershipGoal}
              multiline
            />
          </Stack>
          <Stack spacing={2}>
            <FormSection>Assets</FormSection>
            <Stack direction="row" spacing={2} alignItems="center">
              <Stack>
                <CheckboxsLabel>Assets brought by the partnership proponent:*</CheckboxsLabel>
                {!!formErrors.assetPartnership && (
                  <FormHelperText error>{formErrors.assetPartnership}</FormHelperText>
                )}
              </Stack>
              <Stack direction="row" spacing={4}>
                <FormGroup sx={{ minWidth: 190 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partnershipForm.assetPartnership.includes('Product or service')}
                        value="Product or service"
                        onChange={(e) =>
                          handleAssetCheckboxes(
                            'assetPartnership',
                            'Product or service',
                            e.target.checked
                          )
                        }
                      />
                    }
                    sx={{ color: '#132332' }}
                    label="Product or service"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partnershipForm.assetPartnership.includes('Channel')}
                        value="Channel"
                        onChange={(e) =>
                          handleAssetCheckboxes('assetPartnership', 'Channel', e.target.checked)
                        }
                      />
                    }
                    sx={{ color: '#132332' }}
                    label="Channel"
                  />
                </FormGroup>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Stack>
                <CheckboxsLabel>Assets brought by the partner:*</CheckboxsLabel>
                {!!formErrors.assetPartner && (
                  <FormHelperText error>{formErrors.assetPartner}</FormHelperText>
                )}
              </Stack>
              <Stack direction="row" spacing={4}>
                <FormGroup sx={{ minWidth: 190 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partnershipForm.assetPartner.includes('Product or service')}
                        value="Product or service"
                        onChange={(e) =>
                          handleAssetCheckboxes(
                            'assetPartner',
                            'Product or service',
                            e.target.checked
                          )
                        }
                      />
                    }
                    sx={{ color: '#132332' }}
                    label="Product or service"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partnershipForm.assetPartner.includes('Channel')}
                        value="Channel"
                        onChange={(e) =>
                          handleAssetCheckboxes('assetPartner', 'Channel', e.target.checked)
                        }
                      />
                    }
                    sx={{ color: '#132332' }}
                    label="Channel"
                  />
                </FormGroup>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <FormSection>Avatar partner | Company</FormSection>
            <TextField
              fullWidth
              label="Why should a company want a partner?"
              value={partnershipForm.whyPartner}
              onChange={(e) => handleFormInput('whyPartner', e.target.value)}
              error={!!formErrors.whyPartner}
              helperText={formErrors.whyPartner}
              multiline
            />
            <TextField
              fullWidth
              label="Why should a company want to partner in the current context?"
              required
              value={partnershipForm.whyPartnerContext}
              onChange={(e) => handleFormInput('whyPartnerContext', e.target.value)}
              error={!!formErrors.whyPartnerContext}
              helperText={formErrors.whyPartnerContext}
              multiline
            />
          </Stack>
          <Stack spacing={2}>
            <FormSection>Avatar partner | People</FormSection>
            <TextField
              fullWidth
              label="Decision Maker"
              required
              value={partnershipForm.decisionMaker}
              onChange={(e) => handleFormInput('decisionMaker', e.target.value)}
              error={!!formErrors.decisionMaker}
              helperText={formErrors.decisionMaker}
            />
            <TextField
              fullWidth
              label="Influencer"
              required
              value={partnershipForm.influencer}
              onChange={(e) => handleFormInput('influencer', e.target.value)}
              error={!!formErrors.influencer}
              helperText={formErrors.influencer}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label="Publication date"
              type="date"
              variant="outlined"
              value={partnershipForm.publicationDate}
              onChange={(e) => handleFormInput('publicationDate', e.target.value)}
              error={!!formErrors.publicationDate}
              helperText={formErrors.publicationDate}
              InputProps={{
                inputProps: {
                  min: format(new Date(), 'yyyy-MM-dd'),
                },
              }}
            />
            <TextField
              fullWidth
              label="Closing date"
              type="date"
              variant="outlined"
              value={partnershipForm.closingDate}
              onChange={(e) => handleFormInput('closingDate', e.target.value)}
              error={!!formErrors.closingDate}
              helperText={formErrors.closingDate}
              InputProps={{
                inputProps: {
                  min: format(new Date(), 'yyyy-MM-dd'),
                },
              }}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <Stack direction="row" spacing={4}>
              <FileUpload
                label="Upload Logo"
                buttonText="Choose an Image"
                fileExtensions=".jpg, .png"
                required
                file={partnershipForm.image}
                onChange={(f) => handleFormInput('image', f)}
              />
              <FileUpload
                label="Upload Brochure"
                buttonText="Choose an File"
                required
                fileExtensions=".pdf, .docx, .pptx"
                file={partnershipForm.brochure}
                onChange={(f) => handleFormInput('brochure', f)}
              />
            </Stack>
          </Stack>
        </Form>
      </Wrapper>

      <Dialog open={endDialog} onClose={handleCancel}>
        <DialogTitle>Great!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This information has been correctly submited. Our team will review it as soon as
            possible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const Wrapper = styled(Container)`
  margin-bottom: 60px;
`

// const GuaranteedPartnership = styled.div`
//   color: #132332;
//   margin-bottom: 54px;

//   & > div {
//     display: flex;
//     gap: 100px;
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 24px;

//     & p {
//       margin: 0;

//       & > em {
//         color: #358feb;
//         font-weight: 400;
//         font-style: normal;
//         margin-left: 8px;
//       }
//     }
//   }
// `

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
`

export const FormSection = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  background: #d3e6fb;

  color: #132332;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const FormSectionText = styled.div`
  color: #132332;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 70px;
`

const CheckboxsLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 340px;

  color: #000;
`

export const CancelButton = styled.button`
  border: 0;
  background: unset;
  color: #315a80;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
export const SubmitButton = styled.button`
  border-radius: 24.58px;
  background: #358feb;
  border: 0;
  padding: 8px 16px;

  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: 200ms;

  &:hover {
    background: #6ca2ef;
  }
`

export default CreateParnership
