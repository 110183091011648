import React from 'react'
import axios from 'axios'
import history from '../../history'
import { Row, Col } from 'react-bootstrap'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withTranslation } from 'react-i18next'
class GiveReferral extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        participant: '',
        request_type: 'give',
        reffer_type: this.props.referpage,
      },
      submitted: false,
    }

    this.selectParticipant = this.selectParticipant.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  selectParticipant = (event, value) => {
    const { formData } = this.state
    if (value) {
      formData['participant'] = value.id
    } else {
      formData['participant'] = ''
    }
    this.setState({ formData })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const api_url = process.env.REACT_APP_API_URL + '/api/addReferralPermit'
      axios
        .post(api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          const { data } = response.data
          history.push('/give/referral/' + data.request_code)
          this.setState({ submitted: false })
        })
        .catch((error) => {
          console.log(error)
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state
    const { participants } = this.props

    return (
      <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
        <Row>
          <Col lg="12">
            <div className="form-group">
              <label>
                {t('Choose to what BW Participant you want to give a Referral')} <sup>*</sup>
              </label>
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                options={participants}
                onChange={this.selectParticipant}
                getOptionLabel={(option) => option.company_name || option.name || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    variant="outlined"
                    value={formData.participant}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="d-flex align-items-center justify-content-center">
            <Button
              variant="contained"
              type="submit"
              className="submit-btn ml-2 mr-2"
              disabled={submitted}
            >
              {(submitted && 'Your form is submitted!') || (!submitted && t('Send'))}
            </Button>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }
}

export default withTranslation()(GiveReferral)
