import React from 'react'
import history from '../history'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import { apiGet } from 'api'
import { LOGOUT, DETAILS } from 'entities/routes'
import SBAregistrationsteponeform from '../component/registrationform/sbaform/sbaregistrationstep1form'
import SBAregistrationsteptwoform from '../component/registrationform/sbaform/sbaregistrationstep2form'
import SBAregistrationstepthreeform from '../component/registrationform/sbaform/sbaregistrationstep3form'
import { normalizeUserData } from 'mixins/dataNormalizers'

class CompleteSbaRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userDetail: null,
      registerStep: 0,
      loading: true,
    }

    this.nextStep = this.nextStep.bind(this)
    this.prevStep = this.prevStep.bind(this)
  }

  prevStep(value) {
    if (this.state.registerStep > 1) {
      this.setState({ registerStep: value })
    }
  }

  async nextStep(value) {
    if (value) {
      await apiGet(DETAILS)
        .then(({ data }) => {
          this.setState({ userDetail: normalizeUserData(data), registerStep: value })
        })
        .catch(() => {
          history.push(LOGOUT)
        })
    }
  }

  componentDidMount() {
    if (this.props.userDetail) {
      this.setState({ registerStep: this.props.userDetail.step, userDetail: this.props.userDetail })
    }
  }

  render() {
    const { registerStep, userDetail } = this.state

    return (
      <div className="registration-min w-100 pb-2 pt-5">
        <Helmet>
          <title>SBA Registration Form | BridgeWhat: Let's Grow!</title>
        </Helmet>
        <div className="formstep col-12  pb-5">
          <ul>
            <li>{registerStep === 1 ? <span className="active-step">1</span> : <span>1</span>}</li>
            <li>{registerStep === 2 ? <span className="active-step">2</span> : <span>2</span>}</li>
            <li>{registerStep === 3 ? <span className="active-step">3</span> : <span>3</span>}</li>
          </ul>
        </div>
        <div className="d-flex">
          <Container>
            {registerStep === 1 && (
              <SBAregistrationsteponeform
                userDetail={userDetail}
                prevStep={(val) => this.prevStep(val)}
                nextStep={(val) => this.nextStep(val)}
              />
            )}
            {registerStep === 2 && (
              <SBAregistrationsteptwoform
                userDetail={userDetail}
                prevStep={(val) => this.prevStep(val)}
                nextStep={(val) => this.nextStep(val)}
              />
            )}
            {registerStep === 3 && (
              <SBAregistrationstepthreeform
                userDetail={userDetail}
                prevStep={(val) => this.prevStep(val)}
                nextStep={(val) => this.nextStep(val)}
              />
            )}
          </Container>
        </div>
      </div>
    )
  }
}
export default CompleteSbaRegister
