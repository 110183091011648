import React from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next'
import { EMAIL, PHONE, WEBSITE } from 'entities/companyFields'
const LINK_KEYS = [EMAIL, PHONE, WEBSITE]

const IconDataComponent = ({
  t,
  type,
  icon,
  iconSize = 'sm',
  title,
  text,
  color,
  iconColor,
  titleColor,
  textColor,
  bulletColor,
  titleVariant = 'f12',
  textVariant = 'f14',
  titleLineHeight = '12px',
  margin = 2,
  sx = {},
  suffix,
  allowWrap = false,
  textSuffix,
  clickable = false,
  showTextTooltip = false,
  useCustomIcon = false,
  style,
}) => {
  const handleClick = () => {
    if (type === EMAIL) window.location.href = `mailto:${text}`
    if (type === PHONE) window.open(`tel:${text}`)
    if (type === WEBSITE) window.open(text, '_blank')
  }

  // Cut text long
  const textDisplay =
    text && typeof text === 'string' && text.length > 24
      ? text.substring(0, 24) + '\n' + text.substring(24, text.length)
      : text

  return (
    <Box
      className="IconData"
      onClick={handleClick}
      style={style}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: LINK_KEYS.includes(type) || clickable ? 'pointer' : 'default',
        height: 'fit-content',
        ...sx,
      }}
    >
      {icon && !useCustomIcon && (
        <FontAwesomeIcon
          icon={icon}
          size={iconSize}
          color={iconColor || color || colorsByKey.accentTextStrong}
        />
      )}
      {icon && useCustomIcon && <img src={icon} alt={`custom icon ${icon}`} />}
      <Box
        sx={{
          ml: margin,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {title && typeof title === 'string' && (
          <Typography
            noWrap={!allowWrap}
            color={titleColor || color || colorsByKey.accentTextStrong}
            variant={titleVariant}
            sx={{ lineHeight: titleLineHeight }}
          >
            {suffix ? `${title} ${suffix}` : title}
          </Typography>
        )}
        {title && typeof title !== 'string' && (
          <Box sx={{ display: 'flex' }}>
            <Typography
              noWrap={!allowWrap}
              color={titleColor || color || colorsByKey.accentTextStrong}
              variant={titleVariant}
            >
              {(title[0] || {}).name}
            </Typography>
            {title.length > 1 && (
              <Box
                sx={{
                  display: 'flex',
                  background: bulletColor || colorsByKey.primaryBgStrong,
                  borderRadius: '50%',
                  width: '16px',
                  height: '16px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '4px',
                }}
              >
                <Tooltip
                  sx={{
                    backgroundColor: `${colorsByKey.transparent} !important`,
                    p: 0,
                    m: 0,
                    boxShadow: 'none',
                  }}
                  title={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {title.map(({ name }, idx) => (
                        <Typography
                          key={`item-${idx}`}
                          color={color || colorsByKey.utility}
                          variant={titleVariant}
                        >
                          {name}
                        </Typography>
                      ))}
                    </Box>
                  }
                >
                  <Typography
                    variant="caption"
                    noWrap
                    color={colorsByKey.defaultBg}
                    style={{ letterSpacing: '-1px', fontSize: '9px' }}
                  >
                    + {title.length - 1}
                  </Typography>
                </Tooltip>
              </Box>
            )}
          </Box>
        )}
        {text && typeof text === 'string' && (
          <Tooltip title={showTextTooltip ? (textSuffix ? `${text} ${textSuffix}` : text) : ''}>
            <Typography
              noWrap={!allowWrap}
              color={textColor || colorsByKey.black}
              variant={textVariant}
              sx={{ mt: 0.5 }}
            >
              {textSuffix ? `${textDisplay} ${textSuffix}` : textDisplay}
            </Typography>
          </Tooltip>
        )}
        {text && typeof text !== 'string' && (
          <Box sx={{ display: 'flex', mt: 0.5 }}>
            <Typography
              noWrap={!allowWrap}
              color={textColor || colorsByKey.black}
              variant={textVariant}
            >
              {(text[0] || {}).name}
            </Typography>
            {text.length > 1 && (
              <Box
                sx={{
                  display: 'flex',
                  background: bulletColor || colorsByKey.primaryBgStrong,
                  borderRadius: '50%',
                  width: '16px',
                  height: '16px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '4px',
                }}
              >
                <Tooltip
                  sx={{
                    backgroundColor: `${colorsByKey.transparent} !important`,
                    p: 0,
                    m: 0,
                    boxShadow: 'none',
                  }}
                  title={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {text.map(({ name }, idx) => (
                        <Typography
                          key={`item-${idx}`}
                          color={color || colorsByKey.utility}
                          variant={textVariant}
                        >
                          {name}
                        </Typography>
                      ))}
                    </Box>
                  }
                >
                  <Typography
                    variant="caption"
                    noWrap
                    color={colorsByKey.defaultBg}
                    style={{
                      minWidth: '16px',
                      minHeight: '16px',
                      letterSpacing: '-1px',
                      fontSize: '9px',
                    }}
                  >
                    + {text.length - 1}
                  </Typography>
                </Tooltip>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
export const IconData = withTranslation()(IconDataComponent)
