import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route } from 'react-router'
import history from './history'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { QueryParamProvider } from 'use-query-params'

const rootElement = document.getElementById('root')
const app = (
  <React.StrictMode>
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <App />
      </QueryParamProvider>
    </Router>
  </React.StrictMode>
)

ReactDOM.render(app, rootElement)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
