import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Avatar, Typography } from '@mui/material'
import './MenuSelector.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { colorsByKey } from 'theme/colors'
import { FEED } from 'entities/routes'
import { useHistory } from 'react-router-dom'
import SubscriptionPackage from '../../../component/registrationform/subscriptionpackage/subscriptionpackage'
import ModalWrapper from './ModalWrapper'

const MenuSelector = ({ name, id, image, userDetail, userType }) => {
  let history = useHistory()
  const [updateSubscription, setUpdateSubscription] = useState(false)

  const links = [
    { key: 'feed', path: FEED, label: 'Feed' },
    { key: 'profile', path: `${userType}/${id}`, label: 'Profile' },
    { key: 'payments', path: 'updateSubscription', label: 'Payments' },
    { key: 'logout', path: `/logout`, label: 'Logout' },
  ]
  const handleRedirectTo = (path) => {
    if(path === 'updateSubscription') {
      setUpdateSubscription(true)
      return
    }
    history.push(path)
  }

  return (
    <>
      <Dropdown className="MenuSelector">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="d-flex align-items-center"
          style={{
            display: 'flex',
            justifyContent: 'center',
            background: colorsByKey.transparent,
            margin: 0,
            padding: 0,
          }}
        >
          <Avatar
            alt={`${name} - profile picture`}
            src={image}
            variant="rounded"
            sx={{ width: 24, height: 24, mr: 1 }}
          />
          <FontAwesomeIcon icon={faChevronDown} size="xs" color={colorsByKey.grey} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {links.map(({ key, label, path }) => {
            return (
              <Dropdown.Item onClick={() => handleRedirectTo(path)} key={`item-${key}`}>
                <div className="form-check dropdown-item">
                  <Typography
                    variant="f14"
                    component="label"
                    className="form-check-label"
                    sx={{ cursor: 'pointer' }}
                    color={colorsByKey.defaultText}
                  >
                    {label}
                  </Typography>
                </div>
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
      {updateSubscription && (
        
        <ModalWrapper
          userDetails={userDetail}
          open={() => setUpdateSubscription(true)}
          handleClose={() => setUpdateSubscription(false)}
        />
        // <SubscriptionPackage
        //   userDetail={userDetail}
        //   subscription={userDetail.subscription}
        //   close={() => setUpdateSubscription(false)}
        //   open={() => setUpdateSubscription(true)}
        // />
      )}
    </>
  )
}

export default MenuSelector
