import React, { useEffect, useState } from 'react'
import './chat.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { colorsByKey } from 'theme/colors'
import { Avatar } from '@mui/material'
import { getChat, getChatList, openChat } from 'store/actions/chatActions'
import { connect } from 'react-redux'

const defaultImage = require('assets/icons/user.svg')

const ChatListItem = ({createdAt, image, name, message, messageCount, onClick, status}) => {
  return (
    <div className='chat-list-item' onClick={onClick}>
      <Avatar
        alt={`post-picture`}
        variant="circle"
        src={image}
        sx={{ width: 53, height: 53, zIndex: 1 }}
      />
      <div className='last-message'>
        <h1>{name}</h1>
        <p className={status}>{message}</p>
      </div>
      <div className='sent-at'>
        <span className='time'>{createdAt}</span>
        {status != 'viewed' && <span className='badge'>{messageCount}</span>}
      </div>
    </div>
  )
}

const ChatList = ({ userDetail, chats, getChat, getChatList, openChat}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')

  const { id, image, avatar, company_logo, name, userType } = userDetail
  const [chatList, setChatList] = useState([])
  const [filteredChatList, setFilteredChatList] = useState([])

  const loadChatList = () => {
    getChatList()
  }

  const openChatRoom = (id) => {
    openChat(id)
  }

  const filter = (e) => {
    setSearch(e.target.value)
    if(e.target.value == '') return setFilteredChatList(chatList)
    let filtered = chatList.filter(chat => chat.user.name && String(chat.user.name).toLowerCase().includes(String(e.target.value).toLowerCase()))
    filtered.sort((a, b) => {
      const nameA = a.user.name.toLowerCase();
      const nameB = b.user.name.toLowerCase();
      
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    setFilteredChatList(filtered)
  }
  
  useEffect(() => {
    if(chats) {
      setChatList(chats)
      if(search == '') {
        setFilteredChatList((chats))
      }
    }
    const intervalId = setInterval(loadChatList, 10000);

    return () => clearInterval(intervalId);
  }, [chats]);
  
  return (
    <div id='chatList' className={isOpen ? 'open' : ''}>
      <header onClick={() => setIsOpen(!isOpen)}>
        <Avatar alt={`${name} - profile picture`}
          src={avatar ?? (company_logo ? `${process.env.REACT_APP_API_URL}${company_logo}` : ( image ?? defaultImage))}
          variant='rounded'
          sx={{ width: 21, height: 21 }}
        />
        <h1>Chat</h1>
        <div className='input-container' onClick={(e) => !isOpen || e.stopPropagation()}>
          <label htmlFor="search"><FontAwesomeIcon icon={faMagnifyingGlass} size='xs' color={colorsByKey.blue} /></label>
          <input type='text' id='search' name='search' placeholder='Search conversations' onKeyUp={filter}></input>
        </div>
        <button onClick={() => setIsOpen(!isOpen)}><FontAwesomeIcon icon={faChevronDown} size='xs' color={colorsByKey.grey} /></button>
      </header>
      <main style={{overflowY: 'auto'}}>
      { isOpen && filteredChatList && filteredChatList.map(chat => 
        <ChatListItem image={chat.user?.avatar ?? defaultImage} 
          name={chat.user.companyName ?? (chat.user.name ?? ' -- ')} 
          message={chat.lastMessage ? chat.lastMessage.content : ''} 
          status={chat.chat.currentParticipant.id !== chat.lastMessage?.senderId ? ( chat.lastMessage?.status ?? 'viewed') : 'viewed'}  
          messageCount={chat.unreadMessages.filter(m => m?.senderId !== id).length} 
          createdAt={chat.lastMessage?.createdAt ? (new Date(chat.lastMessage?.createdAt).toLocaleDateString('pt-BR', {month: 'long', day: 'numeric'})).replace('de', ''):''} 
          key={chat.id} onClick={() => openChatRoom(chat.id)} />
      )}
      </main>
    </div>
  )
}

const mapStateToProps = (state) => ({
  chats: state.chat.chats,
})

const mapDispatchToProps = {
  getChatList,
  getChat,
  openChat
}

export default connect(mapStateToProps, mapDispatchToProps) (ChatList)