import React from 'react'
import Certificationawards from '../component/profile/certificationawards'
import { withTranslation } from 'react-i18next'
class Certificationspage extends React.Component {
  render() {
    const { t } = this.props
    return (
      <div>
        <Certificationawards
          pagetitle={t('Certificate')}
          title={t('Certificate')}
          listendpoint="/api/listCertificates"
          addendpoint="/api/addCertificate"
          editendpoint="/api/editCertificate"
          delendpoint="/api/deleteCertificate"
        ></Certificationawards>
      </div>
    )
  }
}
export default withTranslation()(Certificationspage)
