import React, { useState, useEffect } from 'react'
import { Box, Typography, Avatar, Container } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { apiGet } from 'api'
import { useParams } from 'react-router-dom'
import { normalizeBlogData } from 'mixins/dataNormalizers'
import { colorsByKey } from 'theme/colors'
import { useHistory } from 'react-router-dom'
import { FEED } from 'entities/routes'
import { Spinner, CompanyAvatar, IconDataBadge } from 'components'
import {
  CREATED_AT,
  USER,
  TITLE,
  HEADER_IMAGE,
  DESCRIPTION,
  CONTENT,
  ID,
  IMAGE,
} from 'entities/formFields'
import { LEVERS_OF_GROWTH, NAME, SUBSCRIPTION } from 'entities/companyFields'
import moment from 'moment'
import { Helmet } from 'react-helmet'

const BlogDetailScreen = ({ t }) => {
  let history = useHistory()
  const { id } = useParams()
  const [postData, setPostData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const metadata = {}

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await apiGet(`/api/blogDetail/${id}`)
        .then(({ data }) => {
          const normalizedData = normalizeBlogData(data)
          setPostData(normalizedData)
        })
        .catch(() => {
          history.push(FEED)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    fetchData()
  }, [history, id])

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && !!Object.keys(postData).length && (
        <Container maxWidth={'xl'} sx={{ my: 4 }}>
          <Helmet>
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content={'post'} />
          </Helmet>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1,
            }}
          >
            {postData[TITLE] && (
              <div>
                <Helmet>
                  <title>{"BridgeWhat: Let's Grow! - " + postData[TITLE]}</title>
                  <meta
                    property="og:title"
                    content={"BridgeWhat: Let's Grow! - " + postData[TITLE]}
                  />
                </Helmet>
                <Typography variant="f24" color={colorsByKey.accentTextStrong}>
                  {postData[TITLE]}
                </Typography>
              </div>
            )}
            {postData[CREATED_AT] && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <Typography noWrap variant="f12" color={colorsByKey.secondaryText}>
                  {t('Publish Date')}:
                </Typography>
                <Typography noWrap variant="f12" color={colorsByKey.defaultText} sx={{ ml: 1 }}>
                  {moment(postData[CREATED_AT]).format('Do MMMM YYYY, h:mm')}
                </Typography>
              </Box>
            )}
          </Box>
          {/* TODO: Print header image */}
          {(postData[HEADER_IMAGE] || {}).url && (
            <div>
              <Helmet>
                <meta property="og:image" content={postData[HEADER_IMAGE].url} />
              </Helmet>
              <Avatar
                alt={`${(postData[HEADER_IMAGE] || {}).name} - Image`}
                src={(postData[HEADER_IMAGE] || {}).url}
                variant="rounded"
                sx={{
                  width: '100%',
                  height: '400px',
                  my: 2,
                }}
              />
            </div>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              my: 3,
              pb: 3,
              borderBottom: `2px solid ${colorsByKey.primaryBgWeak}`,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CompanyAvatar
                name={postData[USER][NAME]}
                subscription={postData[USER][SUBSCRIPTION]}
                userId={postData[USER][ID]}
                image={postData[USER][IMAGE]}
                size="sm"
                variant="rounded"
                customSx={{ mr: 2 }}
              />
              <Box>
                {postData[USER][NAME] && (
                  <Typography sx={{ color: colorsByKey.defaultText }} variant="f16">
                    {postData[USER][NAME]}
                  </Typography>
                )}
                {postData[USER][DESCRIPTION] && (
                  <Typography sx={{ color: colorsByKey.secondaryText }} variant="f12">
                    {postData[USER][DESCRIPTION]}
                  </Typography>
                )}
                {!!(postData[LEVERS_OF_GROWTH] || []).length && (
                  <>
                    <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
                      {postData[LEVERS_OF_GROWTH].map((item, idx) => (
                        <IconDataBadge key={`${item.id}-${idx}`} {...item} />
                      ))}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            {postData[CONTENT] && typeof postData[CONTENT] === 'string' && (
              <div>
                <Helmet>
                  <meta property="description" content={postData[CONTENT]} />
                </Helmet>
                <div dangerouslySetInnerHTML={{ __html: postData[CONTENT] }} />
              </div>
            )}
          </Box>
        </Container>
      )}
    </>
  )
}
export default withTranslation()(BlogDetailScreen)
