import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import ProfileHeader from '../component/profile/ProfileHeader'
import Profileform from '../component/profile/profile'
import Profileformtwo from '../component/profile/profiletwo'
import Profileformthree from '../component/profile/profilethree'
import SBAProfilepage from '../component/profile/sbaprofile'
import { withTranslation } from 'react-i18next'

class Profilepage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'profile',
    }

    this.changeTab = this.changeTab.bind(this)
  }

  changeTab(val) {
    this.setState({ activeTab: val })
  }

  render() {
    const { userDetail, t } = this.props
    return (
      <div className="registration-min w-100 pb-2 pb-5 mb-1">
        <Helmet>
          <title>Profile | BridgeWhat: Let's Grow!</title>
        </Helmet>

        <Container>
          <div className="boxshadow p-3 pt-1 pb-4">
            {userDetail.cb_roles_id === 1 && (
              <>
                <ProfileHeader
                  activekey={this.state.activeTab}
                  onChangeTab={(val) => this.changeTab(val)}
                />
                {this.state.activeTab === 'profile' && (
                  <Profileform
                    updateProfile={() => this.props.updateProfile()}
                    userDetail={userDetail}
                  ></Profileform>
                )}
                {this.state.activeTab === 'profile-two' && (
                  <Profileformtwo
                    updateProfile={() => this.props.updateProfile()}
                    userDetail={userDetail}
                  ></Profileformtwo>
                )}
                {this.state.activeTab === 'profile-three' && (
                  <Profileformthree
                    updateProfile={() => this.props.updateProfile()}
                    userDetail={userDetail}
                  ></Profileformthree>
                )}
              </>
            )}
            {/* TODO: this form is not updated */}
            {userDetail.cb_roles_id === 2 && (
              <SBAProfilepage
                updateProfile={() => this.props.updateProfile()}
                userDetail={userDetail}
              />
            )}
          </div>
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{
              __html: t('TermsAccepted'),
            }}
          />
        </Container>
      </div>
    )
  }
}
export default withTranslation()(Profilepage)
