import {
  ADVISOR_FEATURE,
  CAMPAIGNS_FEATURE,
  CONTENTS_BLOG_FEATURE,
  CONTENTS_PUBLICATIONS_FEATURE,
  NEEDS_AND_LEADS_FEATURE,
  PROTOCOLS_FEATURE,
  REGISTER_FEATURE,
  REVIEWS_FEATURE,
  SAMPLING_FEATURE,
  SCORING_BOARD_FEATURE,
  LOG_MARKET_FEATURE,
  OFFERS_FILTER,
  OPPORTUNITIES_FILTER,
  CAMPAIGNS_FILTER,
  PROTOCOLS_FILTER,
  SAMPLING_FILTER,
  POST_FILTER,
  COMPANIES_FILTER,
  ADVISOR_FILTER,
  PARTNERSHIPS_FILTER,
  ACCELERATORS_FILTER,
  NEW_PARTNERSHIPS_FILTER,
} from './features'

import {
  OFFERS_ACTION,
  OPPORTUNITIES_ACTION,
  CAMPAIGNS_ACTION,
  COMPANIES_ACTION,
  POST_ACTION,
  PROTOCOLS_ACTION,
  SAMPLING_ACTION,
  PARTNERSHIPS_ACTION,
  NEW_PARTNERSHIPS_ACTION,
  ACCELERATOR_ACTION,
  IM_LOOKING_FOR_ACTION,
} from './actions'

export const CRYSTAL_SUBSCRIPTION = 1
export const SILVER_SUBSCRIPTION = 2
export const GOLD_SUBSCRIPTION = 3
export const PLATINUM_SUBSCRIPTION = 4
export const CONNECT_SUBSCRIPTION = 5

export const SUBSCRIPTION_COLORS = {
  1: 'orange',
  2: 'blue',
  3: 'yellow',
  4: 'purple',
  5: 'purple',
}

export const CRYSTAL_FEATURES = [
  REGISTER_FEATURE,
  REVIEWS_FEATURE,
  SAMPLING_FEATURE,
  CONTENTS_BLOG_FEATURE,
  LOG_MARKET_FEATURE, // TODO: Check package avaiability

  OFFERS_FILTER,
  OPPORTUNITIES_FILTER,
  CAMPAIGNS_FILTER,
  PROTOCOLS_FILTER,
  SAMPLING_FILTER,
  POST_FILTER,
  COMPANIES_FILTER,
  ADVISOR_FILTER,
  PARTNERSHIPS_FILTER,
  NEW_PARTNERSHIPS_FILTER,
]

export const CRYSTAL_ACTIONS = [
  POST_ACTION,
  //SAMPLING_ACTION
]

export const CRYSTAL_CARD = []

export const SILVER_FEATURES = [
  ...CRYSTAL_FEATURES,
  SCORING_BOARD_FEATURE,
  PROTOCOLS_FEATURE,
  CONTENTS_PUBLICATIONS_FEATURE,

  OFFERS_FILTER,
  OPPORTUNITIES_FILTER,
  CAMPAIGNS_FILTER,
  PROTOCOLS_FILTER,
  SAMPLING_FILTER,
  POST_FILTER,
  COMPANIES_FILTER,
  ADVISOR_FILTER,
  PARTNERSHIPS_FILTER,
  NEW_PARTNERSHIPS_FILTER,
  ACCELERATORS_FILTER,
]

export const SILVER_ACTIONS = [
  ...CRYSTAL_ACTIONS,
  PROTOCOLS_ACTION,
  OFFERS_ACTION,
  OPPORTUNITIES_ACTION,
  CAMPAIGNS_ACTION,
  SAMPLING_ACTION,
  ACCELERATOR_ACTION,
  IM_LOOKING_FOR_ACTION,
]

export const SILVER_CARD = [
  OFFERS_ACTION,
  OPPORTUNITIES_ACTION,
  CAMPAIGNS_ACTION,
  COMPANIES_ACTION,
  POST_ACTION,
  PROTOCOLS_ACTION,
  SAMPLING_ACTION,
  ADVISOR_FILTER,
  ACCELERATOR_ACTION,
  IM_LOOKING_FOR_ACTION,
]

export const CONNECT_FEATURES = [
  ...CRYSTAL_FEATURES,
  SCORING_BOARD_FEATURE,
  PROTOCOLS_FEATURE,
  CONTENTS_PUBLICATIONS_FEATURE,

  OFFERS_FILTER,
  OPPORTUNITIES_FILTER,
  CAMPAIGNS_FILTER,
  PROTOCOLS_FILTER,
  SAMPLING_FILTER,
  POST_FILTER,
]
export const CONNECT_ACTIONS = [
  ...CRYSTAL_ACTIONS,
  PROTOCOLS_ACTION,
  OFFERS_ACTION,
  OPPORTUNITIES_ACTION,
  CAMPAIGNS_ACTION,
  SAMPLING_ACTION,
  ACCELERATOR_ACTION,
  IM_LOOKING_FOR_ACTION,
]
export const CONNECT_CARD = [
  OFFERS_ACTION,
  OPPORTUNITIES_ACTION,
  CAMPAIGNS_ACTION,
  POST_ACTION,
  PROTOCOLS_ACTION,
  SAMPLING_ACTION,
  ACCELERATOR_ACTION,
  IM_LOOKING_FOR_ACTION,
]

export const GOLD_FEATURES = [
  ...SILVER_FEATURES,
  PARTNERSHIPS_FILTER,
  NEW_PARTNERSHIPS_FILTER,
  NEEDS_AND_LEADS_FEATURE,
  CAMPAIGNS_FEATURE,
  ACCELERATORS_FILTER,
]

export const GOLD_ACTIONS = [
  ...SILVER_ACTIONS,
  NEW_PARTNERSHIPS_ACTION,
  PARTNERSHIPS_ACTION,
  SAMPLING_ACTION,
  ACCELERATOR_ACTION,
]

export const GOLD_CARD = [...SILVER_CARD, SAMPLING_ACTION, ADVISOR_FILTER]

export const PLATINUM_FEATURES = [...GOLD_FEATURES, ADVISOR_FEATURE]

export const PLATINUM_ACTIONS = [
  ...SILVER_ACTIONS,
  NEW_PARTNERSHIPS_ACTION,
  PARTNERSHIPS_ACTION,
  SAMPLING_ACTION,
  ACCELERATOR_ACTION,
]

export const PLATINUM_CARD = [...GOLD_CARD, SAMPLING_ACTION, ADVISOR_FILTER]

const SUBSCRIPTIONS = {
  [CRYSTAL_SUBSCRIPTION]: {
    id: CRYSTAL_SUBSCRIPTION,
    name: 'Crystal',
    pname: 'crystal',
    available_features: CRYSTAL_FEATURES,
    available_actions: CRYSTAL_ACTIONS,
    available_card_btn: CRYSTAL_CARD,
    color: '#89C6ED',
  },
  [SILVER_SUBSCRIPTION]: {
    id: SILVER_SUBSCRIPTION,
    name: 'Silver',
    pname: 'silver',
    available_features: SILVER_FEATURES,
    available_actions: SILVER_ACTIONS,
    available_card_btn: SILVER_CARD,
    color: '#81949E',
  },
  [GOLD_SUBSCRIPTION]: {
    id: GOLD_SUBSCRIPTION,
    name: 'Gold',
    pname: 'gold',
    available_features: GOLD_FEATURES,
    available_actions: GOLD_ACTIONS,
    available_card_btn: GOLD_CARD,
    color: '#968F6B',
  },
  [PLATINUM_SUBSCRIPTION]: {
    id: PLATINUM_SUBSCRIPTION,
    name: 'Platinum',
    pname: 'platinum',
    available_features: PLATINUM_FEATURES,
    available_actions: PLATINUM_ACTIONS,
    available_card_btn: PLATINUM_CARD,
    color: '#AFBEC7',
  },
  [CONNECT_SUBSCRIPTION]: {
    id: CONNECT_SUBSCRIPTION,
    name: 'CONNECT 4.0',
    pname: 'connect',
    available_features: CONNECT_FEATURES,
    available_actions: CONNECT_ACTIONS,
    available_card_btn: CONNECT_CARD,
    color: '#81949E',
  },
}

export default SUBSCRIPTIONS
