import React from 'react'
import { normalizeData } from 'mixins/dataNormalizers'
import { useList } from 'hooks'
import { withTranslation } from 'react-i18next'
import SidebarListCard from './SidebarListCard'
import SidebarPostsList from './SidebarPostsList'
import { SELL } from 'entities/feed'

const SidebarSectionComponent = ({
  t,
  url,
  method,
  defaultFilterParams = {},
  type,
  title,
  props,
}) => {
  const { isLoading, isLoaded, error, data } = useList({
    url,
    method,
    filters: {
      // BACKEND: this endpoint should return last 3 added items
      perPage: 3,
    },
    defaultFilterParams,
    currentPage: 1,
    type: type || SELL,
  })

  if (error) {
    return <div>{error}</div>
  }

  // TODO: Delete slice when filter perPage param is OK
  const normalizedData = normalizeData(data).slice(0, 3)

  return (
    <div style={{ marginBottom: '40px' }}>
      {type !== 'posts' && (
        <SidebarListCard
          title={title}
          items={normalizedData}
          isLoading={isLoading}
          isLoaded={isLoaded}
          props={props}
        />
      )}
      {type === 'posts' && (
        <SidebarPostsList title={title} items={normalizedData} isLoading={isLoading} />
      )}
    </div>
  )
}
export const SidebarSection = withTranslation()(SidebarSectionComponent)
