import React, { useState, useEffect } from 'react'
import { Box, Button, Avatar, Typography } from '@mui/material'
import ModalImage from 'react-modal-image'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import { IconData, CompanyAvatar, FinancialReportButton, Tag, IconDataBadge } from 'components'
import moment from 'moment'
import {
  credentials,
  CREDENTIALS,
  CERTIFICATES,
  REVIEWS,
  AWARDS,
  EDUCATION,
  RECOMMENDATIONS,
  TESTIMONIALS,
} from 'entities/company'

import {
  SELL,
  BUY,
  SAMPLINGS,
  PROTOCOLS,
  CAMPAIGNS,
  POSTS,
  ALL_TABS,
  COMPANIES,
  ADVISORS,
  NEW_PARTNERSHIPS,
  ACCELERATORS,
} from 'entities/feed'
import {
  TYPE,
  CREATED_AT,
  USER,
  TITLE,
  DESCRIPTION,
  CONTENT,
  IMAGE,
  HEADER_IMAGE,
  THUMBNAIL_IMAGE,
  IMAGES,
  NUMBER_OF_SAMPLES,
  DEADLINE,
  BROCHURES,
  ID,
  CARD_FOOTER_FIELDS,
  numberOfSamples,
  brochures,
  PUBLICATION_DATE,
  TARGET_COMPANY_REVENUE,
  TARGET_COMPANY_SIZE,
} from 'entities/formFields'
import {
  NAME,
  WEBSITE,
  COMPANY_CARD_FIELDS,
  ADVISOR_CARD_FIELDS,
  SUBSCRIPTION,
  LEVERS_OF_GROWTH,
  website,
  linkedin,
  SOCIAL_MEDIA,
  FINANTIAL_REPORT,
  HIDE_IDENTITY,
  JOB_FUNCTION,
  USER_INDUSTRY_SECTORS,
  userIndustrySectors,
  GEOGRAPHY,
  SUBSCRIPTION_OPTION,
} from 'entities/companyFields'
import { COMPANY, ADVISOR } from 'entities/routes'
import { CardFooter } from './CardFooter'
import { useHistory } from 'react-router-dom'
import SUBSCRIPTIONS from 'utils/subscriptions'
import { ModalUpgradePackage } from 'components'
import '../../component/annualpackage/annualpackage.css'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import PartnershipImages from './PartnershipImages'

const LIMIT_MAX_DESCRIPTION = 180

const CardBodyComponent = (props) => {
  let history = useHistory()
  const { t, filters } = props

  const [POST_IMAGE_WIDTH, setPOST_IMAGE_WIDTH] = useState()
  const [POST_IMAGE_HEIGTH, setPOST_IMAGE_HEIGTH] = useState()

  let normalizedProps = props

  let description = [POSTS, NEW_PARTNERSHIPS].includes(normalizedProps[TYPE])
    ? normalizedProps[CONTENT]
    : normalizedProps[DESCRIPTION]

  if (description === null) description = ''
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    if (description?.length <= LIMIT_MAX_DESCRIPTION) {
      setShowMore(true)
      return
    }
    setShowMore(false)
  }, [description])

  const handleDownloadBrochure = (url) => {
    window.open(url, '_blank')
  }
  const handleRedirectToWebsite = () => {
    window.open(props[WEBSITE], '_blank')
  }

  const [visibleModalUpgrade, setVisibleModalUpgrade] = useState(false)
  const [dataModalUpgrade, setDataModalUpgrade] = useState({
    data: '',
    linkUpgrade: '',
  })
  const [dataModalCurrent, setDataModalCurrent] = useState({
    data: '',
    linkUpgrade: '',
  })
  const userCurrent = props.userDetail
  const subscriptionCurrent = SUBSCRIPTIONS[userCurrent.subscription]

  function handleCloseModal() {
    setVisibleModalUpgrade(false)
  }

  const handleRedirectToProfile = () => {
    let found = subscriptionCurrent.available_card_btn.find(
      (element) => element == normalizedProps[TYPE]
    )
    let access = true
    if (
      found !== undefined ||
      (userCurrent.subscription === 5 && normalizedProps.subscription === 5)
    ) {
      //Tiene acceso
      setVisibleModalUpgrade(false)
    } else {
      let upgrade =
        userCurrent.subscription == 1 || userCurrent.subscription == 5
          ? 2
          : userCurrent.subscription + 1
      access = false
      setVisibleModalUpgrade(true)
      setDataModalUpgrade({
        data: SUBSCRIPTIONS[upgrade],
        linkUpgrade: userCurrent,
      })
      setDataModalCurrent({
        data: subscriptionCurrent,
        linknowBenefits: '',
      })
      //No tiene acceso y levanta modal de upgrade
    }

    if (normalizedProps[TYPE] === COMPANIES) {
      if (access == true) history.push(`${COMPANY}/${normalizedProps[ID]}`)
    } else if (normalizedProps[TYPE] === ADVISORS) {
      if (access == true) history.push(`${ADVISOR}/${normalizedProps[ID]}`)
    }
  }

  const { slug, id: levelOfGrowthId } = (normalizedProps[LEVERS_OF_GROWTH] || [])[0] || {}
  const companyDefaultImage = require('assets/icons/company.svg')
  const advisorDefaultImage = require('assets/icons/advisor.svg')
  const marketplaceDefaultImage =
    slug && levelOfGrowthId
      ? require(`assets/images/20LOG/${slug}/${levelOfGrowthId}.svg`)
      : require(`assets/images/20LOG/default.svg`)

  const defaultCampaignsHeaderImage = require('assets/images/card-default-images/campaigns.svg')

  const defaultHeaderSamplingsImage = require(`assets/images/card-default-images/samplings.svg`)
  const defaultHeaderProtocolsImage = require(`assets/images/card-default-images/protocols.svg`)
  const defaultHeaderImage =
    normalizedProps[TYPE] === SAMPLINGS ? defaultHeaderSamplingsImage : defaultHeaderProtocolsImage

  let userImage
  if (normalizedProps[IMAGE] && !normalizedProps[HIDE_IDENTITY]) {
    userImage = normalizedProps[IMAGE]
  } else {
    userImage = normalizedProps[TYPE] === ADVISORS ? advisorDefaultImage : companyDefaultImage
  }

  let cardFields = CARD_FOOTER_FIELDS
  if (normalizedProps[TYPE] === COMPANIES) cardFields = COMPANY_CARD_FIELDS
  else if (normalizedProps[TYPE] === ADVISORS) cardFields = ADVISOR_CARD_FIELDS

  const countDown = () => {
    const start = moment()
    const end = moment(normalizedProps[DEADLINE] || '')
    const numOfYears = end.diff(start, 'years')
    const numdays = end.diff(start, 'days')
    const numOfMonths = end.diff(start, 'months')

    if (numOfMonths < 1) return { value: numdays, type: 'days' }
    if (numOfMonths >= 1 && numOfMonths <= 12) return { value: numOfMonths, type: 'months' }
    return { value: numOfYears, type: 'years' }
  }

  const getMeta = (url) => {
    const img = new Image()
    img.src = url
    return new Promise((res) => {
      img.onload = function () {
        res({ width: this.width, height: this.height })
      }
    })
  }

  const services = (normalizedProps[LEVERS_OF_GROWTH] || []).sort((a, b) => a.id - b.id)

  const sections =
    normalizedProps[TYPE] === COMPANIES
      ? credentials.companySections
      : normalizedProps[USER_INDUSTRY_SECTORS] || []

  const formatCredentialsData = (section = {}) => {
    const itemData = (normalizedProps[CREDENTIALS] || {})[section.key]

    const empty = { title: `${(itemData || {}).total || 0} ${section.label}` }
    // const empty = { title: t('no data yet!') }

    if (section.key === CERTIFICATES || section.key === AWARDS)
      return !(itemData || []).length
        ? empty
        : {
            title: itemData[0].title,
            text: itemData.length > 1 ? `+${itemData.length - 1} ${section.label}` : section.label,
          }
    else if (section.key === RECOMMENDATIONS || section.key === TESTIMONIALS)
      return {
        title: `${(itemData || {}).total || 0} ${section.label}`,
      }
    else if (section.key === REVIEWS)
      return !(itemData || {}).total
        ? empty
        : { title: `${itemData.average}/5 (${itemData.total} ${section.label})` }
    else if (section.key === EDUCATION) {
      // console.log('section.key', section.key, itemData)
      // TODO:format education
      return !(itemData || {}).total
        ? empty
        : { title: `${itemData.average}/5 (${itemData.total} ${section.label})` }
    }
  }

  // if(normalizedProps[TYPE] === POSTS){
  //   var div = document.createElement("div");
  //   div.innerHTML = description.replace(/<br>/g, ' ').replace(/<\/p>/g, ' ');
  //   description = div.textContent || div.innerText || "";
  // }
  const userReadOnly = (normalizedProps.userDetail || {}).isReadOnlyUser

  getMeta(
    (normalizedProps[THUMBNAIL_IMAGE] || {}).url ||
      (normalizedProps[HEADER_IMAGE] || {}).url ||
      defaultHeaderImage
  ).then((data) => {
    setPOST_IMAGE_WIDTH(data.width)
    setPOST_IMAGE_HEIGTH(data.height)
  })

  const handleImage = () => {
    const ALLOWED_EXT = ['jpg', 'png']
    if (!normalizedProps[IMAGE]) return
    if (ALLOWED_EXT.includes(normalizedProps[IMAGE]?.ext)) return normalizedProps?.[IMAGE]?.url

    return null
  }

  const handleShowMore = () => {
    setShowMore((prev) => !prev)
  }

  const handleDescription = () => {
    if (showMore) return description
    let smallDesc = description.slice(0, LIMIT_MAX_DESCRIPTION)
    if (description.length > LIMIT_MAX_DESCRIPTION) smallDesc += '...'

    return smallDesc
  }

  return (
    <Box
      sx={{
        backgroundColor: colorsByKey.defaultBg,
        borderRadius: '6px',
        boxShadow: '2px 0px 8px rgba(0,0,0,0.10)',
        position: 'relative',
      }}
    >
      {normalizedProps[TYPE] === CAMPAIGNS && (
        <Box sx={{ position: 'relative' }}>
          {ALL_TABS[normalizedProps[TYPE]] && (
            <Tag
              customSx={{ zIndex: 2, position: 'absolute', top: 0, left: '16px', m: 0 }}
              text={t((ALL_TABS[normalizedProps[TYPE]] || {}).label)}
            />
          )}
          <Avatar
            alt={`post-picture`}
            variant="square"
            src={(normalizedProps[HEADER_IMAGE] || {}).url || defaultCampaignsHeaderImage}
            sx={{ width: '100%', height: 130, zIndex: 1 }}
          />
          {normalizedProps[DEADLINE] && (
            <Typography
              variant="f20"
              color={colorsByKey.utility}
              sx={{ zIndex: 2, position: 'absolute', top: '8px', right: '16px', m: 0 }}
            >
              {`${countDown().value} ${t(countDown().type)}`}
            </Typography>
          )}
        </Box>
      )}
      <Box className="Content" sx={{ px: 2, pb: 2 }}>
        <Box className="ContentHeader" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {ALL_TABS[normalizedProps[TYPE]]?.label && normalizedProps[TYPE] !== CAMPAIGNS && (
              <Tag text={ALL_TABS[normalizedProps[TYPE]]?.label} />
            )}
          </Box>
          {normalizedProps[CREATED_AT] && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2,
                mt: 1,
              }}
            >
              <Typography variant="f12" color={colorsByKey.secondaryText}>
                {t('Publish Date')}:
              </Typography>
              <Typography variant="f12" color={colorsByKey.defaultText} sx={{ ml: 1 }}>
                {moment(normalizedProps[CREATED_AT]).format('Do MMMM YYYY, h:mm')}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          className="ContentBody"
          sx={{
            display: 'flex',
            alignItems: 'space-between',
          }}
        >
          {[SELL, BUY].includes(normalizedProps[TYPE]) && (
            <CompanyAvatar
              subscription={normalizedProps[USER][SUBSCRIPTION]}
              name={normalizedProps[USER][NAME]}
              image={marketplaceDefaultImage}
              userId={normalizedProps[USER][ID]}
              type="user"
              variant="rounded"
              bordered={false}
              size="md"
              customSx={{ mr: 2 }}
              expoImage={handleImage()}
            />
          )}
          {(normalizedProps[TYPE] === ADVISORS || normalizedProps[TYPE] === COMPANIES) && (
            <Box sx={{ mr: 4 }}>
              <CompanyAvatar
                subscription={normalizedProps[SUBSCRIPTION]}
                useSubscriptionName={
                  normalizedProps[TYPE] === COMPANIES && normalizedProps[SUBSCRIPTION]
                }
                name={normalizedProps[NAME]}
                useLinkedinIcon
                linkedinLink={(normalizedProps[SOCIAL_MEDIA] || {})[linkedin.key]}
                image={userImage}
                userId={normalizedProps[ID]}
                size="md"
                variant={normalizedProps[TYPE] === COMPANIES ? 'rounded' : ''}
                customSx={{
                  mr: 2,
                  '& img': { padding: 1 },
                }}
                // usePlusOption={
                //   normalizedProps[TYPE] === COMPANIES && normalizedProps[SUBSCRIPTION_OPTION]
                // }
                // plusOption={normalizedProps[SUBSCRIPTION_OPTION]}
              />
              {normalizedProps[TYPE] === ADVISORS && normalizedProps[HIDE_IDENTITY] && (
                <Typography
                  variant="f10"
                  color={colorsByKey.secondaryText}
                  align="center"
                  sx={{ maxWidth: '150px', mt: 2, fontStyle: 'italic' }}
                >
                  {t('This advisor prefers to keep its identity hidden')}
                </Typography>
              )}
            </Box>
          )}
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: 2,
              }}
            >
              {normalizedProps[TITLE] && (
                <Typography
                  variant="f20"
                  onClick={handleRedirectToProfile}
                  sx={{
                    color: colorsByKey.defaultText,
                    cursor:
                      normalizedProps[TYPE] === COMPANIES || normalizedProps[TYPE] === ADVISORS
                        ? 'pointer'
                        : 'default',
                  }}
                >
                  {normalizedProps[TYPE] === ADVISORS && normalizedProps[HIDE_IDENTITY]
                    ? 'Nondisclosure'
                    : normalizedProps[TITLE]}
                  {normalizedProps[TYPE] === ADVISORS && normalizedProps[JOB_FUNCTION] && (
                    <>
                      <span style={{ marginRight: '10px', marginLeft: '10px' }}>|</span>
                      <span style={{ fontSize: '14px' }}>{normalizedProps[JOB_FUNCTION]}</span>
                    </>
                  )}
                </Typography>
              )}
              {(normalizedProps[TYPE] === COMPANIES || normalizedProps[TYPE] === ADVISORS) &&
                normalizedProps[WEBSITE] && (
                  <Button
                    onClick={handleRedirectToWebsite}
                    type="button"
                    variant="text"
                    sx={{ ml: 1 }}
                  >
                    <IconData title={t(website.btn)} icon={website.icon} margin={1} />
                  </Button>
                )}
            </Box>
            {description && (
              <Typography variant="f16" pr={6} sx={{ color: colorsByKey.secondaryText }}>
                <Typography
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: handleDescription(),
                  }}
                ></Typography>

                {description.length > LIMIT_MAX_DESCRIPTION && (
                  <Typography component="span">
                    <Typography
                      onClick={handleShowMore}
                      variant="link"
                      component="span"
                      sx={{
                        color: colorsByKey.accentText,
                        ml: 1,
                      }}
                    >
                      {showMore ? t('view less') : t('view more')}
                    </Typography>
                  </Typography>
                )}
              </Typography>
            )}
            {/* {normalizedProps[CONTENT] && typeof normalizedProps[CONTENT] === 'string' && (
              <div
                dangerouslySetInnerHTML={{ __html: normalizedProps[CONTENT] }}
                style={{ maxHeight: '52px', overflow: 'hidden' }}
              />
            )} */}
            {(!!(normalizedProps[BROCHURES] || []).length ||
              !(normalizedProps[NUMBER_OF_SAMPLES] === undefined)) && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                {!!(normalizedProps[BROCHURES] || []).length &&
                  (normalizedProps[BROCHURES] || []).map(({ id, name, url }) => {
                    return (
                      <Box key={`brochure-${id}- ${name}`} sx={{ mr: 2, display: 'flex' }}>
                        <Button
                          type="button"
                          variant="outlined"
                          onClick={() => handleDownloadBrochure(url)}
                          sx={{
                            color: colorsByKey.accentTextStrong,
                            width: 'fit-content',
                            py: 1,
                            px: 2,
                          }}
                        >
                          <IconData title={t(brochures.label)} icon={brochures.icon} margin={1} />
                        </Button>
                      </Box>
                    )
                  })}
                {!(normalizedProps[NUMBER_OF_SAMPLES] === undefined) && (
                  <IconData
                    title={`${normalizedProps[NUMBER_OF_SAMPLES]}`}
                    icon={numberOfSamples.icon}
                    margin={1}
                  />
                )}
              </Box>
            )}
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '14px' }}>
                {cardFields.map(({ key, icon, suffix, getText, optionsKey }, idx) => {
                  if (!normalizedProps[key] || !normalizedProps[key].length) return <></>
                  const notThisOnesInAccelerators = [TARGET_COMPANY_REVENUE, TARGET_COMPANY_SIZE]
                  if (
                    normalizedProps[TYPE] === ACCELERATORS &&
                    notThisOnesInAccelerators.includes(key)
                  )
                    return <></>
                  else {
                    // Normalize 'title' for IconData
                    for (let i = 0; i < normalizedProps[key].length; i++) {
                      if (normalizedProps[key][i].marketoffer_revenue) {
                        normalizedProps[key][
                          i
                        ].name = `${normalizedProps[key][i].marketoffer_revenue} M €`
                      } else if (normalizedProps[key][i].marketoffer_size) {
                        normalizedProps[key][
                          i
                        ].name = `${normalizedProps[key][i].marketoffer_size} people`
                      }
                    }
                    return (
                      <Box
                        key={`${key}-${idx}`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background:
                            normalizedProps[TYPE] === COMPANIES ||
                            normalizedProps[TYPE] === ADVISORS
                              ? colorsByKey.utility
                              : colorsByKey.defaultBgWeak,
                          borderRadius: '24px',
                        }}
                      >
                        <IconData
                          type={key}
                          title={
                            getText
                              ? getText(normalizedProps[key], filters[optionsKey])
                              : normalizedProps[key]
                          }
                          icon={icon}
                          suffix={suffix}
                          margin={1}
                          useCustomIcon={key === GEOGRAPHY}
                        />
                      </Box>
                    )
                  }
                })}
              </Box>
            </Box>
            {/* {normalizedProps[TYPE] === ADVISORS && !!(normalizedProps[USER_INDUSTRY_SECTORS] || []).length && (
                  <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
                        <IconData
                          type={userIndustrySectors.key}
                          allowWrap
                          title={userIndustrySectors.getText(
                            normalizedProps[USER_INDUSTRY_SECTORS],
                            filters.sectors
                          )}
                          icon={userIndustrySectors.icon}
                          sx={{ alignItems: 'flex-start' }}
                        />
                  </Box>
              )} */}
            {((normalizedProps[TYPE] === COMPANIES && normalizedProps[FINANTIAL_REPORT]) ||
              ((normalizedProps[TYPE] === ADVISORS || normalizedProps[TYPE] === POSTS) &&
                !!(normalizedProps[LEVERS_OF_GROWTH] || []).length)) && (
              <>
                <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
                  {(services || []).map((item, idx) => (
                    <IconDataBadge key={`${item.id}-${idx}`} {...item} />
                  ))}
                </Box>
                {normalizedProps[TYPE] === COMPANIES && (
                  <FinancialReportButton {...normalizedProps[FINANTIAL_REPORT]} />
                )}
              </>
            )}
            {!!(normalizedProps[IMAGES] || []).length && (
              <Box sx={{ mr: 2, display: 'flex' }}>
                {normalizedProps[IMAGES].map(({ id, url }, idx) => (
                  /* <Avatar
                    key={`image-${id}`}
                    alt={`post-picture`}
                    src={url}
                    variant="rounded"
                    sx={{ width: 80, height: 80, mr: 2 }}
                  /> */
                  <ModalImage
                    className={'modalimage-small'}
                    small={url}
                    large={url}
                    alt={normalizedProps[TITLE]}
                  />
                ))}
              </Box>
            )}
            {normalizedProps.subscription === 5 &&
              normalizedProps.invitation_type != 'inovadora24' && (
                <img
                  src={require('assets/images/barras-COMPETE2020-PT2020-FEDER.png')}
                  alt="pt2020 logos"
                  style={{ height: 40, padding: 10 }}
                />
              )}
            {normalizedProps.subscription === 5 &&
              normalizedProps.invitation_type == 'inovadora24' && (
                <img
                  src={require('assets/images/Logo_Inovadora.png')}
                  alt="inovadora24 logos"
                  style={{ height: 50, padding: 5, top: 5, position: 'relative' }}
                />
              )}

            {/* {console.log(normalizedProps)} */}
          </Box>
          {normalizedProps[TYPE] === COMPANIES && (
            <Box
              sx={{
                borderLeft: `1px solid ${colorsByKey.separators}`,
                ml: 2,
                px: 3,
                maxWidth: '250px',
                minWidth: '250px',
              }}
            >
              {(sections || []).map((section) => {
                const sectionData = formatCredentialsData(section)
                return (
                  <Box sx={{ mb: 2 }} key={section.key}>
                    <IconData
                      type={section.key}
                      title={sectionData.title}
                      text={sectionData.text}
                      icon={section.icon}
                      iconColor={colorsByKey.accentText}
                      titleColor={colorsByKey.primaryBgStrong}
                      textColor={colorsByKey.accentText}
                      titleVariant="f14"
                      textVariant="f12"
                      titleLineHeight="18px"
                      allowWrap
                      sx={{ alignItems: 'flex-start' }}
                      margin={2}
                    />
                  </Box>
                )
              })}
            </Box>
          )}
          {normalizedProps[TYPE] === ADVISORS && sections.length > 0 && (
            <Box
              sx={{
                borderLeft: `1px solid ${colorsByKey.separators}`,
                ml: 2,
                px: 3,
                maxWidth: '250px',
                minWidth: '250px',
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="f12" color={colorsByKey.defaultText} sx={{ ml: 1 }}>
                  {t('Sector experience')}
                </Typography>
              </Box>
              {(sections || []).map((section, index) => {
                return (
                  <Box sx={{ mb: 2 }} key={section.key + '' + index}>
                    <IconData
                      type={userIndustrySectors.key}
                      allowWrap
                      title={section.name}
                      icon={userIndustrySectors.icon}
                      sx={{ alignItems: 'flex-start' }}
                    />
                  </Box>
                )
              })}
            </Box>
          )}
          {(normalizedProps[TYPE] === SAMPLINGS ||
            normalizedProps[TYPE] === PROTOCOLS ||
            normalizedProps[TYPE] === NEW_PARTNERSHIPS ||
            normalizedProps[TYPE] === ACCELERATORS ||
            normalizedProps[TYPE] === POSTS) && (
            <Box sx={{ display: 'flex' }}>
              {normalizedProps[TYPE] === NEW_PARTNERSHIPS && (
                <Pubishdate>
                  Publish date:{' '}
                  <span>{format(new Date(normalizedProps[PUBLICATION_DATE]), 'dd MMMM yyyy')}</span>
                </Pubishdate>
              )}
              {normalizedProps[TYPE] === POSTS && POST_IMAGE_WIDTH && POST_IMAGE_HEIGTH && (
                <ModalImage
                  className={
                    POST_IMAGE_WIDTH <= POST_IMAGE_HEIGTH
                      ? 'modalimage-big-widthresponsive'
                      : 'modalimage-big'
                  }
                  small={
                    (normalizedProps[THUMBNAIL_IMAGE] || {}).url ||
                    (normalizedProps[HEADER_IMAGE] || {}).url ||
                    defaultHeaderImage
                  }
                  large={
                    (normalizedProps[THUMBNAIL_IMAGE] || {}).url ||
                    (normalizedProps[HEADER_IMAGE] || {}).url ||
                    defaultHeaderImage
                  }
                  alt={'post-picture'}
                  style={{ marginLeft: '1vw' }}
                />
              )}
              {normalizedProps[TYPE] !== POSTS && normalizedProps[TYPE] !== NEW_PARTNERSHIPS && (
                <ModalImage
                  className={'modalimage-medium'}
                  small={(normalizedProps[HEADER_IMAGE] || {}).url || defaultHeaderImage}
                  large={(normalizedProps[HEADER_IMAGE] || {}).url || defaultHeaderImage}
                  alt={'post-picture'}
                  style={{ marginLeft: '1vw' }}
                />
              )}
              {normalizedProps[TYPE] === NEW_PARTNERSHIPS && (
                <PartnershipImages
                  partnershipImage={normalizedProps[IMAGE]}
                  secondaryImage={normalizedProps.partnerImage}
                />
              )}
              {/* <Avatar
                alt={`post-picture`}
                src={(normalizedProps[HEADER_IMAGE] || {}).url || defaultHeaderImage}
                variant="rounded"
                sx={{ width: 300, height: 'fit-content', ml: 2 }}
              /> */}
            </Box>
          )}
        </Box>
        {/* {normalizedProps[TYPE] === POSTS && (normalizedProps[HEADER_IMAGE] || {}).url && (
          <Avatar
            alt={`post-picture`}
            src={(normalizedProps[HEADER_IMAGE] || {}).url}
            variant="rounded"
            sx={{ width: '100%', height: 'auto', maxHeight: '300px', mt: 2 }}
          />
        )} */}
      </Box>
      <ModalUpgradePackage
        visible={visibleModalUpgrade}
        dataUpgrade={dataModalUpgrade}
        dataCurrent={dataModalCurrent}
        closeModal={() => handleCloseModal()}
      />
      {!userReadOnly && <CardFooter {...normalizedProps} />}
    </Box>
  )
}

const Pubishdate = styled.span`
  position: absolute;
  top: 12px;
  right: 20px;

  color: #6e798c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  & > span {
    color: #132332;
  }
`

export const CardBody = withTranslation()(CardBodyComponent)
