import React, { useCallback, useMemo, useRef } from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import SUBSCRIPTIONS, { CRYSTAL_SUBSCRIPTION } from 'utils/subscriptions'

const HeaderMenuItem = ({ dropdownSubItem, menuItem: { title, link, feature }, userDetail }) => {
  const { t } = useTranslation()
  const elementRef = useRef()

  const isDisabled = useMemo(() => {
    if (
      feature &&
      userDetail?.subscription?.id &&
      !SUBSCRIPTIONS[userDetail?.subscription?.id]?.available_features?.includes(feature)
    )
      return true

    return false
  }, [userDetail, feature])

  const linkMinimumSubscription = useMemo(() => {
    if (!feature) return SUBSCRIPTIONS[CRYSTAL_SUBSCRIPTION].name

    for (let i = 0, length = Object.values(SUBSCRIPTIONS).length; i < length; i++) {
      const targetSubscription = Object.values(SUBSCRIPTIONS)[i]

      if (targetSubscription?.available_features?.includes(feature)) return targetSubscription.name
    }
  }, [feature])

  const showTooltip = () => {
    ReactTooltip.show(elementRef.current)
  }

  const hideTooltip = () => {
    ReactTooltip.hide(elementRef.current)
  }

  const handleTouchEnd = useCallback(() => {
    hideTooltip()

    document.body.removeEventListener('touchstart', handleTouchEnd)
  }, [])

  const handleTouchStart = () => {
    showTooltip()

    document.body.addEventListener('touchstart', handleTouchEnd)
  }

  const navProps = {}
  if (isDisabled) {
    navProps['data-tip'] = `<div>
      <span>${t(`Only for {{targetSubscription}} users`, {
        targetSubscription: linkMinimumSubscription,
      })}.</span> <a style="color: #368bcf!important;text-decoration: underline!important;" href='/'>${t(
      'Upgrade'
    )}</a>
    </div>`
    navProps.onMouseEnter = showTooltip
    navProps.onMouseLeave = hideTooltip
    navProps.onTouchStart = handleTouchStart
  }

  if (dropdownSubItem) {
    return (
      <NavDropdown.Item
        className={isDisabled ? 'isDisabled' : ''}
        as="div"
        ref={elementRef}
        {...navProps}
      >
        <Link className={isDisabled ? 'isDisabled' : ''} to={link}>
          <span>{t(title)}</span>
        </Link>
      </NavDropdown.Item>
    )
  }
  return (
    <Nav.Item ref={elementRef} {...navProps}>
      <Link className={`nav-link ${isDisabled ? 'isDisabled' : ''}`} to={link}>
        <span>{t(title)}</span>
      </Link>
    </Nav.Item>
  )
}

export default HeaderMenuItem
