import React from 'react'
import { Box } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import { CardHeader } from './CardHeader'
import { CardBody } from './CardBody'
import { TYPE } from 'entities/formFields'
import { COMPANIES, ADVISORS } from 'entities/feed'
const CardComponent = (props) => {
  return (
    <Box
      className="Card"
      sx={{
        mb: 4,
        backgroundColor: colorsByKey.defaultBgWeak,
        borderRadius: '8px',
        flex: 1,
      }}
    >
      {props[TYPE] !== COMPANIES && props[TYPE] !== ADVISORS && (
        <CardHeader user={props.userDetail} {...props} />
      )}
      <CardBody {...props} />
    </Box>
  )
}
export const Card = withTranslation()(CardComponent)
