import React from 'react'
import { Box } from '@mui/material'
import { Feed } from 'components'

export const UserChannelSection = ({ userId, tabs, userDetail }) => {
  return (
    <Box flexGrow={1} sx={{ py: 4 }}>
      {userId && <Feed userDetail={userDetail} userId={userId} tabs={tabs} />}
    </Box>
  )
}
