import React from 'react'
import { Route } from 'react-router-dom'
import { Header } from 'components'
import { Container, Stack } from '@mui/material'
import history from '../history'
import { LOGOUT, DETAILS } from 'entities/routes'
import { apiGet } from 'api'
import { normalizeUserData } from 'mixins/dataNormalizers'
class NewAppRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userDetail: null,
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('accessToken')) {
      await apiGet(DETAILS)
        .then(({ data }) => {
          if (data.step >= 4) {
            this.setState({ userDetail: normalizeUserData(data) })
          }
        })
        .catch(() => {
          history.push(LOGOUT)
        })
    }
  }

  render() {
    const { component: Component, hideInnerHeader = false, ...rest } = this.props
    const { userDetail } = this.state

    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <Header userDetail={userDetail} hideInnerHeader={hideInnerHeader}></Header>
              <Container maxWidth={'xl'}>
                <Stack sx={{ marginTop: '110px' }}>
                  <Component userDetail={userDetail} {...props} />
                </Stack>
              </Container>
            </>
          )
        }}
      />
    )
  }
}

export default NewAppRoute
