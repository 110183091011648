import React from 'react'
import { SidebarSection } from './components/SidebarSection'
import { FEED_SIDEBAR_TABS } from 'entities/feed'
import { Box } from '@mui/material'
import { withTranslation } from 'react-i18next'

const FeedSidebar = ({ t, props }) => {
  return (
    <Box sx={{ minWidth: 340, maxWidth: 340 }}>
      {Object.values(FEED_SIDEBAR_TABS).map(({ list, key, sidebarTitle }) => (
        <SidebarSection
          key={`section-${key}`}
          url={list.endpoint}
          method={list.method}
          defaultFilterParams={list.defaultFilterParams}
          type={key}
          title={t(sidebarTitle)}
          props={props}
        />
      ))}
    </Box>
  )
}
export default withTranslation()(FeedSidebar)
