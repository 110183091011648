import React, { useContext, useEffect } from 'react'
import { Button, Typography, Box } from '@mui/material'
import { normalizeData } from 'mixins/dataNormalizers'
import { useList } from 'hooks'
import { withTranslation } from 'react-i18next'
import { colorsByKey } from 'theme/colors'
import { Spinner, Card } from 'components'
import filters from 'mixins/filters'
import { ALL } from 'entities/feed'
import { apiGet } from 'api'
import * as XLSX from 'xlsx'
import { GOLD_SUBSCRIPTION, PLATINUM_SUBSCRIPTION } from 'utils/subscriptions'

import QueryFiltersContext from 'context/queryFiltersContext'

const SectionComponent = ({
  t,
  url,
  method,
  filterParams,
  defaultFilterParams = {},
  type,
  userDetail,
}) => {
  const ctx = useContext(QueryFiltersContext)
  const allFilters = filters()

  const { isLoaded, isLoading, error, data, isFirstFetch, showMore, meta, } = useList({
    url,
    method,
    filters: filterParams,
    defaultFilterParams,
    currentPage: ctx.filters.page,
    type: type || ALL,
  })

  const handleScroll = () => {
     const threshold = 300 // Adjust this threshold as needed
     const { scrollHeight, scrollTop, clientHeight } = document.documentElement
     const distanceToBottom = scrollHeight - scrollTop - clientHeight

     if (
      (window.innerHeight + window.scrollY) >= document.body.offsetHeight-30 && !isLoading
         
     ) {
       console.log("you're at the bottom of the page")
      onShowMore()
     }
  }
  
  const onShowMore = () => {
    ctx.setFilter('page', ctx.filters.page + 1)
  }

  useEffect(() => {
    if (showMore && meta.currentPage != meta.lastPage) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isLoading])

  if (isFirstFetch) {
    return <Spinner />
  }

  if (error) {
    return <div>{error}</div>
  }

  if (!data.length) {
    return (
      <Typography variant="f14" color={colorsByKey.accentTextStrong}>
        {t('no data yet!')}
      </Typography>
    )
  }

  const normalizedData = normalizeData(data)

  const onClickExport = () => {
    
    const emptyFilterValues = ctx.formatFilterData(ctx.defaultFilterValues)
    const appliedFilters = {}
    Object.keys(ctx.savedFilters).forEach((key) => {
      if (ctx.savedFilters[key] && ctx.savedFilters[key] !== emptyFilterValues[key]) {
        appliedFilters[key] = ctx.savedFilters[key]
      }
    })

    apiGet('/api/'+type+'/export', appliedFilters).then(response => {
      if (response.data) {
        const jsonData = response.data.map(company => {
          let expertises = !company.expertises ? [] : company.expertises.map(expertise => expertise.name)
          const services   = !company.user_services ? [] : company.user_services.map(service => service.service_name)
          const languages = !company.languages ? [] : company.languages.map(language => language.name)
          
          if(type === 'advisors') {
            const job_function = company.job_function ? `(${company.job_function})` : ''
            const getAge = company.experience_start_date ? Math.floor((new Date() - new Date(company.experience_start_date).getTime()) / 3.15576e+10) + 'years': ''
            expertises = !company.user_industry_sectors ? [] : company.user_industry_sectors.map(expertise => expertise.name)
            const countries = !company.user_country_of_operations ? [] : company.user_country_of_operations.map(co => co.name)
            return {
              Name: (company.name ?? '')+ job_function,
              Bio: company.bio ?? '',
              Website: company.website ?? '',
              Experience: getAge,
              Country: countries.join(', '),
              Languages: languages.join(', '),
              Expertises: expertises.join(', '),
              '20 LOG': services.join(', ')
            }
          }

          if (userDetail.email === 'bridgewhat@bridgewhat.com') {
            return {
              Name: company.company_name ?? company.name,
              About: company.about_company ?? '',
              Website: company.website ?? '',
              Contact: company.contact_name ?? '',
              phone: company.contact_phone ?? company.phone_number,
              Email: company.company_email ?? company.email,
              Address: company.address1 ?? '',
              Country: company.country_of_operation ?? '',
              Expertises: expertises.join(', '),
              '20 LOG': services.join(', '),
              Employees: company?.no_of_emplyees?.length ? company.no_of_emplyees[0] : '',
              'Turnover (M)': company?.last_year_turnover?.length ? company.last_year_turnover[0] : '',
              'Vat Number': company?.vatnumber ?? ''
            }
          }
          return {
            Name: company.company_name ?? company.name,
            About: company.about_company ?? '',
            Website: company.website ?? '',
            // Contact: company.contact_name ?? '',
            // phone: company.contact_phone ?? company.phone_number,
            // Email: company.company_email ?? company.email,
            // Address: company.address1 ?? ''
            Country: company.country_of_operation ?? '',
            Expertises: expertises.join(', '),
            '20 LOG': services.join(', '),
            Employees: company?.no_of_emplyees?.length ? company.no_of_emplyees[0] : '',
            'Turnover (M)': company?.last_year_turnover?.length ? company.last_year_turnover[0] : ''
          }
        })
        
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        workbook.SheetNames.push(type);

        // Convert JSON data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(jsonData);

        // Add the worksheet to the workbook
        workbook.Sheets[type] = worksheet;

        // Create an array buffer from the workbook
        const arrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Convert array buffer to Blob
        const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = type+'.xlsx';
        document.body.appendChild(a);
        a.click();

        // Cleanup
        window.setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    })
  }

  return (
    <div>
      {!isLoaded && isLoading && <Spinner customStyles={{ my: 4 }} />}
      {isLoaded && (
        <>
          {meta.total && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Typography variant="f14" color={colorsByKey.accentTextStrong}>
                {`${meta.total} ${t('results')}`}
                {([GOLD_SUBSCRIPTION, PLATINUM_SUBSCRIPTION].includes(userDetail.subscription) || userDetail.email === 'bridgewhat@bridgewhat.com') && ['advisors', 'companies'].includes(type) && 
                  <Button onClick={onClickExport}>
                    Export
                  </Button>
                }
              </Typography>
            </Box>
          )}
          {normalizedData.map((item, idx) => {
            return (
              <Card
                userDetail={userDetail}
                {...item}
                key={`Card-${item.type}-${idx}`}
                filters={allFilters}
              />
            )
          })}

          {isLoaded && isLoading && <Spinner customStyles={{ my: 4 }} />}
          {/* {showMore && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 4 }}>
              <Button
                type="button"
                variant="outlined"
                disabled={isLoading}
                onClick={onShowMore}
                sx={{ color: colorsByKey.accentTextStrong }}
              >
                <Typography variant="f12" color={colorsByKey.accentTextStrong}>
                  {t('Show more')}
                </Typography>
              </Button>
            </Box>
          )} */}
        </>
      )}
    </div>
  )
}
export const Section = withTranslation()(SectionComponent)
