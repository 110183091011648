import React from 'react'
import Certificationawards from '../component/profile/certificationawards'

class Awardspage extends React.Component {
  render() {
    return (
      <div>
        <Certificationawards
          pagetitle="Awards"
          title="Award"
          listendpoint="/api/listAwards"
          updateendpoint="/api/editAward"
          addendpoint="/api/addAward"
          editendpoint="/api/editAward"
          delendpoint="/api/deleteAward"
        ></Certificationawards>
      </div>
    )
  }
}
export default Awardspage
