import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiGet, apiPost, apiPut, apiDelete } from 'api'
import useDeepCompareEffect from 'use-deep-compare-effect'
import Form from 'screens/User/sections/Profile/components/Form'
import { credentialsSections } from 'entities/companyFields'
import { EXPERTISES, EDUCATION } from 'entities/company'
import { withTranslation } from 'react-i18next'
import { IconData } from 'components'
import { faPlusCircle, faSave, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { Spinner } from 'components'
import { Box, Typography, Button, Container } from '@mui/material'
import { colorsByKey } from 'theme/colors'

const CredentialsScreen = ({ t }) => {
  const { section } = useParams()

  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState([])

  const setItemsData = (data) => {
    setIsLoaded(true)
    setItems(data)
  }

  useDeepCompareEffect(() => {
    const fetchList = async () => {
      setIsLoading(true)
      apiGet(credentialsSections[section].list)
        .then(({ data }) => {
          const normalizedData = credentialsSections[section].dataNormalizer(data)
          const formattedData = normalizedData.map((d) => ({
            ...d,
            publish: d.user_status === 'publish' ? true : false,
          }))
          setItemsData(formattedData)
        })
        .finally(() => setIsLoading(false))
    }
    fetchList()
  }, [credentialsSections, section])

  const addItem = () => {
    const newItems = items.concat([credentialsSections[section].defaultItem])
    // const newItems = [credentialsSections[section].defaultItem].concat(items)
    // setItems([credentialsSections[section].defaultItem, ...items])
    setItems(newItems)
  }
  const handleSave = (item, idx) => {
    setIsLoading(true)
    const formData = formRef.current.getFormData()
    const formattedData = {
      ...formData,
      // user_status: formData.user_status === true ? 'publish' : 'unpublish',
      // admin_status: 'approved',
    }
    if (item.id) {
      // EDIT ITEM
      if (section === EXPERTISES || section === EDUCATION) {
        apiPut(`${credentialsSections[section].edit}/${item.id}`, { ...formattedData })
          .then(({ data }) => {
            let newItems = items
            newItems[idx] = data
            setItems(newItems)
          })
          .finally(() => setIsLoading(false))
      } else {
        apiPost(credentialsSections[section].create, { ...formattedData, id: item.id })
          .then(({ success }) => {
            let newItems = items
            newItems[idx] = success
            setItems(newItems)
          })
          .finally(() => setIsLoading(false))
      }
    } else {
      // CREATE ITEM
      apiPost(credentialsSections[section].create, formattedData)
        .then(({ data }) => {
          let newItems = items
          newItems[idx] = data
          setItems(newItems)
        })
        .finally(() => setIsLoading(false))
    }
  }
  const handleDelete = (item, idx) => {
    setIsLoading(true)

    if (item.id) {
      // DELETE
      if (section === EXPERTISES || section === EDUCATION) {
        apiDelete(`${credentialsSections[section].delete}/${item.id}`)
          .then((response) => {
            const newItems = items.filter(({ id }) => id !== item.id)
            setItems(newItems)
          })
          .finally(() => setIsLoading(false))
      } else {
        apiPost(credentialsSections[section].delete, { id: item.id })
          .then((response) => {
            const newItems = items.filter(({ id }) => id !== item.id)
            setItems(newItems)
          })
          .finally(() => setIsLoading(false))
      }
    } else {
      // CANCEL
      const newItems = items.filter((item, index) => index !== idx)
      setItems(newItems)
      setIsLoading(false)
    }
  }
  const formRef = useRef(null)

  return (
    <Container maxWidth={'xl'} sx={{ py: 4, background: colorsByKey.defaultBgWeaker }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="f20" sx={{ display: 'inline-block' }}>
          {credentialsSections[section].title}
        </Typography>
        <Box>
          <Button
            onClick={addItem}
            variant="contained"
            sx={{
              ml: 2,
              borderRadius: '48px !important',
              backgroundColor: colorsByKey.accentBg,
            }}
          >
            <IconData
              title={t('Add More')}
              icon={faPlusCircle}
              titleVariant="f14"
              margin={1}
              color={colorsByKey.utility}
              sx={{ padding: '6px 8px' }}
            />
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: '100%', py: 4 }}>
        {!isLoaded && isLoading && <Spinner customStyles={{ my: 4 }} />}
        {isLoaded && (
          <>
            {!(items || []).length && (
              <Typography variant="f16" sx={{ display: 'inline-block' }}>
                {t('no data yet!')}
              </Typography>
            )}
            {items.map((item, idx) => {
              return (
                <Box
                  sx={{
                    backgroundColor: colorsByKey.defaultBg,
                    borderRadius: '6px',
                    boxShadow: '2px 0px 8px rgba(0,0,0,0.10)',
                    position: 'relative',
                    display: 'flex',
                    p: 4,
                    mb: 4,
                  }}
                  key={idx}
                >
                  <Box sx={{ flex: 1 }}>
                    <Form
                      ref={formRef}
                      postData={item}
                      formFields={credentialsSections[section].fields}
                      requiredFields={credentialsSections[section].requiredFields}
                      disabled={isLoading}
                    />
                    <Box sx={{ display: 'flex', mt: 4 }}>
                      <Button
                        onClick={() => handleSave(item, idx)}
                        variant="contained"
                        disabled={isLoading}
                        sx={{
                          borderRadius: '48px !important',
                          backgroundColor: colorsByKey.accentBg,
                        }}
                      >
                        <IconData
                          title={t('Save')}
                          icon={faSave}
                          titleVariant="f14"
                          margin={1}
                          color={colorsByKey.utility}
                          sx={{ padding: '6px 8px' }}
                        />
                      </Button>
                      <Button
                        onClick={() => handleDelete(item, idx)}
                        variant="contained"
                        disabled={isLoading}
                        sx={{
                          ml: 2,
                          borderRadius: '48px !important',
                          backgroundColor: item.id
                            ? colorsByKey.alertBorder
                            : colorsByKey.defaultTextDisabled,
                        }}
                      >
                        <IconData
                          title={item.id ? t('Delete') : t('Cancel')}
                          icon={faTrashAlt}
                          titleVariant="f14"
                          margin={1}
                          color={colorsByKey.utility}
                          sx={{ padding: '6px 8px' }}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </>
        )}
      </Box>
    </Container>
  )
}
export default withTranslation()(CredentialsScreen)
