import { colorsByKey } from './colors'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  // typography: {
  //   fontFamily: 'DM+Serif+Display',
  // },
  palette: {
    primary: {
      main: colorsByKey.primary,
    },
    secondary: {
      main: colorsByKey.secondary,
    },
    tertiary: {
      main: colorsByKey.tertiary,
    },
    black: {
      main: colorsByKey.black,
    },
    white: {
      main: colorsByKey.white,
    },
    blue: {
      main: colorsByKey.blue,
    },
    greyLight: {
      main: colorsByKey.greyLight,
    },
  },
  //   status: {
  //     danger: "orange",
  //   },
  spacing: 8,
  components: {
    MuiTypography: {
      defaultProps: {
        // fontFamily: 'DM+Serif+Display',
        variantMapping: {
          // TOD0: assign a tag to the typo
          //   h1: "h2",
        },
      },
    },
  },
  typography: {
    fontSize: 14,
    f24: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '24px',
      display: 'block',
    },
    f20: {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '24px',
      display: 'block',
    },
    f16: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      display: 'block',
    },
    f14: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '17px',
      display: 'block',
    },
    f12: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '18px',
      display: 'block',
    },
    f10: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: '12px',
      display: 'block',
    },
    link: {
      color: createTheme.accentBg,
      fontWeight: 400,
      fontSize: 12,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
})
