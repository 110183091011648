import React from 'react'
import SidebarPostCard from './SidebarPostCard'
import { colorsByKey } from 'theme/colors'
import { Typography, Box } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { Spinner } from 'components'

const SidebarPostsList = ({ t, title, items, isLoading }) => {
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="f20" color={colorsByKey.accentTextStrong} sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Box>

      {isLoading && <Spinner />}
      {!isLoading &&
        items.map((item, idx) => {
          return <SidebarPostCard key={`sidebar-post-card-${idx}`} {...item} />
        })}
    </>
  )
}
export default withTranslation()(SidebarPostsList)
