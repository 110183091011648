import {
  Autocomplete,
  TextField,
  Stack,
  Grid,
  Box,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Chip,
  Typography,
} from '@mui/material'
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import moment from 'moment'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'

import { withTranslation } from 'react-i18next'
import { colorsByKey } from 'theme/colors'
import FileUploader2 from 'component/form/FileUploader2'
import filters from 'mixins/filters'
import { Spinner } from 'components'
import { CAMPAIGNS_BLOCKS } from 'entities/campaignExtraFields'

const Form = forwardRef(({ t, entity, postData, showDialog }, ref) => {
  const Filters = filters()

  const { formFields: createFormFields } = entity.create

  const formFields = createFormFields.map((p) =>
    p.optionType ? { ...p, options: Filters[p.optionType] } : p
  )

  const [disableChanges, setDisableChanges] = useState(false)
  const [campaignScore, setCampaignScore] = useState(0)
  const [submitted, setSubmitted] = useState(false)

  let blocksDisplayObject = {}
  CAMPAIGNS_BLOCKS.forEach(({ key, display }) => {
    blocksDisplayObject[key] = display
  })
  const [blocksDisplay, setBlocksDisplay] = useState(blocksDisplayObject)

  const [values, setValues] = useState(
    createFormFields.reduce((acc, field) => {
      let initialValue = field.defaultValue ?? ''
      if (field.type === 'number') initialValue = 1
      else if (field.type === 'checkbox') initialValue = false
      else if (field.type === 'select' || field.type === 'files') initialValue = []
      else if (field.type === 'autocomplete') {
        initialValue = []
        field.options = Filters.companies
      }
      else if (field.type === 'checkboxGroups') {
        field.groups.forEach((fg) => {
          fg.fields.forEach((fgf) => {
            initialValue = false
            acc = { ...acc, [fg.key]: { ...acc[fg.key] ?? {}, [fgf.key]: initialValue } }
          })
        })
        return acc
      }
      return { ...acc, [field.key]: initialValue }
    }, {})
  )

  const updateCampaignScore = (value) => {
    let score = campaignScore
    score += value

    setCampaignScore(score)
  }

  const handleChange =
    (prop, type, theValues = values, score = 0) =>
      (eventOrValue) => {
        if (eventOrValue?.target?.value?.scorePoints >= 0) {
          let prevScore = theValues[prop].scorePoints ?? 0
          if (type === 'select') {
            score -= prevScore
          }
          score += eventOrValue.target.value.scorePoints
        }
        if (score !== 0) {
          updateCampaignScore(score)
        }
        if (!disableChanges) {
          if (prop === 'discounts') {
            Object.keys(theValues[prop]).map((item) => {
              if (item === eventOrValue.target.value) {
                theValues[prop][item] = true
              } else {
                theValues[prop][item] = false
              }
            })
            return setValues(theValues)
          }
          setValues({ ...theValues, [prop]: eventOrValue?.target?.value ?? eventOrValue })
        }
      }

  useDeepCompareEffect(() => {
    // Fill form with data only when mounted
    setTimeout(() => {
      if (Object.keys(postData || []).length > 0) {
        setDisableChanges(true)
        setTimeout(() => setDisableChanges(false), 2000)
        setValues(
          formFields.reduce((acc, field) => {
            let value = values[field.key]
            let postValue = postData[field.key]
            if (field.key === 'bw20logs_services' || field.key === 'bw20log_services') {
              postValue = postValue || postData.bw20logsServices || postData.leversOfGrowth
            }
            if (postValue !== undefined && postValue !== null) {
              if (field.type === 'select') {
                const toOption = (v) => ({ id: v.id, key: v.key, name: v.name || v.service_name })
                if (field.multiple) {
                  value = postValue
                } else if (postValue.id || postValue.key) {
                  value = toOption(postValue)
                }
                value = postValue
              } else if (field.type === 'files') {
                if (Array.isArray(postValue)) {
                  value = (postValue || []).map((v) => ({ ...v, status: 'uploaded' }))
                } else {
                  value = [{ ...postValue, status: 'uploaded' }]
                }
              } else if (field.type === 'date') {
                value = moment(postValue).format('YYYY-MM-DD')
              } else {
                value = postValue
              }
            }
            return { ...acc, [field.key]: value }
          }, {})
        )
      }
    }, 200)
  }, [postData])

  const isFieldRequired = (field) => {
    return entity.create.requiredFields.includes(field.key)
  }

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      setSubmitted(true)
      const fieldsWithError = []
      entity.create.requiredFields.forEach((requiredField) => {
        const v = values[requiredField]
        if (v === '' || v === undefined || v === null || (Array.isArray(v) && v.length === 0)) {
          fieldsWithError.push(createFormFields.find((f) => f.key === requiredField).label)
        }

        if(requiredField === 'discounts' && !Object.values(v).find(discount => discount)){
          fieldsWithError.push(createFormFields.find((f) => f.key === requiredField).label)
        }
      })

      if ((values['bwParticipantExamples'] === '' || values['bwParticipantExamples'] === undefined || values['bwParticipantExamples'] === null || (Array.isArray(values['bwParticipantExamples']) && values['bwParticipantExamples'].length === 0)) &&
        (values['companyExample'] === '' || values['companyExample'] === undefined || values['companyExample'] === null || (Array.isArray(values['companyExample']) && values['companyExample'].length === 0))) {
        fieldsWithError.push('Target Company(ies) example(s)')
      }
      if (fieldsWithError.length > 0) {
        const fieldsStr = fieldsWithError.join(', ')
        return {
          errorMessage: `Please, fill in the following fields that are required: ${fieldsStr}`,
        }
      }
      const extract = (
        value,
        keyExtractor,
        type,
        isMultiple = false,
        maxFiles = 1,
        toServerBooleanYesNo = false
      ) => {
        if (value === undefined || value === null || type === 'checkboxGroups') {
          return value
        } else if (type === 'number') {
          return parseInt(value, 10)
        } else if (toServerBooleanYesNo) {
          return value ? 'Yes' : 'No'
        } else if (Array.isArray(value)) {
          if (type === 'files' && value.some((f) => f.id < 0)) {
            return extract(
              value.filter((f) => f.id < 0),
              keyExtractor,
              type,
              isMultiple,
              maxFiles
            )
          } else if (isMultiple) {
            return value.map((v) => extract(v, keyExtractor))
          } else {
            return extract(value[0], keyExtractor, type)
          }
        }
        return keyExtractor ? value[keyExtractor] : value
      }
      const formData = formFields.reduce(
        (acc, field) => {
          if (field.type === 'autocomplete') {
            return { ...acc, [field.backendKey]: values[field.key].map(val => val.name).join(';') }
          }
          else if (field.type === 'checkboxGroups') {
            field.groups.forEach(group => {
              if (group.key === 'discounts') {
                acc = { ...acc, [group.backendKey]: Object.keys(values[group.key]).filter((key) => values[group.key][key])[0] ?? '' }
              } else {
                acc = { ...acc, [group.backendKey]: Object.keys(values[group.key]).filter((key) => values[group.key][key]) }
              }
            })
            return acc
          } else if (field.type === 'inputGroup') {
            field.fields.forEach(innerField => {
              acc = {
                ...acc,
                [innerField.backendKey]: extract(
                  values[innerField.key],
                  innerField.toServerKeyExtractor,
                  innerField.type,
                  innerField.multiple,
                  innerField.maxFiles,
                  innerField.toServerBooleanYesNo
                ),
              }
            })
            return acc
          }

          return {
            ...acc,
            [field.backendKey]: extract(
              values[field.key],
              field.toServerKeyExtractor,
              field.type,
              field.multiple,
              field.maxFiles,
              field.toServerBooleanYesNo
            ),
          }
        },
        {}
      )
      Object.entries(entity.create.hiddenFields || {}).forEach(([k, v]) => (formData[k] = v))
      formData['campaignScore'] = (campaignScore >= 9.5 && campaignScore < 10) ? Math.round(campaignScore) : campaignScore
      return formData
    },
  }))

  const handleUploadFileError = (msg) => {
    showDialog(({ close }) => ({
      title: 'Error',
      text: msg,
      actions: [{ text: 'Ok', action: () => close() }],
    }))
  }
  const toggleBlockDisplay = (block) => {
    setBlocksDisplay({ ...blocksDisplay, [block]: !blocksDisplay[block] })
  }

  return (
    <Stack className="Form">
      {submitted && Math.round(campaignScore) < 10 && <div className={`scoring-message ${campaignScore >= 7 ? 'warning' : ''}`}>
        <span>Your scoring is below 10. You need 10 or more points to submiit a Campaign</span>
      </div>}
      <Box component="form" autoComplete="off">
        {Filters.fetchingFilters && <Spinner />}
        {!Filters.fetchingFilters &&
          CAMPAIGNS_BLOCKS.map(({ key: blockKey, label: blockLabel, blockTitle, display }) => {
            return (
              <Box key={blockKey} sx={{ mb: 4 }}>
                {blockLabel && (
                  <Box
                    sx={{
                      backgroundColor: colorsByKey.primaryBgWeak,
                      color: colorsByKey.defaultText,
                      px: 2,
                      py: 1,
                      borderRadius: '8px',
                      mb: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    }}
                    onClick={() => toggleBlockDisplay(blockKey)}
                  >
                    <Typography>{blockLabel}</Typography>
                    <FontAwesomeIcon
                      icon={blocksDisplay[blockKey] ? faChevronUp : faChevronDown}
                      color={colorsByKey.defaultText}
                      size="sm"
                    />
                  </Box>
                )}
                {blockTitle && (
                  <h3 className='block-title' dangerouslySetInnerHTML={{ __html: blockTitle }} />
                )}
                {/* {blockKey === 'others' && (
                  <Box sx={{ borderTop: `2px solid ${colorsByKey.primaryBgWeak}`, pt: 4, mt: 2 }} />
                )} */}
                {blocksDisplay[blockKey] && (
                  <Grid
                    style={{
                      backgroundColor: colorsByKey.white,
                    }}
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    {formFields
                      .filter(({ groupBy }) => groupBy === blockKey)
                      .map((field, idx) => (
                        <>
                          {field.key == 'bwParticipantExamples' && (
                            <>
                              <div style={{ display: 'block', width: '100%' }} />
                              {field.title && <h4 className='group-title' style={{ marginLeft: '25px', marginTop: '25px' }}>{field.title}</h4>}
                              <div style={{ display: 'block', width: '100%' }} />
                            </>
                          )}
                          <Grid
                            item
                            xs={field.grid || 12}
                            sm={field.grid || 12}
                            md={field.grid || 12}
                            key={`${blockKey} - ${idx}`}
                          >
                            <Box>
                              {field.type === 'separator' && (
                                <Box sx={{ borderTop: `2px solid ${colorsByKey.primaryBgWeak}`, pt: 4, mt: 2 }} />
                              )}
                              {field.type === 'autocomplete' && (
                                <Autocomplete
                                  fullWidth
                                  multiple
                                  disabled={field?.options?.length === 0}
                                  options={field?.options || []}
                                  getOptionLabel={(option) => option?.name}
                                  value={values[field.key] ?? []}
                                  onChange={(event, newValue) => {
                                    handleChange(field.key, field.type, values)(newValue?.company_name || newValue)
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      required={isFieldRequired(field)}
                                      label={field.label}
                                    />
                                  )}
                                />
                              )}
                              {field.type === 'text' && (
                                <FormControl
                                  required={isFieldRequired(field)}
                                  fullWidth
                                  sx={{ m: 0 }}
                                >
                                  <InputLabel htmlFor={field.key}>{field.label}</InputLabel>
                                  <OutlinedInput
                                    id={field.key}
                                    value={values[field.key]}
                                    onChange={handleChange(field.key, field.type, values)}
                                    startAdornment=""
                                    label={field.label}
                                    type={field.type}
                                  />
                                </FormControl>
                              )}
                              {field.type === 'date' && (
                                <FormControl
                                  required={isFieldRequired(field)}
                                  fullWidth
                                  sx={{ m: 0 }}
                                >
                                  <InputLabel htmlFor={field.key} shrink>
                                    {field.label}
                                  </InputLabel>
                                  <OutlinedInput
                                    id={field.key}
                                    value={values[field.key]}
                                    onChange={handleChange(field.key, field.type, values)}
                                    startAdornment=""
                                    label={field.label}
                                    type={field.type}
                                  />
                                </FormControl>
                              )}
                              {field.type === 'checkboxGroups' && (
                                <>
                                  {field.label && (<h4 className='group-title'>{field.label}</h4>)}
                                  {field.groups && field.groups.map(group => (
                                    <>
                                      {group.label && (<h5 className='group-subtitle'>{group.label}</h5>)}
                                      <div className={group.key === 'discounts' ? 'd-flex horizontal-checkboxes' : ''}>

                                        {group.key === 'discounts' && (<RadioGroup name={group.key} onChange={(event) => handleChange(group.key, group.type, values, group.fields.find(f => f.key === Object.keys(values[group.key]).find(key => values[group.key][key] === true))?.scorePoints ? (group.fields.find(f => f.key === event.target.value).scorePoints - group.fields.find(f => f.key === Object.keys(values[group.key]).find(key => values[group.key][key] === true))?.scorePoints) : group.fields.find(f => f.key === event.target.value).scorePoints)(event)}>
                                          {group.fields && group.fields.map((groupField, idx) => (
                                            <FormGroup>
                                              <FormControlLabel
                                                control={<Radio checked={values[groupField.label][idx]} sx={{
                                                  color: "#6E798C",
                                                  '&.Mui-checked': {
                                                    color: "#358FEB",
                                                  },
                                                }} required={isFieldRequired(group.key)} />}
                                                name={groupField.key}
                                                value={groupField.key}
                                                label={groupField.name}
                                              />
                                            </FormGroup>
                                          ))}
                                        </RadioGroup>)}
                                        {group.key !== 'discounts' && (
                                          <>{group.fields && group.fields.map((groupField, idx) => (
                                            <FormGroup>
                                              <FormControlLabel
                                                control={<Checkbox checked={values[groupField.label][idx]} sx={{
                                                  color: "#6E798C",
                                                  '&.Mui-checked': {
                                                    color: "#358FEB",
                                                  },
                                                }} />}
                                                name={groupField.key}
                                                label={groupField.name}
                                                onChange={(event) =>
                                                  handleChange(
                                                    group.key,
                                                    field.type,
                                                    values, event.target.checked ? groupField.scorePoints : - groupField.scorePoints
                                                  )({ ...values[group.key], [groupField.key]: event.target.checked })
                                                }
                                              />
                                            </FormGroup>
                                          ))}</>
                                        )}

                                      </div>
                                      {group.key === 'discounts' && (
                                        <div className='campaign-scoring'>
                                          <div className='dashed-row'></div>
                                          <div className={`scoring ${Math.round(campaignScore) >= 10 ? 'ok' : campaignScore >= 7 ? 'warning' : ''}`}>
                                            <h1 className='score'><strong>TOTAL SCORING</strong> <span>{ (campaignScore >= 9.5 && campaignScore < 10) ? Math.round(campaignScore).toFixed(1) : campaignScore.toFixed(1)}<sub>/20</sub></span></h1>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                              {field.type === 'inputGroup' && (
                                <>
                                  {field.label && (<h4 className='group-title'>{field.label}</h4>)}
                                  {field.fields && field.fields.map((groupField, idx) => {

                                    return groupField.type === 'select' ? (
                                      <FormGroup className="fix-spaces-in-form">
                                        <FormControl
                                          required={isFieldRequired(groupField)}
                                          fullWidth
                                          sx={{ m: 0 }}
                                        >
                                          <InputLabel id={`${groupField.key}-label`}>{groupField.label}</InputLabel>
                                          <Select
                                            id={groupField.key}
                                            labelId={`${groupField.key}-label`}
                                            label={groupField.label}
                                            value={values[groupField.key]}
                                            multiple={groupField.multiple}
                                            onChange={handleChange(groupField.key, groupField.type, values)}
                                            input={
                                              <OutlinedInput id="select-multiple-chip" label={groupField.label} />
                                            }
                                            renderValue={(selected) => (
                                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {groupField.multiple &&
                                                  selected.map((value, idx) => (
                                                    <Chip
                                                      key={`selected-option-${groupField.key}-${value.id}-${idx}`}
                                                      label={value.name}
                                                    />
                                                  ))}
                                                {!groupField.multiple && (
                                                  <Chip
                                                    key={`selected-option-${groupField.key}-${selected.id}`}
                                                    label={selected.name}
                                                  />
                                                )}
                                              </Box>
                                            )}
                                          >
                                            {!groupField.multiple && (
                                              <MenuItem value="">
                                                <em>-</em>
                                              </MenuItem>
                                            )}
                                            {groupField.options.map((option, idx) => {
                                              return (
                                                <MenuItem
                                                  value={option}
                                                  key={`select-option-${groupField.key}-${option.name}-${idx}`}
                                                >
                                                  {option.name}
                                                </MenuItem>
                                              )
                                            })}
                                          </Select>
                                        </FormControl></FormGroup>
                                    ) : (
                                      <FormGroup className="fix-spaces-in-form">
                                        <FormControl
                                          required={isFieldRequired(groupField)}
                                          fullWidth
                                          sx={{ m: 0 }}
                                        >
                                          <InputLabel htmlFor={groupField.key}>{groupField.label}</InputLabel>
                                          <OutlinedInput
                                            id={groupField.key}
                                            value={values[groupField.key]}
                                            rows={3}
                                            multiline
                                            onChange={handleChange(groupField.key, groupField.type, values)}
                                            startAdornment=""
                                            label={groupField.label}
                                            type={groupField.type}
                                          />
                                        </FormControl>
                                      </FormGroup>
                                    )
                                  })}
                                </>
                              )}
                              {field.type === 'checkbox' && (
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox checked={values[field.key]} />}
                                    label={field.label}
                                    onChange={(event) =>
                                      handleChange(
                                        field.key,
                                        field.type,
                                        values
                                      )(event.target.checked)
                                    }
                                  />
                                </FormGroup>
                              )}
                              {field.type === 'select' && (
                                <FormControl
                                  required={isFieldRequired(field)}
                                  fullWidth
                                  sx={{ m: 0 }}
                                >
                                  <InputLabel id={`${field.key}-label`}>{field.label}</InputLabel>
                                  <Select
                                    id={field.key}
                                    labelId={`${field.key}-label`}
                                    label={field.label}
                                    value={values[field.key]}
                                    multiple={field.multiple}
                                    onChange={handleChange(field.key, field.type, values)}
                                    input={
                                      <OutlinedInput id="select-multiple-chip" label={field.label} />
                                    }
                                    renderValue={(selected) => (
                                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {field.multiple &&
                                          selected.map((value, idx) => (
                                            <Chip
                                              key={`selected-option-${field.key}-${value.id}-${idx}`}
                                              label={value.name}
                                            />
                                          ))}
                                        {!field.multiple && (
                                          <Chip
                                            key={`selected-option-${field.key}-${selected.id}`}
                                            label={selected.name}
                                          />
                                        )}
                                      </Box>
                                    )}
                                  >
                                    {!field.multiple && (
                                      <MenuItem value="">
                                        <em>-</em>
                                      </MenuItem>
                                    )}
                                    {field.options.map((option, idx) => {
                                      return (
                                        <MenuItem
                                          value={option}
                                          key={`select-option-${field.key}-${option.name}-${idx}`}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      )
                                    })}
                                  </Select>
                                </FormControl>
                              )}
                              {field.type === 'files' && (
                                <FileUploader2
                                  label={field.label}
                                  files={values[field.key]}
                                  onSetFiles={handleChange(field.key, field.type, values)}
                                  maxFiles={field.maxFiles}
                                  accept={field.accept}
                                  onError={handleUploadFileError}
                                />
                              )}
                              <div>{t(field.hint)}</div>
                            </Box>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                )}
              </Box>
            )
          })}
      </Box>
    </Stack>
  )
})
export default withTranslation(undefined, { withRef: true })(Form)
