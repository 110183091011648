import React from 'react'
import axios from 'axios'
import countries from 'utils/countries'
import Button from '@material-ui/core/Button'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Row } from 'react-bootstrap'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheckCircle,
  faExclamationCircle,
  faAngleRight,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons'
library.add(faCheckCircle, faExclamationCircle, faAngleRight, faAngleLeft)

class SBAregistrationsteptwoform extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        state: '',
        city: '',
        postal_code: '',
        address1: '',
        payment_account_swift: '',
        payment_account_iban: '',
        company_legal_name: '',
        country: null,
        vatnumber: '',
      },
      country_of_selected: '',
      submitted: false,
      showHide: false,
      loading: true,
      vatvalidation: 'nosubmit',
      countries: countries(),
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateVatnumber = this.validateVatnumber.bind(this)
  }
  async componentDidMount() {
    if (this.props.userDetail) {
      const success = this.props.userDetail
      const formData = {
        state: success.state,
        city: success.city,
        postal_code: success.postal_code,
        address1: success.address1,
        address2: success.address2,
        payment_account_swift: success.payment_account_swift,
        payment_account_iban: success.payment_account_iban,
        company_legal_name: success.company_legal_name,
        country: success.country,
        vatnumber: success.vatnumber,
      }

      if (success.vatnumber) {
        this.setState({ vatvalidation: 'valid' })
      }
      this.setState({ formData })
    }

    const page_api_url = process.env.REACT_APP_API_URL + '/api/pages/2'
    await axios
      .get(page_api_url)
      .then((response) => {
        // handle success
        const { data } = response.data
        this.setState({ policypage: data, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleCountryOperation = (event, value) => {
    const { formData } = this.state
    formData['country'] = value
    if (value !== null) {
      this.setState({ country_of_selected: 1 })
    } else {
      this.setState({ country_of_selected: 0 })
    }
    this.setState({ formData })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
    if (event.target.name === 'vatnumber') {
      this.setState({ vatvalidation: 'nosubmit' })
    }
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/addSbaRegistrationForm2'

      axios
        .post(sector_api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          // handle success
          this.props.nextStep(3)
          this.setState({ submitted: false })
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, error: data.error })
              }
            } else {
              alert(this.props.t('Something went wrong, please try again'))
            }
          }
        })
    })
  }

  validateVatnumber() {
    if (this.state.formData.vatnumber != '') {
      this.setState({ vatvalidation: 'checking' })

      const vat_api_url = process.env.REACT_APP_API_URL + '/api/uniqueVatnumber'
      axios
        .post(vat_api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          const data = response.data
          if (data.valid) {
            var NewformData = { ...this.state.formData }
            NewformData.company_legal_name = data.company_name
            this.setState({ vatvalidation: 'valid', formData: NewformData })
          } else {
            if(data.type == 'optional') {
              this.setState({vatvalidation: 'optional'})
              return
            }
            this.setState({ vatvalidation: 'Notvalid' })
          }
        })
        .catch((error) => {
          this.setState({ vatvalidation: 'AlreadyExist' })
        })
    } else {
      console.log(this.props.t('Vat number is empty'))
    }
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }
    return (
      <div>
        <div className="boxshadow p-3 pt-1 mb-5">
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <Row>
              <div className="col-md-12">
                <h6 className="pb-3">{t('Detailed Individual/Company Information')}</h6>
              </div>
              <div className="col-md-12">
                <Row>
                  <div className="col-md-12">
                    <label>{t('VAT Number')} (e.g. PT51... or ESB...)</label>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <TextValidator
                        variant="outlined"
                        margin="0"
                        fullWidth
                        type="text"
                        name="vatnumber"
                        onChange={this.handleChange}
                        value={formData.vatnumber}
                        validators={['required']}
                        errorMessages={t('This field is required')}
                      />

                      {this.state.vatvalidation === 'AlreadyExist' && (
                        <p className="text-danger">Vat number already exist</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group w-100 float-left">
                      <button
                        type="button"
                        className="submit-btn btn-check mr-3"
                        onClick={this.validateVatnumber}
                      >
                        {this.state.vatvalidation === 'checking'
                          ? 'Validating'
                          : 'Validate Vat Number'}
                      </button>
                      {this.state.vatvalidation === 'valid' && (
                        <div className="valid-vat">
                          <FontAwesomeIcon icon={faCheckCircle} /> {t('Vat Number is Vaild')}
                        </div>
                      )}
                      {this.state.vatvalidation === 'Notvalid' || this.state.vatvalidation === 'optional' && (
                        <div className="notvalid-vat">
                          <FontAwesomeIcon icon={faExclamationCircle} />
                          {t('Vat Number is not valid')}
                        </div>
                      )}
                    </div>
                  </div>
                </Row>
              </div>
              <div className="col-md-12">
                {this.state.vatvalidation === 'valid' && (
                  <div className="form-group">
                    <label>
                      {t('Company Legal Name')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="company_legal_name"
                      onChange={this.handleChange}
                      value={formData.company_legal_name}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                )}
              </div>
            </Row>
            <hr className="mt-4"></hr>
            {this.state.vatvalidation === 'valid' && (
              <Row>
                <div className="col-12">
                  <h6 className="pb-3 mt-1">{t('Payment Account Details')}</h6>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {t('IBAN')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="payment_account_iban"
                      onChange={this.handleChange}
                      value={formData.payment_account_iban}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {t('BIC/SWIFT')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="payment_account_swift"
                      onChange={this.handleChange}
                      value={formData.payment_account_swift}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </div>
              </Row>
            )}
            {this.state.vatvalidation === 'valid' && <hr className="mt-4"></hr>}
            {this.state.vatvalidation === 'valid' && (
              <Row>
                <div className="col-12">
                  <h6 className="pb-3 mt-1">{t('Billing Address')}</h6>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {t('Address 1')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="address1"
                      onChange={this.handleChange}
                      value={formData.address1}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{t('Address 2 (Optional)')}</label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="address2"
                      onChange={this.handleChange}
                      value={formData.address2}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      {t('Postal Code')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="postal_code"
                      onChange={this.handleChange}
                      value={formData.postal_code}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>{t('State (Optional)')}</label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="state"
                      onChange={this.handleChange}
                      value={formData.state}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      {t('City')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="city"
                      onChange={this.handleChange}
                      value={formData.city}
                      validators={['required']}
                      errorMessages={t('This field is required')}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      {t('Country')} <sup>*</sup>
                    </label>
                    <Autocomplete
                      options={this.state.countries}
                      getOptionLabel={(option) => option.name}
                      name="country"
                      selected={formData.country_selected}
                      onChange={this.handleCountryOperation}
                      renderInput={(params) => (
                        <TextValidator
                          Select
                          {...params}
                          fullWidth
                          variant="outlined"
                          name="country_selected"
                          placeholder="Country"
                          value={this.state.country_of_selected}
                          validators={['required']}
                          errorMessages={t('This field is required')}
                        />
                      )}
                    />
                  </div>
                </div>
              </Row>
            )}
            {this.state.vatvalidation === 'valid' && (
              <Row>
                <div className="col-md-12">
                  <div className="form-group text-center d-flex justify-content-center">
                    <Button
                      type="button"
                      variant="contained"
                      className="submit-btn ml-0 mr-3"
                      onClick={() => this.props.prevStep(1)}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                      &nbsp;{t('Prev Step')}
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      className="submit-btn ml-3 mr-0"
                      disabled={submitted || !['valid', 'optional'].includes(this.state.vatvalidation)}
                    >
                      {(submitted && t('Your form is submitted!')) ||
                        (!submitted && t('Next Step'))}
                      &nbsp;
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                  </div>
                </div>
              </Row>
            )}
          </ValidatorForm>
        </div>
      </div>
    )
  }
}
export default withTranslation()(SBAregistrationsteptwoform)
