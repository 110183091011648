import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  OutlinedInput,
  Select,
  MenuItem,
  Avatar,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { colorsByKey } from 'theme/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useDeepCompareEffect from 'use-deep-compare-effect'
import FileUploader2 from 'component/form/FileUploader2'
import { faTrashAlt, faCloudUploadAlt } from '@fortawesome/pro-light-svg-icons'

const Field = forwardRef(
  (
    {
      t,
      isRequired,
      key,
      label,
      helper = '',
      toServerKeyExtractor,
      type,
      value,
      multiple,
      maxOptions,
      validate,
      multiline,
      options = [],
      handleChange,
      maxFiles,
      accept,
      showDialog,
      disabled = false,
    },
    ref
  ) => {
    const uploaderRef = useRef()

    const [isValid, setIsValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    useImperativeHandle(ref, () => ({
      getFieldValidation: () => {
        return { errorMessage, isValid }
      },
    }))

    if (type === 'select' && value.length > 0) {
      for (let index = 0; index < value.length; index++) {
        delete value[index].short_name
      }
    }

     const handleSelectChange = (label, event) => {
       const selectedValue = event.target.value
       // Check if the number of selected options exceeds the maxOptions limit
       if (label === 'Levers Of Growth' && selectedValue.length > maxOptions ) {
          return
       } else {
         handleChange(event)
       }
     }

    const setValidation = (error) => {
      setIsValid(!error)
      setErrorMessage(error)
    }
    const handleUploadFileError = (msg) => {
      showDialog(({ close }) => ({
        title: 'Error',
        text: msg,
        actions: [{ text: 'Ok', action: () => close() }],
      }))
    }
    const validateFormField = () => {
      setValidation('')
      if (isRequired) {
        const isValueEmpty =
          value == null || value === '' || (Array.isArray(value) && !value.length)
        setValidation(isValueEmpty ? t('This field is required') : '')
      }
      if (validate === 'isUrl' && value != null && value !== '') {
        var pattern = new RegExp(
          '((http|https)://)(www.)?' +
            '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
        )
        setValidation(!pattern.test(value) ? t('The URL is not valid') : '')
      }
    }
    useDeepCompareEffect(() => {
      validateFormField()
    }, [setValidation, value])

    return (
      <FormControl required={isRequired} fullWidth sx={{ m: 0 }}>
        {(type === 'text' || type === 'number' || type === 'date') && (
          <>
            <TextField
              id={key}
              value={value}
              name={key}
              multiline={multiline}
              onChange={handleChange}
              //onChange={(event) => handleChange(key, type, value)(event)}
              label={label}
              disabled={disabled}
              placeholder={helper}
              type={type}
              error={!isValid}
              helperText={errorMessage}
              InputLabelProps={{ shrink: true }}
            />
          </>
        )}
        {type === 'checkbox' && (
          <>
            <FormControlLabel
              control={<Checkbox checked={value} />}
              label={label}
              value={value}
              // onChange={(event) => handleChange(key, type, value)(event)}
              onChange={(event) => handleChange(event.target.checked)}
            />
          </>
        )}
        {type === 'select' && (
          <>
            <InputLabel id={`${key}-label`}>{label}</InputLabel>
            <Select
              id={key}
              labelId={`${key}-label`}
              label={label}
              value={
                typeof value === 'string'
                  ? options.find((o) => o[toServerKeyExtractor] == value)
                  : value && value.length > 0
                  ? options.filter((item) =>
                      value.map((v) => v[toServerKeyExtractor]).includes(item[toServerKeyExtractor])
                    )
                  : value
              }
              multiple={multiple}
              disabled={disabled}
              // onChange={(event) => {
              //   if (label === 'Levers Of Growth') {
              //     handleChange(key, type, value, maxOptions)(event)
              //   } else {
              //     handleChange(key, type, value)(event)
              //   }
              // }}
              onChange={(event) => handleSelectChange(label, event)}
              input={<OutlinedInput id="select-multiple-chip" label={label} />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {multiple &&
                    (selected || []).map((value, idx) => (
                      <Chip key={`selected-option-${key}-${idx}`} label={value.name} />
                    ))}
                  {!multiple && typeof selected === 'string' && (
                    <Chip
                      key={`selected-option-${key}-${selected}`}
                      label={options.find((o) => o[toServerKeyExtractor] === selected).name}
                    />
                  )}
                  {!multiple && typeof selected === 'object' && (
                    <Chip key={`selected-option-${key}-${selected.key}`} label={selected.name} />
                  )}
                </Box>
              )}
            >
              {options.map((option, idx) => {
                return (
                  <MenuItem value={option} key={`select-option-${key}-${option.name}-${idx}`}>
                    {option.name}
                  </MenuItem>
                )
              })}
            </Select>
          </>
        )}
        {type === 'files' && (
          <Box sx={{ display: 'flex', my: 2 }}>
            <Box
              sx={{
                position: 'relative',
                width: 'auto',
                minWidth: 250,
                minHeight: 250,
                height: 'auto',
                mr: 4,
              }}
            >
              {((value || [])[0] || {}).url && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    zIndex: 2,
                    cursor: 'pointer',
                    background: 'white',
                    width: '25px',
                    height: '25px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                  }}
                  onClick={() => uploaderRef.current.deleteFile(((value || [])[0] || {}).id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} color={colorsByKey.defaultText} size="xs" />
                </Box>
              )}

              <Avatar
                alt={`profile picture`}
                variant="rounded"
                src={((value || [])[0] || {}).url}
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <FontAwesomeIcon icon={faCloudUploadAlt} size="2x" />
              </Avatar>
            </Box>
            <FileUploader2
              ref={uploaderRef}
              label={label}
              files={value}
              // onSetFiles={() => handleChange(key, type, value)()}
              onSetFiles={handleChange}
              maxFiles={maxFiles}
              accept={accept}
              onError={handleUploadFileError}
            />
          </Box>
        )}
      </FormControl>
    )
  }
)
export default withTranslation(undefined, { withRef: true })(Field)
