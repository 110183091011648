export default function countries() {
  return [
    {
      id: 11,
      name: 'Afghanistan',
      status: 1,
    },
    {
      id: 12,
      name: 'Albania',
      status: 1,
    },
    {
      id: 13,
      name: 'Algeria',
      status: 1,
    },
    {
      id: 14,
      name: 'Andorra',
      status: 1,
    },
    {
      id: 15,
      name: 'Angola',
      status: 1,
    },
    {
      id: 16,
      name: 'Antigua and Barbuda',
      status: 1,
    },
    {
      id: 17,
      name: 'Argentina',
      status: 1,
    },
    {
      id: 18,
      name: 'Armenia',
      status: 1,
    },
    {
      id: 19,
      name: 'Australia',
      status: 1,
    },
    {
      id: 20,
      name: 'Austria',
      status: 1,
      UE: true,
    },
    {
      id: 21,
      name: 'Azerbaijan',
      status: 1,
    },
    {
      id: 22,
      name: 'Bahamas',
      status: 1,
    },
    {
      id: 23,
      name: 'Bahrain',
      status: 1,
    },
    {
      id: 24,
      name: 'Bangladesh',
      status: 1,
    },
    {
      id: 25,
      name: 'Barbados',
      status: 1,
    },
    {
      id: 26,
      name: 'Belarus',
      status: 1,
    },
    {
      id: 27,
      name: 'Belgium',
      status: 1,
      UE: true,
    },
    {
      id: 28,
      name: 'Belize',
      status: 1,
    },
    {
      id: 29,
      name: 'Benin',
      status: 1,
    },
    {
      id: 30,
      name: 'Bhutan',
      status: 1,
    },
    {
      id: 31,
      name: 'Bolivia (Plurinational State of)',
      status: 1,
    },
    {
      id: 32,
      name: 'Bosnia and Herzegovina',
      status: 1,
    },
    {
      id: 33,
      name: 'Botswana',
      status: 1,
    },
    {
      id: 34,
      name: 'Brazil',
      status: 1,
    },
    {
      id: 35,
      name: 'Brunei Darussalam',
      status: 1,
    },
    {
      id: 36,
      name: 'Bulgaria',
      status: 1,
      UE: true,
    },
    {
      id: 37,
      name: 'Burkina Faso',
      status: 1,
    },
    {
      id: 38,
      name: 'Burundi',
      status: 1,
    },
    {
      id: 39,
      name: 'Cabo Verde',
      status: 1,
    },
    {
      id: 40,
      name: 'Cambodia',
      status: 1,
    },
    {
      id: 41,
      name: 'Cameroon',
      status: 1,
    },
    {
      id: 42,
      name: 'Canada',
      status: 1,
    },
    {
      id: 43,
      name: 'Central African Republic',
      status: 1,
    },
    {
      id: 44,
      name: 'Chad',
      status: 1,
    },
    {
      id: 45,
      name: 'Chile',
      status: 1,
    },
    {
      id: 46,
      name: 'China',
      status: 1,
    },
    {
      id: 47,
      name: 'Colombia',
      status: 1,
    },
    {
      id: 48,
      name: 'Comoros',
      status: 1,
    },
    {
      id: 49,
      name: 'Congo',
      status: 1,
    },
    {
      id: 50,
      name: 'Cook Islands',
      status: 1,
    },
    {
      id: 51,
      name: 'Costa Rica',
      status: 1,
    },
    {
      id: 56,
      name: "Côte d'Ivoire",
      status: 1,
    },
    {
      id: 52,
      name: 'Croatia',
      status: 1,
      UE: true,
    },
    {
      id: 53,
      name: 'Cuba',
      status: 1,
    },
    {
      id: 54,
      name: 'Cyprus',
      status: 1,
    },
    {
      id: 55,
      name: 'Czechia',
      status: 1,
      UE: true,
    },
    {
      id: 57,
      name: "Democratic People's Republic of Korea",
      status: 1,
    },
    {
      id: 58,
      name: 'Democratic Republic of the Congo',
      status: 1,
    },
    {
      id: 59,
      name: 'Denmark',
      status: 1,
      UE: true,
    },
    {
      id: 60,
      name: 'Djibouti',
      status: 1,
    },
    {
      id: 61,
      name: 'Dominica',
      status: 1,
    },
    {
      id: 62,
      name: 'Dominican Republic',
      status: 1,
    },
    {
      id: 63,
      name: 'Ecuador',
      status: 1,
    },
    {
      id: 64,
      name: 'Egypt',
      status: 1,
    },
    {
      id: 65,
      name: 'El Salvador',
      status: 1,
    },
    {
      id: 66,
      name: 'Equatorial Guinea',
      status: 1,
    },
    {
      id: 67,
      name: 'Eritrea',
      status: 1,
    },
    {
      id: 68,
      name: 'Estonia',
      status: 1,
      UE: true,
    },
    {
      id: 69,
      name: 'Eswatini',
      status: 1,
    },
    {
      id: 70,
      name: 'Ethiopia',
      status: 1,
    },
    {
      id: 71,
      name: 'Faroe Islands',
      status: 1,
    },
    {
      id: 72,
      name: 'Fiji',
      status: 1,
    },
    {
      id: 73,
      name: 'Finland',
      status: 1,
      UE: true,
    },
    {
      id: 75,
      name: 'France',
      status: 1,
      UE: true,
    },
    {
      id: 76,
      name: 'Gabon',
      status: 1,
    },
    {
      id: 77,
      name: 'Gambia',
      status: 1,
    },
    {
      id: 78,
      name: 'Georgia',
      status: 1,
    },
    {
      id: 79,
      name: 'Germany',
      status: 1,
      UE: true,
    },
    {
      id: 80,
      name: 'Ghana',
      status: 1,
    },
    {
      id: 81,
      name: 'Greece',
      status: 1,
      UE: true,
    },
    {
      id: 82,
      name: 'Grenada',
      status: 1,
    },
    {
      id: 83,
      name: 'Guatemala',
      status: 1,
    },
    {
      id: 84,
      name: 'Guinea',
      status: 1,
    },
    {
      id: 85,
      name: 'Guinea-Bissau',
      status: 1,
    },
    {
      id: 86,
      name: 'Guyana',
      status: 1,
    },
    {
      id: 87,
      name: 'Haiti',
      status: 1,
    },
    {
      id: 88,
      name: 'Honduras',
      status: 1,
    },
    {
      id: 89,
      name: 'Hungary',
      status: 1,
      UE: true,
    },
    {
      id: 90,
      name: 'Iceland',
      status: 1,
    },
    {
      id: 91,
      name: 'India',
      status: 1,
    },
    {
      id: 92,
      name: 'Indonesia',
      status: 1,
    },
    {
      id: 93,
      name: 'Iran (Islamic Republic of)',
      status: 1,
    },
    {
      id: 95,
      name: 'Iraq',
      status: 1,
    },
    {
      id: 96,
      name: 'Ireland',
      status: 1,
      UE: true,
    },
    {
      id: 97,
      name: 'Israel',
      status: 1,
    },
    {
      id: 98,
      name: 'Italy',
      status: 1,
      UE: true,
    },
    {
      id: 99,
      name: 'Jamaica',
      status: 1,
    },
    {
      id: 100,
      name: 'Japan',
      status: 1,
    },
    {
      id: 101,
      name: 'Jordan',
      status: 1,
    },
    {
      id: 102,
      name: 'Kazakhstan',
      status: 1,
    },
    {
      id: 103,
      name: 'Kenya',
      status: 1,
    },
    {
      id: 104,
      name: 'Kiribati',
      status: 1,
    },
    {
      id: 105,
      name: 'Kuwait',
      status: 1,
    },
    {
      id: 106,
      name: 'Kyrgyzstan',
      status: 1,
    },
    {
      id: 107,
      name: "Lao People's Democratic Republic",
      status: 1,
    },
    {
      id: 108,
      name: 'Latvia',
      status: 1,
      UE: true,
    },
    {
      id: 109,
      name: 'Lebanon',
      status: 1,
    },
    {
      id: 110,
      name: 'Lesotho',
      status: 1,
    },
    {
      id: 111,
      name: 'Liberia',
      status: 1,
    },
    {
      id: 112,
      name: 'Libya',
      status: 1,
    },
    {
      id: 113,
      name: 'Lithuania',
      status: 1,
      UE: true,
    },
    {
      id: 114,
      name: 'Luxembourg',
      status: 1,
      UE: true,
    },
    {
      id: 115,
      name: 'Madagascar',
      status: 1,
    },
    {
      id: 116,
      name: 'Malawi',
      status: 1,
    },
    {
      id: 117,
      name: 'Malaysia',
      status: 1,
    },
    {
      id: 118,
      name: 'Maldives',
      status: 1,
    },
    {
      id: 119,
      name: 'Mali',
      status: 1,
    },
    {
      id: 120,
      name: 'Malta',
      status: 1,
      UE: true,
    },
    {
      id: 121,
      name: 'Marshall Islands',
      status: 1,
    },
    {
      id: 122,
      name: 'Mauritania',
      status: 1,
    },
    {
      id: 123,
      name: 'Mauritius',
      status: 1,
    },
    {
      id: 124,
      name: 'Mexico',
      status: 1,
    },
    {
      id: 125,
      name: 'Micronesia (Federated States of)',
      status: 1,
    },
    {
      id: 126,
      name: 'Monaco',
      status: 1,
    },
    {
      id: 127,
      name: 'Mongolia',
      status: 1,
    },
    {
      id: 128,
      name: 'Montenegro',
      status: 1,
    },
    {
      id: 129,
      name: 'Morocco',
      status: 1,
    },
    {
      id: 130,
      name: 'Mozambique',
      status: 1,
    },
    {
      id: 131,
      name: 'Myanmar',
      status: 1,
    },
    {
      id: 132,
      name: 'Namibia',
      status: 1,
    },
    {
      id: 133,
      name: 'Nauru',
      status: 1,
    },
    {
      id: 134,
      name: 'Nepal',
      status: 1,
    },
    {
      id: 135,
      name: 'Netherlands',
      status: 1,
      UE: true,
    },
    {
      id: 136,
      name: 'New Zealand',
      status: 1,
    },
    {
      id: 137,
      name: 'Nicaragua',
      status: 1,
    },
    {
      id: 138,
      name: 'Niger',
      status: 1,
    },
    {
      id: 139,
      name: 'Nigeria',
      status: 1,
    },
    {
      id: 140,
      name: 'Niue',
      status: 1,
    },
    {
      id: 141,
      name: 'North Macedonia',
      status: 1,
    },
    {
      id: 142,
      name: 'Norway',
      status: 1,
    },
    {
      id: 143,
      name: 'Oman',
      status: 1,
    },
    {
      id: 144,
      name: 'Pakistan',
      status: 1,
    },
    {
      id: 145,
      name: 'Palau',
      status: 1,
    },
    {
      id: 146,
      name: 'Panama',
      status: 1,
    },
    {
      id: 147,
      name: 'Papua New Guinea',
      status: 1,
    },
    {
      id: 148,
      name: 'Paraguay',
      status: 1,
    },
    {
      id: 149,
      name: 'Peru',
      status: 1,
    },
    {
      id: 150,
      name: 'Philippines',
      status: 1,
    },
    {
      id: 151,
      name: 'Poland',
      status: 1,
      UE: true,
    },
    {
      id: 152,
      name: 'Portugal',
      status: 1,
      UE: true,
    },
    {
      id: 153,
      name: 'Qatar',
      status: 1,
    },
    {
      id: 154,
      name: 'Republic of Korea',
      status: 1,
    },
    {
      id: 155,
      name: 'Republic of Moldova',
      status: 1,
    },
    {
      id: 156,
      name: 'Romania',
      status: 1,
      UE: true,
    },
    {
      id: 157,
      name: 'Russian Federation',
      status: 1,
    },
    {
      id: 158,
      name: 'Rwanda',
      status: 1,
    },
    {
      id: 160,
      name: 'Saint Kitts and Nevis',
      status: 1,
    },
    {
      id: 161,
      name: 'Saint Lucia',
      status: 1,
    },
    {
      id: 162,
      name: 'Saint Vincent and the Grenadines',
      status: 1,
    },
    {
      id: 163,
      name: 'Samoa',
      status: 1,
    },
    {
      id: 164,
      name: 'San Marino',
      status: 1,
    },
    {
      id: 165,
      name: 'Sao Tome and Principe',
      status: 1,
    },
    {
      id: 166,
      name: 'Saudi Arabia',
      status: 1,
    },
    {
      id: 167,
      name: 'Senegal',
      status: 1,
    },
    {
      id: 168,
      name: 'Serbia',
      status: 1,
    },
    {
      id: 169,
      name: 'Seychelles',
      status: 1,
    },
    {
      id: 170,
      name: 'Sierra Leone',
      status: 1,
    },
    {
      id: 171,
      name: 'Singapore',
      status: 1,
    },
    {
      id: 172,
      name: 'Slovakia',
      status: 1,
      UE: true,
    },
    {
      id: 173,
      name: 'Slovenia',
      status: 1,
      UE: true,
    },
    {
      id: 174,
      name: 'Solomon Islands',
      status: 1,
    },
    {
      id: 176,
      name: 'Somalia',
      status: 1,
    },
    {
      id: 177,
      name: 'South Africa',
      status: 1,
    },
    {
      id: 178,
      name: 'South Sudan',
      status: 1,
    },
    {
      id: 179,
      name: 'Spain',
      status: 1,
      UE: true,
    },
    {
      id: 180,
      name: 'Sri Lanka',
      status: 1,
    },
    {
      id: 181,
      name: 'Sudan',
      status: 1,
    },
    {
      id: 182,
      name: 'Suriname',
      status: 1,
    },
    {
      id: 183,
      name: 'Sweden',
      status: 1,
      UE: true,
    },
    {
      id: 184,
      name: 'Switzerland',
      status: 1,
    },
    {
      id: 185,
      name: 'Syrian Arab Republic',
      status: 1,
    },
    {
      id: 186,
      name: 'Tajikistan',
      status: 1,
    },
    {
      id: 187,
      name: 'Thailand',
      status: 1,
    },
    {
      id: 188,
      name: 'Timor-Leste',
      status: 1,
    },
    {
      id: 189,
      name: 'Togo',
      status: 1,
    },
    {
      id: 190,
      name: 'Tokelau',
      status: 1,
    },
    {
      id: 191,
      name: 'Tonga',
      status: 1,
    },
    {
      id: 192,
      name: 'Trinidad and Tobago',
      status: 1,
    },
    {
      id: 193,
      name: 'Tunisia',
      status: 1,
    },
    {
      id: 194,
      name: 'Turkey',
      status: 1,
    },
    {
      id: 195,
      name: 'Turkmenistan',
      status: 1,
    },
    {
      id: 196,
      name: 'Tuvalu',
      status: 1,
    },
    {
      id: 197,
      name: 'Uganda',
      status: 1,
    },
    {
      id: 198,
      name: 'Ukraine',
      status: 1,
    },
    {
      id: 199,
      name: 'United Arab Emirates',
      status: 1,
    },
    {
      id: 200,
      name: 'United Kingdom of Great Britain and Northern Ireland',
      status: 1,
    },
    {
      id: 201,
      name: 'United Republic of Tanzania',
      status: 1,
    },
    {
      id: 202,
      name: 'United States of America',
      status: 1,
    },
    {
      id: 203,
      name: 'Uruguay',
      status: 1,
    },
    {
      id: 204,
      name: 'Uzbekistan',
      status: 1,
    },
    {
      id: 205,
      name: 'Vanuatu',
      status: 1,
    },
    {
      id: 206,
      name: 'Venezuela (Bolivarian Republic of)',
      status: 1,
    },
    {
      id: 207,
      name: 'Viet Nam',
      status: 1,
    },
    {
      id: 208,
      name: 'Yemen',
      status: 1,
    },
    {
      id: 209,
      name: 'Zambia',
      status: 1,
    },
    {
      id: 210,
      name: 'Zimbabwe',
      status: 1,
    },
  ]
}
