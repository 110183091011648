import { useState, useEffect } from 'react'
import { apiGet } from '../api'

export const COMPANY_SIZES = [
  // reffers to employees
  {
    key: '1-10',
    name: '1 - 10',
  },
  {
    key: '11-50',
    name: '11 - 50',
  },
  {
    key: '51-200',
    name: '51 - 200',
  },
  {
    key: '200',
    name: '+ 200',
  },
]
export const COMPANY_REVENUES = [
  // reffers to money gain
  {
    key: '0-2',
    name: '0 - 2 M €',
  },
  {
    key: '2-10',
    name: '2 - 10 M €',
  },
  {
    key: '10-50',
    name: '10 - 50 M €',
  },
  {
    key: '50',
    name: '> 50 M €',
  },
]

export const COMPANY_PACKAGE = [
  // reffers to money gain
  {
    key: 1,
    name: 'Crystal',
  },
  {
    key: 2,
    name: 'Silver',
  },
  {
    key: 3,
    name: 'Gold',
  },
  {
    key: 4,
    name: 'Platinum',
  },
]
export const REQUIRED = [
  {
    key: true,
    name: 'Required',
  },
  {
    key: false,
    name: 'Not required',
  },
]
export const BUSINESS_AREAS_OPTIONS = [
  {
    key: 'generalManagement',
    name: 'General Management',
  },
  {
    key: 'sales',
    name: 'Sales',
  },
  {
    key: 'marketing',
    name: 'Marketing',
  },
  {
    key: 'IT',
    name: 'IT',
  },
  {
    key: 'finance',
    name: 'Finance',
  },
  {
    key: 'operations',
    name: 'Operations',
  },
  {
    key: 'HR',
    name: 'HR',
  },
  {
    key: 'manufacturingProduction',
    name: 'Manufacturing and Production',
  },
]
export const EXPERIENCE_PREVIOUS_FUNCIONS_OPTIONS = [
  {
    key: 'CXO',
    name: 'CXO',
  },
  {
    key: 'boardMember',
    name: 'Board Member',
  },
  {
    key: 'partner',
    name: 'Partner',
  },
  {
    key: 'founder',
    name: 'Founder',
  },
]

const Filters = (props) => {
  const [proposalFactors, setProposalFactors] = useState([])
  const [serviceCategories, setServiceCategories] = useState([])
  const [services, setServices] = useState([])
  const [sectors, setSectors] = useState([])
  const [countries, setCountries] = useState([])
  const [languages, setLanguages] = useState([])
  const [companies, setCompanies] = useState([])
  const [fetchingFilters, setFetchingFilters] = useState(true)

  useEffect(() => {
    const fetchProposalFactors = async () => {
      await apiGet('/api/proposal-factors').then(({ data }) => {
        const normalizedData = (data || []).map(({ id, factorStage, factorName }) => ({
          id,
          name: `${factorName} | (${factorStage})`,
          simpleName: factorName,
          type: factorStage,
        }))
        setProposalFactors(normalizedData)
      })
    }
    const fetchServiceCategories = async () => {
      await apiGet('/api/pom_categories').then(({ data }) => {
        const normalizedData = (data || []).map(({ id, category_name }) => ({
          id,
          name: category_name,
        }))
        setServiceCategories(normalizedData)
      })
    }
    const fetchServices = async () => {
      await apiGet('/api/services').then(({ data }) => {
        const sortedData = data.sort((a, b) => a.id - b.id)
        const normalizedData = (sortedData || []).map(({ id, service_name, service_stage }) => ({
          id,
          name: service_name,
          type: service_stage,
        }))
        setServices(normalizedData)
      })
    }
    const fetchSectors = async () => {
      await apiGet('/api/sectors').then(({ data }) => {
        const normalizedData = (data || [])
          .map(({ id, name }) => ({
            id,
            name,
          }))
          .sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))
        setSectors(normalizedData)
      })
    }
    //* danger if company_name is null it breaks the frontend
    const fetchCompanies = async () => {
      await apiGet('/api/partnershipsFormParameters').then((response) => {
        const normalizedData = (response.success.companies_list || [])
          .map(({ id, company_name }) => ({
            id,
            name: company_name,
          }))
          .sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))
        setCompanies(normalizedData)
      })
    }
    const fetchCountries = async () => {
      await apiGet('/api/countries').then(({ data }) => {
        // BACKEND
        // Finland is duplicated
        const sortedData = data.sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))
        const normalizedData = (sortedData || []).map(({ id, name }) => ({
          id,
          name,
        }))
        setCountries(normalizedData)
      })
    }
    const fetchLanguages = async () => {
      await apiGet('/api/languages').then(({ data }) => {
        const sortedData = data.sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))
        const normalizedData = (sortedData || []).map(({ id, name }) => ({
          id,
          name,
        }))
        setLanguages(normalizedData)
      })
    }

    const actions = [
      fetchProposalFactors(),
      fetchServiceCategories(),
      fetchServices(),
      fetchSectors(),
      fetchCountries(),
      fetchLanguages(),
      fetchCompanies(),
    ]
    Promise.all(actions).then(() => {
      setFetchingFilters(false)
    })
  }, [])

  return {
    proposalFactors,
    serviceCategories,
    services,
    countries,
    languages,
    sectors,
    companies,
    companySizes: COMPANY_SIZES,
    companyRevenues: COMPANY_REVENUES,
    fetchingFilters,
    companyPackages: COMPANY_PACKAGE,
  }
}

export default Filters
