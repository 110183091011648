import 'date-fns'
import React from 'react'
import axios from 'axios'
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import history from '../history'
import { Container, Row, Col } from 'react-bootstrap'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
// import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Textarea from '@material-ui/core/TextareaAutosize'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import Rating from '@material-ui/lab/Rating'
import MuiPickersUtilsProviderValidator from '../component/profile/ErrorComponent/MuiPickersUtilsProviderValidator'

class Givereview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        code: '',
        description: '',
        // project_name: '',
        from_date: null,
        to_date: null,
        name: '',
        company_name: '',
        sector: null,
        rating_value: 0,
      },
      companysectors: [],
      hideform: false,
      msg: '',
      policypage: '',
      minDate: new Date(),
      showHide: false,
      maxDate: null,
      loading: true,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async componentDidMount() {
    const sector_api_url = process.env.REACT_APP_API_URL + '/api/sectors'
    await axios
      .get(sector_api_url)
      .then((response) => {
        // handle success
        const { data } = response.data
        this.setState({ companysectors: data })
      })
      .catch((error) => {
        console.log(error)
      })
    const page_api_url = process.env.REACT_APP_API_URL + '/api/pages/2'
    await axios
      .get(page_api_url)
      .then((response) => {
        // handle success
        const { data } = response.data
        this.setState({ policypage: data })
      })
      .catch((error) => {
        console.log(error)
      })

    if (localStorage.getItem('accessToken')) {
      const api_url = process.env.REACT_APP_API_URL + '/api/validateReviewPermit'
      await axios
        .post(
          api_url,
          { code: this.props.match.params.refercode },
          { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
        )
        .then((response) => {
          const { formData } = this.state
          formData['code'] = this.props.match.params.refercode
          if (this.props.userDetail) {
            formData['name'] = this.props.userDetail.name
            formData['job_function'] = this.props.userDetail.job_function
            formData['company_name'] =
              this.props.userDetail.company_name || this.props.userDetail.name
            formData['sector'] = this.props.userDetail.company_sector
            this.setState({ company_sector_selected: '1' })
          }
          this.setState({ formData, loading: false })
        })
        .catch((error) => {
          history.push('/')
        })
    } else {
      const guest_api_url = process.env.REACT_APP_API_URL + '/api/validateReviewPermitGuest'
      await axios
        .post(guest_api_url, { code: this.props.match.params.refercode })
        .then((response) => {
          const { formData } = this.state
          formData['code'] = this.props.match.params.refercode
          this.setState({ formData, loading: false })
        })
        .catch((error) => {
          history.push('/')
        })
    }
  }
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
  }
  handleCompanySector = (event, value) => {
    const { formData } = this.state
    formData['sector'] = value
    if (value !== null) {
      this.setState({ company_sector_selected: '1' })
    } else {
      this.setState({ company_sector_selected: '' })
    }
    this.setState({ formData })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData, error: [] })
  }

  handlerating = (event, value) => {
    const { formData } = this.state
    formData['rating_value'] = value
    this.setState({ formData })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      if (localStorage.getItem('accessToken')) {
        const api_url = process.env.REACT_APP_API_URL + '/api/addReview'
        axios
          .post(api_url, this.state.formData, {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
          })
          .then((response) => {
            // handle success
            this.setState({ hideform: true, msg: 'Review submitted' })
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        const guest_api_url = process.env.REACT_APP_API_URL + '/api/addReviewGuest'
        axios
          .post(guest_api_url, this.state.formData)
          .then((response) => {
            // handle success
            this.setState({ hideform: true, msg: 'Review submitted' })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })
  }

  handleDateChange = (date) => {
    const { formData } = this.state
    if (formData['to_date'] !== null) {
      if (date > formData['to_date']) {
        formData['to_date'] = null
      }
    }
    formData['from_date'] = date
    this.setState({ minDate: date })
    this.setState({ formData })
  }
  handleEndDateChange = (date) => {
    const { formData } = this.state
    if (formData['from_date'] !== null) {
      if (date < formData['from_date']) {
        formData['from_date'] = null
      }
    }
    formData['to_date'] = date
    this.setState({ maxDate: date })
    this.setState({ formData })
  }

  render() {
    const { formData, submitted, companysectors, hideform, loading } = this.state
    const { t } = this.props

    if (loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div className="registration-min seller-details-min give-review w-100 minheight-100">
        <Helmet>
          <title>Review | BridgeWhat: Let's Grow!</title>
        </Helmet>
        <Container>
          {hideform && (
            <div className="welcome-min w-bg d-flex align-items-center justify-content-center">
              <div className="text-center">
                <img
                  className="mb-4 logo-img"
                  src={require('assets/images/check-icon.png')}
                  alt="#"
                />
                <h2 className="pb-5">{this.state.msg}</h2>
              </div>
            </div>
          )}
          {!hideform && (
            <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
              <Row>
                {/* <Col lg="6">
                  <div className="form-group">
                    <label>
                      {t('Project Name')} <sup>*</sup>
                    </label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="project_name"
                      onChange={this.handleChange}
                      value={formData.project_name}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </div>
                </Col> */}
                <Col lg="6">
                  <Row>
                    <Col lg="6">
                      <div className="form-group date_time_icon">
                        <label className="mb-0">
                          {t('Start Date')} <sup>*</sup>
                        </label>
                        <MuiPickersUtilsProviderValidator
                          margin="normal"
                          inputVariant="outlined"
                          id="date-picker-dialog"
                          fullWidth
                          maxDate={this.state.maxDate}
                          format="dd/MM/yyyy"
                          name="from_date"
                          value={formData.from_date}
                          onChange={this.handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          keyboardIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
                          validators={['required']}
                          errorMessages={t('This field is required')}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="form-group date_time_icon">
                        <label className="mb-0">
                          {t('End Date')} <sup>*</sup>
                        </label>
                        <MuiPickersUtilsProviderValidator
                          margin="normal"
                          inputVariant="outlined"
                          id="date-picker-dialog"
                          fullWidth
                          minDate={this.state.minDate}
                          format="dd/MM/yyyy"
                          name="to_date"
                          value={formData.to_date}
                          onChange={this.handleEndDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          keyboardIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
                          validators={['required']}
                          errorMessages={t('This field is required')}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <div className="form-group d-flex align-items-center mb-0 review_rate">
                    <label className="mr-3">{t('Rate your review')}</label>
                    <Rating name="size-medium" onChange={this.handlerating} defaultValue={1} />
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="w-100">{t('Description')}</label>
                    <Textarea
                      fullWidth
                      variant="outlined"
                      rowsMax={1}
                      aria-label="maximum height"
                      placeholder=""
                      name="description"
                      onChange={this.handleChange}
                      value={formData.description}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col lg="12">
                  <h6>{t('Contact Information')}</h6>
                </Col>
                <Col lg="12">
                  <div className="form-group">
                    <label>{t('Name of the person to gift a testimonial')}</label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="name"
                      onChange={this.handleChange}
                      value={formData.name}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </div>
                </Col>
                <Col lg="12">
                  <div className="form-group">
                    <label>{t('Job Function')}</label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="job_function"
                      onChange={this.handleChange}
                      value={formData.job_function}
                    />
                  </div>
                </Col>
                <Col lg="12">
                  <div className="form-group">
                    <label>{t('Company Name')}</label>
                    <TextValidator
                      variant="outlined"
                      margin="0"
                      fullWidth
                      type="text"
                      name="company_name"
                      onChange={this.handleChange}
                      value={formData.company_name}
                    />
                  </div>
                </Col>
                <Col lg="12">
                  <div className="form-group">
                    <label>{t('Sector')}</label>
                    <Autocomplete
                      options={companysectors}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => {
                        return option.name === formData.sector.name
                      }}
                      name="sector"
                      defaultValue={formData.sector}
                      selected={formData.sector}
                      onChange={this.handleCompanySector}
                      renderInput={(params) => (
                        <TextValidator
                          Select
                          {...params}
                          fullWidth
                          variant="outlined"
                          name="company_sector_selected"
                          placeholder="Company's sector"
                          value={this.state.company_sector_selected}
                          validators={['required']}
                          errorMessages={['This field is required']}
                        />
                      )}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12" className="d-flex align-items-center justify-content-center">
                  <Button
                    variant="contained"
                    type="submit"
                    className="submit-btn ml-2 mr-2"
                    disabled={submitted}
                  >
                    {(submitted && t('Give a Review')) || (!submitted && t('Give a Review'))}
                  </Button>
                </Col>
              </Row>
            </ValidatorForm>
          )}
        </Container>
        <Modal show={this.state.showHide} backdrop="static">
          <Modal.Header>
            <Modal.Title
              dangerouslySetInnerHTML={{
                __html: this.state.policypage.name,
              }}
            ></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.policypage.content,
              }}
            ></div>
            <Button
              closeButton
              onClick={() => this.handleModalShowHide()}
              className="submit-btn mt-1 ml-0 mr-0 mb-1 border-0 MuiButton-root MuiButton-contained"
            >
              Agree and continue
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(Givereview)
