import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './footer.css'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedinIn,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons'
library.add(faFacebookF, faTwitter, faYoutube, faLinkedinIn, faInstagramSquare)

class Footer extends React.Component {
  render() {
    const { t } = this.props
    return (
      <footer className="navbar-fixed-bottom">
        <div className="footer-bottom">
          <Container>
            <Row>
              <Col lg="12" className="mt-4">
                <h6>{t('Subscribe our Newsletter - BridgeWhat Growth Week')}</h6>
              </Col>
              <Col lg="5" className="mt-2">
                <div className="d-flex footer-social mt-md-0 align-items-center">
                  <a href="mailto:bridgewhat@bridgewhat.com" className="text-lowercase">
                    bridgewhat@bridgewhat.com
                  </a>
                  <ul className="social_icon">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/bridgewhat/?viewAsMember=true"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/BridgeWhat/"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCPqjl7kGEnKRVF2DcERrh_Q?view_as=subscriber"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://twitter.com/bridge_what" rel="noreferrer">
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/bridgewhat/"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faInstagramSquare} />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="7" className="mt-2 ">
                <ul>
                  <li>
                    <Link to="/blog">{t('Blog')}</Link>
                  </li>
                  <li>|</li>
                  <li>
                    <Link to="/page/terms-of-use">{t('Terms & Conditions')}</Link>
                  </li>
                  <li>|</li>
                  <li>
                    <Link to="/page/privacy-policy">{t('privacy policy')}</Link>
                  </li>
                  <li>|</li>
                  <li>
                    <Link to="/page/cookies-policy">{t('cookies policy')}</Link>
                  </li>
                  <li>|</li>
                  <li>
                    <Link to="/page/participants-terms-of-service">
                      {t('Participants Contract')}
                    </Link>
                  </li>
                </ul>

                <ul>
                  <li>BridgeWhat&copy; 2023.</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    )
  }
}

export default withTranslation()(Footer)
