import styled from '@emotion/styled'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import {
  Autocomplete,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button as MButton,
} from '@mui/material'
import FileUpload from 'component/form/file-upload'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { FormSection } from 'screens/Partnerships/create'
import { Divider } from 'screens/Partnerships/view'
import history from '../../history'
import axiosInstance from 'api/axios'
import { useParams } from 'react-router'
import Loader from 'component/loader'
import { IconData } from 'components'
import { colorsByKey } from 'theme/colors'

function CreateExpo({ userDetail }) {
  const params = useParams()
  const [form, setForm] = useState({
    title: '',
    targetIndustrySector: [],
    targetIndustrySegment: '',
    targetCompanyEmployees: [],
    targetCompanyRevenue: [],
    targetCountries: [],

    offer: '',

    whatAwake: '',
    buyContext: '',

    helpCustomer: '',
    problemOppo: '',
    problemSolving: '',

    painRelievers: '',
    gainCreators: '',

    areasInnovation: '',
    productStrength: '',
    famousReason: '',

    diffFactor: [],

    reviews: 0,
    testimonials: 0,
    recommendations: 0,
    certificates: 0,
    awards: 0,
    publications: 0,

    publicationDate: null,
    closingDate: null,

    logo: null,
    brochure: null,
  })

  const [image, setImage] = useState(null)
  const [brochure, setBrochure] = useState(null)
  const [companySectors, setCompanySectors] = useState([])
  const [countries, setCountries] = useState([])
  const [factors, setFactors] = useState([])
  const [errors, setErrors] = useState(null)
  const [endDialog, setEndDialog] = useState(false)

  const [loading, setLoading] = useState(params.id ? true : false)

  useEffect(() => {
    if (!params.id) return
    setLoading(true)
    axiosInstance
      .get(`/api/market/offers/${params.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setForm({
          title: data.data.title,
          targetIndustrySector: data.data.sectors,
          targetIndustrySegment: data.data.industry_segments,
          targetCompanyEmployees: data.data.targetCompanySize,
          targetCompanyRevenue: data.data.targetCompanyRevenue,
          targetCountries: data.data.countries,

          offer: data.data.description,

          whatAwake: data.data.awake_at_night,
          buyContext: data.data.why_buy_context,

          helpCustomer: data.data.will_help_to,
          problemOppo: data.data.problem_opportunity,
          problemSolving: data.data.while,

          painRelievers: data.data.pain_relievers,
          gainCreators: data.data.pain_relievers,

          areasInnovation: data.data.innovation_areas,
          productStrength: data.data.strengths,
          famousReason: data.data.what_brand_famous,

          diffFactor: data.data.proposalFactors,

          reviews: data.data.reviews ? 1 : 0,
          testimonials: data.data.testimonials ? 1 : 0,
          recommendations: data.data.recommendations ? 1 : 0,
          certificates: data.data.certificates ? 1 : 0,
          awards: data.data.awards ? 1 : 0,
          publications: data.data.publications ? 1 : 0,

          publicationDate: data.data.opening_date,
          closingDate: data.data.deadline,

          logo: data.data.files[0].id,
          brochure: data.data.image?.id ?? null,
        })
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        // setLoading(false)
        // history.push('/')
      })
  }, [params.id])

  useEffect(() => {
    axiosInstance
      .get('/api/companysector', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setCompanySectors(data.data)
      })
      .catch(() => {
        history.push('/')
      })

    axiosInstance
      .get('/api/countries', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setCountries(data.data)
      })
      .catch(() => {
        history.push('/')
      })

    axiosInstance
      .get('/api/proposal-factors', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setFactors(data.data)
      })
      .catch(() => {
        history.push('/')
      })
  }, [])

  const uploadFile = (type, file) => {
    if (!file) return

    if (type === 'brochure') {
      setBrochure(file)
    } else if (type === 'logo') {
      setImage(file)
    }

    const formData = new FormData()
    formData.append('file', file)
    axiosInstance
      .post('/api/files', formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        if (type === 'brochure') {
          handleFormInput(type, data.data.id)
          return
        } else if (type === 'logo') {
          handleFormInput(type, data.data.id)
          return
        }
      })
  }

  const handleFormInput = (key, value) => setForm((prev) => ({ ...prev, [key]: value }))

  const handleCreateUpdate = async () => {
    const postData = new FormData()
    postData.append('title', form.title)
    postData.append('description', form.offer)
    for (let i = 0; i < form.targetIndustrySector.length; i++) {
      postData.append('sectors[]', form.targetIndustrySector[i].id)
    }
    for (let i = 0; i < form.targetCountries.length; i++) {
      postData.append('countries[]', form.targetCountries[i].id)
    }

    for (let i = 0; i < form.diffFactor.length; i++) {
      postData.append('proposalFactors[]', form.diffFactor[i])
    }
    for (let i = 0; i < form.targetCompanyEmployees.length; i++) {
      postData.append('target_company_size[]', form.targetCompanyEmployees[i])
    }
    for (let i = 0; i < form.targetCompanyRevenue.length; i++) {
      postData.append('target_company_revenue[]', form.targetCompanyRevenue[i])
    }
    postData.append('files[]', form.brochure)
    postData.append('image', form.logo)
    postData.append('industry_segments', form.targetIndustrySegment)
    postData.append('awake_at_night', form.whatAwake)
    postData.append('why_buy_context', form.buyContext)
    postData.append('will_help_to', form.helpCustomer)
    postData.append('problem_opportunity', form.problemOppo)
    postData.append('while', form.problemSolving)
    postData.append('pain_relievers', form.painRelievers)
    postData.append('gain_creators', form.gainCreators)
    postData.append('will_buy_context', form.buyContext)
    postData.append('what_brand_famous', form.famousReason)
    postData.append('strengths', form.productStrength)
    postData.append('innovation_areas', form.areasInnovation)
    // postData.append('scoring_board_reviews', form.reviews)
    // postData.append('scoring_board_testimonials', form.testimonials)
    // postData.append('scoring_board_recommendations', form.recommendations)
    // postData.append('scoring_board_certificates', form.certificates)
    // postData.append('scoring_board_awards', form.awards)
    // postData.append('scoring_board_publications', form.publications)
    postData.append('opening_date', form.publicationDate)
    postData.append('deadline', form.closingDate)

    // UPDATE
    if (params.id) {
      postData.append('user_id', userDetail.id)
      axiosInstance
        .put(`/api/market/offers/${params.id}`, postData, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        })
        .then(({ data }) => {
          setEndDialog(true)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            setErrors(Object.keys(err.response.data.errors).map((k) => err.response.data.errors[k]))
            return
          }
          history.push('/')
        })

      return
    }

    // CREATE
    axiosInstance
      .post('/api/market/offers', postData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setEndDialog(true)
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrors(Object.keys(err.response.data.errors).map((k) => err.response.data.errors[k]))
        }
      })
  }

  const handleCancel = () => {
    history.push('/')
  }

  return (
    <ContainerWrapper maxWidth={'xl'} sx={{ my: 4 }}>
      {loading && <Loader />}
      {!loading && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
            <Typography variant="f20" sx={{ display: 'inline-block' }}>
              {params.id ? 'Edit' : 'Create new'}
              <Typography
                variant="f20"
                component="span"
                color={colorsByKey.accentText}
                sx={{ ml: 1, display: 'inline-block' }}
              >
                EXPO
              </Typography>
            </Typography>
            <Box>
              <MButton onClick={handleCancel}>
                <Typography noWrap>Cancel</Typography>
              </MButton>
              <MButton
                onClick={handleCreateUpdate}
                variant="contained"
                sx={{
                  ml: 2,
                  borderRadius: '48px !important',
                  backgroundColor: colorsByKey.accentBg,
                }}
              >
                <IconData
                  title="Submit for approval"
                  icon={faSave}
                  titleVariant="f14"
                  margin={1}
                  color={colorsByKey.utility}
                  sx={{ padding: '6px 8px' }}
                />
              </MButton>
            </Box>
          </Box>
          <Stack spacing={8} useFlexGap>
            <Stack spacing={2}>
              <TextField
                label="Title"
                value={form.title}
                onChange={(e) => handleFormInput('title', e.target.value)}
                required
                fullWidth
              />
              <Stack direction="row" spacing={2}>
                <Autocomplete
                  multiple
                  fullWidth
                  disabled={companySectors.length === 0}
                  options={companySectors}
                  getOptionLabel={(option) => option.name}
                  value={form.targetIndustrySector}
                  onChange={(event, newValue) => {
                    handleFormInput('targetIndustrySector', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth required label="Target industry sectors" />
                  )}
                />
                <TextField
                  fullWidth
                  label="Target industry segments"
                  value={form.targetIndustrySegment}
                  onChange={(e) => handleFormInput('targetIndustrySegment', e.target.value)}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel required>Target Company employees</InputLabel>
                  <Select
                    label="Target Company employees"
                    value={form.targetCompanyEmployees}
                    onChange={(e) => handleFormInput('targetCompanyEmployees', e.target.value)}
                    required
                    multiple
                  >
                    <MenuItem value="1 - 10">1 - 10</MenuItem>
                    <MenuItem value="11 - 50">11 - 50</MenuItem>
                    <MenuItem value="51 - 200">51 - 200</MenuItem>
                    <MenuItem value="200">+200</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel required>Target Company Revenue (M)</InputLabel>
                  <Select
                    fullWidth
                    label="Target Company Revenue (M)"
                    value={form.targetCompanyRevenue}
                    onChange={(e) => handleFormInput('targetCompanyRevenue', e.target.value)}
                    required
                    multiple
                  >
                    <MenuItem value="0 - 2">0 - 2M €</MenuItem>
                    <MenuItem value="2 - 10">2 - 10M €</MenuItem>
                    <MenuItem value="10 - 50">10 - 50M €</MenuItem>
                    <MenuItem value="50">&gt; 50M €</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Autocomplete
                multiple
                fullWidth
                disabled={countries.length === 0}
                options={countries}
                getOptionLabel={(option) => option.name}
                value={form.targetCountries}
                onChange={(event, newValue) => {
                  handleFormInput('targetCountries', newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth required label="Target Countries" />
                )}
              />
            </Stack>

            <Stack spacing={2}>
              <FormSection>Description</FormSection>
              <TextField
                label="What we offer (Service or Product)"
                value={form.offer}
                onChange={(e) => handleFormInput('offer', e.target.value)}
                required
                fullWidth
              />
            </Stack>

            <Stack spacing={2}>
              <FormSection>Client Perspective</FormSection>
              <TextField
                label="What keeps our client awake at night?"
                value={form.whatAwake}
                onChange={(e) => handleFormInput('whatAwake', e.target.value)}
                required
                fullWidth
              />
              <TextField
                label="Why should they buy in the current context?"
                value={form.buyContext}
                onChange={(e) => handleFormInput('buyContext', e.target.value)}
                required
                fullWidth
              />
            </Stack>

            <Stack spacing={2}>
              <FormSection>Product/Service job to be done</FormSection>
              <TextField
                label="This product/service will help the costumer to"
                value={form.helpCustomer}
                onChange={(e) => handleFormInput('helpCustomer', e.target.value)}
                fullWidth
              />
              <TextField
                label="Problem/Opportunity"
                value={form.problemOppo}
                onChange={(e) => handleFormInput('problemOppo', e.target.value)}
                fullWidth
              />
              <TextField
                label="... while... (the use context when the problem solving/avoiding or the opportunity capturing occur)"
                value={form.problemSolving}
                onChange={(e) => handleFormInput('problemSolving', e.target.value)}
                fullWidth
              />
            </Stack>

            <Stack spacing={2}>
              <FormSection>Solutions</FormSection>
              <TextField
                label="Pain relievers"
                value={form.painRelievers}
                onChange={(e) => handleFormInput('painRelievers', e.target.value)}
                fullWidth
                required
              />
              <TextField
                label="Gain creators"
                value={form.gainCreators}
                onChange={(e) => handleFormInput('gainCreators', e.target.value)}
                fullWidth
                required
              />
            </Stack>

            <Stack spacing={2}>
              <FormSection>Unique selling proposition</FormSection>
              <TextField
                label="Areas of innovation"
                value={form.areasInnovation}
                onChange={(e) => handleFormInput('areasInnovation', e.target.value)}
                fullWidth
                required
              />
              <TextField
                label="Product/Service strengths"
                value={form.productStrength}
                onChange={(e) => handleFormInput('productStrength', e.target.value)}
                fullWidth
                required
              />
              <TextField
                label="What is your brand famous for?"
                value={form.famousReason}
                onChange={(e) => handleFormInput('famousReason', e.target.value)}
                fullWidth
                required
              />
              <FormControl fullWidth>
                <InputLabel required>Offer differentiator Factors</InputLabel>
                <Select
                  fullWidth
                  multiple
                  label="Offer differentiator Factors"
                  value={typeof form.diffFactor === 'object' ? form.diffFactor : []}
                  onChange={(e) => handleFormInput('diffFactor', e.target.value)}
                  disabled={form.diffFactor.length > 3}
                >
                  {factors.map((f) => (
                    <MenuItem value={f.id}>{f.factorName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack>
              {/* <FormSection>Credentials</FormSection> */}
              {/* <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel required>Reviews</InputLabel>
                  <Select
                    label="Reviews"
                    value={form.reviews}
                    onChange={(e) => handleFormInput('reviews', e.target.value)}
                    required
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel required>Testimonials</InputLabel>
                  <Select
                    label="Testimonials"
                    value={form.testimonials}
                    onChange={(e) => handleFormInput('testimonials', e.target.value)}
                    required
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel required>Recommendations</InputLabel>
                  <Select
                    label="Recommendations"
                    value={form.recommendations}
                    onChange={(e) => handleFormInput('recommendations', e.target.value)}
                    required
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel required>Certificates</InputLabel>
                  <Select
                    label="Certificates"
                    value={form.certificates}
                    onChange={(e) => handleFormInput('certificates', e.target.value)}
                    required
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel required>Awards</InputLabel>
                  <Select
                    label="Awards"
                    value={form.awards}
                    onChange={(e) => handleFormInput('awards', e.target.value)}
                    required
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel required>Publications</InputLabel>
                  <Select
                    label="Publications"
                    value={form.publications}
                    onChange={(e) => handleFormInput('publications', e.target.value)}
                    required
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Stack> */}

              <Divider style={{ marginBottom: '25px' }} />

              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="Publication date"
                  type="date"
                  variant="outlined"
                  value={form.publicationDate}
                  onChange={(e) => handleFormInput('publicationDate', e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: format(new Date(), 'yyyy-MM-dd'),
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Closing date"
                  type="date"
                  variant="outlined"
                  value={form.closingDate}
                  onChange={(e) => handleFormInput('closingDate', e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: format(new Date(), 'yyyy-MM-dd'),
                    },
                  }}
                />
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
              <Stack direction="row" spacing={4}>
                <FileUpload
                  label="Upload Image"
                  buttonText="Choose an Image"
                  fileExtensions=".jpg, .png"
                  required
                  file={image}
                  onChange={(f) => uploadFile('logo', f)}
                />
                <FileUpload
                  label="Upload Brochure"
                  buttonText="Choose a File"
                  fileExtensions=".pdf, .docx, .pptx"
                  required
                  file={brochure}
                  onChange={(f) => uploadFile('brochure', f)}
                />
              </Stack>
            </Stack>
          </Stack>
        </>
      )}

      <Dialog open={endDialog} onClose={handleCancel}>
        <DialogTitle>Great!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This information has been correctly submited. Our team will review it as soon as
            possible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MButton onClick={handleCancel}>Ok</MButton>
        </DialogActions>
      </Dialog>

      {!!errors && (
        <Dialog open={!!errors} onClose={() => setErrors(null)}>
          <DialogTitle>Errors</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {errors.map((err) => (
                <ErrorP>{err}</ErrorP>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MButton onClick={() => setErrors(null)}>Close</MButton>
          </DialogActions>
        </Dialog>
      )}
    </ContainerWrapper>
  )
}

const ContainerWrapper = styled(Container)`
  margin-bottom: 60px;
`

const ErrorP = styled.p`
  margin: 0;
`

export default CreateExpo
