import React from 'react'
import { colorsByKey } from 'theme/colors'
import { Box, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { TYPE, ID, USER, TITLE, HEADER_IMAGE } from 'entities/formFields'
import { SUBSCRIPTION, NAME, IMAGE } from 'entities/companyFields'
import { CompanyAvatar, Tag } from 'components'
import { POST } from 'entities/routes'
import { useHistory } from 'react-router-dom'
import { Avatar } from '@mui/material'

const defaultImage = require('assets/images/card-default-images/post.svg')

const SidebarPostCard = (props) => {
  let history = useHistory()

  return (
    <Box
      className="SidebarPostCard"
      onClick={() => history.push(`${POST}/${props[ID]}`)}
      sx={{
        mb: 2,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.10)',
        borderRadius: '8px',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {props[TYPE] && (
          <Tag
            customSx={{ zIndex: 2, position: 'absolute', top: 0, left: '16px', m: 0 }}
            text={props[TYPE]}
          />
        )}
        <Avatar
          alt={`post-picture`}
          variant="square"
          src={(props[HEADER_IMAGE] || {}).url || defaultImage}
          sx={{ width: '100%', height: (props[HEADER_IMAGE] || {}).url ? 130 : 60, zIndex: 1 }}
        />
      </Box>

      <Box
        sx={{
          p: 2,
          display: 'flex',
          background: colorsByKey.defaultBg,
        }}
      >
        <CompanyAvatar
          subscription={props[USER][SUBSCRIPTION]}
          name={props[USER][NAME]}
          image={props[USER][IMAGE]}
          userId={props[USER][ID]}
          customSx={{ mr: 2 }}
          variant="rounded"
        />
        <div>
          {true && (
            <Typography variant="f14" sx={{ fontWeight: 'bold' }} color={colorsByKey.primaryBorder}>
              {props[TITLE]}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  )
}
export default withTranslation()(SidebarPostCard)
