const APPLIED_SCIENCES = 'Applied sciences'
const FORMAL_SCIENCES = 'Formal sciences'
const HUMANITIES_AND_SOCIAL_SCIENCE = 'Humanities and social science'
const NATURAL_SCIENCE = 'Natural sciences'

export const FIELDS_OF_STUDY_TYPES = [
  APPLIED_SCIENCES,
  FORMAL_SCIENCES,
  HUMANITIES_AND_SOCIAL_SCIENCE,
  NATURAL_SCIENCE,
]
export const FIELDS = [
  {
    key: 'agriculture',
    name: 'Agriculture',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'architectureAndDesign',
    name: 'Architecture and design',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'businessManagement',
    name: 'Business Management',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'education',
    name: 'Education',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'engineeringAndTechnology',
    name: 'Engineering and technology',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'environmentStudiesAndForestry',
    name: 'Environment studies and forestry',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'familyAndConsumerScience',
    name: 'Family and consumer science',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'humanOhysicalPerformanceAndRecreation',
    name: 'Human ohysical performance and recreation',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'journalism',
    name: 'Journalism, media studies and communication',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'law',
    name: 'Law',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'libraryAndMuseumDtudies',
    name: 'Library and museum studies',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'medicine',
    name: 'Medicine',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'militaryScience',
    name: 'Military science',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'publicAdministration',
    name: 'Public administration',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'publicPolicy',
    name: 'Public policy',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'socialWork',
    name: 'Social work',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'transportation',
    name: 'Transportation',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'otherAppliedSciences',
    name: 'Other applied sciences',
    type: APPLIED_SCIENCES,
  },
  {
    key: 'computerSciences',
    name: 'Computer sciences',
    type: FORMAL_SCIENCES,
  },
  {
    key: 'logic',
    name: 'Logic',
    type: FORMAL_SCIENCES,
  },
  {
    key: 'mathematics',
    name: 'Mathematics',
    type: FORMAL_SCIENCES,
  },
  {
    key: 'systemSciences',
    name: 'System sciences',
    type: FORMAL_SCIENCES,
  },
  {
    key: 'otherFormalSciences',
    name: 'Other formal sciences',
    type: FORMAL_SCIENCES,
  },
  {
    key: 'anthropology',
    name: 'Anthropology',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'history',
    name: 'History',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'linguisticAndLanguages',
    name: 'Linguistic and languages',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'philisophy',
    name: 'Philisophy',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'religion',
    name: 'Religion',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'arts',
    name: 'Arts',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'economics',
    name: 'Economics',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'geography',
    name: 'Geography',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'politicalScience',
    name: 'Political Science',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'psychology',
    name: 'Psychology',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'sociology',
    name: 'Sociology',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'otherHumanitiesAndSocialScience',
    name: 'Other humanities and social science',
    type: HUMANITIES_AND_SOCIAL_SCIENCE,
  },
  {
    key: 'biology',
    name: 'Biology',
    type: NATURAL_SCIENCE,
  },
  {
    key: 'chemistry',
    name: 'Chemistry',
    type: NATURAL_SCIENCE,
  },
  {
    key: 'earthSciences',
    name: 'Earth sciences',
    type: NATURAL_SCIENCE,
  },
  {
    key: 'physics',
    name: 'Physics',
    type: NATURAL_SCIENCE,
  },
  {
    key: 'spaceSciences',
    name: 'Space sciences',
    type: NATURAL_SCIENCE,
  },
  {
    key: 'otherNaturalSciences',
    name: 'Other natural sciences',
    type: NATURAL_SCIENCE,
  },
]

export const FIELDS_OF_STUDY = FIELDS.map((field) => ({
  ...field,
  name: `${field.type} - ${field.name}`,
  // name: `${field.name} (${field.type})`,
}))

export const TYPES_OF_DEGREE = [
  {
    key: 'bachelor',
    name: 'Bachelor',
  },
  {
    key: 'master',
    name: 'Master',
  },
  {
    key: 'doctoral',
    name: 'Doctoral',
  },
  {
    key: 'academic',
    name: 'Academic',
  },
  {
    key: 'executive',
    name: 'Executive',
  },
  {
    key: 'otherDegree',
    name: 'Other degree',
  },
]
