import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col, Table } from 'react-bootstrap'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { withTranslation } from 'react-i18next'
class Sbapaymentinformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        month: '',
        year: '',
        participants: [],
      },
      filterData: {
        month: '',
        year: '',
        status: '',
      },
      participants: [],
      months: [
        { name: 'January', value: 1 },
        { name: 'February', value: 2 },
        { name: 'March', value: 3 },
        { name: 'April', value: 4 },
        { name: 'May', value: 5 },
        { name: 'June', value: 6 },
        { name: 'July', value: 7 },
        { name: 'August', value: 8 },
        { name: 'September', value: 9 },
        { name: 'October', value: 10 },
        { name: 'November', value: 11 },
        { name: 'December', value: 12 },
      ],
      years: [],
      statusoptions: [
        { name: 'Submitted', value: 'Submitted' },
        { name: 'Approved', value: 'Approved' },
        { name: 'Paid', value: 'Paid' },
      ],
      policypage: '',
      showHide: false,
      loading: true,
      loadingReqs: true,
      payments: [],
      showForm: false,
      errormsg: '',
      successmsg: '',
      submitted: false,
      submitFilter: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.selectParticipant = this.selectParticipant.bind(this)
    this.loadingRequests = this.loadingRequests.bind(this)

    this.success_notify = React.createRef()
    this.error_notify = React.createRef()
  }

  async componentDidMount() {
    var currentYear = new Date().getFullYear()
    var years = []
    for (var i = 2020; i <= currentYear; i++) {
      years.push({ name: i.toString(), value: i })
    }
    this.setState({ years })

    const page_api_url = process.env.REACT_APP_API_URL + '/api/pages/2'
    await axios
      .get(page_api_url)
      .then((response) => {
        // handle success
        const { data } = response.data
        this.setState({ policypage: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const api_url = process.env.REACT_APP_API_URL + '/api/getSbaAssociatedParticipants'
    await axios
      .get(api_url, { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } })
      .then((response) => {
        // handle success
        const { data } = response.data
        this.setState({ participants: data, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })

    this.loadingRequests()
  }

  loadingRequests() {
    const pay_api_url = process.env.REACT_APP_API_URL + '/api/listSbaPaymentInformation'
    axios
      .post(pay_api_url, this.state.filterData, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      })
      .then((response) => {
        // handle success
        const { success } = response.data
        this.setState({ payments: success, loadingReqs: false, submitFilter: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
  }

  selectParticipant = (event, value) => {
    const { formData } = this.state
    if (value !== null) {
      formData['participants'] = value
    } else {
      formData['participants'] = ''
    }
    this.setState({ formData })
  }

  handleMonth = (event, value) => {
    const { formData } = this.state
    if (value !== null) {
      formData['month'] = value.value
    } else {
      formData['month'] = ''
    }
    this.setState({ formData })
  }

  handleYear = (event, value) => {
    const { formData } = this.state
    if (value !== null) {
      formData['year'] = value.value
    } else {
      formData['year'] = ''
    }
    this.setState({ formData })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handleFilterMonth = (event, value) => {
    const { filterData } = this.state
    if (value !== null) {
      filterData['month'] = value.value
    } else {
      filterData['month'] = ''
    }
    this.setState({ filterData })
  }

  handleFilterYear = (event, value) => {
    const { filterData } = this.state
    if (value !== null) {
      filterData['year'] = value.value
    } else {
      filterData['year'] = ''
    }
    this.setState({ filterData })
  }

  handleFilterStatus = (event, value) => {
    const { filterData } = this.state
    if (value !== null) {
      filterData['status'] = value.value
    } else {
      filterData['status'] = ''
    }
    this.setState({ filterData })
  }

  handleFilterSubmit = () => {
    this.setState({ submitFilter: true }, () => {
      this.loadingRequests()
    })
  }

  requestPaymentForm() {
    this.setState({ showForm: true })
  }

  backPaymentList() {
    this.setState({ showForm: false })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/addSbaPaymentInformation'

      axios
        .post(sector_api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          // handle success
          this.props.updateProfile()
          this.setState({
            submitted: false,
            loadingReqs: true,
            showForm: false,
            successmsg: 'Request added successfully',
          })
          this.success_notify.current.handleOpen()
          this.loadingRequests()
        })
        .catch((error) => {
          if (error.response.status === 401) {
            const { data } = error.response
            if (data.error) {
              this.setState({ submitted: false, errormsg: data.error })
              this.error_notify.current.handleOpen()
            }
          } else {
            this.setState({ submitted: false, errormsg: 'Something went wrong, please try again' })
            this.error_notify.current.handleOpen()
          }
        })
    })
  }

  render() {
    const { submitted, submitFilter, loading, participants, formData, filterData, showForm } =
      this.state
    const { t } = this.props
    if (loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div className="registration-min w-100 seller-details-min mb-4">
        <Helmet>
          <title>SBA Profile | BridgeWhat: Let's Grow!</title>
        </Helmet>
        <div className="top-heading">
          <Container className="d-flex justify-content-between flex-wrap align-items-center">
            <h6>{t('SBA Payment Information')}</h6>
            {!showForm && (
              <Button
                type="button"
                className="submit-btn m-0 border-0 MuiButton-root MuiButton-contained"
                onClick={() => this.requestPaymentForm()}
              >
                {t('Payment Request')}
              </Button>
            )}
            {showForm && (
              <Button
                type="button"
                className="submit-btn m-0 border-0 MuiButton-root MuiButton-contained"
                onClick={() => this.backPaymentList()}
              >
                {t('Back to List')}
              </Button>
            )}
          </Container>
        </div>
        {showForm && (
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <Container className="sba_payment">
              <div className="boxshadow p-3 pt-1 pb-4 ">
                <Row>
                  <Col lg="12" className="pb-3">
                    <h6>{t('Payment Request')}</h6>
                  </Col>
                  <Col lg="12">
                    <label>{t('Choose time Period')}</label>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label>{t('Month')}</label>
                      <Autocomplete
                        options={this.state.months}
                        getOptionLabel={(option) => option.name}
                        name="month"
                        selected={formData.month}
                        onChange={this.handleMonth}
                        renderInput={(params) => (
                          <TextValidator
                            Select
                            {...params}
                            fullWidth
                            variant="outlined"
                            placeholder={t('Choose Month')}
                            value={formData.month}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label>{t('Year')} </label>
                      <Autocomplete
                        options={this.state.years}
                        getOptionLabel={(option) => option.name}
                        name="year"
                        selected={formData.year}
                        onChange={this.handleYear}
                        renderInput={(params) => (
                          <TextValidator
                            Select
                            {...params}
                            fullWidth
                            variant="outlined"
                            placeholder={t('Choose Year')}
                            value={formData.year}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="form-group">
                      <label>{t('Select User From List')}</label>
                      <Autocomplete
                        id="combo-box-demo"
                        fullWidth
                        multiple
                        options={participants}
                        onChange={this.selectParticipant}
                        getOptionLabel={(option) => option.company_name}
                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            variant="outlined"
                            value={formData.participants}
                            validators={['required']}
                            errorMessages={['This field is required']}
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="mt-4">
                    <strong>
                      {t(
                        'As a BridgeWhat Strategic Business Advidor I declare that I have worked four hours with each of the select users in the designated month.'
                      )}
                    </strong>
                    <div className="privact-check">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck"
                          required
                        />
                        <label
                          className="custom-control-label mb-0 ml-0"
                          htmlFor="customCheck"
                          onClick={() => this.handleModalShowHide()}
                        >
                          {t('Read Privacy policy')}
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <Button
                        type="submit"
                        variant="contained"
                        className="submit-btn mb-3 "
                        disabled={submitted}
                      >
                        {(submitted && t('Submit payment')) || (!submitted && t('Submit payment'))}
                      </Button>
                    </div>
                  </div>
                </Row>
              </div>
            </Container>
          </ValidatorForm>
        )}

        {!showForm && (
          <Container>
            <Row>
              <Col lg="3">
                <div className="side_filter">
                  <strong className="pb-4 d-block">{t('Filter By')}:</strong>
                  <ValidatorForm ref="form" onSubmit={this.handleFilterSubmit}>
                    <Row>
                      <Col md="12">
                        <div className="form-group">
                          <label>{t('Month')}</label>
                          <Autocomplete
                            options={this.state.months}
                            getOptionLabel={(option) => option.name}
                            name="month"
                            selected={filterData.month}
                            onChange={this.handleFilterMonth}
                            renderInput={(params) => (
                              <TextValidator
                                Select
                                {...params}
                                fullWidth
                                variant="outlined"
                                placeholder={t('Choose Month')}
                                value={filterData.month}
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label>{t('Year')} </label>
                          <Autocomplete
                            options={this.state.years}
                            getOptionLabel={(option) => option.name}
                            name="year"
                            selected={filterData.year}
                            onChange={this.handleFilterYear}
                            renderInput={(params) => (
                              <TextValidator
                                Select
                                {...params}
                                fullWidth
                                variant="outlined"
                                placeholder={t('Choose Year')}
                                value={filterData.year}
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label>{t('Status')} </label>
                          <Autocomplete
                            options={this.state.statusoptions}
                            getOptionLabel={(option) => option.name}
                            name="status"
                            selected={filterData.status}
                            onChange={this.handleFilterStatus}
                            renderInput={(params) => (
                              <TextValidator
                                Select
                                {...params}
                                fullWidth
                                variant="outlined"
                                placeholder={t('Choose Status')}
                                value={filterData.status}
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <Button
                          type="submit"
                          variant="contained"
                          className="submit-btn apply-filter mt-2 mb-2"
                          disabled={submitFilter}
                        >
                          {(submitFilter && t('Applying')) || (!submitFilter && t('Apply filter'))}
                        </Button>
                      </Col>
                    </Row>
                  </ValidatorForm>
                </div>
              </Col>
              <Col lg="9">
                <div className="boxshadow p-3 pt-1 pb-4 ">
                  <Row>
                    <Col lg="12">
                      {this.state.loadingReqs && (
                        <div className="loader-min">
                          <div className="loader"></div>
                        </div>
                      )}
                      {!this.state.loadingReqs && (
                        <Table
                          hover
                          bordered
                          responsive
                          className="needtable table-striped need-table"
                        >
                          <thead>
                            <tr>
                              <th>{t('Date')}</th>
                              <th>{t('Participant')}</th>
                              <th>{t('Status')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.payments.map((payment) => (
                              <tr>
                                <td>
                                  {payment.month}/{payment.year}
                                </td>
                                <td>{payment.participants}</td>
                                <td>{payment.status}</td>
                              </tr>
                            ))}
                            {this.state.payments.length === 0 && (
                              <tr>
                                <td colspan="3">no data yet</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <Modal show={this.state.showHide} backdrop="static">
          <Modal.Header>
            <Modal.Title
              dangerouslySetInnerHTML={{
                __html: this.state.policypage.name,
              }}
            ></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.policypage.content,
              }}
            ></div>
            <Button
              closeButton
              onClick={() => this.handleModalShowHide()}
              className="submit-btn mt-1 ml-0 mr-0 mb-1 border-0 MuiButton-root MuiButton-contained"
            >
              Agree and continue
            </Button>
          </Modal.Body>
        </Modal>
        {/* TODO: Add notification errormsg and successmsg */}
      </div>
    )
  }
}
export default withTranslation()(Sbapaymentinformation)
