import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Snackbar } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { apiPost } from 'api'
import { RGPD } from 'entities/routes'

const RGPDBox = ({ t, userDetail }) => {
  var SNACKBAR_INITIAL_DATA = {}
  useEffect(() => {
    setSnackbarData({
      isOpen: userDetail.accept_rgpd && userDetail.accept_rgpd === true ? false : true,
      text: t('New RGPD notification message'),
      actions: [{ text: t('Confirm'), action: () => closeSnackbar() }],
    })
  }, [userDetail])

  const [snackbarData, setSnackbarData] = useState(SNACKBAR_INITIAL_DATA)
  const closeSnackbar = () => {
    apiPost(RGPD, { accept: true })
    setSnackbarData({ ...SNACKBAR_INITIAL_DATA, isOpen: false })
  }

  const action = (
    <div>
      <span sx={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: snackbarData.text }}></span>
      <Button
        onClick={closeSnackbar}
        variant="contained"
        sx={{
          ml: 2,
          borderRadius: '48px !important',
          backgroundColor: colorsByKey.accentBg,
        }}
      >
        {t('Accept')}
      </Button>
    </div>
  )

  return <Snackbar open={snackbarData.isOpen} action={action} sx={{ width: '100%' }} />
}
export default withTranslation()(RGPDBox)
