import React from 'react'
import axios from 'axios'
import history from '../../../history'
import Button from '@material-ui/core/Button'
import { Helmet } from 'react-helmet'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Container, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Textarea from '@material-ui/core/TextareaAutosize'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCloudUploadAlt, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
library.add(faCloudUploadAlt)
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  display: 'flex',
}

class SBAregistrationstepthreeform extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        company_sector: [],
        services: [],
        bio: '',
        photo: '',
        files: null,
        cv: null,
        cv_documentfile: null,
        cv_documenttype: null,
      },
      services_selected: '',
      company_sector_selected: '',
      online_presence_name: '',
      companysectors: [],
      growth: [],
      submitted: false,
      loading: true,
      showHide: false,
      loaded: false,
    }

    this.onChange = this.onChange.bind(this)
    this.removeFile = this.removeFile.bind(this)
    this.onChangecv = this.onChangecv.bind(this)
    this.removeFilecv = this.removeFilecv.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  onChange(e) {
    var files = e.target.files

    if (!files.length) return

    var currentfile = files[0]

    if (currentfile.type === 'image/png' || currentfile.type === 'image/jpeg') {
      let reader = new FileReader()
      reader.onload = (e) => {
        const { formData } = this.state
        formData['files'] = e.target.result
        this.setState({ formData })
      }
      reader.readAsDataURL(currentfile)
    } else {
      alert('Only image can be uploaded')
    }
  }

  removeFile(f) {
    const { formData } = this.state
    formData['files'] = null
    this.setState({ formData })
  }

  onChangecv(e) {
    var files = e.target.files
    if (!files.length) return

    var currentfile = files[0]

    if (
      currentfile.type === 'image/png' ||
      currentfile.type === 'image/jpeg' ||
      currentfile.type === 'application/pdf'
    ) {
      let reader = new FileReader()
      reader.onload = (e) => {
        const { formData } = this.state
        formData['cv'] = e.target.result
        formData['cv_documentfile'] = URL.createObjectURL(currentfile)
        formData['cv_documenttype'] = currentfile.type
        this.setState({ formData })
      }
      reader.readAsDataURL(currentfile)
    } else {
      alert('Only image or pdf can be uploaded')
    }
  }

  removeFilecv(f) {
    const { formData } = this.state
    formData['cv'] = null
    this.setState({ formData })
  }

  async componentDidMount() {
    if (this.props.userDetail) {
      const success = this.props.userDetail
      const { formData } = this.state

      if ('user_services' in success) {
        if (success.user_services) {
          formData['services'] = success.user_services
          this.setState({ services_selected: '1' })
        }
      }

      if (success.company_sector) {
        formData['company_sector'] = success.company_sector
        this.setState({ company_sector_selected: '1' })
      }

      formData['bio'] = success.bio

      this.setState({ formData })
    }

    const page_api_url = process.env.REACT_APP_API_URL + '/api/pages/2'
    await axios
      .get(page_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ policypage: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const country_api_url = process.env.REACT_APP_API_URL + '/api/countries'
    await axios
      .get(country_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ countries: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const growth_api_url = process.env.REACT_APP_API_URL + '/api/services'
    await axios
      .get(growth_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ growth: data })
      })
      .catch((error) => {
        console.log(error)
      })

    const sector_api_url = process.env.REACT_APP_API_URL + '/api/sectors'
    await axios
      .get(sector_api_url)
      .then((response) => {
        const { data } = response.data
        this.setState({ companysectors: data, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleServices = (event, value) => {
    const { formData } = this.state
    formData['services'] = value
    if (value.length > 0) {
      this.setState({ services_selected: '1' })
    } else {
      this.setState({ services_selected: '' })
    }
    this.setState({ formData })
  }

  handleOnlinePresence = (event, value) => {
    const { formData } = this.state
    formData['online_presence'] = value
    if (value !== null) {
      this.setState({ online_presence_name: '1' })
    } else {
      this.setState({ online_presence_name: '' })
    }
    this.setState({ formData })
  }

  handleCompanySector = (event, value) => {
    const { formData } = this.state
    formData['company_sector'] = value
    if (value !== null) {
      this.setState({ company_sector_selected: '1' })
    } else {
      this.setState({ company_sector_selected: '' })
    }
    this.setState({ formData })
  }

  handleCountryOperation = (event, value) => {
    const { formData } = this.state
    formData['country'] = value
    if (value !== null) {
      this.setState({ country_of_selected: 1 })
    } else {
      this.setState({ country_of_selected: 0 })
    }
    this.setState({ formData })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
    console.log(formData)
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/addSbaRegistrationForm3'

      axios
        .post(sector_api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          this.setState({ submitted: false })
          history.push('/profile')
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, error: data.error })
              }
            } else {
              alert(this.props.t('Something went wrong, please try again'))
            }
          }
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted, loaded } = this.state
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div>
        <div className="boxshadow p-3 pt-1 mb-5">
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <Row>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="w-100">{t('BIO')}</label>
                  <Textarea
                    fullWidth
                    variant="outlined"
                    rowsMax={1}
                    aria-label="maximum height"
                    placeholder=""
                    name="bio"
                    onChange={this.handleChange}
                    value={formData.bio}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-md-6">
                <label className="w-100">{t('Upload Photo')}</label>
                <div className="border p-3 logoimg_insert">
                  {formData.files ? (
                    <img src={formData.files} alt="#" className="img-fluid" />
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faCloudUploadAlt} />
                      <span>{t('Insert Photo')}</span>
                    </div>
                  )}
                </div>
                <div style={styles} className="form-group">
                  <label className="custom-file-upload">
                    <input type="file" name="photo" onChange={this.onChange} />
                    {t('Upload Photo')}&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <label className="w-100">{t('Upload CV')}</label>
                <div className="border p-3 logoimg_insert">
                  {formData.cv_documentfile ? (
                    <div>
                      {formData.cv_documenttype === 'application/pdf' ? (
                        <div>
                          <a target="_blank" href={formData.cv_documentfile} rel="noreferrer">
                            <img
                              src="https://upload-icon.s3.us-east-2.amazonaws.com/uploads/icons/png/15519179861536080156-512.png"
                              className="img-fluid"
                              alt="#"
                            />
                          </a>
                        </div>
                      ) : (
                        <>
                          {formData.cv_documenttype === 'application/msword' ||
                          formData.cv_documenttype ===
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                            <div>
                              <a target="_blank" href={formData.cv_documentfile} rel="noreferrer">
                                <img
                                  src="https://icons.iconarchive.com/icons/zhoolego/material/256/Filetype-Docs-icon.png"
                                  className="img-fluid"
                                  alt="#"
                                />
                              </a>
                            </div>
                          ) : (
                            <div>
                              <img src={formData.cv_documentfile} className="img-fluid" alt="#" />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faCloudUploadAlt} />
                      <span>{t('Insert CV')}</span>
                    </div>
                  )}
                </div>
                <div style={styles} className="form-group">
                  <label className="custom-file-upload">
                    <input type="file" onChange={this.onChangecv} />
                    {t('Upload CV')}&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                  </label>
                  {this.state.formData.cv && (
                    <div className="file-preview" onClick={this.removeFilecv.bind(this)}>
                      {this.state.formData.cv.name}
                    </div>
                  )}
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="w-100">
                    {t('Expertese Sector')} <sup>*</sup>
                  </label>
                  <Autocomplete
                    options={this.state.companysectors}
                    getOptionLabel={(option) => option.name}
                    name="company_sector"
                    selected={formData.company_sector}
                    onChange={this.handleCompanySector}
                    renderInput={(params) => (
                      <TextValidator
                        Select
                        {...params}
                        fullWidth
                        variant="outlined"
                        name="company_sector_selected"
                        placeholder="Company's sector"
                        value={this.state.company_sector_selected}
                        validators={['required']}
                        errorMessages={t('This field is required')}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="w-100">
                    {t('Experience in the list of Bridgewhat')} <sup>*</sup>
                  </label>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={this.state.growth}
                    name="services"
                    selected={formData.services}
                    onChange={this.handleServices}
                    validators={['required']}
                    errorMessages={['This field is required']}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.service_name}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          color="primary"
                        />
                        {option.service_name}
                      </React.Fragment>
                    )}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextValidator
                        Select
                        {...params}
                        variant="outlined"
                        name="services_selected"
                        placeholder="Select services"
                        value={this.state.services_selected}
                        validators={['required']}
                        errorMessages={t('This field is required')}
                      />
                    )}
                  />
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <div className="form-group text-center d-flex justify-content-center mb-0">
                  <Button
                    type="button"
                    variant="contained"
                    className="submit-btn ml-0 mr-3"
                    onClick={() => this.props.prevStep(2)}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                    &nbsp;{t('Prev Step')}
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    className="submit-btn ml-3 mr-0"
                    disabled={submitted}
                  >
                    {(submitted && t('Your form is submitted!')) || (!submitted && t('Save'))}&nbsp;
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                </div>
              </div>
            </Row>
          </ValidatorForm>
        </div>
      </div>
    )
  }
}
export default withTranslation()(SBAregistrationstepthreeform)
