import React from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { Helmet } from 'react-helmet'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Container, Row, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
class Changepasswordpage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        current_password: '',
        password: '',
        c_password: '',
      },
      submitted: false,
      successmsg: '',
      open: false,
      errormsg: '',
    }

    this.handleChange = this.handleChange.bind(this)

    this.success_notify = React.createRef()
    this.error_notify = React.createRef()
  }
  handleClick = () => {
    this.setState({ open: true })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }

  componentDidMount() {
    if (!ValidatorForm.hasValidationRule('isPasswordMatch')) {
      ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        const { formData } = this.state
        if (value !== formData.password) {
          return false
        }
        return true
      })
    }
    if (!ValidatorForm.hasValidationRule('PasswordStrength')) {
      ValidatorForm.addValidationRule('PasswordStrength', (value) => {
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/
        return re.test(value)
      })
    }
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
      ValidatorForm.removeValidationRule('isPasswordMatch')
    }
    if (ValidatorForm.hasValidationRule('PasswordStrength')) {
      ValidatorForm.removeValidationRule('PasswordStrength')
    }
  }
  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/changePassword'
      axios
        .post(sector_api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          const { data } = response

          if (data.error) {
            this.setState({ errormsg: data.error, submitted: false })
            this.error_notify.current.handleOpen()
          } else {
            this.setState({ successmsg: data.success, submitted: false })
            this.success_notify.current.handleOpen()
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, errormsg: data.error })
                this.error_notify.current.handleOpen()
              }
            } else {
              this.setState({
                submitted: false,
                errormsg: this.props.t('Something went wrong, please try again'),
              })
              this.error_notify.current.handleOpen()
            }
          }
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted } = this.state
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }
    return (
      <div className="create-account-min w-100 pb-5 d-flex align-items-center">
        <Helmet>
          <title>Change Password | BridgeWhat: Let's Grow!</title>
          <body className="header_s" />
        </Helmet>
        <Container className="d-flex align-items-center ">
          <div className="create-account ">
            <div className="boxshadow p-3 pt-1">
              <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                <Row>
                  <Col md="12">
                    <h2 className="text-center h5 pb-3">{t('Change Password')}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="form-group">
                      <label>{t('Current Password')}</label>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="current_password"
                        type="password"
                        value={formData.current_password}
                        validators={['required']}
                        errorMessages={t('This field is required')}
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label>{t('Create Password')}</label>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        value={formData.password}
                        validators={['required', 'PasswordStrength']}
                        errorMessages={[
                          t('This field is required'),
                          t(
                            'password must be at least one number, one lowercase and one uppercase letter and min 6 character'
                          ),
                        ]}
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label>{t('Confirm Password')}</label>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="c_password"
                        type="password"
                        value={formData.c_password}
                        validators={['isPasswordMatch', 'required']}
                        errorMessages={[t('password mismatch'), t('This field is required')]}
                      />
                    </div>
                  </Col>
                  <Button
                    variant="contained"
                    type="submit"
                    className="submit-btn"
                    disabled={submitted}
                  >
                    {(submitted && t('Your form is submitted!')) ||
                      (!submitted && t('Change Password'))}
                  </Button>
                </Row>
              </ValidatorForm>
            </div>
          </div>
          <div className="w-75 site-logo">
            <img className="img-fluid" src={require('assets/images/logo.svg')} alt="logo" />
          </div>
        </Container>
        {/* TODO: Add notification errormsg or successmsg */}
      </div>
    )
  }
}
export default withTranslation()(Changepasswordpage)
