import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { CompanyAvatar, Scores } from 'components'
import { colorsByKey } from 'theme/colors'
import { NAME, ID, SUBSCRIPTION, IMAGE } from 'entities/companyFields'

export const ReviewsCard = ({ company = {}, text, score, total, localization }) => {
  const LIMIT_TEXT = 200

  const [displayShowMoreDescriptionBtn, setDisplayShowMoreDescriptionBtn] = useState(false)
  const [displayShowLessDescriptionBtn, setDisplayShowLessDescriptionBtn] = useState(false)

  useEffect(() => {
    if ((text || '').length > LIMIT_TEXT) setDisplayShowMoreDescriptionBtn(true)
  }, text)

  const handleShowMoreDescription = () => {
    setDisplayShowMoreDescriptionBtn(false)
    setDisplayShowLessDescriptionBtn(true)
  }
  const handleShowLessDescription = () => {
    setDisplayShowMoreDescriptionBtn(true)
    setDisplayShowLessDescriptionBtn(false)
  }

  return (
    <Box
      className="ReviewsCard"
      sx={{ display: 'flex', p: 2, borderRadius: '6px', boxShadow: '2px 0px 8px rgba(0,0,0,0.10)' }}
    >
      {(company || {})[NAME] && (
        <CompanyAvatar
          name={company[NAME]}
          subscription={company[SUBSCRIPTION]}
          image={company[IMAGE]}
          userId={company[ID]}
          size="sm"
          variant="rounded"
          customSx={{ mr: 2 }}
        />
      )}

      <Box>
        <Scores total={total} score={score} />
        <Typography
          variant="f16"
          color={colorsByKey.defaultText}
          sx={{ fontWeight: 'bold', mt: 2 }}
        >
          {company[NAME]}
        </Typography>
        <Typography variant="f12" color={colorsByKey.defaultText} sx={{ mt: 1 }}>
          {displayShowMoreDescriptionBtn && `${text.slice(0, LIMIT_TEXT)} ...`}
          {!displayShowMoreDescriptionBtn && text}
          {displayShowMoreDescriptionBtn && (
            <Typography component="span">
              ...
              <Typography
                onClick={handleShowMoreDescription}
                variant="link"
                component="span"
                sx={{
                  color: colorsByKey.accentText,
                  ml: 1,
                }}
              >
                {localization('view more')}
              </Typography>
            </Typography>
          )}
          {displayShowLessDescriptionBtn && (
            <Typography
              onClick={handleShowLessDescription}
              variant="link"
              component="span"
              sx={{
                color: colorsByKey.accentText,
                ml: 1,
              }}
            >
              {localization('view less')}
            </Typography>
          )}
        </Typography>
      </Box>
    </Box>
  )
}
