import React from 'react'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import dateFormat from 'utils/dateFormat'

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#82a4cc',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #a3a5a8`,
    backgroundColor: '#a3a5a8',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

class ListReferral extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      loading: true,
    }

    this.handleChecked = this.handleChecked.bind(this)
  }
  async componentDidMount() {
    const api_url = process.env.REACT_APP_API_URL + '/api/listmyReferral'
    await axios
      .post(
        api_url,
        { reffer_type: this.props.referpage },
        { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
      )
      .then((response) => {
        const { success } = response.data
        this.setState({ items: success, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleChecked(e, i) {
    const { name, checked } = e.target
    const { items } = this.state
    const payload = { id: items[i]['id'] }
    if (checked) {
      payload.reffer_status = 'yes'
      items[i][name] = 'yes'
    } else {
      payload.reffer_status = 'no'
      items[i][name] = 'no'
    }

    this.setState({ items })

    var saving_api = process.env.REACT_APP_API_URL + '/api/changeMyReferral'
    axios
      .post(saving_api, payload, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      })
      .catch((error) => {
        alert(this.props.t('Something went wrong, please try again'))
      })
  }

  render() {
    const { t } = this.props
    const { items, loading } = this.state
    const { referpage } = this.props

    if (loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div>
        <Row>
          <Col md="12" className="mb-5">
            <h6>{t(referpage)}</h6>
          </Col>
        </Row>
        {items.length === 0 && (
          <div>
            <p>{t('no data yet!')}</p>
          </div>
        )}
        {items.map((item, index) => (
          <>
            <Row>
              <Col md="5" className="testimonial-info d-flex flex-wrap">
                <div className="test-info">
                  <h5>{item.name}</h5>
                  <pre>
                    {t('Company Name')}: <span>{item.company_name || item.name}</span>
                  </pre>
                  <pre>
                    {t('Job Function')}: <span>{item.job_function}</span>
                  </pre>
                  {(item.sector || {}).name && (
                    <pre>
                      {t('Sector')}: <span>{(item.sector || {}).name}</span>
                    </pre>
                  )}

                  {item.reffer_type === 'endorsement' && (
                    <>
                      <pre>
                        {t('Project Name')}: <span>{item.project_name}</span>
                      </pre>
                      <pre>
                        {t('Timestamp')}:
                        <span>
                          {item.from_date &&
                            item.to_date &&
                            `${dateFormat(item.from_date)} - ${dateFormat(item.to_date)}`}
                        </span>
                      </pre>
                    </>
                  )}
                </div>
              </Col>
              <Col md="7" className="pb-3 testimonial-text">
                <p>{item.description}</p>
                <div className="d-flex flex-wrap justify-content-end">
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>
                        <IOSSwitch
                          checked={item.show_public === 'yes'}
                          onChange={(e) => this.handleChecked(e, index)}
                          name="show_public"
                        />
                      </Grid>
                      <Grid item>{t('Show Public')}</Grid>
                    </Grid>
                  </Typography>
                </div>
              </Col>
            </Row>
            <hr className="mb-4 pb-2"></hr>
          </>
        ))}
      </div>
    )
  }
}

export default withTranslation()(ListReferral)
