import axios from 'axios'
import qs from 'qs'

export const apiGet = (url, params) => {
  let finalUrl = process.env.REACT_APP_API_URL + url

  if (params) {
    finalUrl += `?${qs.stringify(params, { skipNulls: true })}`
  }

  return axios
    .get(finalUrl, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
    .then((res) => res.data)
}

export const apiPost = (url, data) =>
  axios
    .post(process.env.REACT_APP_API_URL + url, data, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
    .then((res) => res.data)

export const apiPut = (url, data) => {
  return axios
    .put(process.env.REACT_APP_API_URL + url, data, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
    .then((res) => res.data)
}
export const apiDelete = (url, params) => {
  let finalUrl = process.env.REACT_APP_API_URL + url

  if (params) {
    finalUrl += `?${qs.stringify(params, { skipNulls: true })}`
  }

  return axios
    .delete(finalUrl, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
    .then((res) => res.data)
}

export const apiFormDataPost = (url, data) =>
  axios
    .post(process.env.REACT_APP_API_URL + url, data, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)

export const apiFormDataPut = (url, data) =>
  axios
    .post(process.env.REACT_APP_API_URL + url + '?_method=PUT', data, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)

export const getUserInfo = (id) => apiPost('/api/userInfoForPublic', { id })

export const getCampaigns = (params) => apiGet('/api/campaigns', params)

export const getCampaign = (id) => apiGet(`/api/campaigns/${id}`)

export const createCampaign = (data) => apiFormDataPost('/api/campaigns', data)

export const updateCampaign = (id, data) => apiFormDataPut(`/api/campaigns/${id}`, data)

export const deleteCampaign = (id) => apiDelete(`/api/campaigns/${id}`)

export const getDetailBlog = (id) => apiGet(`/api/blogDetail/${id}`)
