import {
  SELL,
  BUY,
  SAMPLINGS,
  CAMPAIGNS,
  PROTOCOLS,
  COMPANIES,
  POSTS,
  ADVISORS,
  NEW_PARTNERSHIPS,
  ACCELERATORS,
} from '../entities/feed'

import {
  CREDENTIALS,
  // CERTIFICATES,
  // REVIEWS,
  // AWARDS,
  RECOMMENDATIONS,
  TESTIMONIALS,
} from '../entities/company'

import {
  TYPE,
  KEY,
  ID,
  CREATED_AT,
  USER,
  TITLE,
  HEADLINE,
  DESCRIPTION,
  BUSINESS_SERVICE_ITEM,
  BUSINESS_SERVICE_CATEGORIES,
  BUSINESS_SERVICE,
  TARGET_INDUSTRY_SECTORS,
  TARGET_COUNTRIES,
  TARGET_COMPANY_SIZE,
  TARGET_COMPANY_REVENUE,
  PROPOSAL_DIFFERENTIATOR_FACTORS,
  BROCHURE,
  BROCHURES,
  FILES,
  HEADER_IMAGE,
  THUMBNAIL_IMAGE,
  WATERMARK_IMAGE,
  IMAGE,
  IMAGES,
  CLOSING_DATE,
  // DEADLINE,
  BW20LOGS_SERVICES,
  CAMPAIGNS_FIELDS,
  PUBLICATION_DATE,
  PREFERRED_DATE,
  NUMBER_OF_SAMPLES,
  CONTENT,
  STATUS,
  PUBLISHED,
} from 'entities/formFields'
import {
  NAME,
  HIDE_IDENTITY,
  IS_SELLER,
  OPERATION_COUNTRIES,
  SIZE,
  REVENUE,
  EMAIL,
  PASSWORD,
  INDUSTRY_SECTORS,
  PHONE,
  WEBSITE,
  SUBSCRIPTION,
  LEGAL_NAME,
  FOUNDATION_YEAR,
  LEVERS_OF_GROWTH,
  FISCAL_NUMBER,
  SOCIAL_MEDIA,
  LINKEDIN,
  INSTAGRAM,
  FACEBOOK,
  TWITTER,
  YOUTUBE,
  FINANTIAL_REPORT,
  DELIQUENCY_RATING,
  FAILURE_RATING,
  TAX,
  SOCIAL_SECURITY,
  EXPERIENCE_START_DATE,
  EXPERIENCE_YEARS,
  LANGUAGES,
  FULL_ADDRESS,
  ADDRESS,
  POSTAL_CODE,
  CITY,
  COUNTRY,
  STATE,
  USER_TYPE,
  ADVISOR,
  COMPANY,
  ABOUT,
  AVATAR,
  // ADVISORS_FIELDS,
  JOB_FUNCTION,
  MAIN_CAREER_ACHIEVEMENT,
  GEOGRAPHY,
  BUSINESS_AREAS,
  EXPERIENCE_PREVIOUS_FUNCIONS,
  USER_INDUSTRY_SECTORS,
  INVITATION_TYPE,
  SUBSCRIPTION_OPTION,
  SUBSCRIPTION_OPTION_EXPIRES_AT
} from 'entities/companyFields'
import moment from 'moment'
import { AREAS_OF_INNOVATION, BRAND_FAME } from 'entities/campaignExtraFields'

const toBool = (v) => (typeof v === 'string' && v.toLowerCase() === 'no' ? false : !!v)

const normalizeAddress = ({ address, city, postalCode, country }) => {
  let ad = ''
  if (address) ad += `${address}, `
  if (city) ad += `${city} `
  if (postalCode) ad += `- ${postalCode}. `
  if (country) ad += `${country}`
  return ad
}
const normalizeMarketplaceData = (post) => {
  const companyData = (post.user || {}).id ? normalizeCompanyData(post.user) : {}
  const type = post.type === 'offer' || post.type === SELL ? SELL : BUY

  // console.log(post)
  // console.log(Object.keys(post))
  // BACKEND
  //   // Missing:
  //   // // image
  //   // // createdAt
  //   // // operationCountries -> ya está
  //   // // operationSectors -> ya está
  //   // // targetCompanySize
  //   // // targetCompanyRevenue
  //   // Fix:
  //   // // targetIndustrySectors should be an array of sectors
  //   // // services: post.user.services is a dirty string, should be an array
  //   // // company countries of operation should be an array, got only one id
  //   // // company sectors of operation should be an array, got only one id
  // console.log('normalizeMarketplaceData', post)

  // revisar que parece que viene ya el user services
  return {
    [TYPE]: type,
    [KEY]: `${post.type}-${post.id}`,
    [ID]: post.id,
    [TITLE]: post.title,
    [DESCRIPTION]: post.description,
    [BROCHURES]: post.files,
    [FILES]: post.files,
    [TARGET_INDUSTRY_SECTORS]: post.sectors, // industrySectors
    [LEVERS_OF_GROWTH]: post.service
      ? [
          {
            [ID]: post.service.id,
            [NAME]: post.service.service_name,
            color: post.service.color,
            slug: post.service.icon,
            icon: require(`assets/icons/20LOG/${post.service.icon}.svg`),
          },
        ]
      : [{}],
    [BUSINESS_SERVICE]: post.service,
    [BUSINESS_SERVICE_ITEM]: post.service,
    services: post.services,
    [TARGET_COUNTRIES]: post.countries,
    [CREATED_AT]: post.createdAt,
    [CLOSING_DATE]: post.deadline,
    [STATUS]: post.status,
    [IMAGE]: post.image ? post.image : (post.files || [])[0],
    [USER]: { ...companyData },
    [TARGET_COMPANY_SIZE]: post.targetCompanySize,
    [TARGET_COMPANY_REVENUE]: post.targetCompanyRevenue,
    [PROPOSAL_DIFFERENTIATOR_FACTORS]: (post.proposalFactors === '-'
      ? []
      : post.proposalFactors || []
    ).map((proposalFactor) => ({
      [ID]: proposalFactor.id,
      [NAME]: proposalFactor.factorName,
      [TYPE]: proposalFactor.factorStage,
    })),
    [AREAS_OF_INNOVATION]: post.innovation_areas,
    strengths: post.strengths,
    [BRAND_FAME]: post.what_brand_famous,
    [PUBLICATION_DATE]: post.opening_date,
    need: post.need,
    reviews: post.scoring_board_reviews,
    testimonials: post.scoring_board_testimonials,
    recommendations: post.scoring_board_recommendations,
    certificates: post.scoring_board_certificates,
    awards: post.scoring_board_awards,
    publications: post.scoring_board_publications,
    canTalk: companyData.canTalk,
  }
}
const normalizeSamplingData = (post) => {
  // console.log('Sampling', post)
  // // BACKEND
  // // Missing:
  // // // deadline,
  // // // targetIndustrySectors,
  // // // targetCountries,
  // // // targetCompanySize,
  // // // targetCompanyRevenue
  // // // image -> ya está
  // // // images
  // // Fix:
  // // // description is html
  // console.log('normalizeSamplingData', post)
  const companyData = (post.user || {}).id ? normalizeCompanyData(post.user) : {}
  const headerImage = post.headerImage || {}
  const headerImageData = {
    ...headerImage,
    url: headerImage.url,
    name: headerImage.originalName,
  }
  return {
    [TYPE]: SAMPLINGS,
    [KEY]: `${SAMPLINGS}-${post.id}`,
    [ID]: post.id,
    [TITLE]: post.title,
    [DESCRIPTION]: post.description,
    [BROCHURES]: post.brochure ? [post.brochure] : [],
    [BROCHURE]: post.brochure,
    [HEADER_IMAGE]: headerImageData,
    [CREATED_AT]: post.createdAt,
    dateOfGiveAway: post.dateOfGiveAway,
    [NUMBER_OF_SAMPLES]: post.noAvailableOffers,
    winners: post.winners,
    winningCriteria: post.winningCriteria,
    [IMAGES]: post.images,
    [STATUS]: post.status,
    [USER]: { ...companyData },
    [PUBLISHED]: post.published,
    [PREFERRED_DATE]: post.preferedDate,
    canTalk: post.user.canTalk,
  }
}
export const normalizeCampaignData = (post) => {
  // console.log('campaign', post)

  const companyData = (post.user || {}).id ? normalizeCompanyData(post.user) : {}
  const image = post.image || {}
  const imageData = {
    ...image,
    url: image.url,
    name: image.originalName || image.original_name,
  }
  let documents = []
  if (post.document1) documents.push(post.document1)
  if (post.document2) documents.push(post.document2)
  if (post.document3) documents.push(post.document3)
  if (post.document4) documents.push(post.document4)
  if (post.document5) documents.push(post.document5)

  let data = {}
  CAMPAIGNS_FIELDS.forEach(({ type, key, backendKey, multiple, getText = (text) => text }) => {
    if (type === 'select') {
      if (multiple) {
        if (typeof post[backendKey] === 'string' || typeof post[backendKey] === null)
          data[key] = post[backendKey] || ''
        else {
          data[key] = (post[backendKey] || []).map((item) => {
            if (typeof item === 'object') return item
            else if (item === '') return {}
            else
              return {
                key: item,
                id: item,
                label: getText(item),
                name: getText(item),
              }
          })
        }
      } else
        data[key] = {
          key: post[backendKey],
          id: post[backendKey],
          label: getText(post[backendKey]),
          name: getText(post[backendKey]),
        }
    } else data[key] = post[backendKey]
  })
  return {
    ...post,
    [TYPE]: CAMPAIGNS,
    [KEY]: `${CAMPAIGNS}-${post.id}`,
    [ID]: post.id,
    ...data,
    [IMAGE]: imageData,
    [HEADER_IMAGE]: imageData,
    [USER]: { ...companyData },
    [OPERATION_COUNTRIES]: (post.countries || []).map((country) => ({
      [ID]: country.id,
      [NAME]: country.name,
      shortName: country.short_name,
    })),
    [BW20LOGS_SERVICES]: (post.bw20logs_services || []).map(({ id, service_name }) => ({
      key: id,
      [ID]: id,
      [NAME]: service_name,
    })),
    canTalk: post.user.canTalk,
  }
}
const normalizeProtocolData = (post) => {
  // console.log('protocol', post)
  const companyData = (post.user || {}).id ? normalizeCompanyData(post.user) : {}
  const image = post.image || {}
  const imageData = {
    ...image,
    url: image.url,
    name: image.originalName,
  }
  return {
    [TYPE]: PROTOCOLS,
    [KEY]: `${PROTOCOLS}-${post.id}`,
    [ID]: post.id,
    [TITLE]: post.title,
    [DESCRIPTION]: post.description,
    [BROCHURE]: post.brochure,
    [BROCHURES]: post.brochure ? [post.brochure] : [], // keep is to print the brochures on the card
    [CREATED_AT]: post.createdAt,
    [IMAGE]: imageData,
    [HEADER_IMAGE]: imageData,
    [USER]: { ...companyData },
    [STATUS]: post.status,
    [TARGET_COUNTRIES]: post.countries,
    [TARGET_INDUSTRY_SECTORS]: post.sectors,
    [PUBLICATION_DATE]: post.dateOfPublication,
    [CLOSING_DATE]: post.dateOfValidity,
    [BUSINESS_SERVICE_CATEGORIES]: (post.categories || []).map(({ id, category_name }) => ({
      [ID]: id,
      [NAME]: category_name,
    })),
    canTalk: post.user.canTalk,
    // adminStatus
    // userStatus
    // publishDate
    // dateOfPublication
    // dateOfValidity
    // services: [{}],
    // sectors: [{}],
    // countries: [{}],
    // updatedAt
    // // BACKEND
    // // Missing:
    // // // NO DATA AVAILABLE
    // // Fix:
    // // // The fetch is a POST and requieres filters in order to succeed
  }
}
export const normalizeCompanyData = (company) => {
  // // UX
  // // Market entry, sales conversion, Multichannel dev, Informe financiero (cv?),
  // // No info available from the right box of the card

  // console.log("normalizeCompanyData", company);
  // 20log palancas de crecimiento
  // console.log('company', company)
  // TODO: bio? companySector phoneNumber sectorsOfExpertise

  const userServices = !!(company.user_services || []).length
    ? company.user_services
    : company.userServices
  const userCountries = !!(company.user_country_of_operations || []).length
    ? company.user_country_of_operations
    : company.userCountryOfOperations
  const credentials = company.credentials || {}

  return {
    [TYPE]: COMPANIES,
    [USER_TYPE]: COMPANY,
    [KEY]: `${COMPANIES}-${company.id}`,
    [ID]: company.id,
    [TITLE]: company.company_name,
    [NAME]: company.company_name,
    [HEADLINE]: company.headline || company.headlines || '',
    [DESCRIPTION]: company.about_company,
    [BROCHURE]: company.brochures
      ? `${process.env.REACT_APP_API_URL}${company.brochures.replace('show/', '')}`
      : '',
    // files: [company.cv],
    [OPERATION_COUNTRIES]: (userCountries || []).map((country) => ({
      [ID]: country.id,
      [NAME]: country.name,
      shortName: country.short_name,
    })),
    [SIZE]: company.no_of_emplyees,
    [REVENUE]: company.last_year_turnover,
    // [INDUSTRY_SECTORS]: (company.expertises || []).map((service) => ({
    //   [ID]: service.id,
    //   [NAME]: service.name,
    // })),
    [INDUSTRY_SECTORS]: company.company_sector,
    updatedAt: company.updated_at,
    [EMAIL]: company.company_email || company.contact_email || company.email,
    [IMAGE]: company.company_logo ? `${process.env.REACT_APP_API_URL}${company.company_logo}` : '', // TODO: Fix backend image /public ?
    [PHONE]: company.company_phone,
    [WEBSITE]: company.website,
    [SUBSCRIPTION]: company.subscription,
    [IS_SELLER]: !!company.is_seller,
    [LEGAL_NAME]: company.company_legal_name,

    [INVITATION_TYPE]: company.invitation_type,
    [FOUNDATION_YEAR]: null, // TODO:
    [LEVERS_OF_GROWTH]: (userServices || []).map((service) => ({
      [ID]: service.id,
      [NAME]: service.service_name,
      color: service.color,
      slug: service.icon,
      icon: require(`assets/icons/20LOG/${service.icon}.svg`),
    })),
    [FISCAL_NUMBER]: company.vatnumber,
    [CREATED_AT]: company.created_at,
    [STATUS]: company.status,
    address: normalizeAddress({
      address: company.address1,
      city: company.city,
      postalCode: company.postal_code,
      country: company.country,
    }),
    fullAddress: {
      address: company.address1,
      city: company.city,
      postalCode: company.postal_code,
      country: company.country,
      state: company.state,
    },
    contact: {
      [EMAIL]: company.company_email,
      [PHONE]: company.company_phone,
      user: {
        [NAME]: company.contact_name,
        [EMAIL]: company.contact_email,
        [PHONE]: company.contact_phone,
        [JOB_FUNCTION]: company.contact_job_function,
        [SOCIAL_MEDIA]: {
          [LINKEDIN]: company.personal_linkedin,
          [INSTAGRAM]: company.personal_instagram,
          [FACEBOOK]: company.personal_facebook,
          [TWITTER]: company.personal_twitter,
          [YOUTUBE]: company.personal_youtube,
          [WEBSITE]: company.personal_website,
        },
      },
    },
    payment: {
      [LEGAL_NAME]: company.company_legal_name,
      [FISCAL_NUMBER]: company.vatnumber,
      stripe: {
        [ID]: company.stripe_id,
        customer: {
          [ID]: company.stripe_customer_id,
        },
      },
      [SUBSCRIPTION]: {
        status: company.stripe_subscription_status,
        [ID]: company.subscription_id,
        expiryDate: company.subscription_expiry_date,
        [NAME]: company.subscription_name,
        periodicity: company.subscription_type,
      },
    },
    [LINKEDIN]: company.linkedin,
    [INSTAGRAM]: company.instagram,
    [FACEBOOK]: company.facebook,
    [TWITTER]: company.twitter,
    [YOUTUBE]: company.youtube,
    [SOCIAL_MEDIA]: {
      [LINKEDIN]: company.linkedin,
      [INSTAGRAM]: company.instagram,
      [FACEBOOK]: company.facebook,
      [TWITTER]: company.twitter,
      [YOUTUBE]: company.youtube,
    },
    [FINANTIAL_REPORT]: {
      [DELIQUENCY_RATING]: company.delinquency_rating,
      [FAILURE_RATING]: company.failure_rating,
      [TAX]: company.tax_debts,
      [SOCIAL_SECURITY]: company.social_security_debts,
    },
    [CREDENTIALS]: {
      ...credentials,
      [RECOMMENDATIONS]: { total: credentials.totalRecomendations },
      [TESTIMONIALS]: { total: credentials.totalTestimonials },
    },
    canTalk: company.canTalk,
    [SUBSCRIPTION_OPTION]: company.subscription_option,
    [SUBSCRIPTION_OPTION_EXPIRES_AT]: company.subscription_option_expires_at,
  }
}
export const normalizeAdvisorData = (user) => {
  // console.log('raw user', user)
  const numOfYears = moment().diff(moment(user.experience_start_date || ''), 'years')
  const credentials = user.credentials || {}

  return {
    [TYPE]: ADVISORS,
    [USER_TYPE]: ADVISOR,
    [KEY]: `${ADVISORS}-${user.id}`,
    [ID]: user.id,
    [NAME]: user.name,
    [JOB_FUNCTION]: user.job_function,
    [TITLE]: user.name,
    [EXPERIENCE_YEARS]: numOfYears > 0 ? `${numOfYears} year${numOfYears > 1 ? 's' : ''} of experience` : '',
    [EXPERIENCE_START_DATE]: user.experience_start_date
      ? moment(user.experience_start_date || '').format('yyyy-MM-DD')
      : null,
    [HIDE_IDENTITY]: user.hide_identity,
    // [CREATED_AT]: user.created_at,
    [DESCRIPTION]: user.bio,
    [ABOUT]: user.about_company,
    [LANGUAGES]: user.languages,
    [OPERATION_COUNTRIES]: user.user_country_of_operations,
    [LEVERS_OF_GROWTH]: (user.user_services || []).map((service) => ({
      [ID]: service.id,
      [NAME]: service.service_name,
      color: service.color,
      slug: service.icon,
      icon: require(`assets/icons/20LOG/${service.icon}.svg`),
    })),
    [SIZE]: user.no_of_emplyees,
    [REVENUE]: user.last_year_turnover,
    [USER_INDUSTRY_SECTORS]: user.user_industry_sectors,
    [INDUSTRY_SECTORS]: user.company_sector,
    [BUSINESS_AREAS]: user.business_areas,
    [EXPERIENCE_PREVIOUS_FUNCIONS]: user.experience_previous_functions,
    [EMAIL]: user.email,
    [PASSWORD]: user.password,
    // [IMAGE]: `${process.env.REACT_APP_API_URL}/show/images/company_logo/61d82b0726647.png`,
    [IMAGE]: user.avatar
      ? user.avatar.url
      : user.photo
      ? `${process.env.REACT_APP_API_URL}/${user.photo}`
      : user.image
      ? `${process.env.REACT_APP_API_URL}/${user.image}`
      : '',
    [BROCHURE]: user.brochure,
    [AVATAR]: user.avatar,
    [PHONE]: user.phone_number,
    [WEBSITE]: user.website,
    [FULL_ADDRESS]: normalizeAddress({
      address: user.address1,
      city: user.city,
      postalCode: user.postal_code,
      // country: user.country,
    }),
    [ADDRESS]: user.address1,
    [POSTAL_CODE]: user.postal_code,
    [CITY]: user.city,
    [COUNTRY]: user.country,
    [STATE]: user.state,

    [SOCIAL_MEDIA]: {
      [LINKEDIN]: user.linkedin,
      [INSTAGRAM]: user.instagram,
      [FACEBOOK]: user.facebook,
      [TWITTER]: user.twitter,
      [YOUTUBE]: user.youtube,
    },
    [LINKEDIN]: user.linkedin,
    [INSTAGRAM]: user.instagram,
    [FACEBOOK]: user.facebook,
    [TWITTER]: user.twitter,
    [YOUTUBE]: user.youtube,
    [CREDENTIALS]: {
      ...credentials,
      [RECOMMENDATIONS]: { total: credentials.totalRecomendations },
      [TESTIMONIALS]: { total: credentials.totalTestimonials },
    },
    [MAIN_CAREER_ACHIEVEMENT]: user.main_career_achievement,
    [GEOGRAPHY]: user.geography,
    canTalk: user.canTalk,
  }
}
export const normalizeUserData = (data) => {
  const normalizedUserData =
    data.type === ADVISOR || data.user_type === ADVISOR
      ? normalizeAdvisorData(data)
      : normalizeCompanyData(data)
  return {
    ...data,
    ...normalizedUserData,
  }
}

export const normalizePartnershipData = (p) => {
  const companyData = (p.user || {}).id ? normalizeUserData(p.user) : {}
  const headerImageData = {
    url: p.image_url,
    name: 'company logo',
  }

  return {
    [TYPE]: NEW_PARTNERSHIPS,
    [KEY]: `${NEW_PARTNERSHIPS}-${p.id}`,
    [ID]: p.id,
    [CREATED_AT]: p.createdAt,
    [DESCRIPTION]: p.description,
    [HEADER_IMAGE]: headerImageData,
    [IMAGE]: p.image_url,
    [WATERMARK_IMAGE]: p.image,
    [BROCHURE]: p.brochure_url,
    [BROCHURES]: p.brochure_url ? [{ url: p.brochure_url }] : [],
    [TITLE]: p.title,
    [CONTENT]: p.description, // is html code
    [USER]: { ...companyData },
    [STATUS]: p.status,
    [PUBLISHED]: toBool(p.published),
    [PUBLICATION_DATE]: p.date_of_publication,
    [TARGET_INDUSTRY_SECTORS]: p.company_sectors,
    [TARGET_COUNTRIES]: p.countries,
    [TARGET_COMPANY_SIZE]: p.user.no_of_emplyees,
    [TARGET_COMPANY_REVENUE]: p.user.last_year_turnover,
    partnerImage: p?.partner?.company_logo
      ? process.env.REACT_APP_API_URL + p?.partner?.company_logo
      : null,
    services: p?.services.map((s) => ({ name: s.category_name })),
    canTalk: p.user.canTalk,
  }
}

export const normalizeAcceleratorData = (p) => {
  const headerImageData = {
    url: p.image_url,
    name: 'image',
  }

  return {
    [TYPE]: ACCELERATORS,
    [KEY]: `${ACCELERATORS}-${p.id}`,
    [ID]: p.id,
    [CREATED_AT]: p.created_at,
    [DESCRIPTION]: p.description,
    [HEADER_IMAGE]: headerImageData,
    [IMAGE]: p.image_url,
    [BROCHURE]: p.brochure_url,
    [BROCHURES]: p.brochure_url ? [{ url: p.brochure_url }] : [],
    [TITLE]: p.title,
    [CONTENT]: p.description, // is html code
    [STATUS]: p.status,
    [PUBLISHED]: toBool(p.published),
    [PUBLICATION_DATE]: p.publication_date,
    [TARGET_INDUSTRY_SECTORS]: p.company_sectors,
    [TARGET_COUNTRIES]: [p.countrie],
    [USER]: normalizeUserData(p.user),
    [TARGET_COMPANY_SIZE]: p.user.no_of_emplyees,
    [TARGET_COMPANY_REVENUE]: p.user.last_year_turnover,
    canTalk: p.user.canTalk,
  }
}

export const normalizeBlogData = (post) => {
  const companyData = (post.user || {}).id ? normalizeUserData(post.user) : {}
  const leversOfGrowth = post.leversOfGrowth || post.bw20logServices || post.bw20log_services
  const headerImage = post.headerImage || {}
  const headerImageData = {
    ...headerImage,
    url: headerImage.url,
    name: headerImage.originalName,
  }
  const thumbnailImage = post.thumbnailImage || {}
  const thumbnailImageData = {
    ...thumbnailImage,
    url: thumbnailImage.url,
    name: thumbnailImage.originalName,
  }
  return {
    [TYPE]: POSTS,
    [KEY]: `${POSTS}-${post.id}`,
    [ID]: post.id,
    [CREATED_AT]: post.createdAt,
    [HEADER_IMAGE]: headerImageData,
    [THUMBNAIL_IMAGE]: thumbnailImageData,
    [WATERMARK_IMAGE]: post.image,
    [LEVERS_OF_GROWTH]: leversOfGrowth
      ? [
          {
            ...leversOfGrowth,
            [NAME]: leversOfGrowth.service_name,
            slug: leversOfGrowth.icon,
            icon: require(`assets/icons/20LOG/${leversOfGrowth.icon}.svg`),
          },
        ]
      : [],
    [TITLE]: post.title,
    [CONTENT]: post.description, // is html code
    [USER]: { ...companyData },
    [STATUS]: post.status,
    [PUBLISHED]: toBool(post.published),
    canTalk: post.user.canTalk,
  }
}
const normalizeAll = (post) => {
  if (post.cardContent) {
    if (post.entityType === 'MarketItem') return normalizeMarketplaceData(post.cardContent)
    if (post.entityType === 'pom_services') return normalizeProtocolData(post.cardContent)
    if (post.entityType === 'surprise_boxes') return normalizeSamplingData(post.cardContent)
    if (post.entityType === 'User' && post.cardContent.type !== 'advisor')
      return normalizeCompanyData(post.cardContent)
    if (post.entityType === 'Campaign') return normalizeCampaignData(post.cardContent)
    if (post.entityType === 'advisors' || post.cardContent.type === 'advisor')
      return normalizeAdvisorData(post.cardContent)
    if (post.entityType === 'blogs') return normalizeBlogData(post.cardContent)
    if (post.entityType === 'AlreadyHavePartnership')
      return normalizePartnershipData(post.cardContent)
    if (post.entityType === 'Accelerator') return normalizeAcceleratorData(post.cardContent)
    return post
  }
  return {}
}
export const normalizeData = (item, dataType) => {
  if (Array.isArray(item)) {
    return item.map((i) => normalizeData(i, dataType))
  }
  const { type } = item
  const useType = dataType || type
  if (useType === SELL || useType === BUY) return normalizeMarketplaceData(item)
  if (useType === CAMPAIGNS) return normalizeCampaignData(item)
  if (useType === SAMPLINGS) return normalizeSamplingData(item)
  if (useType === PROTOCOLS) return normalizeProtocolData(item)
  if (useType === COMPANIES) return normalizeCompanyData(item)
  if (useType === ADVISORS) return normalizeAdvisorData(item)
  if (useType === POSTS) return normalizeBlogData(item)
  if (useType === NEW_PARTNERSHIPS) return normalizePartnershipData(item)
  if (useType === ACCELERATORS) return normalizeAcceleratorData(item)
  return normalizeAll(item)
}
