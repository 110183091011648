import React, { useState, useEffect } from 'react'
import { createContext } from 'react'
import { NumberParam, StringParam, ArrayParam, withDefault, useQueryParams, BooleanParam } from 'use-query-params'
import { FEED_SECTION_TABS } from 'entities/feed'
import { normalizeFilters } from 'mixins/filterNormalizers'

const defaultValues = {
  functionValue: () => {},
  booleanValue: false,
  numberValue: 0,
  objectValue: {},
  arrayValue: [],
  dateValue: [null, null],
  stringValue: '',
}

const QueryFiltersContext = createContext({
  showFilters: defaultValues.arrayValue,
  filterBy: defaultValues.stringValue,
  toggleShowFilters: (type) => {},
  handleClearFilters: defaultValues.functionValue,
  handleFilterData: defaultValues.functionValue,
  filters: defaultValues.objectValue,
  savedFilters: defaultValues.objectValue,
  setFilter: (type, value) => {},
  tabDetails: defaultValues.objectValue,
  handleSelectServices: defaultValues.functionValue,
  handleSelectServiceCategories: defaultValues.functionValue,
  handleSelectSectors: defaultValues.functionValue,
  handleSelectCountries: defaultValues.functionValue,
  selectedCountries: defaultValues.arrayValue,
  defaultFilterValues: defaultValues.objectValue,
  formatFilterData: defaultValues.functionValue,
  handlePlus: defaultValues.functionValue,
})

export const QueryFiltersContextProvider = (props) => {
  const [showFilters, setShowFilters] = useState(defaultValues.booleanValue)
  const [filterBy, setFilterBy] = useState(defaultValues.stringValue)

  const [selectedCountries, setSelectedCountries] = useState(defaultValues.arrayValue)
  const [savedFilters, setSavedFilters] = useState(defaultValues.objectValue)

  const [selectPlus, setSelectedPlus] = useState(defaultValues.booleanValue)

  const [filters, setFilters] = useQueryParams({
    type: StringParam,
    page: withDefault(NumberParam, 1),
    companySize: StringParam,
    revenue: StringParam,
    selectedServices: withDefault(ArrayParam, defaultValues.arrayValue),
    selectedServiceCategories: withDefault(ArrayParam, defaultValues.arrayValue),
    selectedSectors: withDefault(ArrayParam, defaultValues.arrayValue),
    selectedCountriesIds: withDefault(ArrayParam, defaultValues.arrayValue),
    dates: withDefault(ArrayParam, defaultValues.dateValue),
    search: StringParam,
    plus: withDefault(BooleanParam, defaultValues.booleanValue),
    companyPackage: NumberParam,
  })
  const setFilter = (type, value) => {
    setFilters({ [type]: value })
  }

  const toggleShowFilters = (type = defaultValues.stringValue) => {
    if (type === filterBy) {
      setShowFilters(false)
      setFilterBy(defaultValues.stringValue)
    } else if (!showFilters) {
      setShowFilters(true)
      setFilterBy(type)
    } else setFilterBy(type)
  }

  const handleSelectServices = (e, service) => {
    const newArray = e.target.checked
      ? [...filters.selectedServices, service]
      : filters.selectedServices.filter((s) => Number(s) !== service)
    setFilter('selectedServices', newArray)
  }
  const handleSelectServiceCategories = (e, category) => {
    const newArray = e.target.checked
      ? [...filters.selectedServiceCategories, category]
      : filters.selectedServiceCategories.filter((s) => Number(s) !== category)
    setFilter('selectedServiceCategories', newArray)
  }

  const handlePlus = (type, event) => {
    setSelectedPlus(event.target.checked)
    setFilter('plus', event.target.checked)
  }

  const handleSelectSectors = (e, sector) => {
    const newArray = e.target.checked
      ? [...filters.selectedSectors, sector]
      : filters.selectedSectors.filter((s) => Number(s) !== sector)
    setFilter('selectedSectors', newArray)
  }
  const handleSelectCountries = (values) => {
    setSelectedCountries(values)
    const newArray = values.map(({ id }) => id)
    setFilter('selectedCountriesIds', newArray)
  }
  const { page, type } = filters
  useEffect(() => {
    if (page == null) {
      setFilters({ page: 1 })
    }
  }, [page, setFilters])

  const firstTab = ((props.tabs || [])[0] || {}).key

  useEffect(() => {
    if (type == null) {
      setFilters({ type: firstTab, page: 1 })
    }
  }, [firstTab, page, props, setFilters, type])

  useEffect(() => {
    setFilters({ page: 1 })
  }, [setFilters, type])

  const tabDetails = FEED_SECTION_TABS[type || firstTab]

  const defaultFilterValues = {
    companySize: defaultValues.stringValue,
    revenue: defaultValues.stringValue,
    selectedServices: defaultValues.arrayValue,
    selectedServiceCategories: defaultValues.arrayValue,
    selectedSectors: defaultValues.arrayValue,
    selectedCountriesIds: defaultValues.arrayValue,
    dates: defaultValues.dateValue,
    search: defaultValues.stringValue,
    page: 1,
    plus: defaultValues.booleanValue,
    companyPackage: defaultValues.stringValue
  }

  const handleClearFilters = () => {
    setFilters({
      ...defaultFilterValues,
    })
    setSelectedCountries(defaultValues.arrayValue)
    setSelectedPlus(defaultValues.booleanValue)
    handleFilterData(defaultFilterValues)
  }

  const formatFilterData = (filterValues) => {
    const {
      companySize,
      revenue,
      selectedServices,
      selectedServiceCategories,
      selectedSectors,
      selectedCountriesIds,
      dates,
      search,
      page,
      plus,
      companyPackage,
    } = filterValues
    return {
      dimension: companySize,
      revenue,
      services: selectedServices,
      countries: selectedCountriesIds,
      serviceCategories: selectedServiceCategories,
      sectors: selectedSectors,
      dates,
      search,
      page,
      plus,
      companyPackage
    }
  }

  const handleFilterData = (filterValues) => {
    setFilters({ page: 1 })
    const filterParams = !!filterValues ? formatFilterData(filterValues) : formatFilterData(filters)
    const normalizedFilters = normalizeFilters(filterParams, type)
    setSavedFilters(normalizedFilters)
    setShowFilters(false)
    setFilterBy(defaultValues.stringValue)
  }

  return (
    <QueryFiltersContext.Provider
      value={{
        showFilters,
        filterBy,
        setFilterBy,
        toggleShowFilters,
        handleClearFilters,
        handleFilterData,
        filters,
        savedFilters,
        setFilter,
        tabDetails,
        handleSelectServices,
        handleSelectServiceCategories,
        handleSelectSectors,
        handleSelectCountries,
        selectedCountries,
        defaultFilterValues,
        formatFilterData,
        selectPlus,
        handlePlus
      }}
    >
      {props.children}
    </QueryFiltersContext.Provider>
  )
}
export default QueryFiltersContext
