import React from 'react'
import axios from 'axios'
import history from '../../history'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CssBaseline from '@material-ui/core/CssBaseline'
import queryString from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import SocialButton from '../../component/socialbutton/socialbutton'
import { Container } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import './createaccount.css'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import { library } from '@fortawesome/fontawesome-svg-core'
import { colorsByKey } from 'theme/colors'
import { ADVISOR, COMPANY, USER_PROFILE_TYPES } from 'entities/companyFields'
import { apiPost } from 'api'

import { faFacebookF, faTwitter, faGoogle, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
library.add(faFacebookF, faTwitter, faGoogle, faLinkedinIn)

class CreateAccount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        email: '',
        password: '',
        userType: COMPANY,
        invitationCode: '',
        c_password: '',
        role: '1',
        code: '',
        invitecode: '',
        ref: '',
        acceptedTerms: false,
        invitation_code: null,
      },
      inviteerror: '',
      loaded: false,
      submitted: false,
      error: [],
      emailErr: '',
      invitationCodeErr: '',
      passwordErr: '',
      c_passwordErr: '',
      formErrors: {},
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeTermsAcceptance = this.handleChangeTermsAcceptance.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSocialLogin = this.handleSocialLogin.bind(this)
    this.handleLinkedinLogin = this.handleLinkedinLogin.bind(this)
    this.handleFormValidation = this.handleFormValidation.bind(this)

    if (localStorage.getItem('accessToken')) {
      history.push('/profile')
    }
  }

  componentDidMount() {
    const url = new URL(window.location.href)
    const campaignCode = url.searchParams.get('campaign-code')
    if (!campaignCode) return

    this.setState({ formData: { ...this.state.formData, invitation_code: campaignCode } })
  }

  handleFormValidation = () => {
    const { email, password, c_password, invitationCode, userType } = this.state.formData

    let formErrors = this.state.formErrors
    let formIsValid = true

    if (!this.state.formData.acceptedTerms) formIsValid = false

    // invitationCodeErr

    if (userType === ADVISOR) {
      if (!invitationCode) {
        formIsValid = false
        formErrors['invitationCodeErr'] = 'This field is required'
      } else if (invitationCode !== 'BWCODE') {
        formIsValid = false
        formErrors['invitationCodeErr'] = 'Please enter valid invitation code'
      } else {
        formErrors['invitationCodeErr'] = ''
      }
    }

    if (!email) {
      formIsValid = false
      formErrors['emailErr'] = 'This field is required'
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
      formIsValid = false
      formErrors['emailErr'] = 'Please enter valid email address'
    } else {
      formErrors['emailErr'] = ''
    }

    if (!password) {
      formIsValid = false
      formErrors['passwordErr'] = 'This field is required'
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(password)) {
      formIsValid = false
      formErrors['passwordErr'] =
        'password must be at least one number, one lowercase and one uppercase letter and min 6 character'
    }

    if (!c_password) {
      formIsValid = false
      formErrors['c_passwordErr'] = 'This field is required'
    } else if (this.state.formData.password !== this.state.formData.c_password) {
      formIsValid = false
      formErrors['c_passwordErr'] = 'password mismatch'
    }

    this.setState({ [this.state.formErrors]: formErrors })
    return formIsValid
  }
  async componentWillMount() {
    let query = queryString.parse(this.props.location.search)

    if (query.invitecode) {
      const invite_api_url = process.env.REACT_APP_API_URL + '/api/invitecheck'
      await axios
        .post(invite_api_url, { code: query.invitecode })
        .then((response) => {
          const { data } = response
          if (data.success) {
            const { formData } = this.state
            formData['role'] = '2'
            formData['invitecode'] = query.invitecode
            this.setState({ formData })
            this.setState({ loaded: true })
          } else {
            this.setState({ inviteerror: data.error, loaded: true })
          }
        })
        .catch((error) => {
          this.setState({
            inviteerror: this.props.t('Invalid invitation code for SBA Registration'),
            loaded: true,
          })
        })
    } else {
      this.setState({ loaded: true })
    }
    if (query.ref) {
      const { formData } = this.state
      formData['ref'] = query.ref
      this.setState({ formData })
      this.setState({ loaded: true })
    }
  }
  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData, error: [] })
  }
  handleChangeTab = () => {
    const { formData } = this.state
    formData.userType = formData.userType === ADVISOR ? COMPANY : ADVISOR
    this.setState({ formData })
  }
  handleChangeTermsAcceptance = (event) => {
    const { formData } = this.state
    formData.acceptedTerms = !formData.acceptedTerms
    this.setState({ formData })
  }

  handleLogin = (id) => {
    axios
      .post(process.env.REACT_APP_API_URL + '/api/login', this.state.formData)
      .then((response) => {
        const { data } = response
        this.setState({ submitted: false })
        localStorage.setItem('accessToken', data.success.token)
        history.push(`/complete-registration/${ADVISOR}/${id}`)
      })
  }

  handleSubmit = () => {
    if (this.handleFormValidation()) {
      if (this.state.formData.userType === ADVISOR) {
        this.setState({ submitted: true }, () => {
          axios
            .post(process.env.REACT_APP_API_URL + '/api/advisors', this.state.formData)
            .then(({ data }) => {
              this.handleLogin(data.data.id)
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  const { data } = error.response
                  if (data.error) {
                    this.setState({ submitted: false, error: data.error })
                  }
                } else {
                  alert(this.props.t('Something went wrong, please try again'))
                }
              }
            })
        })
      } else {
        this.setState({ submitted: true }, () => {
          const sector_api_url = process.env.REACT_APP_API_URL + '/api/register'
          axios
            .post(sector_api_url, this.state.formData)
            .then((response) => {
              const { data } = response
              this.setState({ submitted: false })
              localStorage.setItem('accessToken', data.success.token)

              if (this.state.formData.role === '2') {
                history.push('/complete-sba-registration')
              } else {
                let query = queryString.parse(this.props.location.search)
                if (query.subscription) {
                  history.push(
                    '/complete-participant-registration?subscription=' + query.subscription
                  )
                } else {
                  history.push('/complete-participant-registration')
                }
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  const { data } = error.response
                  if (data.error) {
                    this.setState({ submitted: false, error: data.error })
                  }
                } else {
                  alert(this.props.t('Something went wrong, please try again'))
                }
              }
            })
        })
      }
    }
  }

  handleSocialLogin(user) {
    // console.log(user)
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/socialRegistration'
      const socialData = {
        email: user._profile.email,
        providerToken: user._token.accessToken,
        provider: user._provider,
        role: this.state.formData.role,
      }
      axios
        .post(sector_api_url, socialData)
        .then((response) => {
          // handle success
          const { data } = response
          if ('message' in data) {
            alert(data.message)
            this.setState({ submitted: false })
          } else {
            localStorage.setItem('accessToken', data.success.token)
            if (this.state.formData.role === '2') {
              history.push('/complete-sba-registration')
            } else {
              history.push('/complete-participant-registration')
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, error: data.error })
              }
            } else {
              alert(this.props.t('Something went wrong, please try again'))
              this.setState({ submitted: false })
            }
          }
        })
    })
  }

  handleSocialLoginFailure(err) {
    console.error(err)
  }

  handleLinkedinLogin(user) {
    this.setState({ submitted: true }, () => {
      const sector_api_url = process.env.REACT_APP_API_URL + '/api/socialRegistration'
      const socialData = {
        email: '',
        providerToken: user.code,
        provider: 'linkedin',
        role: this.state.formData.role,
      }
      axios
        .post(sector_api_url, socialData)
        .then((response) => {
          // handle success
          const { data } = response
          if ('message' in data) {
            alert(data.message)
          } else {
            localStorage.setItem('accessToken', data.success.token)
            if (this.state.formData.role === '2') {
              history.push('/complete-sba-registration')
            } else {
              history.push('/complete-participant-registration')
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, error: data.error })
              }
            } else {
              alert(this.props.t('Something went wrong, please try again'))
            }
          }
        })
    })
  }

  render() {
    const { formData, submitted, loaded } = this.state
    const { emailErr, passwordErr, c_passwordErr, invitationCodeErr } = this.state.formErrors
    const { t } = this.props
    if (!loaded) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    if (this.state.inviteerror !== '') {
      return (
        <div className="sba-create-account w-100 pt-5">
          <Helmet>
            <title>Create Account | BridgeWhat: Let's Grow!</title>
          </Helmet>
          <div className="d-flex ">
            <Container>
              <div className="create-account ">{this.state.inviteerror}</div>
            </Container>
          </div>
        </div>
      )
    }

    return (
      <div className="sba-create-account w-100">
        <Helmet>
          <title>Create Account | BridgeWhat: Let's Grow!</title>
          <body className="header_s" />
        </Helmet>
        <div className="d-flex" style={{ background: colorsByKey.defaultBgWeaker }}>
          <Container className="d-flex align-items-center">
            <div className="create-account login-min">
              <Tabs
                value={formData.userType}
                onChange={this.handleChangeTab}
                name="userType"
                variant="fullWidth"
                style={{ background: colorsByKey.primaryBgWeak, borderRadius: '12px 12px 0 0 ' }}
              >
                {USER_PROFILE_TYPES.map(({ label, key }) => {
                  const defaultStyles = {
                    minHeight: '100%',
                    textTransform: 'none',
                    flex: 1,
                    fontSize: '14px',
                    borderRadius: '12px 12px 0 0 ',
                    padding: '8px 16px',
                  }
                  const notSelectedStyles = {
                    background: colorsByKey.primaryBgWeak,
                    color: colorsByKey.accentTextStrong,
                  }
                  const selectedStyles = {
                    background: colorsByKey.primaryBgMedium,
                    color: colorsByKey.utility,
                  }
                  return (
                    <Tab
                      label={t(label)}
                      key={key}
                      id={key}
                      style={
                        key === formData.userType
                          ? { ...defaultStyles, ...selectedStyles }
                          : { ...defaultStyles, ...notSelectedStyles }
                      }
                    />
                  )
                })}
              </Tabs>
              <div className="boxshadow p-3 pt-1">
                <CssBaseline />
                <div>
                  <ValidatorForm ref={(r) => (this.form = r)} onSubmit={this.handleSubmit}>
                    {formData.userType === ADVISOR && (
                      <div className="form-group">
                        <label>{t('Invitation Code')}</label>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="invitationCode"
                          value={formData.invitationCode}
                          className={invitationCodeErr ? 'showError' : ''}
                        />
                        {invitationCodeErr && (
                          <div style={{ color: 'red', paddingBottom: 5 }}>{invitationCodeErr}</div>
                        )}
                      </div>
                    )}

                    <div className="form-group">
                      <label>{t('Email Address')}</label>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="email"
                        value={formData.email}
                        className={emailErr ? 'showError' : ''}
                      />
                      {emailErr && <div style={{ color: 'red', paddingBottom: 5 }}>{emailErr}</div>}
                    </div>
                    <div className="form-group">
                      <label>{t('Create Password')}</label>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        className={passwordErr ? 'showError' : ''}
                      />
                      {passwordErr && (
                        <div style={{ color: 'red', paddingBottom: 5 }}>{passwordErr}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>{t('Confirm Password')}</label>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="c_password"
                        type="password"
                        className={c_passwordErr ? 'showError' : ''}
                      />
                      {c_passwordErr && (
                        <div style={{ color: 'red', paddingBottom: 5 }}>{c_passwordErr}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.acceptedTerms}
                            color="primary"
                            style={{ marginLeft: 0 }}
                            onChange={this.handleChangeTermsAcceptance}
                          />
                        }
                        label={ formData.userType === ADVISOR ? 
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t('Accept advisors terms'),
                            }}
                          /> :
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t('Accept terms'),
                            }}
                          />
                        }
                      />
                    </div>
                    <Button
                      variant="contained"
                      type="submit"
                      className="submit-btn"
                      disabled={submitted || !formData.acceptedTerms}
                    >
                      {(submitted && t('Your form is submitted!')) ||
                        (!submitted && t('Create Account'))}
                    </Button>
                  </ValidatorForm>
                  {/* <div className="w-100">
                    <div className="col-md-12 text-center p-0 pb-1">
                      <div className="or-login w-100 position-relative mt-4 mb-4">
                        <span>{t('OR')}</span>
                      </div>
                      <div className="social_icon">
                        <LinkedIn
                          clientId="86d9xc0kub9l4f"
                          onFailure={this.handleSocialLoginFailure}
                          onSuccess={this.handleLinkedinLogin}
                          redirectUri={process.env.REACT_APP_FRONTEND_URL + '/linkedin'}
                        >
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </LinkedIn>
                        <SocialButton
                          className="fb-bg"
                          provider="facebook"
                          appId="1184189502014772"
                          onLoginSuccess={this.handleSocialLogin}
                          onLoginFailure={this.handleSocialLoginFailure}
                        >
                          <FontAwesomeIcon icon={faFacebookF} />
                        </SocialButton>
                        <SocialButton
                          className="g-bg"
                          provider="google"
                          appId="846009446075-4jevf2gebfm0ttj8heek6rimn8shb53b.apps.googleusercontent.com"
                          onLoginSuccess={this.handleSocialLogin}
                          onLoginFailure={this.handleSocialLoginFailure}
                        >
                          <FontAwesomeIcon icon={faGoogle} />
                        </SocialButton>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div
              className="w-75 site-logo"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                margin: '32px',
              }}
            >
              <img
                className="img-fluid"
                src={require('assets/images/claim.svg')}
                alt="logo claim"
              />
              <img
                className="img-fluid"
                src={require('assets/images/waves.svg')}
                alt="logo waves"
              />
              <img className="img-fluid" src={require('assets/images/desk.svg')} alt="logo desk" />
            </div>
          </Container>
        </div>
      </div>
    )
  }
}
export default withTranslation()(CreateAccount)
