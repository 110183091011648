import React from 'react'
import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
class ReferralHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { t } = this.props
    return (
      <div className="profile-header">
        <Nav
          style={{ marginBottom: '20px' }}
          variant="tabs"
          defaultActiveKey={this.props.activekey}
        >
          <Nav.Item>
            <Nav.Link eventKey="testimonial" className="p-0">
              <Link to="/referrals" onClick={() => this.props.onChangeTab('testimonial')}>
                {t('testimonial')}
              </Link>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="recommendation" className="p-0">
              <Link to="/referrals" onClick={() => this.props.onChangeTab('recommendation')}>
                {t('recommendation')}
              </Link>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    )
  }
}

export default withTranslation()(ReferralHeader)
