import React from 'react'
import axios from 'axios'
import history from '../../history'
import { Row, Col } from 'react-bootstrap'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withTranslation } from 'react-i18next'
class GiveReview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        participant: '',
        request_type: 'give',
        need_id: '',
        project_name: '',
      },
      submitted: false,
      needs: [],
      loadingneeds: false,
    }

    this.selectParticipant = this.selectParticipant.bind(this)
    this.selectNeed = this.selectNeed.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  selectParticipant = (event, value) => {
    const { formData } = this.state
    if (value !== null) formData['participant'] = value.id
    else formData['participant'] = ''
    this.setState({ formData })

    this.setState({ loadingneeds: true }, () => {
      if (value !== null) {
        const api_url = process.env.REACT_APP_API_URL + '/api/listofNeedsForReview'
        axios
          .post(
            api_url,
            { participant: value.id },
            { headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') } }
          )
          .then((response) => {
            const { data } = response.data
            this.setState({ needs: data, loadingneeds: false })
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.setState({ needs: [], loadingneeds: false })
      }
    })
  }

  selectNeed = (event, value) => {
    const { formData } = this.state
    if (value !== null) formData['need_id'] = value.id
    else formData['need_id'] = ''
    this.setState({ formData })
  }

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      const api_url = process.env.REACT_APP_API_URL + '/api/addReviewPermit'
      axios
        .post(api_url, this.state.formData, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          const { success } = response.data
          history.push('/give/review/' + success.request_code)
          this.setState({ submitted: false })
        })
        .catch((error) => {
          console.log(error)
        })
    })
  }

  render() {
    const { t } = this.props
    const { formData, submitted, needs, loadingneeds } = this.state
    const { participants } = this.props

    return (
      <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
        <Row>
          <Col lg="12">
            <h6>{t('Give a review')}</h6>
          </Col>
          <Col lg="12">
            <div className="form-group">
              <label>
                {t('Choose to what BW Participant you want to give a review')} <sup>*</sup>
              </label>
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                options={participants}
                onChange={this.selectParticipant}
                getOptionLabel={(option) => option.company_name || option.name || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    variant="outlined"
                    value={formData.participant}
                    validators={['required']}
                    errorMessages={t('This field is required')}
                  />
                )}
              />
            </div>
          </Col>
          {!loadingneeds && (
            <Col lg="12">
              <div className="form-group">
                {/* <label>{t('List of Needs/Leads')}</label>
                <Autocomplete
                  id="combo-box-demo"
                  fullWidth
                  options={needs}
                  getOptionLabel={(option) => option.title}
                  onChange={this.selectNeed}
                  renderInput={(params) => <TextValidator {...params} variant="outlined" />}
                /> */}
                <label>
                  {t('Project Name')} <sup>*</sup>
                </label>
                <TextValidator
                  variant="outlined"
                  margin="0"
                  fullWidth
                  type="text"
                  name="project_name"
                  onChange={this.handleChange}
                  value={formData.project_name}
                  validators={['required']}
                  errorMessages={t('This field is required')}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {!loadingneeds && (
            <Col md="12" className="d-flex align-items-center justify-content-center">
              <Button
                variant="contained"
                type="submit"
                className="submit-btn ml-2 mr-2"
                disabled={submitted}
              >
                {(submitted && 'Your form is submitted!') || (!submitted && t('Give a Review'))}
              </Button>
            </Col>
          )}
        </Row>
      </ValidatorForm>
    )
  }
}

export default withTranslation()(GiveReview)
