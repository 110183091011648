import React from 'react'
import { createContext } from 'react'

const AuthContext = createContext({
  userDetail: {},
})

export const AuthContextProvider = (props) => {
  const { userDetail } = props

  return (
    <AuthContext.Provider
      value={{
        userDetail,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
export default AuthContext
