import { Stack, Grid, Box } from '@mui/material'
import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { withTranslation } from 'react-i18next'
import { colorsByKey } from 'theme/colors'
import Field from './Field'
import filters from 'mixins/filters'

const Form = forwardRef(
  (
    {
      t,
      formFields = [],
      requiredFields = [],
      hiddenFields = [],
      postData = {},
      showDialog,
      disabled = false,
      errorMessage = '',
    },
    ref
  ) => {
    const Filters = filters()

    const [disableChanges, setDisableChanges] = useState(false)

    const [values, setValues] = useState(
      formFields.reduce((acc, field) => {
        let initialValue = field.defaultValue ?? ''
        if (field.type === 'number') initialValue = 1
        else if (field.type === 'checkbox') initialValue = false
        else if (field.type === 'date') initialValue = null
        else if (field.type === 'files') initialValue = []
        else if (field.type === 'select' && !field.multiple) initialValue = {}
        else if ((field.type === 'select' && field.multiple) || field.type === 'files')
          initialValue = []
        return { ...acc, [field.key]: initialValue }
      }, {})
    )

    const handleChange =
      (prop, type, theValues = values, maxOptions) =>
      (eventOrValue) => {
        if (!disableChanges) {
          if (!maxOptions || eventOrValue?.target?.value.length <= maxOptions) {
            setValues({ ...theValues, [prop]: eventOrValue?.target?.value ?? eventOrValue })
          }
        }
      }

    useDeepCompareEffect(() => {
      // Fill form with data only when mounted
      setTimeout(() => {
        if ((Object.keys(postData) || []).length > 0) {
          setDisableChanges(true)
          setTimeout(() => setDisableChanges(false), 2000)
          setValues(
            formFields.reduce((acc, field) => {
              let value = values[field.key]
              let postValue = postData[field.key]

              if (postValue !== undefined && postValue !== null && postValue !== '') {
                if (field.type === 'files') {
                  if (Array.isArray(postValue)) {
                    value = (postValue || []).map((v) => ({ ...v, status: 'uploaded' }))
                  } else {
                    value = [{ ...postValue, status: 'uploaded' }]
                  }
                } else {
                  value = postValue
                }
              }
              return { ...acc, [field.key]: value }
            }, {})
          )
        }
      }, 200)
    }, [postData])

    const validateAll = () => {
      let isFormValid = true
      formFields.forEach((field, idx) => {
        const validation = fieldsRef.current[idx].getFieldValidation()
        if (!validation.isValid) isFormValid = false
      })
      return isFormValid
    }

    useImperativeHandle(ref, () => ({
      validateForm: () => {
        return validateAll()
      },
      getFormData: () => {
        const fieldsWithError = []
        requiredFields.forEach((requiredField) => {
          const v = values[requiredField]
          if (v === '' || v === undefined || v === null || (Array.isArray(v) && v.length === 0)) {
            fieldsWithError.push(formFields.find((f) => f.key === requiredField).label)
          }
        })
        if (fieldsWithError.length > 0) {
          const fieldsStr = fieldsWithError.join(', ')
          return {
            errorMessage: `Please, fill in the following fields that are required: ${fieldsStr}`,
          }
        }
        const extract = (value, keyExtractor, type, isMultiple = false, maxFiles = 1) => {
          if (value === undefined || value === null) {
            return value
          } else if (type === 'number') {
            return parseInt(value, 10)
          } else if (Array.isArray(value)) {
            if (type === 'files' && value.some((f) => f.id < 0)) {
              return extract(
                value.filter((f) => f.id < 0),
                keyExtractor,
                type,
                isMultiple,
                maxFiles
              )
            } else if (isMultiple) {
              return value.map((v) => extract(v, keyExtractor))
            } else {
              return extract(value[0], keyExtractor, type)
            }
          }
          return keyExtractor ? value[keyExtractor] : value
        }
        const formData = formFields.reduce(
          (acc, field) => ({
            ...acc,
            [field.backendKey]: extract(
              values[field.key],
              field.toServerKeyExtractor,
              field.type,
              field.multiple,
              field.maxFiles
            ),
          }),
          {}
        )
        Object.entries(hiddenFields || {}).forEach(([k, v]) => (formData[k] = v))
        return formData
      },
    }))

    const getSelectOptions = ({ optionType, options }) => {
      let selectOptions = []
      if (optionType) selectOptions = Filters[optionType]
      else if (options) selectOptions = options
      return selectOptions
    }

    const fieldsRef = useRef({})

    return (
      <Stack className="Form">
        <Box component="form" autoComplete="off">
          <Grid
            style={{
              backgroundColor: colorsByKey.white,
            }}
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {formFields.map((field, index) => (
              <Grid item xs={field.grid || 6} sm={field.grid || 6} md={field.grid || 6} key={index}>
                <Field
                  ref={(el) => (fieldsRef.current[index] = el)}
                  {...field}
                  options={getSelectOptions(field)}
                  value={values[field.key]}
                  isRequired={requiredFields.includes(field.key)}
                  // handleChange={() => handleChange(field.key, field.type, values, field.maxOptions)}
                  handleChange={handleChange(field.key, field.type, values, field.maxValues)}
                  disabled={disabled || (field.disableIf && values[field.disableIf])}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    )
  }
)
export default withTranslation(undefined, { withRef: true })(Form)
