import React from 'react'
import { Box, Typography } from '@mui/material'
import { Spinner } from 'components'
import { colorsByKey } from 'theme/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const UserInfoCardComponent = ({
  t,
  title,
  children,
  isLoading,
  isEditable = false,
  redirectLink = '',
  actionOnClick,
}) => {
  let history = useHistory()

  const handleRedirectTo = () => {
    history.push(redirectLink)
  }
  return (
    <Box
      className="UserInfoCard"
      sx={{
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        borderRadius: '16px',
        overflow: 'hidden',
        background: colorsByKey.utility,
      }}
    >
      <Box
        className="UserInfoCardHeader"
        sx={{
          backgroundColor: colorsByKey.primaryBgWeak,
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="f16" color={colorsByKey.defaultText} sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        {isEditable && (
          // {isEditable && redirectLink && (
          <Box sx={{ cursor: 'pointer' }} onClick={actionOnClick}>
            {/* <Box sx={{ cursor: 'pointer' }} onClick={handleRedirectTo}> */}
            <FontAwesomeIcon icon={faEdit} />
          </Box>
        )}
      </Box>
      <Box className="UserInfoCardBody" sx={{ p: 2 }}>
        {isLoading && <Spinner />}
        {children}
      </Box>
    </Box>
  )
}
export const UserInfoCard = withTranslation()(UserInfoCardComponent)
