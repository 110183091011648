import { FEED, PROFILE, CHANNEL, POSTS } from 'entities/company'

import { normalizeCompanyData } from './dataNormalizers'
import {
  TYPE,
  KEY,
  ID,
  CREATED_AT,
  USER,
  TITLE,
  DESCRIPTION,
  BROCHURE,
  RANGE_DATES,
  // IMAGE,
} from 'entities/formFields'
import {
  NAME,
  INDUSTRY_SECTORS,
  SCORE,
  JOB_FUNCTION,
  END_DATE,
  START_DATE,
  DATE_RANGE,
  EXPERIENCE_YEARS,
  FIELD_OF_STUDY,
  TYPE_OF_DEGREE,
  fieldOfStudy,
  typeOfDegree,
} from 'entities/companyFields'
import moment from 'moment'

const normalizeFeedData = (data) => {
  return data
}
const normalizeProfileData = (data) => {
  return {
    ...normalizeCompanyData(data),
    type: PROFILE,
    key: `${PROFILE}-${data.id}`,
  }
}

export const normalizeCredentialsReviewsData = (data, type) => {
  return data.map((item) => {
    return {
      reviewer: {
        [NAME]: item.company_name,
        [ID]: item.reffer_by,
        [INDUSTRY_SECTORS]: [item.sector],
        [USER]: {
          [NAME]: item.name,
          [JOB_FUNCTION]: item.job_function,
        },
      },
      company: (item.company || {}).id ? normalizeCompanyData(item.company) : {},
      project: item.project_name,
      [SCORE]: item.rating_value,
      [TYPE]: type,
      [KEY]: `${type}-${item.id}`,
      [ID]: item.id,
      [RANGE_DATES]: [item.from_date, item.to_date],
      [DATE_RANGE]: `${moment(item.from_date || '').format('MM-yyyy')} - ${moment(
        item.to_date || ''
      ).format('MM-yyyy')}`,
      [CREATED_AT]: item.created_at,
      [DESCRIPTION]: item.description,
    }
  })
}
export const normalizeCredentialsDocumentsData = (data, type) => {
  // TODO: UPDATE
  // ADD DOCUMENT'S DATE
  // console.log('doc', data)
  return data.map((item) => {
    const image =
      item.documentfile.charAt(0) === '/' ? item.documentfile.substring(1) : item.documentfile
    return {
      [TYPE]: type,
      [KEY]: `${type}-${item.id}`,
      [ID]: item.id,
      [TITLE]: item.title,
      [DESCRIPTION]: item.description,
      [CREATED_AT]: item.created_at,
      [BROCHURE]: item.documentfile ? `${process.env.REACT_APP_API_URL}/${image}` : '',
      documentType: item.documenttype,
    }
  })
}
export const normalizeCredentialsEducationData = (data, type) => {
  return data.map((item) => {
    const fieldOfStudyKey = item.field_of_study
    const typeOfDegreeKey = item.type_of_degree
    return {
      ...item,
      [TYPE]: type,
      [KEY]: `${type}-${item.id}`,
      [ID]: item.id,
      [JOB_FUNCTION]: item.position,
      [START_DATE]: moment(item.init_date || '').format('yyyy-MM-DD'),
      [END_DATE]: moment(item.end_date || '').format('yyyy-MM-DD'),
      [DATE_RANGE]:
        item.init_date && item.end_date
          ? `${moment(item.init_date || '').format('MMM YYYY')} - ${moment(
              item.end_date || ''
            ).format('MMM YYYY')}`
          : null,
      [TITLE]: item.degree,
      [DESCRIPTION]: item.description,
      academy: item.academy,
      [BROCHURE]: (item.image || {}).url,
      [FIELD_OF_STUDY]: fieldOfStudyKey
        ? {
            key: fieldOfStudyKey,
            id: fieldOfStudyKey,
            label: fieldOfStudy.getText(fieldOfStudyKey),
            name: fieldOfStudy.getText(fieldOfStudyKey),
          }
        : null,
      [TYPE_OF_DEGREE]: typeOfDegreeKey
        ? {
            key: typeOfDegreeKey,
            id: typeOfDegreeKey,
            label: typeOfDegree.getText(typeOfDegreeKey),
            name: typeOfDegree.getText(typeOfDegreeKey),
          }
        : null,
    }
  })
}
export const normalizeExpertisesData = (data, type) => {
  return data.map((item) => {
    const endDate = item.current_job
      ? 'Current job'
      : moment(item.end_date || '').format('MMM YYYY')
    return {
      ...item,
      [TYPE]: type,
      [KEY]: `${type}-${item.id}`,
      [ID]: item.id,
      [JOB_FUNCTION]: item.position,
      company: item.company_name,
      [START_DATE]: item.init_date ? moment(item.init_date || '').format('yyyy-MM-DD') : null,
      [END_DATE]: item.end_date ? moment(item.end_date || '').format('yyyy-MM-DD') : null,
      [DATE_RANGE]:
        item.init_date && item.end_date
          ? `${moment(item.init_date || '').format('MMM YYYY')} - ${endDate}`
          : null,
      [EXPERIENCE_YEARS]:
        item.init_date && item.end_date
          ? moment(item.end_date).diff(moment(item.init_date), 'years')
          : null,
      highlights: item.highlights,
    }
  })
}

const normalizeChannelData = (data) => {}
const normalizePostsData = (data) => {
  return data
}
export const normalizeCompanyProfileData = (data, type) => {
  if (type === FEED) return normalizeFeedData(data)
  if (type === PROFILE) return normalizeProfileData(data)
  if (type === CHANNEL) return normalizeChannelData(data)
  if (type === POSTS) return normalizePostsData(data)
  return data
}
