import React from 'react'
import { Button } from 'react-bootstrap'
import SocialLogin from 'react-social-login'

class SocialButton extends React.Component {
  render() {
    return <Button {...this.props}>{this.props.children}</Button>
  }
}
export default SocialLogin(SocialButton)
