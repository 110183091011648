import React from 'react'
import axios from 'axios'

import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import { withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import Textarea from '@material-ui/core/TextareaAutosize'
import { library } from '@fortawesome/fontawesome-svg-core'
import { withTranslation } from 'react-i18next'
import {
  faCloudUploadAlt,
  faPlusCircle,
  faAward,
  faTrophy,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { colorsByKey } from 'theme/colors'

const defaultImage = require('assets/images/card-default-images/pdf.svg')
library.add(faCloudUploadAlt, faPlusCircle, faAward, faTrophy, faTrashAlt)
const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  display: 'flex',
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#82a4cc',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #a3a5a8`,
    backgroundColor: '#a3a5a8',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

class Certificationawards extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [
        {
          id: 0,
          documentfile: null,
          documenttype: null,
          uploadedfile: null,
          title: '',
          description: '',
          user_status: 'unpublish',
          admin_status: 'underreview',
          itemedited: false,
        },
      ],
      submitted: false,
      showHideDeletePopup: false,
      removeindex: 0,
      loaded: false,
      error: null,
      successmsg: null,
    }

    this.onChange = this.onChange.bind(this)
    this.removeFile = this.removeFile.bind(this)
    this.additem = this.additem.bind(this)
    this.removeitem = this.removeitem.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.success_notify = React.createRef()
    this.error_notify = React.createRef()
  }

  componentDidMount() {
    const list_api_url = process.env.REACT_APP_API_URL + this.props.listendpoint
    axios
      .get(list_api_url, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      })
      .then((response) => {
        const { data } = response.data

        this.setState({ items: data, loaded: true })
      })
      .catch((error) => {
        console.log(error)
      })

    window.scrollTo(0, 0)
  }

  additem() {
    const { items } = this.state
    const newitems = [
      {
        id: 0,
        documentfile: null,
        title: '',
        description: '',
        user_status: 'unpublish',
        admin_status: 'underreview',
        itemedited: false,
      },
      ...items,
    ]
    this.setState({ items: newitems })
  }

  removeitem(i) {
    const { items } = this.state
    const newitems = [...items]

    if (newitems[i].id > 0) {
      var delete_api = process.env.REACT_APP_API_URL + this.props.delendpoint

      axios
        .post(delete_api, newitems[i], {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
        })
        .then((response) => {
          this.setState({ submitted: false, successmsg: this.props.t('Remove successfully') })
          this.success_notify.current.handleOpen()
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              const { data } = error.response
              if (data.error) {
                this.setState({ submitted: false, error: data.error })
                this.error_notify.current.handleOpen()
              }
            } else {
              this.setState({
                submitted: false,
                error: this.props.t('Something went wrong, please try again'),
              })
              this.error_notify.current.handleOpen()
            }
          }
        })
    }

    newitems.splice(i, 1)
    this.setState({ items: newitems })
    this.setState({ removeindex: 0, showHideDeletePopup: !this.state.showHideDeletePopup })
  }

  onChange(e, i) {
    var files = e.target.files

    if (!files.length) return

    var currentfile = files[0]

    if (
      currentfile.type === 'image/png' ||
      currentfile.type === 'image/jpeg' ||
      currentfile.type === 'application/pdf'
    ) {
      let reader = new FileReader()
      reader.onload = (e) => {
        const { items } = this.state
        items[i]['documentfile'] = URL.createObjectURL(currentfile)
        items[i]['documentfile_local'] = true
        items[i]['uploadedfile'] = e.target.result
        items[i]['documenttype'] = currentfile.type
        items[i]['filedeleted'] = '0'
        items[i]['itemedited'] = true
        this.setState({ items })
      }
      reader.readAsDataURL(currentfile)
    } else {
      alert(this.props.t('Only image and pdf file can be uploaded'))
    }
  }

  removeFile(i) {
    const { items } = this.state
    items[i]['documentfile'] = null
    items[i]['uploadedfile'] = null
    items[i]['documenttype'] = null
    items[i]['filedeleted'] = '1'
    items[i]['itemedited'] = true
    this.setState({ items })
  }

  RemoveModalShowHide(index) {
    this.setState({ showHideDeletePopup: !this.state.showHideDeletePopup }, () => {
      if (this.state.showHideDeletePopup) {
        this.setState({ removeindex: index })
      } else {
        this.setState({ removeindex: 0 })
      }
    })
  }
  handleChange(e, i) {
    const { name, value } = e.target
    const { items } = this.state
    items[i][name] = value
    items[i]['itemedited'] = true
    this.setState({ items })
  }

  handleChecked(e, i) {
    const { name, checked } = e.target
    const { items } = this.state
    if (checked) items[i][name] = 'publish'
    else items[i][name] = 'unpublish'
    items[i]['itemedited'] = true
    this.setState({ items })
  }

  handleSubmit() {
    this.setState({ submitted: true }, () => {
      const { items } = this.state

      items.map(async (item, index) => {
        if (item.itemedited) {
          var saving_api = process.env.REACT_APP_API_URL + this.props.addendpoint
          if (item.id !== 0) {
            saving_api = process.env.REACT_APP_API_URL + this.props.editendpoint
          }

          await axios
            .post(saving_api, item, {
              headers: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
            })
            .then((response) => {
              if (item.id === 0) {
                const { success } = response.data
                items[index]['id'] = success.id
                this.setState({ items })
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  const { data } = error.response
                  if (data.error) {
                    this.setState({ submitted: false, error: data.error })
                    this.error_notify.current.handleOpen()
                  }
                } else {
                  this.setState({
                    submitted: false,
                    error: this.props.t('Something went wrong, please try again'),
                  })
                  this.error_notify.current.handleOpen()
                }
              }
            })

          items[index]['itemedited'] = false
          this.setState({ items })
        }

        if (items.length - 1 === index) {
          this.setState({
            submitted: false,
            successmsg: this.props.t('Changes updated successfully'),
          })
          if (this.success_notify.current) {
            this.success_notify.current.handleOpen()
          }
        }
      })
    })
  }

  render() {
    const { t } = this.props
    const { items, submitted } = this.state

    if (!this.state.loaded) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    return (
      <div className="registration-min w-100 pb-2 minheight-100">
        <Helmet>
          <title>{this.props.pagetitle} | BridgeWhat: Let's Grow!</title>
        </Helmet>
        <Container style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            onClick={() => this.additem()}
            variant="contained"
            className="px-2"
            style={{
              backgroundColor: colorsByKey.accentTextStrong,
              color: colorsByKey.utility,
              alignSelf: 'flex-end',
              marginBottom: '20px',
            }}
          >
            {t('Add More')} &nbsp; <FontAwesomeIcon icon={faPlus} />
          </Button>
          <div className="boxshadow pl-3 pr-3 pt-0 pb-0 mb-5">
            <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
              {items.map((item, index) => (
                <Row className="pt-4 pb-1" key={index}>
                  <Col md="5">
                    <div className="certificates-img">
                      {item.documentfile ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '250px',
                          }}
                        >
                          <div
                            className="removeFile"
                            onClick={() => this.removeFile(index)}
                            style={{ zIndex: 2 }}
                          >
                            X
                          </div>
                          <Avatar
                            variant="square"
                            src={
                              document.documentType === 'application/pdf'
                                ? defaultImage
                                : item.documentfile
                            }
                            style={{ width: 'auto', height: '100%', flex: 1, zIndex: 1 }}
                          />
                        </Box>
                      ) : (
                        <FontAwesomeIcon icon={faAward} />
                      )}
                    </div>
                  </Col>
                  <Col md="7">
                    <div className="form-group w-100">
                      <label>
                        {t('Title')} <sup>*</sup>
                      </label>
                      <TextValidator
                        variant="outlined"
                        margin="0"
                        fullWidth
                        type="text"
                        name="title"
                        onChange={(e) => this.handleChange(e, index)}
                        value={item.title}
                        validators={['required']}
                        errorMessages={t('This field is required')}
                      />
                    </div>
                    <div className="form-group w-100">
                      <label>{t('Description')}</label> <sup>*</sup>
                      <TextValidator
                        variant="standard"
                        margin="0"
                        fullWidth
                        multiline
                        type="text"
                        name="description"
                        onChange={(e) => this.handleChange(e, index)}
                        value={item.description}
                        validators={['required']}
                        errorMessages={t('This field is required')}
                      />
                    </div>
                    <div className="w-100">
                      <Row>
                        <Col md="12">
                          <label>{t('Insert File')}</label>
                        </Col>
                        <Col lg="4">
                          <div style={styles} className="form-group">
                            <label className="custom-file-upload mb-0">
                              <input type="file" onChange={(e) => this.onChange(e, index)} />
                              {t('Insert File')} &nbsp;&nbsp;
                              <FontAwesomeIcon icon={faCloudUploadAlt} />
                            </label>
                          </div>
                          <div style={{ marginTop: '-1rem' }}>
                            ({t('jpg, png and pdf file only')})
                          </div>
                        </Col>
                        <Col
                          lg="8"
                          className="d-flex align-items-center form-group file-progress justify-content-lg-end"
                        >
                          <Row style={{ width: '100%' }}>
                            <Col lg="6">
                              {item.admin_status === 'underreview' && (
                                <div>
                                  <div className="progressLabel">
                                    Status:{t('BridgeWhat Review')}
                                  </div>
                                </div>
                              )}

                              {item.admin_status === 'approved' && (
                                <div>
                                  <div className="progressLabel">Status:{t('Approved')}</div>
                                </div>
                              )}

                              {item.admin_status === 'declined' && (
                                <div>
                                  <div className="progressLabel">Status:{t('Declined')}</div>
                                </div>
                              )}
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <Typography component="div">
                                  <Grid
                                    className="mb-0"
                                    component="label"
                                    container
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item>{t('Publish')}</Grid>
                                    <Grid item>
                                      <IOSSwitch
                                        checked={item.user_status === 'publish'}
                                        onChange={(e) => this.handleChecked(e, index)}
                                        name="user_status"
                                      />
                                    </Grid>
                                  </Grid>
                                </Typography>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <span
                                onClick={() => this.RemoveModalShowHide(index)}
                                className="trash_file"
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              ))}

              <Modal show={this.state.showHideDeletePopup} backdrop="static">
                <Modal.Body>
                  <div className="text-center">
                    {t('Are you sure that you want to delete this')} {this.props.title} ?
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                  <Button
                    className="btn_no border-0 MuiButton-root MuiButton-contained"
                    onClick={() => this.RemoveModalShowHide(this.state.removeindex)}
                  >
                    {t('No')}
                  </Button>
                  <Button
                    onClick={() => this.removeitem(this.state.removeindex)}
                    className="btn_yes border-0 ml-3 MuiButton-root MuiButton-contained"
                  >
                    {t('Yes')}
                  </Button>
                </Modal.Footer>
              </Modal>

              {items.length > 0 && (
                <Row>
                  <div className="col-12 text-center">
                    <Button
                      type="submit"
                      variant="contained"
                      className="submit-btn"
                      disabled={submitted}
                    >
                      {(submitted && t('Your form is submitted!')) || (!submitted && t('Save'))}
                    </Button>
                  </div>
                </Row>
              )}
            </ValidatorForm>
          </div>
        </Container>
        {/* TODO: Add notification error and successmsg */}
      </div>
    )
  }
}
export default withTranslation()(Certificationawards)
