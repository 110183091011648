import React, { useState } from 'react'
import { colorsByKey } from 'theme/colors'
import { Box, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { Spinner, CompanyAvatar, IconData } from 'components'
import { useHistory } from 'react-router-dom'
import { COMPANIES, ADVISORS } from 'entities/feed'
import { COMPANY, ADVISOR } from 'entities/routes'
import { CARD_INFO_FIELDS, ID, NAME, SUBSCRIPTION, IMAGE } from 'entities/companyFields'
import { TYPE } from 'entities/formFields'
import SUBSCRIPTIONS from 'utils/subscriptions'
import { ModalUpgradePackage } from 'components'
import '../../../../component/annualpackage/annualpackage.css'

const SidebarListCard = ({ t, id, title, items, isLoading, isLoaded, props }) => {
  let history = useHistory()

  const [visibleModalUpgrade, setVisibleModalUpgrade] = useState(false)
  const [dataModalUpgrade, setDataModalUpgrade] = useState({
    data: '',
    linkUpgrade: '',
  })
  const [dataModalCurrent, setDataModalCurrent] = useState({
    data: '',
    linkUpgrade: '',
  })
  const userCurrent = props.userDetail
  const subscriptionCurrent = SUBSCRIPTIONS[userCurrent.subscription]

  function handleCloseModal() {
    setVisibleModalUpgrade(false)
  }

  const handleRedirectToUserProfile = (type, id, item) => {
    let found = subscriptionCurrent.available_card_btn.find((element) => element == type)
    let access = true

    if (found !== undefined || (userCurrent.subscription === 5 && item.subscription === 5)) {
      //Tiene acceso
      setVisibleModalUpgrade(false)
    } else {
      let upgrade =
        userCurrent.subscription == 1 || userCurrent.subscription == 5
          ? 2
          : userCurrent.subscription + 1
      access = false
      setVisibleModalUpgrade(true)
      setDataModalUpgrade({
        data: SUBSCRIPTIONS[upgrade],
        linkUpgrade: userCurrent,
      })
      setDataModalCurrent({
        data: subscriptionCurrent,
        linknowBenefits: '',
      })
      //No tiene acceso y levanta modal de upgrade
    }

    if (type === COMPANIES) {
      if (access == true) history.push(`${COMPANY}/${id}`)
    } else if (type === ADVISORS) {
      if (access == true) history.push(`${ADVISOR}/${id}`)
    }
  }

  return (
    <Box
      className="SidebarListCard"
      sx={{
        p: 2,
        pt: 3,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.10)',
        borderRadius: '8px',
        position: 'relative',
        overflow: 'hidden',
        background: colorsByKey.defaultBg,
      }}
    >
      <Typography
        variant="f20"
        color={colorsByKey.accentTextStrong}
        sx={{ fontWeight: 'bold', pb: 1 }}
      >
        {title}
      </Typography>
      {isLoading && <Spinner />}
      {!isLoading &&
        items.map((item, idx) => {
          return (
            <Box
              className="UserCard"
              key={`card-${idx}`}
              sx={{
                my: 2,
                display: 'flex',
                borderBottom:
                  items.length !== idx + 1 ? `2px solid ${colorsByKey.primaryBgWeak}` : '',
                pb: items.length !== idx + 1 ? 1 : 0,
              }}
            >
              <CompanyAvatar
                subscription={item[SUBSCRIPTION]}
                name={item[NAME]}
                image={item[IMAGE]}
                userId={item[ID]}
                size="sm"
                variant="rounded"
                customSx={{ mr: 2 }}
              />
              <div>
                {item[NAME] && (
                  <Typography
                    onClick={() => handleRedirectToUserProfile(item[TYPE], item[ID], item)}
                    variant="f16"
                    color={colorsByKey.primaryBorder}
                    sx={{
                      cursor: item[TYPE] === COMPANIES ? 'pointer' : 'default',
                      fontWeight: 'bold',
                      mb: 1,
                    }}
                  >
                    {item[NAME]}
                  </Typography>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                  }}
                >
                  {CARD_INFO_FIELDS.map(({ key, icon, suffix, getText }, idx) => {
                    if (!item[key] || !(item[key] || []).length) return
                    else {
                      return (
                        <Box
                          key={`${item[ID]}-${key}`}
                          sx={{ ml: idx !== 0 ? 1.5 : 0, display: 'flex', alignItems: 'center' }}
                        >
                          <IconData
                            type={key}
                            title={getText ? getText(item[key]) : item[key]}
                            icon={icon}
                            suffix={suffix}
                            margin={0.5}
                            iconSize="xs"
                          />
                        </Box>
                      )
                    }
                  })}
                </Box>
              </div>
            </Box>
          )
        })}
      <ModalUpgradePackage
        visible={visibleModalUpgrade}
        dataUpgrade={dataModalUpgrade}
        dataCurrent={dataModalCurrent}
        closeModal={() => handleCloseModal()}
      />
    </Box>
  )
}
export default withTranslation()(SidebarListCard)
