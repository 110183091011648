import React from 'react'
import axios from 'axios'
import history from '../history'
import queryString from 'query-string'
import Updatepasswordpage from '../component/updatepassword/updatepasswordpage'

class Updatepassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errormsg: '',
      code: '',
      loading: true,
    }
  }

  componentDidMount() {
    try {
      let query = queryString.parse(this.props.location.search)
      if (query.code) {
        const sector_api_url = process.env.REACT_APP_API_URL + '/api/validatePasswordToken'
        axios
          .post(sector_api_url, { password_token: query.code })
          .then((response) => {
            // handle success
            const { data } = response

            if (data.error) {
              this.setState({ errormsg: data.error, loading: false })
            } else {
              this.setState({ loading: false, code: query.code })
            }
          })
          .catch((error) => {
            this.setState({ errormsg: 'Access Denied', loading: false })
          })
      } else {
        this.setState({ errormsg: 'Access Denied', loading: false })
      }
    } catch (error) {
      this.setState({ errormsg: 'Access Denied', loading: false })
    }
  }
  render() {
    if (this.state.loading) {
      return (
        <div className="loader-min">
          <div className="loader"></div>
        </div>
      )
    }

    if (this.state.errormsg !== '') {
      return (
        <div>
          <h2>{this.state.errormsg}</h2>
        </div>
      )
    }
    return (
      <div>
        <Updatepasswordpage code={this.state.code}></Updatepasswordpage>
      </div>
    )
  }
}
export default Updatepassword
