import {
  Stack,
  Grid,
  Box,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Chip,
  Typography,
} from '@mui/material'
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import moment from 'moment'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { withTranslation } from 'react-i18next'
import { colorsByKey } from 'theme/colors'
import FileUploader2 from 'component/form/FileUploader2'
import filters from 'mixins/filters'
import { Spinner } from 'components'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const editorConfig = {
  ckfinder: {
    uploadUrl: 'https://platform-backend.bridgewhat.com/public/api/fileUploads',
  },
  link: {
    defaultProtocol: 'http://',
  },
  mediaEmbed: {
    previewsInData: true,
  },
}

const Form = forwardRef(({ t, entity, postData, showDialog, type }, ref) => {
  const Filters = filters()

  // const isEditMode = type == 'edit'
  const { formFields: fields } = entity[type].formFields ? entity[type] : entity.create
  const { requiredFields: requiredFields } = entity[type].requiredFields
    ? entity[type]
    : entity.create
  const { hiddenFields: hiddenFields } = entity[type].hiddenFields ? entity[type] : entity.create
  // const { formFields: fields } = entity[type]
  // const { formFields: editFormFields } = entity.create
  // const fields = isEditMode ? editFormFields : createFormFields
  // console.log('isEditMode', isEditMode, fields)
  // console.log('type', type, fields)

  const formFields = fields.map((p) =>
    p.optionType ? { ...p, options: Filters[p.optionType] } : p
  )

  const [disableChanges, setDisableChanges] = useState(false)

  const [values, setValues] = useState(
    fields.reduce((acc, field) => {
      let initialValue = field.defaultValue ?? ''
      if (field.type === 'number') initialValue = 1
      else if (field.type === 'checkbox') initialValue = false
      else if (field.type === 'select' || field.type === 'files') initialValue = []
      if (field.type === 'scoringBoard') {
        const itemFields = {}
        field.fields.forEach((itemField) => {
          itemFields[itemField.key] = ''
        })
        return { ...acc, ...itemFields }
      } else return { ...acc, [field.key]: initialValue }
    }, {})
  )

  const handleChange =
    (prop, type, theValues = values, maxValues) =>
    (eventOrValue) => {
      if (!disableChanges) {
        if (!maxValues || eventOrValue?.target?.value.length <= maxValues) {
          setValues({ ...theValues, [prop]: eventOrValue?.target?.value ?? eventOrValue })
        }
      }
    }

  useDeepCompareEffect(() => {
    // Fill form with data only when mounted
    setTimeout(() => {
      if (Object.keys(postData || []).length > 0) {
        setDisableChanges(true)
        setTimeout(() => setDisableChanges(false), 2000)
        setValues(
          formFields.reduce((acc, field) => {
            let value = values[field.key]
            let postValue = postData[field.key]
            if (field.key === 'bw20logs_services' || field.key === 'bw20log_services') {
              postValue = postValue || postData.bw20logsServices || postData.leversOfGrowth
              // if (field.key === 'bw20log_services' || Array.isArray(postValue)) {
              //   postValue = postValue[0]
              // }
            }
            if (postValue !== undefined && postValue !== null) {
              if (field.key === 'targetCompanySize' || field.key === 'targetCompanyRevenue') {
                value = field.options.find((o) => o.key === postValue)
              } else if (field.type === 'select') {
                const toOption = (v) => ({ id: v.id, key: v.key, name: v.name || v.service_name })
                if (field.multiple) {
                  value = postValue
                } else if (postValue.id || postValue.key) {
                  value = toOption(postValue)
                }
              } else if (field.type === 'files') {
                if (Array.isArray(postValue)) {
                  value = (postValue || []).map((v) => ({ ...v, status: 'uploaded' }))
                } else {
                  value = [{ ...postValue, status: 'uploaded' }]
                }
              } else if (field.type === 'date') {
                value = moment(postValue).format('YYYY-MM-DD')
              } else {
                value = postValue
              }
            }
            const formFieldValues = {}
            if (field.type !== 'scoringBoard') {
              formFieldValues[field.key] = value
            } else {
              field.fields.forEach((scoreField) => {
                const selectedOption = scoreField.options.find(
                  (o) => o.key === postData[scoreField.key]
                )
                formFieldValues[scoreField.key] = selectedOption
              })
            }
            return {
              ...acc,
              ...formFieldValues,
            }
          }, {})
        )
      }
    }, 200)
  }, [postData])

  const isFieldRequired = (field) => {
    return entity.create.requiredFields.includes(field.key)
  }

  const uploadFilesFields = fields.filter((e) => e.type === 'files')

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      const fieldsWithError = []
      requiredFields.forEach((requiredField) => {
        const v = values[requiredField]
        if (v === '' || v === undefined || v === null || (Array.isArray(v) && v.length === 0)) {
          fieldsWithError.push(fields.find((f) => f.key === requiredField).label)
        }
      })
      if (fieldsWithError.length > 0) {
        const fieldsStr = fieldsWithError.join(', ')
        return {
          errorMessage: `Please, fill in the following fields that are required: ${fieldsStr}`,
        }
      }
      const extract = (
        value,
        keyExtractor,
        type,
        isMultiple = false,
        maxFiles = 1,
        toServerBooleanYesNo = false
      ) => {
        if (value === undefined || value === null) {
          return value
        } else if (type === 'number') {
          return parseInt(value, 10)
        } else if (toServerBooleanYesNo) {
          return value ? 'Yes' : 'No'
        } else if (Array.isArray(value)) {
          if (type === 'files' && value.some((f) => f.id < 0)) {
            return extract(
              value.filter((f) => f.id < 0),
              keyExtractor,
              type,
              isMultiple,
              maxFiles
            )
          } else if (isMultiple) {
            return value.map((v) => extract(v, keyExtractor))
          } else if (!type) {
            return
          } else {
            return extract(value[0], keyExtractor, type)
          }
        }
        return keyExtractor ? value[keyExtractor] : value
      }
      const formData = formFields.reduce((acc, field) => {
        const formFieldValues = {}
        if (field.backendKey) {
          formFieldValues[field.backendKey] = extract(
            values[field.key],
            field.toServerKeyExtractor,
            field.type,
            field.multiple,
            field.maxFiles,
            field.toServerBooleanYesNo
          )
        } else {
          field.fields.forEach((scoreField) => {
            formFieldValues[scoreField.backendKey] = extract(
              values[scoreField.key],
              scoreField.toServerKeyExtractor,
              scoreField.type,
              scoreField.multiple,
              scoreField.maxFiles,
              scoreField.toServerBooleanYesNo
            )
          })
        }
        return {
          ...acc,
          ...formFieldValues,
        }
      }, {})
      Object.entries(hiddenFields || {}).forEach(([k, v]) => (formData[k] = v))

      return formData
    },
  }))

  const handleUploadFileError = (msg) => {
    showDialog(({ close }) => ({
      title: 'Error',
      text: msg,
      actions: [{ text: 'Ok', action: () => close() }],
    }))
  }

  const theValues = { ...values }

  return (
    <Stack className="Form">
      <Box component="form" autoComplete="off">
        {Filters.fetchingFilters && <Spinner />}
        {!Filters.fetchingFilters && (
          <Grid
            style={{
              backgroundColor: colorsByKey.white,
            }}
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {formFields.map((field, index) => {
              // console.log('field', field)
              if (field.type === 'text' || field.type === 'number') {
                return (
                  <Grid
                    item
                    xs={field.grid || 6}
                    sm={field.grid || 6}
                    md={field.grid || 6}
                    key={index}
                  >
                    <FormControl required={isFieldRequired(field)} fullWidth sx={{ m: 0 }}>
                      <InputLabel htmlFor={field.key}>{field.label}</InputLabel>
                      <OutlinedInput
                        id={field.key}
                        value={values[field.key]}
                        onChange={handleChange(field.key, field.type, values, field.maxValues)}
                        startAdornment=""
                        label={field.label}
                        type={field.type}
                        inputProps={field.inputProps}
                      />
                      {/* errorMessages={t('This field is required')} */}
                    </FormControl>
                  </Grid>
                )
              }

              if (field.type === 'date') {
                return (
                  <Grid
                    item
                    xs={field.grid || 6}
                    sm={field.grid || 6}
                    md={field.grid || 6}
                    key={index}
                  >
                    <FormControl required={isFieldRequired(field)} fullWidth sx={{ m: 0 }}>
                      <InputLabel htmlFor={field.key} shrink>
                        {field.label}
                      </InputLabel>
                      <OutlinedInput
                        id={field.key}
                        value={values[field.key]}
                        onChange={handleChange(field.key, field.type, values, field.maxValues)}
                        startAdornment=""
                        label={field.label}
                        type={field.type}
                      />
                      {/* errorMessages={t('This field is required')} */}
                    </FormControl>
                  </Grid>
                )
              }
              if (field.type === 'checkbox') {
                return (
                  <Grid
                    item
                    xs={field.grid || 6}
                    sm={field.grid || 6}
                    md={field.grid || 6}
                    key={index}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={values[field.key]} />}
                        label={field.label}
                        onChange={(event) =>
                          handleChange(
                            field.key,
                            field.type,
                            values,
                            field.maxValues
                          )(event.target.checked)
                        }
                      />
                    </FormGroup>
                  </Grid>
                )
              }

              if (field.type === 'select') {
                return (
                  <Grid
                    item
                    xs={field.grid || 6}
                    sm={field.grid || 6}
                    md={field.grid || 6}
                    key={index}
                  >
                    <FormControl required={isFieldRequired(field)} fullWidth sx={{ m: 0 }}>
                      <InputLabel id={`${field.key}-label`}>{field.label}</InputLabel>
                      <Select
                        id={field.key}
                        labelId={`${field.key}-label`}
                        label={field.label}
                        value={values[field.key]}
                        multiple={field.multiple}
                        onChange={handleChange(field.key, field.type, values, field.maxValues)}
                        input={<OutlinedInput id="select-multiple-chip" label={field.label} />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {field.multiple &&
                              selected.map((value, idx) => (
                                <Chip
                                  key={`selected-option-${field.key}-${value.id}-${idx}`}
                                  label={value.name}
                                />
                              ))}
                            {!field.multiple && (
                              <Chip
                                key={`selected-option-${field.key}-${selected.id}`}
                                label={selected.name}
                              />
                            )}
                          </Box>
                        )}
                      >
                        {field.options.map((option, idx) => {
                          return (
                            <MenuItem
                              value={option}
                              key={`select-option-${field.key}-${option.name}-${idx}`}
                            >
                              {option.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )
              }

              if (field.type === 'scoringBoard') {
                return (
                  <Grid item xs={12} sm={12} md={12} sx={{ my: 4 }}>
                    <Box
                      sx={{
                        backgroundColor: colorsByKey.primaryBgWeak,
                        color: colorsByKey.defaultText,
                        px: 2,
                        py: 1,
                        borderRadius: '8px',
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                      }}
                    >
                      <Typography>{field.label}</Typography>
                    </Box>
                    <Grid
                      style={{
                        backgroundColor: colorsByKey.white,
                      }}
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 12, sm: 12, md: 12 }}
                    >
                      {field.fields.map((itemField, idx) => {
                        return (
                          <Grid item xs={4} sm={4} md={4} key={idx}>
                            <FormControl
                              required={isFieldRequired(itemField)}
                              fullWidth
                              sx={{ m: 0 }}
                            >
                              <InputLabel id={`${itemField.key}-label`}>
                                {itemField.label}
                              </InputLabel>
                              <Select
                                id={itemField.key}
                                labelId={`${itemField.key}-label`}
                                label={itemField.label}
                                value={values[itemField.key]}
                                onChange={handleChange(
                                  itemField.key,
                                  itemField.type,
                                  values,
                                  field.maxValues
                                )}
                                input={<OutlinedInput id="select-chip" label={itemField.label} />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    <Chip
                                      key={`selected-option-${itemField.key}-${selected.id}`}
                                      label={selected.name}
                                    />
                                  </Box>
                                )}
                              >
                                {itemField.options.map((option, idx) => {
                                  return (
                                    <MenuItem
                                      value={option}
                                      key={`select-option-${itemField.key}-${option.name}-${idx}`}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                )
              }

              if (field.type === 'wysiwyg') {
                return (
                  <Grid item xs={12} sm={12} md={12} key={index}>
                    <FormControl required={isFieldRequired(field)} fullWidth sx={{ m: 0 }}>
                      <label htmlFor={field.key}>{field.label}</label>
                      <CKEditor
                        id={field.key}
                        editor={ClassicEditor}
                        config={editorConfig}
                        rows={5}
                        data={values[field.key]}
                        onChange={(e, editor) => {
                          handleChange(
                            field.key,
                            field.type,
                            theValues,
                            field.maxValues
                          )(editor.getData())
                        }}
                      />
                      {/* errorMessages={t('This field is required')} */}
                    </FormControl>
                  </Grid>
                )
              }

              return <Grid item xs={6} sm={6} md={6} key={index}></Grid>
            })}
            {!!uploadFilesFields.length && (
              <Grid item xs={12}>
                <hr></hr>
              </Grid>
            )}
            {uploadFilesFields.map((field) => (
              <Grid item xs={6} sm={6} md={6} key={`uploadFilesFields-${field.key}`}>
                <FileUploader2
                  label={field.label}
                  files={values[field.key]}
                  endpoint={field.endpoint}
                  onSetFiles={handleChange(field.key, field.type, values, field.maxValues)}
                  maxFiles={field.maxFiles}
                  accept={field.accept}
                  onError={handleUploadFileError}
                />
                <div>{t(field.hint)}</div>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Stack>
  )
})
export default withTranslation(undefined, { withRef: true })(Form)
