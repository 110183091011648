import React, { useState, useEffect, useRef } from 'react'
import './chat.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faChevronUp, faClose, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { colorsByKey } from 'theme/colors'
import { Avatar } from '@mui/material'
import { connect } from 'react-redux'
import { closeChat, getChat, sendChatMessage } from 'store/actions/chatActions'

const maximizeIcon = require('assets/icons/maximize.svg')
const defaultImage = require('assets/icons/user.svg')

const ChatMessageComponent = ({getChat, message, isSending, user}) => {
  return (
    <div className={`chat-message-component${isSending ? ' sending':''}`}>
      <Avatar alt={`${user.company_name ?? ''} - profile picture`} src={user.avatar?.url ?? (user.company_logo ? `${process.env.REACT_APP_API_URL}${user.company_logo}` : (user.image ?? defaultImage))} variant='circle' sx={{ width: 28, height: 28 }} />
      <div className={`message-container${isSending ? ' sending':''}`}>
        {message.content}
      </div>
      <span>{message.updatedAt}</span>
    </div>
  )
}

const Chat = ({ chat, closeChat, getChat, userDetail, sendChatMessage }) => {
  const [messages, setMessages] = useState([])
  const [participant, setParticipant] = useState(null)
  const [isFull, setIsFull] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const placeholder = 'Write message'
  const [content, setContent] = useState('')
  const [inputSize, setInputSize] = useState('small')
  const inputRef = useRef(null)
  const messagesRef = useRef(null)
  const footerRef = useRef(null)
  const { id, name, avatar } = userDetail

  const loadChat = (id) => {
    if(!isNaN(id))
      getChat(id)
  }

  useEffect(() => {
    if (inputRef.current && !content) {
      inputRef.current.textContent = placeholder
      inputRef.current.classList.add('placeholder')
      inputRef.current.focus();
    }
  }, [content])

  useEffect(() => {
      setMessages(chat.messages ?? [])
      setParticipant(chat.otherParticipant ?? null)
  }, [chat, chat.messages])

  useEffect(() => {
    const chatMessages = messagesRef.current
    if (chatMessages) {
      chatMessages.scrollTop = chatMessages.scrollHeight
    }
  })

  useEffect(() => {
    const intervalId = setInterval(loadChat(chat.id), 10000);
    
    return () => clearInterval(intervalId);
  }, [participant]);

  const handleInputFocus = () => {
    if (content === placeholder || content === '') {
      inputRef.current.textContent = ''
      inputRef.current.classList.remove('placeholder')
    }
  }

  const handleInputBlur = () => {
    if (content === '') {
      inputRef.current.textContent = placeholder
      inputRef.current.classList.add('placeholder')
    }
  }

  const handleSubmit = () => {
    const newMessage = {
      chat: chat.id,
      sender: id,
      senderId: id,
      content: inputRef.current.textContent
    }

    sendChatMessage({...newMessage, senderId: id})
    inputRef.current.textContent = ''
    setMessages([...messages, newMessage])
    inputRef.current.textContent = ''
  }

  const handleInputKeyPress = (e) => {
    if (String(e.key).toLocaleLowerCase() === 'enter') {
      const newMessage = {
        chat: chat.id,
        sender: id,
        senderId: id,
        content: e.target.textContent
      }

      sendChatMessage({...newMessage, senderId: id})
      e.target.textContent = ''
      setMessages([...messages, newMessage])
    }
    if (e.target.textContent === placeholder) {
      e.target.textContent = ''
    }
  }

  const handleInputChange = (e) => {
    setContent(e.target.textContent)
  }

  const handleIncreaseInput = () => {
    footerRef.current.classList.toggle('full')
    if (inputSize === 'small')
      setInputSize('large')
    else
      setInputSize('small')
  }


  if (!participant) return (<></>)
  return (
    participant && <div id='chat' className={`${isOpen ? 'open' : ''} ${isFull ? 'full' : ''}`} key={chat.id ?? Math.random()}>
      <header>
        <div onClick={() => setIsOpen(!isOpen)}>
          <Avatar alt={`${participant.name} - profile picture`} src={participant.avatar ?? defaultImage} variant='circle' sx={{ width: 33, height: 32 }} />
          <div>
            <h1>{participant.companyName ?? participant.name}</h1>
          </div>
        </div>
        <div>
          <button onClick={() => (setIsOpen(true), setIsFull(!isFull))}><img src={maximizeIcon} color={colorsByKey.grey} /></button>
          <button onClick={() => closeChat()}><FontAwesomeIcon icon={faClose} color={colorsByKey.grey} /></button>
        </div>
      </header>
      {isOpen &&
        <main ref={messagesRef} key={`main-${chat.id ?? Math.random()}`}>
          <div className='messagesContainer' key={`message-container-${chat.id ?? Math.random()}`}>
            {messages && messages.map(message => <ChatMessageComponent isSending={message.senderId === id} user={message.senderId === id ? {id, name, avatar} : participant} message={message} key={message.id*(chat.id * 2)} />)}
          </div>
          <footer ref={footerRef}>
            <div id='chat-input' contentEditable ref={inputRef} onClick={handleInputFocus} onInput={handleInputChange} onFocus={handleInputFocus} onBlur={handleInputBlur} onKeyDown={handleInputKeyPress}></div>
            <button id='increase-input' onClick={handleIncreaseInput}>
              <FontAwesomeIcon icon={inputSize === 'small' ? faChevronUp : faChevronDown} size='xs' color='#808080' />
            </button>
            <button id='chat-submit' onClick={() => handleSubmit()}>
              <FontAwesomeIcon icon={faChevronRight} size='xs' color='#FFF' />
            </button>
          </footer>
        </main>}
    </div>
  )
}

const mapStateToProps = state => ({
  chat: state.chat.chat
})

const mapDispatchToProps = {
  sendChatMessage,
  closeChat,
  getChat
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat)