import React, { useContext } from 'react'
import { Box, Typography, Avatar } from '@mui/material'
import { CredentialsCard } from './CredentialsCard'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import { Spinner, FinancialReportBox } from 'components'

import userContext from 'context/userContext'
import { credentials, CERTIFICATES, EDUCATION } from 'entities/company'
import { KEY, ID, CREATED_AT, TITLE, DESCRIPTION, BROCHURE, TYPE } from 'entities/formFields'
import { DATE_RANGE, TYPE_OF_DEGREE } from 'entities/companyFields'
import { COMPANY } from 'entities/routes'

const defaultImage = require('assets/images/card-default-images/pdf.svg')

const UserCredentialsSectionSidebarComponent = ({ t, data }) => {
  const ctx = useContext(userContext)

  const { brochure, createdAt, userType } = ctx.userData
  const BWcertificate = {
    [TYPE]: CERTIFICATES,
    [KEY]: `BW-${CERTIFICATES}`,
    [ID]: `BW-${CERTIFICATES}`,
    [TITLE]: t('BW Certificate'),
    [DESCRIPTION]: '',
    [CREATED_AT]: createdAt,
    [BROCHURE]: brochure || '',
    documentType: 'application/pdf',
  }
  const handleOpenDocument = (doc) => {
    if (doc) window.open(doc, '_blank')
  }
  const { companySidebarSections, advisorSidebarSections } = credentials
  const isAdvisor = window.location.pathname.includes(COMPANY) ? false : true
  const sections = isAdvisor ? advisorSidebarSections : companySidebarSections

  return (
    <Box
      className="UserCredentialsSectionSidebar"
      width={280}
      sx={{ background: colorsByKey.defaultBgWeaker, pr: 4, py: 4 }}
    >
      <Box sx={{ mb: 4 }}>
        {sections.map((section) => {
          const sectionData = data[section.key] || []
          const formattedData =
            section.key === CERTIFICATES && brochure && userType === 'company'
              ? [BWcertificate, ...sectionData]
              : sectionData

          return (
            <CredentialsCard
              title={t(section.label)}
              icon={section.icon}
              isEditable={ctx.isMyProfile}
              redirectLink={section[`${userType}ActionPath`]}
              key={section.key}
            >
              {!data[section.key] && <Spinner />}
              {data[section.key] && !(formattedData || []).length && (
                <Typography variant="f14" color={colorsByKey.accentTextStrong}>
                  {t('no data yet!')}
                </Typography>
              )}
              {data[section.key] && (
                <Box sx={{ maxHeight: '380px', overflow: 'auto' }}>
                  {formattedData.map((document) => {
                    return (
                      <Box key={document[KEY]} sx={{ display: 'flex', mb: 4 }}>
                        {!(isAdvisor && section.key === EDUCATION) && (
                          <Avatar
                            key={`certificate-${document[ID]}`}
                            alt={`certificate`}
                            src={
                              document.documentType === 'application/pdf'
                                ? defaultImage
                                : document[BROCHURE]
                            }
                            variant="rounded"
                            sx={{
                              width: 100,
                              height: 80,
                              mr: 2,
                              cursor: document[BROCHURE] ? 'pointer' : 'default',
                            }}
                            onClick={() => handleOpenDocument(document[BROCHURE])}
                          />
                        )}
                        <Box>
                          {document[DATE_RANGE] && !document[TYPE_OF_DEGREE] && (
                            <Typography
                              variant="f10"
                              color={colorsByKey.accentTextStrong}
                              sx={{ fontWeight: 'bold', mb: 1 }}
                            >
                              {document[DATE_RANGE]}
                            </Typography>
                          )}
                          {document[DATE_RANGE] && document[TYPE_OF_DEGREE] && (
                            <Typography
                              variant="f10"
                              color={colorsByKey.accentTextStrong}
                              sx={{ fontWeight: 'bold', mb: 1 }}
                            >
                              {document[TYPE_OF_DEGREE].label} ({document[DATE_RANGE]})
                            </Typography>
                          )}

                          <Typography variant="f14" color={colorsByKey.defaultText} sx={{ mb: 1 }}>
                            {document[TITLE]}
                          </Typography>
                          <Typography variant="f12" color={colorsByKey.secondaryText}>
                            {document[DESCRIPTION]}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              )}
            </CredentialsCard>
          )
        })}
        <FinancialReportBox {...ctx.userData.financialReport} />
      </Box>
    </Box>
  )
}
export const UserCredentialsSectionSidebar = withTranslation()(
  UserCredentialsSectionSidebarComponent
)
