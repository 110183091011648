import axiosInstance from 'api/axios'
import {
  CAMPAIGN_WILL_OPTIONS,
  BUSINESS_CASE_OPTIONS,
  IMPROVES_CLIENT_COMPETITIVENESS_OPTIONS,
  IMPROVES_CLIENT_RESULTS_OPTIONS,
  REDUCES_CLIENT_RISKS_OPTIONS,
  SCORING_BOARD_OPTIONS,
  DISCOUNTS,
} from 'mixins/campaignFilters'

const getText = (text, options) => {
  const item = options.find(({ key }) => key === text)
  return (item || {}).name || text
}

// export const getParticipants = () => {
//   return new Promise((resolve, reject) => {
//     axiosInstance.get('/api/partnershipsFormParameters', {
//       headers: {
//         Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
//       },
//     })
//     .then(({ data }) => {
//       return resolve(data?.success?.companies_list ?? [])
//     }).catch(error => {
//       console.error(error)
//       return reject([])
//     })
//   })
// }

/* CAMPAIGN FORM FIELDS */
export const TARGET_INDUSTRY_SEGMENTS = 'targetIndustrySegments'

export const PRODUCT = 'product'
export const ADVANTAGE = 'advantage'

export const PAIN_PROBLEMS = 'painProblems'
export const BUY_CONTEXT = 'why_buy_context'
export const GAIN_DESIRE = 'gainDesire'

export const CAMPAIGN_WILL = 'campaignWill'
export const PROBLEM = 'problem'
export const PROBLEM_USE_CONTEXT = 'problemUseContext'

export const PAIN_RELIEVERS = 'painRelievers'
export const GAIN_CREATORS = 'gainCreators'

export const AREAS_OF_INNOVATION = 'areasOfInnovation'
export const CAMPAIGN_STRENGHTS = 'campaignStrengths'
export const BRAND_FAME = 'brandFame'

export const BUSINESS_CASE = 'businessCase'
export const IMPROVES_CLIENT_COMPETITIVENESS = 'improvesClientsCompetitiveness'
export const IMPROVES_CLIENT_RESULTS = 'improvesClientsResults'
export const REDUCES_CLIENT_RISKS = 'reducesClientsRisks'
export const POTENTIAL_OBJECTIONS = 'potentialObjections'
export const POTENTIAL_COMPETITORS = 'potentialCompetitors'
export const SPECIAL_CONDITIONS = 'specialConditions'
export const LOWER_COSTS = 'lowerCosts'
export const SEPARATOR = 'separator'

/* CAMPAIGN GROUPS OF FIELDS */
export const MAIN = 'main'
export const DESCRIPTION = 'description'
export const COMPANY = 'company'
export const PEOPLE = 'people'
export const JOB_TO_DO = 'jobToDo'
export const SOLUTIONS = 'solutions'
export const SELLING_PROPOSITION = 'sellingProposition'
export const BENEFITS = 'benefits'
export const SCORING_BOARD = 'scoringBoard'
export const IRRESISTIBLE_SCORING = 'irresistibleScoring'
export const OTHERS = 'others'
export const CREDENTIALS = 'credentials'

export const main = {
  key: MAIN,
  label: '',
  detailsLabel: '',
  display: true,
}
export const description = {
  key: DESCRIPTION,
  label: 'Description',
  detailsLabel: 'Description',
  display: true,
}
export const company = {
  key: COMPANY,
  label: 'Avatar Clients | Company',
  detailsLabel: 'Client Perspective',
  display: true,
}
export const people = {
  key: PEOPLE,
  label: 'Avatar Clients | People',
  display: true,
}
export const jobToDo = {
  key: JOB_TO_DO,
  label: 'Campaign job to be done',
  detailsLabel: 'Campaign Job to be done',
  display: true,
}
export const solutions = {
  key: SOLUTIONS,
  label: 'Solutions',
  detailsLabel: 'Solutions',
  display: true,
}
export const sellingProposition = {
  key: SELLING_PROPOSITION,
  label: 'Unique selling proposition',
  display: true,
}
export const benefits = {
  key: BENEFITS,
  label: 'Benefits',
  display: true,
}
export const irresistibleScoring = {
  key: IRRESISTIBLE_SCORING,
  label: 'Measure how irresistible your offer is (scoring)',
  detailsLabel: 'Measure how irresistible your offer is (scoring)',
  blockTitle: 'Now you are going to pass a test to measure the competitiveness of your offer. Please note that if the scoring is <strong>lower than 10</strong> you cannot publish your campaign.*',
  display: true,
}
export const scoringBoard = {
  key: CREDENTIALS,
  label: 'Additional reasons to close the deal',
  detailsLabel: 'Credentials',
  display: true,
}
export const others = {
  key: OTHERS,
  label: '',
  display: true,
}
export const CAMPAIGNS_BLOCKS = [
  main,
  description,
  company,
  // people,
  jobToDo,
  solutions,
  // sellingProposition,
  // benefits,
  irresistibleScoring,
  scoringBoard,
  others,
]

/* CAMPAIGN FORM FIELDS */

export const targetIndustrySegments = {
  key: TARGET_INDUSTRY_SEGMENTS,
  label: 'Target industry segments',
  type: 'text',
  backendKey: 'target_industry_segments',
  grid: 6,
  groupBy: MAIN,
}
export const product = {
  key: PRODUCT,
  label: 'Offer (Service or Product)',
  detailsLabel: 'Offer',
  type: 'text',
  backendKey: 'product',
  grid: 12,
  groupBy: DESCRIPTION,
}
export const advantage = {
  key: ADVANTAGE,
  label: 'Advantage',
  type: 'text',
  backendKey: 'advantage',
  grid: 12,
  groupBy: DESCRIPTION,
}

//// Avatar clients
// Company pains
const companyPain = {
  type: 'text',
  grid: 6,
  groupBy: COMPANY,
  kind: 'pain',
}
export const painProblems = {
  ...companyPain,
  key: PAIN_PROBLEMS,
  label: 'What keeps them awake at night',
  backendKey: 'pain_problems',
  grid: 12,
}
export const buyContext = {
  ...companyPain,
  key: 'why_buy_context',
  label: 'Why should they buy in the current context?',
  backendKey: 'why_buy_context',
  grid: 12,
}
export const painFears = {
  ...companyPain,
  key: 'painFears',
  label: 'What are exactly their fears',
  backendKey: 'pain_fears',
}
export const painFrustrations = {
  ...companyPain,
  key: 'painFrustrations',
  label: 'What makes them frustrated / angry',
  backendKey: 'pain_frustrations',
}
// Company gains
const companyGain = {
  ...companyPain,
  kind: 'gain',
}
export const gainDesire = {
  ...companyGain,
  key: GAIN_DESIRE,
  label: 'What is the ONE the desire above all',
  backendKey: 'gain_desire',
}
export const gainConfidence = {
  ...companyGain,
  key: 'gainConfidence',
  label: 'What gives them confidence in the future',
  backendKey: 'gain_confidence',
}
export const gainHapiness = {
  ...companyGain,
  key: 'gainHapiness',
  label: 'What makes them gratified / happy',
  backendKey: 'gain_hapiness',
}
// People names
const peopleNames = {
  type: 'text',
  grid: 3,
  groupBy: PEOPLE,
  kind: 'people',
}
export const decisionMaker = {
  ...peopleNames,
  key: 'decisionMaker',
  label: 'Decision Maker',
  backendKey: 'decision_maker',
}
export const financier = {
  ...peopleNames,
  key: 'financier',
  label: 'Financier (budget)',
  backendKey: 'financier',
}
export const purchaser = {
  ...peopleNames,
  key: 'purchaser',
  label: 'Purchaser',
  backendKey: 'purchaser',
}
export const endUser = {
  ...peopleNames,
  key: 'endUser',
  label: 'End User',
  backendKey: 'end_user',
}
export const influencer = {
  ...peopleNames,
  key: 'influencer',
  label: 'Influencer',
  backendKey: 'influencer',
}
// People roles
const peopleRoles = {
  ...peopleNames,
  kind: 'roles',
}
export const businessCaser = {
  ...peopleRoles,
  key: 'businessCaser',
  label: 'Business Caser',
  backendKey: 'business_caser',
}
export const devilsAdvocate = {
  ...peopleRoles,
  key: 'devilsAdvocate',
  label: 'Devils Advocate (skeptical)',
  backendKey: 'devils_advocate',
}
export const earlyAdopter = {
  ...peopleRoles,
  key: 'earlyAdopter',
  label: 'Early Adopter (innovative)',
  backendKey: 'early_adopter',
}
export const friend = {
  ...peopleRoles,
  key: 'friend',
  label: 'Friend',
  backendKey: 'friend',
}
export const diplomat = {
  ...peopleRoles,
  key: 'diplomat',
  label: 'Diplomat (wants consensus)',
  backendKey: 'diplomat',
}
//// Differentiation from competitors
export const campaignWill = {
  key: CAMPAIGN_WILL,
  label: 'This campaign will help the customer to...',
  type: 'select',
  multiple: false,
  options: CAMPAIGN_WILL_OPTIONS,
  getText: (text) => getText(text, CAMPAIGN_WILL_OPTIONS),
  toServerKeyExtractor: 'key',
  backendKey: 'campaign_will',
  grid: 6,
  groupBy: JOB_TO_DO,
}
export const problem = {
  key: PROBLEM,
  label: 'Problem / Opportunity',
  detailsLabel: 'It will help...',
  type: 'text',
  backendKey: 'problem',
  grid: 6,
  groupBy: JOB_TO_DO,
}
export const problemUseContext = {
  key: PROBLEM_USE_CONTEXT,
  label:
    '... while... (the use context when the problem solving / avoiding or the opportunity capturing occur',
  detailsLabel: '...while...',
  type: 'text',
  backendKey: 'problem_use_context',
  grid: 12,
  groupBy: JOB_TO_DO,
}
export const painRelievers = {
  key: PAIN_RELIEVERS,
  label: 'Pain relievers',
  type: 'text',
  backendKey: 'pain_relievers',
  grid: 12,
  groupBy: SOLUTIONS,
}
export const gainCreators = {
  key: GAIN_CREATORS,
  label: 'Gain creators',
  type: 'text',
  backendKey: 'gain_creators',
  grid: 12,
  groupBy: SOLUTIONS,
}
export const lowerCosts = {
  key: LOWER_COSTS,
  label: 'Lower costs',
  type: 'checkbox',
  backendKey: 'lower_costs',
  grid: 12,
  groupBy: IRRESISTIBLE_SCORING,
}

export const areasOfInnovation = {
  key: AREAS_OF_INNOVATION,
  label: 'Areas Of Innovation',
  type: 'text',
  backendKey: 'innovation_areas',
  grid: 12,
  groupBy: SELLING_PROPOSITION,
}
export const campaignStrengths = {
  key: CAMPAIGN_STRENGHTS,
  label: 'Campaign Strengths',
  type: 'text',
  backendKey: 'strengths',
  grid: 12,
  groupBy: SELLING_PROPOSITION,
}
export const brandFame = {
  key: BRAND_FAME,
  label: 'What is your brand famous for?',
  type: 'text',
  backendKey: 'what_brand_famous',
  grid: 12,
  groupBy: SELLING_PROPOSITION,
}
export const businessCase = {
  key: BUSINESS_CASE,
  label: 'Business case (benefits vs costs of the offer)',
  type: 'select',
  multiple: false,
  options: BUSINESS_CASE_OPTIONS,
  getText: (text) => getText(text, BUSINESS_CASE_OPTIONS),
  toServerKeyExtractor: 'key',
  backendKey: 'business_case',
  grid: 12,
  groupBy: BENEFITS,
}

export const improvesClientsCompetitiveness = {
  key: IMPROVES_CLIENT_COMPETITIVENESS,
  label: 'Improves clients competitiveness',
  type: 'select',
  multiple: true,
  options: IMPROVES_CLIENT_COMPETITIVENESS_OPTIONS,
  getText: (text) => getText(text, IMPROVES_CLIENT_COMPETITIVENESS_OPTIONS),
  toServerKeyExtractor: 'key',
  backendKey: 'improves_clients_competitiveness',
  grid: 6,
  groupBy: BENEFITS,
}
export const improvesClientsResults = {
  key: IMPROVES_CLIENT_RESULTS,
  label: 'Improves clients results',
  type: 'select',
  multiple: true,
  options: IMPROVES_CLIENT_RESULTS_OPTIONS,
  getText: (text) => getText(text, IMPROVES_CLIENT_RESULTS_OPTIONS),
  toServerKeyExtractor: 'key',
  backendKey: 'improves_clients_results',
  grid: 6,
  groupBy: BENEFITS,
}
export const reducesClientsRisks = {
  key: REDUCES_CLIENT_RISKS,
  label: 'Reduces clients risks',
  type: 'select',
  multiple: true,
  options: REDUCES_CLIENT_RISKS_OPTIONS,
  getText: (text) => getText(text, REDUCES_CLIENT_RISKS_OPTIONS),
  toServerKeyExtractor: 'key',
  backendKey: 'reduces_clients_risks',
  grid: 6,
  groupBy: BENEFITS,
}
//// Scoring board
const scoringBoardDefault = {
  type: 'select',
  multiple: false,
  options: SCORING_BOARD_OPTIONS,
  getText: (text) => getText(text, SCORING_BOARD_OPTIONS),
  toServerKeyExtractor: 'key',
  grid: 4,
  groupBy: SCORING_BOARD,
}
export const scoringBoardReviews = {
  ...scoringBoardDefault,
  key: 'reviews',
  label: 'Reviews',
  backendKey: 'scoring_board_reviews',
}
export const scoringBoardTestimonials = {
  ...scoringBoardDefault,
  key: 'testimonials',
  label: 'Testimonials',
  backendKey: 'scoring_board_testimonials',
}
export const scoringBoardRecommendations = {
  ...scoringBoardDefault,
  key: 'recommendations',
  label: 'Recommendations',
  backendKey: 'scoring_board_recommendations',
}
export const scoringBoardCertificates = {
  ...scoringBoardDefault,
  key: 'certificates',
  label: 'Certificates',
  backendKey: 'scoring_board_certificates',
}
export const scoringBoardAwards = {
  ...scoringBoardDefault,
  key: 'awards',
  label: 'Awards',
  backendKey: 'scoring_board_awards',
}
export const scoringBoardPublications = {
  ...scoringBoardDefault,
  key: 'publications',
  label: 'Publications',
  backendKey: 'scoring_board_publications',
}
export const potentialObjections = {
  key: POTENTIAL_OBJECTIONS,
  label: 'Potential Objections',
  type: 'text',
  backendKey: 'potential_objections',
  grid: 6,
  groupBy: CREDENTIALS,
}
export const potentialCompetitors = {
  key: POTENTIAL_COMPETITORS,
  label: 'Potential Competitors',
  type: 'text',
  backendKey: 'potential_competitors',
  grid: 6,
  groupBy: CREDENTIALS,
}
export const specialConditions = {
  key: SPECIAL_CONDITIONS,
  label: 'Concrete special conditions by BridgeWhat LOG',
  type: 'text',
  backendKey: 'special_conditions',
  grid: 12,
  groupBy: CREDENTIALS,
}
export const separator = {
  // key: SEPARATOR,
  type: 'separator',
  groupBy: OTHERS
}
export const irresistibleOffer = {
  type: 'checkboxGroups',
  label: 'B. Please identify benefits related to the three following sources (choose the ones that apply to your Campaign):*',
  detailsLabel: 'B. Buseness Case Benefits',
  groupBy: IRRESISTIBLE_SCORING,
  multiple: true,
  groups: [
    {
      label: '1. Improves clients competitiveness',
      key: 'improvesClientsCompetitiveness',
      backendKey: 'improves_clients_competitiveness',
      fields: IMPROVES_CLIENT_COMPETITIVENESS_OPTIONS,
    },
    {
      label: '2. Improves clients results',
      key: IMPROVES_CLIENT_RESULTS,
      backendKey: 'improves_clients_results',
      fields: IMPROVES_CLIENT_RESULTS_OPTIONS,
    },
    {
      label: '3. Reduces clients risks',
      key: REDUCES_CLIENT_RISKS,
      backendKey: 'reduces_clients_risks',
      fields: REDUCES_CLIENT_RISKS_OPTIONS,
    }
  ]
}
export const businessCaseReason = {
  key: 'businessCaseReason',
  type: 'textarea',
  label: 'Plase explain the selected option (maximm of 200 characters)',
  detailsLabel: '...Reason',
  backendKey: 'business_case_reason',
  grid: 12,
}
export const businessCaseOptions = {
  type: 'inputGroup',
  label: 'A. How would you classify the business case of your offer?',
  detailsLabel: 'A. Business Case',
  grid: 12,
  groupBy: IRRESISTIBLE_SCORING,
  fields: [
    businessCase,
    businessCaseReason,
  ]
}
export const irresistibleOfferDiscounts = {
  type: 'checkboxGroups',
  label: 'C. Discounts',
  key: 'discounts',
  groupBy: IRRESISTIBLE_SCORING,
  multiple: false,
  showScore: true,
  groups: [
    {
      type: 'radio',
      label: 'If not many/any of the previous benefits apply to your campaign, you can achieve a better scoring by offering additional discounts:* ',
      key: 'discounts',
      backendKey: 'discounts',
      fields: DISCOUNTS,
    },
  ]
}

export const bwParticipants = {
  key: 'bwParticipantExamples',
  type: 'autocomplete',
  label: 'Bridgewhat Participants',
  title: 'Target Company(ies) example(s)*',
  detailsLabel: 'Target Company(ies) examples:',
  options: [],
  backendKey: 'bridgewhat_partner_examples',
  grid: 6,
  groupBy: MAIN
}