import React from 'react'
import Container from 'react-bootstrap/Container'
import history from '../history'
import { Helmet } from 'react-helmet'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
class Banktransfer extends React.Component {
  gotowelcome() {
    history.push('/welcome')
  }

  render() {
    const { t } = this.props

    let packagePrice = 0
    // if (this.props.userDetail.subscription_type === 'monthly') {
    //   packagePrice = parseInt(this.props.userDetail.subscription.monthly_cost)
    // } else {
    //   packagePrice = parseInt(this.props.userDetail.subscription.yearly_cost)
    // }

    let taxprice = 0
    if (this.props.userDetail.country.id === 179) {
      taxprice = packagePrice * 0.21
    } else if (this.props.userDetail.country.id === 152) {
      taxprice = packagePrice * 0.23
    }

    packagePrice = parseInt(packagePrice) + parseFloat(taxprice)

    packagePrice = packagePrice.toFixed(2)

    return (
      <div>
        <Helmet>
          <title>Bank Transfer | BridgeWhat: Let's Grow!</title>
          <body className="welcomepage" />
        </Helmet>
        <div>
          <Container className="pt-1 pb-1">
            <div className="welcome-div" style={{ maxWidth: '840px', padding: '40px' }}>
              <div className="text-center">
                <img
                  className="mb-4"
                  style={{ maxWidth: '430px', width: '100%' }}
                  src={require('assets/logo.png')}
                  alt="BWS Logo"
                />
              </div>
              <div style={{ maxWidth: '370px', padding: '25px 0 0 0', margin: '0px auto' }}>
                <span>{t('Bank transfer Details')}</span>
                <span>
                  <b>{t('Name')}:</b> WHYSTAR DELTA PT
                </span>
                <span>
                  <b>{t('Account number')}:</b> PT50 0018 0003 52461308020 45
                </span>
                <span>
                  <b>{t('BIC/SWIFT')}:</b> TOTAPTPL
                </span>
                <span>
                  <b>{t('Amount')}:</b> &euro;{packagePrice}
                </span>

                <div className="text-center">
                  <Button
                    onClick={() => this.gotowelcome()}
                    type="button"
                    className="submit-btn ml-auto mr-auto mt-5 mb-0 border-0 MuiButton-root MuiButton-contained"
                  >
                    {t('Let’s Grow')}
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Banktransfer)
