import React, { useContext } from 'react'
import { Section } from './Section'
import QueryFiltersContext from 'context/queryFiltersContext'
import { Box } from '@mui/material'
import { FeedFiltersPanel } from './Filters/FeedFiltersPanel'

export const Feed = ({ userId, tabs, userDetail }) => {
  const ctx = useContext(QueryFiltersContext)

  const filterParams = userId ? { [ctx.tabDetails.list.companyDefaultFilterParam]: userId } : {}

  const emptyFilterValues = ctx.formatFilterData(ctx.defaultFilterValues)
  const appliedFilters = {}
  Object.keys(ctx.savedFilters).forEach((key) => {
    if (ctx.savedFilters[key] && ctx.savedFilters[key] !== emptyFilterValues[key]) {
      appliedFilters[key] = ctx.savedFilters[key]
    }
  })

  const params = {
    url: ctx.tabDetails.list.endpoint,
    method: ctx.tabDetails.list.method,
    filterParams: appliedFilters,
    defaultFilterParams: {
      ...filterParams,
      ...ctx.tabDetails.list.defaultFilterParams,
    },
    type: ctx.filters.type,
  }

  return (
    <Box flexGrow={1}>
      {/* {console.log(tabs)} */}
      <FeedFiltersPanel tabs={tabs} userDetail={userDetail} />
      {params.type && <Section userDetail={userDetail} {...params} />}
    </Box>
  )
}
