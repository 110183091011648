import React from 'react'
import { Route } from 'react-router-dom'
import history from '../history'
import { apiGet } from 'api'
import { LOGOUT, DETAILS } from 'entities/routes'
import { normalizeUserData } from 'mixins/dataNormalizers'

import Header from '../component/header/header'
import Footer from '../component/footer/footer'

class PrivateUserRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userDetail: null,
    }

    if (!localStorage.getItem('accessToken')) {
      history.push('/')
    }

    this.changeSeller = this.changeSeller.bind(this)
    this.updateProfile = this.updateProfile.bind(this)
  }

  async componentDidMount() {
    await apiGet(DETAILS)
      .then(({ data }) => {
        const { cb_roles_id, step, payment_status, status } = data
        const { accessRole } = this.props

        if (accessRole === '1') {
          if (cb_roles_id !== 1) {
            history.push('/complete-sba-registration')
          } else if (step < 4) {
            history.push('/complete-participant-registration')
          } else {
            if (status === 0 || payment_status === 'Payment nok') {
              history.push('/account-inactive')
            }
            this.setState({ userDetail: normalizeUserData(data) })
          }
        } else if (accessRole === '2') {
          if (cb_roles_id !== 2) {
            history.push('/complete-participant-registration')
          } else if (step < 4) {
            history.push('/complete-sba-registration')
          } else {
            this.setState({ userDetail: normalizeUserData(data) })
          }
        } else {
          if (step < 4) {
            if (cb_roles_id === 1) {
              history.push('/complete-participant-registration')
            } else if (cb_roles_id === 2) {
              history.push('/complete-sba-registration')
            }
          } else {
            if (cb_roles_id === 1) {
              if (status === 0 || payment_status === 'Payment nok') {
                history.push('/account-inactive')
              }
            }

            this.setState({ userDetail: normalizeUserData(data) })
          }
        }
      })
      .catch(() => {
        history.push(LOGOUT)
      })
  }

  changeSeller(val) {
    const { userDetail } = this.state
    userDetail['is_seller'] = val
    this.setState({ userDetail })
  }

  async updateProfile() {
    await apiGet(DETAILS)
      .then(({ data }) => {
        this.setState({ userDetail: normalizeUserData(data) })
      })
      .catch(() => {
        history.push(LOGOUT)
      })
  }

  render() {
    const { props } = this
    const { component: Component, ...rest } = props
    const { userDetail } = this.state

    if (!userDetail) {
      return <div></div>
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <Header userDetail={userDetail} layout="PrivateRoute"></Header>
              <Component
                userDetail={userDetail}
                updateProfile={() => this.updateProfile()}
                changeSeller={(val) => this.changeSeller(val)}
                {...props}
              />
              <Footer></Footer>
            </>
          )
        }}
      />
    )
  }
}

export default PrivateUserRoute
