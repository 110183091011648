import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { colorsByKey } from 'theme/colors'

export const Spinner = ({ icon, title, text, onClick, customStyles }) => {
  return (
    <Box
      className="Spinner"
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 2,
        ...customStyles,
      }}
    >
      <CircularProgress sx={{ color: colorsByKey.primaryBgWeak }} />
    </Box>
  )
}
