import React from 'react'
import { Box, Container } from '@mui/material'
import { UserSection, UserHeader, UserSectionTabs } from './components'
import { UserContextProvider } from 'context/userContext'
import { colorsByKey } from 'theme/colors'
import { COMPANY, ADVISOR } from 'entities/companyFields'
import { COMPANY_SECTION_TABS, ADVISOR_SECTION_TABS } from 'entities/company'
import { HEADER_COMPANY_INFO_FIELDS, HEADER_ADVISOR_INFO_FIELDS } from 'entities/companyFields'

const User = ({ userDetail }) => {
  const userType = window.location.pathname.includes(COMPANY) ? COMPANY : ADVISOR
  const sectionTabs = userType === COMPANY ? COMPANY_SECTION_TABS : ADVISOR_SECTION_TABS
  const headerFields =
    userType === COMPANY ? HEADER_COMPANY_INFO_FIELDS : HEADER_ADVISOR_INFO_FIELDS

  return (
    <UserContextProvider userDetail={userDetail}>
      <Box>
        <UserHeader userType={userType} headerFields={headerFields} />
        <UserSectionTabs sectionTabs={sectionTabs} />
        <Container sx={{ background: colorsByKey.defaultBgWeaker }} maxWidth={false} disableGutters>
          <UserSection userDetail={userDetail} userType={userType} sectionTabs={sectionTabs} />
        </Container>
      </Box>
    </UserContextProvider>
  )
}
export default User
