import React from 'react'
import { Route } from 'react-router-dom'
import history from '../history'
import { apiGet } from 'api'
import { LOGOUT, DETAILS } from 'entities/routes'
import { normalizeUserData } from 'mixins/dataNormalizers'

import Header from '../component/header/header'
import Footer from '../component/footer/footer'

class RegisterSbaRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userDetail: null,
    }

    if (!localStorage.getItem('accessToken')) {
      history.push('/')
    }
  }

  async componentDidMount() {
    await apiGet(DETAILS)
      .then(({ data }) => {
        if (data.cb_roles_id !== 2) {
          history.push('/complete-participant-registration')
        } else if (data.step >= 4) {
          history.push('/sba-profile')
        } else {
          this.setState({ userDetail: normalizeUserData(data) })
        }
      })
      .catch((error) => {
        history.push(LOGOUT)
      })
  }

  render() {
    const { props } = this
    const { component: Component, ...rest } = props
    const { userDetail } = this.state

    if (!userDetail) {
      return <div></div>
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <Header></Header>
              <Component userDetail={userDetail} {...props} />
              <Footer></Footer>
            </>
          )
        }}
      />
    )
  }
}

export default RegisterSbaRoute
