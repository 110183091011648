import React, { useRef, useState } from 'react'
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Container,
} from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { useParams, useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { apiPut } from 'api'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { IconData } from 'components'
import Form from 'screens/User/sections/Profile/components/Form'
import { ADVISORS_REGISTRATION_FIELDS } from 'entities/companyFields'

const CompleteRegistration = ({ t }) => {
  let history = useHistory()

  const { id: userId } = useParams()
  // entity

  const DIALOG_INITIAL_DATA = {
    isOpen: false,
    title: '',
    text: '',
    actions: [],
  }
  const [dialogData, setDialogData] = useState(DIALOG_INITIAL_DATA)
  const closeDialog = () => setDialogData(DIALOG_INITIAL_DATA)
  const showDialog = (fn) => setDialogData({ isOpen: true, ...fn({ close: closeDialog }) })

  const goBack = () => {
    history.goBack()
  }
  const handleSubmit = () => {
    triggerSubmit().then(() => {
      history.push(`/advisor/${userId}`)
    })
  }
  const handleCancel = () => {
    goBack()
  }
  if (!userId) {
    goBack()
  }
  const formRef = useRef(null)

  const triggerSubmit = async () => {
    const formData = formRef.current.getFormData()
    if (formData?.errorMessage) {
      showDialog(({ close }) => ({
        title: 'Error',
        text: formData.errorMessage,
        actions: [{ text: 'Ok', action: () => close() }],
      }))
      return Promise.reject()
    }
    const request = apiPut(`/api/advisors/${userId}`, formData) // TODO:

    return request.then(
      (response) =>
        new Promise((resolve) => {
          showDialog(({ close }) => ({
            title: 'Great!',
            text: 'This information has been correctly submited',
            actions: [
              {
                text: 'Ok',
                action: () => {
                  close()
                  resolve(response)
                },
              },
            ],
          }))
        })
    )
  }

  return (
    <Container maxWidth={'xl'} sx={{ py: 4, background: colorsByKey.defaultBgWeaker }}>
      <Box
        sx={{
          m: 4,
          backgroundColor: colorsByKey.utility,
          boxShadow: '2px 0px 8px rgba(0,0,0,0.10)',
          borderRadius: '8px',
          flex: 1,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="f20" sx={{ display: 'inline-block' }}>
            {t('Complete advisor registration')}
          </Typography>
          <Box>
            <Button onClick={handleCancel}>
              <Typography noWrap>{t('Cancel')}</Typography>
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                ml: 2,
                borderRadius: '48px !important',
                backgroundColor: colorsByKey.accentBg,
              }}
            >
              <IconData
                title={t('Submit')}
                icon={faSave}
                titleVariant="f14"
                margin={1}
                color={colorsByKey.utility}
                sx={{ padding: '6px 8px' }}
              />
            </Button>
          </Box>
        </Box>
        <Box sx={{ width: '100%', py: 4 }}>
          <Form
            ref={formRef}
            formFields={ADVISORS_REGISTRATION_FIELDS}
            requiredFields={['name']}
            showDialog={showDialog}
          />
        </Box>
      </Box>

      <Dialog
        open={dialogData.isOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogData.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogData.actions.map(({ text, action }, index) => (
            <Button key={index} onClick={action}>
              {text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </Container>
  )
}
export default withTranslation()(CompleteRegistration)
