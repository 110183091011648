import React from 'react'
import { Box, Typography } from '@mui/material'
import { CompanyAvatar, IconData } from 'components'
import { colorsByKey } from 'theme/colors'
import {
  ID,
  IMAGE,
  DESCRIPTION,
  NAME,
  SUBSCRIPTION,
  CARD_INFO_FIELDS,
  INDUSTRY_SECTORS,
  JOB_FUNCTION,
} from 'entities/companyFields'
import { USER, RANGE_DATES } from 'entities/formFields'
import { industrySectors } from 'entities/companyFields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaste } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'

export const RecommendationsCard = (props) => {
  const { displayVertical = false, sectors } = props

  return (
    <Box
      className="RecommendationsCard"
      sx={{
        display: 'flex',
        flexDirection: displayVertical ? 'column' : 'row',
        overflow: 'hidden',
        borderRadius: '6px',
        boxShadow: '2px 0px 8px rgba(0,0,0,0.10)',
        ...props.sx,
      }}
    >
      {displayVertical && props.project && (
        <Box sx={{ display: 'flex', backgroundColor: colorsByKey.primaryBgWeak, p: 2 }}>
          <FontAwesomeIcon icon={faPaste} size="sm" color={colorsByKey.accentText} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="f14" color={colorsByKey.accentText} sx={{ fontWeight: 'bold' }}>
              {props.project}
            </Typography>
            <Typography variant="f10" color={colorsByKey.primaryBgStrong}>
              {moment(props[RANGE_DATES][0]).format('MMM YY')}
              <Typography
                component="span"
                variant="f10"
                color={colorsByKey.primaryBgStrong}
                sx={{ mx: 0.5, display: 'inline-block' }}
              >
                -
              </Typography>
              {moment(props[RANGE_DATES][1]).format('MMM YY')}
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ p: 2 }}>
        {((props.company || {})[NAME] || (props.reviewer || {})[NAME]) && (
          <Box sx={{ display: 'flex' }}>
            <CompanyAvatar
              name={(props.company || {})[NAME] || (props.reviewer || {})[NAME]}
              subscription={(props.company || {})[SUBSCRIPTION] || true}
              redirectToCompanyProfile={!!(props.company || {})[SUBSCRIPTION]}
              image={(props.company || {})[IMAGE]}
              userId={(props.company || {})[ID]}
              size="sm"
              variant="rounded"
              customSx={{ mr: 2 }}
            />
            <Box sx={{}}>
              <Typography
                variant="f16"
                color={colorsByKey.defaultText}
                sx={{ fontWeight: 'bold', mb: 1 }}
              >
                {(props.company || {})[NAME] || (props.reviewer || {})[NAME]}
              </Typography>
              {(props.company || {})[NAME] && (
                <>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {CARD_INFO_FIELDS.map(({ key, icon, suffix, getText }) => {
                      if (!props.company[key]) return
                      else {
                        return (
                          <Box
                            key={`${props.company[ID]}-${key}`}
                            sx={{
                              mr: 2,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <IconData
                              type={key}
                              title={getText ? getText(props.company[key]) : props.company[key]}
                              icon={icon}
                              suffix={suffix}
                              margin={1}
                            />
                          </Box>
                        )
                      }
                    })}
                  </Box>
                  <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
                    {props.company[INDUSTRY_SECTORS] && (
                      <Box
                        sx={{
                          mr: 2,
                          mt: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: colorsByKey.defaultBgWeak,
                          borderRadius: '24px',
                          py: 0.5,
                          px: 2,
                        }}
                      >
                        <IconData
                          type={INDUSTRY_SECTORS}
                          title={industrySectors.getText(props.company[INDUSTRY_SECTORS], sectors)}
                          icon={industrySectors.icon}
                          margin={1}
                        />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          maxHeight: displayVertical ? '180px' : '130px',
          overflow: 'auto',
          borderTop: displayVertical ? `2px solid ${colorsByKey.defaultBgWeak}` : 'none',
          p: 2,
          mt: displayVertical ? 2 : 0,
          borderLeft: displayVertical ? 'none' : `2px solid ${colorsByKey.defaultBgWeak}`,
          pl: displayVertical ? 2 : 4,
          ml: displayVertical ? 0 : 4,
        }}
      >
        <Typography variant="f16" color={colorsByKey.accentTextStrong} sx={{ fontWeight: 'bold' }}>
          {props.reviewer[USER][NAME]}
        </Typography>
        <Typography variant="f14" color={colorsByKey.secondaryText} sx={{ mt: 1 }}>
          {props.reviewer[USER][JOB_FUNCTION]}
        </Typography>

        <Typography variant="f12" color={colorsByKey.defaultText} sx={{ mt: 1 }}>
          {props[DESCRIPTION]}
        </Typography>
      </Box>
    </Box>
  )
}
