import { Tab, Tabs } from '@mui/material'
import React, { useContext, useState } from 'react'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import QueryFiltersContext from 'context/queryFiltersContext'
import SUBSCRIPTIONS from 'utils/subscriptions'
import { ModalUpgradePackage } from 'components'
import '../../../component/annualpackage/annualpackage.css'

const ItemTypeTabsComponent = ({ t, tabs, userDetail }) => {
  const ctx = useContext(QueryFiltersContext)

  const [visibleModalUpgrade, setVisibleModalUpgrade] = useState(false)
  const [dataModalUpgrade, setDataModalUpgrade] = useState({
    data: '',
    linkUpgrade: '',
  })
  const [dataModalCurrent, setDataModalCurrent] = useState({
    data: '',
    linkUpgrade: '',
  })

  const userCurrent = userDetail
  const subscriptionCurrent = SUBSCRIPTIONS[userCurrent.subscription]

  function handleCloseModal() {
    setVisibleModalUpgrade(false)
  }

  const handleChangeTab = (_, value) => {
    let found = subscriptionCurrent?.available_features?.find((element) => element == tabs[value].key)

    //ctx.handleClearFilters()
    //ctx.setFilter('type', tabs[value].key)

    //MODAL UPGRADE
    if (found != undefined || tabs[value].key == 'all') {
      //Tiene acceso
      setVisibleModalUpgrade(false)
      ctx.handleClearFilters()
      ctx.setFilter('type', tabs[value].key)
    } else {
      let upgrade =
        userCurrent.subscription == 1 || userCurrent.subscription == 5
          ? 2
          : userCurrent.subscription + 1

      setVisibleModalUpgrade(true)
      setDataModalUpgrade({
        data: SUBSCRIPTIONS[upgrade],
        linkUpgrade: userCurrent,
      })
      setDataModalCurrent({
        data: subscriptionCurrent,
        linknowBenefits: '',
      })
      //No tiene acceso y levanta modal de upgrade
    }
  }

  const value = (ctx.filters || {}).type
    ? tabs.findIndex(({ key }) => key === (ctx.filters || {}).type)
    : 0

  return (
    <Tabs
      value={value}
      onChange={handleChangeTab}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        backgroundColor: colorsByKey.primaryBgWeak,
        borderRadius: '8px',
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      }}
    >
      {tabs.map(({ label, key, displayAppart, disabled }) => {
        const displayAppartStyles = displayAppart
          ? {
              marginLeft: '32px',
              overflow: 'visible',
              '&::before': {
                content: "'|'",
                position: 'absolute',
                left: '-16px',
                color: colorsByKey.accentTextStrong,
              },
            }
          : {}
        const displaySelectedAppartStyles = displayAppart ? { '&::before': { left: '-24px' } } : {}
        return (
          <Tab
            label={t(label)}
            key={key}
            id={key}
            disabled={disabled}
            sx={{
              color: colorsByKey.primaryBgStrong,
              minHeight: 'auto',
              textTransform: 'none',
              fontSize: '14px',
              padding: '8px 16px',
              '&.Mui-selected': {
                background: colorsByKey.accentTextStrong,
                color: 'white !important',
                margin: '6px',
                borderRadius: '8px',
                overflow: 'hidden',
                height: 'auto !important',
                ...displayAppartStyles,
                ...displaySelectedAppartStyles,
              },
              ...displayAppartStyles,
            }}
          />
        )
      })}
      <ModalUpgradePackage
        visible={visibleModalUpgrade}
        dataUpgrade={dataModalUpgrade}
        dataCurrent={dataModalCurrent}
        closeModal={() => handleCloseModal()}
      />
    </Tabs>
  )
}
export const ItemTypeTabs = withTranslation()(ItemTypeTabsComponent)
