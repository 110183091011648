export const CAMPAIGN_WILL_OPTIONS = [
  {
    key: 'problem',
    name: 'Solve / Avoid a problem',
  },
  {
    key: 'opportunity',
    name: 'Capture an opportunity',
  },
]
export const BUSINESS_CASE_OPTIONS = [
  {
    key: 'undisputed',
    name: 'Undisputed (to good to refuse)',
    scorePoints: 5,
  },
  {
    key: 'favorable',
    name: 'Very favorable (gains above average)',
    scorePoints: 3.75,
  },
  {
    key: 'fair',
    name: 'Fair (average gains)',
    scorePoints: 2.5,
  },
  {
    key: 'doubtful',
    name: 'Doubtful (uncertaint or unknown gains)',
    scorePoints: 1.25,
  },
]
export const IMPROVES_CLIENT_COMPETITIVENESS_OPTIONS = [
  {
    key: 'lowerCosts',
    name: 'Lower costs',
    scorePoints: 0.75,
  },
  {
    key: 'intangibleAssets',
    name: 'Higher Intangible Assets (e.g.: brands, patents)',
    scorePoints: 0.75,
  },
  {
    key: 'switchingCosts',
    name: "Higher switching costs for client client's (measured in terms of costs and risks)",
    scorePoints: 0.75,
  },
  {
    key: 'efficientScale',
    name: 'More efficient scale (e.g.: market share, new markets entry)',
    scorePoints: 0.75,
  },
  {
    key: 'networkEffects',
    name: 'Network effects (buyers that bring sellers and vice-versa)',
    scorePoints: 0.75,
  },
]
export const IMPROVES_CLIENT_RESULTS_OPTIONS = [
  {
    key: 'revenues',
    name: 'Higher Revenues (Growth)',
    scorePoints: 0.75,
  },
  {
    key: 'productivity',
    name: 'Higher productivity and efficiency',
    scorePoints: 0.75,
  },
]
export const REDUCES_CLIENT_RISKS_OPTIONS = [
  {
    key: 'customerSatisfaction',
    name: 'Improve customer satisfaction',
    scorePoints: 0.75,
  },
  {
    key: 'productionStandards',
    name: 'Improve delivery/production standards (higher quality and/or less mistakes)',
    scorePoints: 0.75,
  },
  {
    key: 'standardizeProcesses',
    name: 'Standardize processes (increase ease of business and mitigate risk)',
    scorePoints: 0.75,
  },
  {
    key: 'improvesMoral',
    name: 'Improves moral (Feel good; show off; solidify a relationship...)',
    scorePoints: 0.75,
  },
  {
    key: 'disruption',
    name: 'Responds to an industry disruption/requisite (please choose impacted industry and correspondent disruption/requisite)',
    scorePoints: 0.75,
  },
]
export const SCORING_BOARD_OPTIONS = [
  {
    key: true,
    name: 'Yes',
  },
  {
    key: false,
    name: 'No',
  },
]
export const DISCOUNTS = [
  {
    key: '0-15',
    name: '0% - 15%',
    scorePoints: 1.2,
  },
  {
    key: '15-30',
    name: '15% - 30%',
    scorePoints: 2.4,
  },
  {
    key: '30-45',
    name: '30% - 45%',
    scorePoints: 3.6,
  },
  {
    key: '45-60',
    name: '45% - 60%',
    scorePoints: 4.8,
  },
  {
    key: '>60',
    name: '>60%',
    scorePoints: 6,
  },
]
