import React from 'react'
import { Route } from 'react-router-dom'
import history from '../history'
import { Header } from 'components'
import { Stack } from '@mui/material'
import { FEED, COMPANY, LOGOUT, DETAILS } from 'entities/routes'
import { AuthContextProvider } from 'context/authContext'
import { normalizeUserData } from 'mixins/dataNormalizers'
import { apiGet } from 'api'
import ChatList from 'component/chat/chatList'
import Chat from 'component/chat/chat'

class PrivateUserRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userDetail: null,
    }

    if (!localStorage.getItem('accessToken')) {
      history.push('/')
    }

    this.changeSeller = this.changeSeller.bind(this)
    this.updateProfile = this.updateProfile.bind(this)
  }

  async componentDidMount() {
    await apiGet(DETAILS)
      .then(({ data }) => {
        const normalizedData = normalizeUserData(data)
        const { accessRole } = this.props

        if (accessRole === '1') {
          if (data.cb_roles_id !== 1) {
            history.push('/complete-sba-registration')
          } else if (data.step < 4) {
            history.push('/complete-participant-registration')
          } else {
            if (data.status === 0 || data.payment_status === 'Payment nok') {
              history.push('/account-inactive')
            }
            this.setState({ userDetail: normalizedData })
          }
        } else if (accessRole === '2') {
          if (data.cb_roles_id !== 2) {
            history.push('/complete-participant-registration')
          } else if (data.step < 4) {
            history.push('/complete-sba-registration')
          } else {
            this.setState({ userDetail: normalizedData })
          }
        } else {
          if (data.step < 4) {
            if (data.cb_roles_id === 1) {
              history.push('/complete-participant-registration')
            } else if (data.cb_roles_id === 2) {
              history.push('/complete-sba-registration')
            }
          } else {
            if (data.cb_roles_id === 1) {
              if (data.status === 0 || data.payment_status === 'Payment nok') {
                history.push('/account-inactive')
              }
            }
            this.setState({ userDetail: normalizedData })
          }
        }
      })
      .catch(() => {
        history.push(LOGOUT)
      })
  }

  changeSeller(val) {
    const { userDetail } = this.state
    userDetail['is_seller'] = val
    this.setState({ userDetail })
  }

  async updateProfile() {
    await apiGet(DETAILS)
      .then(({ data }) => {
        this.setState({ userDetail: normalizeUserData(data) })
      })
      .catch((error) => {
        history.push(LOGOUT)
      })
  }

  render() {
    const { props } = this
    const { component: Component, ...rest } = props
    const { userDetail } = this.state

    const userReadOnly = (userDetail || {}).isReadOnlyUser

    let hideInnerHeader = userReadOnly ? true : props.hideInnerHeader || false

    const path = props.location.pathname
    const isMyProfile = path.includes(COMPANY) && path.includes((userDetail || {}).id)

    const displayCreateItemsBar = path.includes(FEED) || isMyProfile
    const displayGoBack = !path.includes(FEED)
    const displayExplore = path.includes(FEED)

    if (!userDetail) {
      return <div></div>
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <AuthContextProvider userDetail={userDetail}>
                <Header
                  userDetail={userReadOnly ? {} : userDetail}
                  {...props}
                  displayCreateItemsBar={displayCreateItemsBar}
                  displayGoBack={displayGoBack}
                  displayExplore={displayExplore}
                  hideInnerHeader={hideInnerHeader}
                />
                <Stack sx={{ marginTop: userReadOnly ? '100px' : '200px' }}>
                  <Component
                    userDetail={userDetail}
                    updateProfile={() => this.updateProfile()}
                    changeSeller={(val) => this.changeSeller(val)}
                    {...props}
                  />
                </Stack>
                {/* <Footer /> */}
                <Chat userDetail={userDetail} />
                <ChatList userDetail={userDetail} />
              </AuthContextProvider>
            </>
          )
        }}
      />
    )
  }
}

export default PrivateUserRoute
