import {
  faFileCertificate,
  faStar,
  faTrophyAlt,
  faThumbsUp,
  faCommentSmile,
  faGraduationCap,
} from '@fortawesome/pro-light-svg-icons'
import {
  // normalizeCompanyProfileData,
  normalizeExpertisesData,
  normalizeCredentialsEducationData,
  normalizeCredentialsDocumentsData,
  normalizeCredentialsReviewsData,
} from 'mixins/companyDataNormalizers'
import { normalizeAdvisorData, normalizeCompanyData } from 'mixins/dataNormalizers'

export const CERTIFICATES = 'certificates'
export const REVIEWS = 'reviews'
export const AWARDS = 'awards'
export const RECOMMENDATIONS = 'recommendations'
export const TESTIMONIALS = 'testimonials'
export const EDUCATION = 'education'
export const EXPERTISES = 'expertises'

export const certificates = {
  key: CERTIFICATES,
  label: 'Certificates',
  icon: faFileCertificate,
  list: {
    endpoint: `/api/userCertificatesList`,
    method: 'POST',
    paramIdKey: 'id',
    responseKey: 'data',
    dataNormalizer: (data) => normalizeCredentialsDocumentsData(data, CERTIFICATES),
  },
  // advisorActionPath: '/credentials/certificates',
  advisorActionPath: '/certifications',
  companyActionPath: '/certifications',
}
export const awards = {
  key: AWARDS,
  label: 'Awards',
  icon: faTrophyAlt,
  list: {
    endpoint: `/api/userAwardsList`,
    method: 'POST',
    paramIdKey: 'id',
    responseKey: 'data',
    dataNormalizer: (data) => normalizeCredentialsDocumentsData(data, AWARDS),
  },
  // advisorActionPath: '/credentials/awards',
  advisorActionPath: '/awards',
  companyActionPath: '/awards',
}
export const education = {
  key: EDUCATION,
  label: 'Education',
  icon: faGraduationCap,
  list: {
    endpoint: `/api/advisors/educations`,
    method: 'GET',
    paramIdKey: 'id',
    responseKey: 'data',
    dataNormalizer: (data) => normalizeCredentialsEducationData(data, EDUCATION),
  },
  advisorActionPath: `/credentials/${EDUCATION}`,
}
export const expertises = {
  key: EXPERTISES,
  label: 'Complete experience',
  list: {
    endpoint: (id) => `/api/advisors/expertises/${id}`,
    method: 'GET',
    paramIdKey: 'id',
    responseKey: 'data',
    dataNormalizer: (data) => normalizeExpertisesData(data, EXPERTISES),
  },
  advisorActionPath: `/credentials/${EXPERTISES}`,
}
export const reviews = {
  key: REVIEWS,
  label: 'Reviews',
  btn: 'Write a review',
  icon: faStar,
  list: {
    endpoint: `/api/listPublicReview`,
    method: 'POST',
    paramIdKey: 'user_id',
    responseKey: 'success',
    dataNormalizer: (data) => normalizeCredentialsReviewsData(data, REVIEWS),
  },
  advisorActionPath: '/reviews',
  companyActionPath: '/reviews',
  linkBtn: 'Ask for a review',
}
export const recommendations = {
  key: RECOMMENDATIONS,
  label: 'Recommendations',
  btn: 'Write a recommendation',
  icon: faThumbsUp,
  list: {
    endpoint: `/api/listPublicReferral`,
    method: 'POST',
    paramIdKey: 'user_id',
    params: { reffer_type: 'recommendation' },
    responseKey: 'success',
    dataNormalizer: (data) => normalizeCredentialsReviewsData(data, RECOMMENDATIONS),
  },
  advisorActionPath: '/referrals',
  companyActionPath: '/referrals',
  linkBtn: 'Ask for a recommendation',
}
export const testimonials = {
  key: TESTIMONIALS,
  label: 'Testimonials',
  btn: 'Write a testimonial',
  icon: faCommentSmile,
  list: {
    endpoint: `/api/listPublicReferral`,
    method: 'POST',
    paramIdKey: 'user_id',
    params: { reffer_type: 'testimonial' },
    responseKey: 'success',
    dataNormalizer: (data) => normalizeCredentialsReviewsData(data, TESTIMONIALS),
  },
  advisorActionPath: '/referrals',
  companyActionPath: '/referrals',
  linkBtn: 'Ask for a testimonial',
}

/* COMPANIES */
export const FEED = 'feed'
export const PROFILE = 'profile'
export const CREDENTIALS = 'credentials'
export const CHANNEL = 'channel'
export const POSTS = 'posts'

// TODO: Pending search endpoints for each tab and normalize data
export const companyProfile = {
  key: PROFILE,
  label: 'Company profile',
  path: 'profile',
  endpoint: `/api/companies`,
  method: 'GET',
  useIdOnUrl: true,
  sections: [],
  dataNormalizer: (data) => normalizeCompanyData(data),
  // dataNormalizer: (data, type) => normalizeCompanyProfileData(data, type),
}
export const advisorProfile = {
  key: PROFILE,
  label: 'Advisor profile',
  path: 'profile',
  endpoint: `/api/advisors`, // TODO: Update
  method: 'GET',
  useIdOnUrl: true,
  sections: [],
  dataNormalizer: (data) => normalizeAdvisorData(data),
}
export const credentials = {
  key: CREDENTIALS,
  label: 'Credentials Board',
  path: 'credentials',
  // endpoint: `/api/userInfoForPublic`, // UPDATE
  companySections: [certificates, awards, recommendations, testimonials, reviews],
  advisorSections: [education, certificates, awards, recommendations, testimonials],
  companySidebarSections: [certificates, awards],
  advisorSidebarSections: [education, certificates, awards],
  companyContentSections: [reviews, recommendations, testimonials],
  advisorContentSections: [recommendations, testimonials],
  // THERE MIGHT BE VARIOUS ENDPOINTS
  // method: 'POST',
}

const channel = {
  key: CHANNEL,
  label: 'Channel',
  path: 'channel',
  endpoint: '',
  method: '',
}

export const COMPANY_SECTION_TABS = [companyProfile, credentials, channel]
export const ADVISOR_SECTION_TABS = [advisorProfile, credentials, { ...channel, label: 'Posts' }]
