import React, { useRef, useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Container,
} from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { useParams, useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { FEED_SECTION_TABS_ARRAY } from 'entities/feed'
import { apiPost, apiGet, apiPut } from 'api'
import { normalizeData } from 'mixins/dataNormalizers'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { IconData } from 'components'
import { CAMPAIGNS } from 'entities/feed'
import Form from './components/Form'
import CampaignForm from './components/CampaignForm'
const EDIT = 'edit'
const CREATE = 'create'

const PostEditor = ({ t }) => {
  let history = useHistory()

  const { id: postId, entity } = useParams()

  const DIALOG_INITIAL_DATA = {
    isOpen: false,
    title: '',
    text: '',
    actions: [],
  }
  const [dialogData, setDialogData] = useState(DIALOG_INITIAL_DATA)
  const [postData, setPostData] = useState({})
  const closeDialog = () => setDialogData(DIALOG_INITIAL_DATA)
  const showDialog = (fn) => setDialogData({ isOpen: true, ...fn({ close: closeDialog }) })

  const goBack = () => {
    history.goBack()
  }
  const handleSubmit = () => {
    triggerSubmit().then(() => {
      goBack()
    })
  }
  const handleCancel = () => {
    goBack()
  }

  let entityFromPath = {}

  FEED_SECTION_TABS_ARRAY.forEach((tab) => {
    if (entity === tab.key || entity === tab.path) {
      entityFromPath = tab
    }
  })

  const formRef = useRef(null)

  useEffect(() => {
    const fetchPostData = async () => {
      const { detail } = entityFromPath
      const { endpoint } = detail || {}
      const response = await apiGet(endpoint(postId))

      const postResponse = normalizeData(response.data, entity)
      setPostData(postResponse)
    }
    if (postId) fetchPostData()
  }, [entity, entityFromPath, postId])

  const actionBtnLabel = (entityFromPath[postId ? EDIT : CREATE] || {}).requiresApproval
    ? t('Submit for approval')
    : t('Submit')

  if (!entityFromPath) {
    history.push(`/feed`)
  }
  const triggerSubmit = async () => {
    const formData = formRef.current.getFormData()
    if (formData?.errorMessage) {
      showDialog(({ close }) => ({
        title: 'Error',
        text: formData.errorMessage,
        actions: [{ text: 'Ok', action: () => close() }],
      }))
      return Promise.reject()
    }
    const request = postId
      ? apiPut(entityFromPath[EDIT].endpoint(postId), formData)
      : apiPost(entityFromPath[CREATE].endpoint, formData)
    return request.then(
      (response) =>
        new Promise((resolve) => {
          showDialog(({ close }) => ({
            title: 'Great!',
            text: entityFromPath[CREATE].requiresApproval
              ? 'This information has been correctly submited. Our team will review it as soon as possible.'
              : 'This information has been correctly submited',
            actions: [
              {
                text: 'Ok',
                action: () => {
                  close()
                  resolve(response)
                },
              },
            ],
          }))
        })
    )
  }

  return (
    <Container maxWidth={'xl'} sx={{ my: 4 }}>
      {/* <PostEditorHeader triggerSubmit={triggerSubmit} actionBtnLabel={actionBtnLabel} /> */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="f20" sx={{ display: 'inline-block' }}>
          {postId ? t('Edit') : t('Create new')}
          <Typography
            variant="f20"
            component="span"
            color={colorsByKey.accentText}
            sx={{ ml: 1, display: 'inline-block' }}
          >
            {entityFromPath.btn}
          </Typography>
        </Typography>
        <Box>
          <Button onClick={handleCancel}>
            <Typography noWrap>{t('Cancel')}</Typography>
          </Button>
          <Button
            id='formSubmit'
            onClick={handleSubmit}
            variant="contained"
            sx={{
              ml: 2,
              borderRadius: '48px !important',
              backgroundColor: colorsByKey.accentBg,
            }}
          >
            <IconData
              title={actionBtnLabel}
              icon={faSave}
              titleVariant="f14"
              margin={1}
              color={colorsByKey.utility}
              sx={{ padding: '6px 8px' }}
            />
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: '100%', py: 4 }}>
        {entity !== CAMPAIGNS && (
          <Form
            ref={formRef}
            entity={entityFromPath}
            postData={postData}
            showDialog={showDialog}
            type={postId ? 'edit' : 'create'}
          />
        )}
        {entity === CAMPAIGNS && (
          <CampaignForm
            ref={formRef}
            entity={entityFromPath}
            postData={postData}
            showDialog={showDialog}
          />
        )}
      </Box>

      <Dialog
        open={dialogData.isOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogData.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogData.actions.map(({ text, action }, index) => (
            <Button key={index} onClick={action}>
              {text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </Container>
  )
}
export default withTranslation()(PostEditor)
