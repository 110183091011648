import React from 'react'
import { Avatar, Typography, Box } from '@mui/material'
import SUBSCRIPTIONS from 'utils/subscriptions'
import { colorsByKey } from 'theme/colors'
import { COMPANY, ADVISOR } from 'entities/routes'
import { useHistory } from 'react-router-dom'
import { linkedin } from 'entities/companyFields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COMPANIES, ADVISORS } from 'entities/feed'
import ModalImage from 'react-modal-image'

const SIZES = {
  sm: { width: '36px', height: '36px', borderWidth: '2px' },
  md: { width: '120px', height: '120px', borderWidth: '4px' },
  lg: { width: '130px', height: '130px', borderWidth: '4px' },
}
const TYPES = {
  company: { defaultImage: require('assets/icons/company.svg') },
  user: { defaultImage: require('assets/icons/user.svg') },
}

export const CompanyAvatar = ({
  subscription,
  name,
  image,
  variant,
  userId,
  type = 'company',
  size = 'sm',
  bordered = false,
  useLinkedinIcon = false,
  linkedinLink = '',
  redirectToUserProfile = true,
  useSubscriptionName = false,
  usePlusOption = false,
  plusOption = "",
  customSx = {},
  expoImage = null,
}) => {
  let history = useHistory()
  const handleRedirectToUserProfile = () => {
    if (redirectToUserProfile) {
      if (type === COMPANIES) history.push(`${COMPANY}/${userId}`)
      else if (type === ADVISORS) history.push(`${ADVISOR}/${userId}`)
    }
  }
  const redirectTo = (web) => {
    window.open(web, '_blank')
  }
  const defaultImage = TYPES[type].defaultImage
  // const useBorder = bordered && subscription

  const { width, height, borderWidth } = SIZES[size]

  const borderStyles = {
    borderWidth,
    borderStyle: 'solid',
    borderRadius: '8px',
    borderImageSlice: 1,
    // borderImageSource: `linear-gradient(to bottom right, ${
    //   (SUBSCRIPTIONS[subscription] || {}).color
    // }, ${colorsByKey.transparent})`,
  }
  const customSyles = bordered ? borderStyles : {}

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        ...customSx,
      }}
    >
      {useLinkedinIcon && linkedinLink && (
        <Box
          sx={{
            position: 'absolute',
            right: variant === '' ? 0 : -15,
            top: variant === '' ? 0 : -15,
            background: linkedin.color,
            width: '34px',
            height: '34px',
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
            cursor: 'pointer',
          }}
          onClick={() => redirectTo(linkedinLink)}
        >
          <FontAwesomeIcon icon={linkedin.fortawesomeIcon} size="1x" color={colorsByKey.utility} />
        </Box>
      )}
      {expoImage && (
        <ModalImage
          className={'modalimage-small'}
          small={expoImage}
          large={expoImage}
          alt={'expo image'}
        />
      )}
      {!expoImage && (
        <Avatar
          onClick={handleRedirectToUserProfile}
          alt={`${name} - company logo`}
          src={expoImage || image || defaultImage}
          variant={variant}
          sx={{
            width,
            height,
            zIndex: 1,
            cursor: redirectToUserProfile ? 'pointer' : 'default',
            '& img': {
              maxWidth: '100% !important',
              height: 'auto !important',
              padding: '0 !important',
            },
            ...customSyles,
          }}
        />
      )}
      {useSubscriptionName && subscription && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              background: (SUBSCRIPTIONS[subscription] || {}).color,
              borderRadius: '24px',
              py: 0.5,
              px: 2,
              marginTop: '38px',
            }}
          >
            <span style={{ color: 'white' }}>{SUBSCRIPTIONS[subscription].name}</span>
          </Box>
        </>
      )}
      {usePlusOption && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              background: (SUBSCRIPTIONS[subscription] || {}).color,
              borderRadius: '24px',
              py: 0.5,
              px: 2,
              marginTop: '38px',
            }}
          >
            <span style={{ color: 'white' }}>{plusOption}</span>
          </Box>
        </>
      )}
    </Box>
  )
}
