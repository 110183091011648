import React from 'react'
import Forgotpassword from '../component/forgotpassword/forgotpassword'

class Forgotpasswordpage extends React.Component {
  render() {
    return (
      <div>
        <Forgotpassword></Forgotpassword>
      </div>
    )
  }
}
export default Forgotpasswordpage
