import React, { useContext } from 'react'
import { Box, Typography, Button, Container } from '@mui/material'
import { colorsByKey } from 'theme/colors'
import { withTranslation } from 'react-i18next'
import { IconData, CompanyAvatar, Spinner } from 'components'
import {
  SOCIAL_MEDIA_FIELDS,
  WEBSITE,
  ID,
  IMAGE,
  NAME,
  DESCRIPTION,
  SUBSCRIPTION,
  website,
  SOCIAL_MEDIA,
  HIDE_IDENTITY,
  ADVISOR,
  COMPANY,
} from 'entities/companyFields'
import userContext from 'context/userContext'
import filters from 'mixins/filters'
import { HEADLINE } from 'entities/formFields'

const UserHeaderComponent = ({ t, userType, headerFields }) => {
  const ctx = useContext(userContext)
  const allFilters = filters()

  const redirectTo = (web) => {
    window.open(web, '_blank')
  }

  const companyDefaultImage = require('assets/icons/company.svg')
  const advisorDefaultImage = require('assets/icons/advisor.svg')
  let userImage = userType === COMPANY ? companyDefaultImage : advisorDefaultImage
  if (ctx.userData[IMAGE] && !ctx.userData[HIDE_IDENTITY]) userImage = ctx.userData[IMAGE]

  console.log(ctx.userData)

  return (
    <Container className="UserHeader" sx={{ pt: 3, pb: 4 }} maxWidth={'xl'}>
      <Box
        className="Content"
        sx={{
          flex: 1,
          my: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-betwwen',
          }}
        >
          {ctx.loadingUserData && <Spinner />}
          {!ctx.loadingUserData && (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mr: 4 }}>
                <CompanyAvatar
                  subscription={ctx.userData[SUBSCRIPTION]}
                  name={ctx.userData[NAME]}
                  image={userImage}
                  userId={ctx.userData[ID]}
                  size="md"
                  variant={userType === COMPANY ? 'rounded' : ''}
                  useSubscriptionName
                  customSx={{
                    mr: 4,
                    '& img': { padding: 1 },
                  }}
                />
                {userType !== COMPANY && ctx.userData[HIDE_IDENTITY] && (
                  <Typography
                    variant="f10"
                    color={colorsByKey.secondaryText}
                    align="center"
                    sx={{ maxWidth: '150px', mt: 2, fontStyle: 'italic' }}
                  >
                    {t('This advisor prefers to keep its identity hidden')}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {ctx.userData[NAME] && (
                  <Typography sx={{ color: colorsByKey.defaultText, mb: 1 }} variant="f20">
                    {userType === ADVISOR && ctx.userData[HIDE_IDENTITY]
                      ? 'Nondisclosure'
                      : ctx.userData[NAME]}
                  </Typography>
                )}
                {userType === ADVISOR && ctx.userData[DESCRIPTION] && (
                  <Typography
                    sx={{ color: colorsByKey.secondaryText, lineHeight: '24px' }}
                    variant="f14"
                  >
                    {ctx.userData[DESCRIPTION]}
                  </Typography>
                )}
                {userType === COMPANY && ctx.userData[HEADLINE] && (
                  <Typography
                    sx={{ color: colorsByKey.secondaryText, lineHeight: '24px' }}
                    variant="f14"
                  >
                    {ctx.userData[HEADLINE]}
                  </Typography>
                )}
                <Box sx={{ display: 'flex', mt: 2 }}>
                  {/* {console.log(ctx.userData)} */}
                  {/* {console.log(headerFields, ctx.userData)} */}

                  {headerFields.map(({ key, icon, suffix, getText, optionsKey }, idx) => {
                    if (!ctx.userData[key] || !(ctx.userData[key] || []).length) return undefined
                    else {
                      return (
                        <Box
                          key={key}
                          sx={{ ml: idx !== 0 ? 2 : 0, display: 'flex', alignItems: 'center' }}
                        >
                          <IconData
                            type={key}
                            title={
                              getText
                                ? getText(ctx.userData[key], allFilters[optionsKey])
                                : ctx.userData[key]
                            }
                            icon={icon}
                            suffix={suffix}
                            margin={1}
                          />
                        </Box>
                      )
                    }
                  })}
                </Box>
                <Box sx={{ display: 'flex', mt: 2 }}>
                  {SOCIAL_MEDIA_FIELDS.map((field, idx) => {
                    if (!(ctx.userData[SOCIAL_MEDIA] || {})[field.key]) return undefined
                    else
                      return (
                        <img
                          key={`${field.key} - ${idx}`}
                          src={field.icon}
                          alt={`Social media icon - ${field.icon}`}
                          onClick={() => redirectTo((ctx.userData[SOCIAL_MEDIA] || {})[field.key])}
                          style={{
                            marginRight: '16px',
                            cursor: 'pointer',
                            width: '24px',
                            height: 'auto',
                          }}
                        />
                      )
                  })}
                  {ctx.userData[WEBSITE] && (
                    <Button
                      onClick={() => redirectTo(ctx.userData[WEBSITE])}
                      type="button"
                      variant="outlined"
                    >
                      <IconData title={t(website.btn)} icon={website.icon} margin={1} />
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  )
}
export const UserHeader = withTranslation()(UserHeaderComponent)
