import styled from '@emotion/styled'
import { Container, Stack, Tooltip } from '@mui/material'
import axiosInstance from 'api/axios'
import Loader from 'component/loader'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  DownloadImage,
  PartnerTag,
  SectorBadge,
  SectorBadgePlus,
  TooltipP,
} from 'screens/Partnerships/view'

function AcceleratorView() {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    setLoading(true)
    axiosInstance
      .get(`/api/acceleratorsDetail/${params.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
      .then(({ data }) => {
        setData(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [params.id])

  const downloadBrochure = () => {
    const anchor = document.createElement('a')
    anchor.href = data.brochure_url
    anchor.target = '_blank'
    anchor.download = data.files[0].url.split('/').pop()
    anchor.click()
  }

  return (
    <>
      {loading && <Loader width={54} height={54} />}
      {!loading && (
        <Wrapper maxWidth={'xl'} sx={{ my: 4 }}>
          <ImageHeader src={data.image_url}></ImageHeader>
          <Stack direction="row" gap="72px" justifyContent="space-between">
            <Stack spacing={2} flexGrow={1}>
              <Title>{data.title}</Title>
              <Stack>
                <Stack marginBottom={2}>
                  <AboutPart>Promoter</AboutPart>
                  <PurposeText>{data.promoter}</PurposeText>
                </Stack>
                <Stack marginBottom={2}>
                  <AboutPart>Description</AboutPart>
                  <PurposeText>{data.description}</PurposeText>
                </Stack>
              </Stack>

              <Divider />

              <Stack spacing={2}>
                <Stack marginBottom={2}>
                  <Purpose>Funding Programme</Purpose>
                  <PurposeText>{data.funding_programmes}</PurposeText>
                </Stack>
                <Stack marginBottom={2}>
                  <Purpose>Elegibility</Purpose>
                  <PurposeText>{data.elegibility}</PurposeText>
                </Stack>
                <Stack marginBottom={2}>
                  <Purpose>Benefits of the funding programme</Purpose>
                  <PurposeText>{data.funding_programme_benefits}</PurposeText>
                </Stack>
              </Stack>
            </Stack>

            <Stack minWidth="200px" width="200px">
              <Sidebar spacing={1}>
                <SecondTitle>ABOUT</SecondTitle>
                <PartnerTag>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.9883 5.42627L10.1875 7.94189V5.97314C10.1875 5.48096 9.61328 5.15283 9.17578 5.42627L5.375 7.94189V3.56689C5.375 3.07471 4.96484 2.69189 4.5 2.69189H1.875C1.38281 2.69189 1 3.07471 1 3.56689V14.0669C1 14.5317 1.38281 14.9419 1.875 14.9419H14.125C14.5898 14.9419 15 14.5317 15 14.0669V5.97314C15 5.48096 14.4258 5.15283 13.9883 5.42627ZM12.0469 12.3169H10.9531C10.7617 12.3169 10.625 12.1802 10.625 11.9888V10.895C10.625 10.731 10.7617 10.5669 10.9531 10.5669H12.0469C12.2109 10.5669 12.375 10.731 12.375 10.895V11.9888C12.375 12.1802 12.2109 12.3169 12.0469 12.3169ZM8.54688 12.3169H7.45312C7.26172 12.3169 7.125 12.1802 7.125 11.9888V10.895C7.125 10.731 7.26172 10.5669 7.45312 10.5669H8.54688C8.71094 10.5669 8.875 10.731 8.875 10.895V11.9888C8.875 12.1802 8.71094 12.3169 8.54688 12.3169ZM5.04688 12.3169H3.95312C3.76172 12.3169 3.625 12.1802 3.625 11.9888V10.895C3.625 10.731 3.76172 10.5669 3.95312 10.5669H5.04688C5.21094 10.5669 5.375 10.731 5.375 10.895V11.9888C5.375 12.1802 5.21094 12.3169 5.04688 12.3169Z" />
                  </svg>
                  <SectorBadge>
                    <span>{data.company_sectors[0].name}</span>
                    {data.company_sectors.length > 1 && (
                      <Tooltip
                        title={data.company_sectors.map((cs) => (
                          <TooltipP>{cs.name}</TooltipP>
                        ))}
                      >
                        <SectorBadgePlus>+{data.company_sectors.length - 1}</SectorBadgePlus>
                      </Tooltip>
                    )}
                  </SectorBadge>
                </PartnerTag>
                <PartnerTag>
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 0.283203C3.07422 0.283203 0.75 2.63477 0.75 5.5332C0.75 7.66602 1.46094 8.26758 5.45312 14.0098C5.69922 14.3926 6.27344 14.3926 6.51953 14.0098C10.5117 8.24023 11.25 7.66602 11.25 5.5332C11.25 2.63477 8.89844 0.283203 6 0.283203ZM6 7.7207C4.76953 7.7207 3.8125 6.73633 3.8125 5.5332C3.8125 4.30273 4.76953 3.3457 6 3.3457C7.20312 3.3457 8.1875 4.30273 8.1875 5.5332C8.1875 6.73633 7.20312 7.7207 6 7.7207Z" />
                  </svg>

                  <span>{data.countrie.name}</span>
                </PartnerTag>
              </Sidebar>
              {data.brochure_url && (
                <Stack>
                  <DownloadImage onClick={downloadBrochure} style={{ marginTop: '30px' }}>
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M6.69922 11.1406C6.78125 11.2227 6.89062 11.25 7 11.25C7.08203 11.25 7.19141 11.2227 7.27344 11.1406L11.2109 7.64062C11.4023 7.50391 11.4023 7.20312 11.2383 7.03906C11.1016 6.84766 10.8008 6.84766 10.6367 7.01172L7.4375 9.85547V1.1875C7.4375 0.96875 7.21875 0.75 7 0.75C6.75391 0.75 6.5625 0.96875 6.5625 1.1875V9.85547L3.33594 6.98438C3.17188 6.84766 2.87109 6.84766 2.73438 7.03906C2.57031 7.20312 2.57031 7.50391 2.76172 7.64062L6.69922 11.1406ZM12.25 9.5H10.9375C10.6914 9.5 10.5 9.71875 10.5 9.9375C10.5 10.1836 10.6914 10.375 10.9375 10.375H12.25C12.7148 10.375 13.125 10.7852 13.125 11.25V13C13.125 13.4922 12.7148 13.875 12.25 13.875H1.75C1.25781 13.875 0.875 13.4922 0.875 13V11.25C0.875 10.7852 1.25781 10.375 1.75 10.375H3.0625C3.28125 10.375 3.5 10.1836 3.5 9.9375C3.5 9.71875 3.28125 9.5 3.0625 9.5H1.75C0.765625 9.5 0 10.293 0 11.25V13C0 13.9844 0.765625 14.75 1.75 14.75H12.25C13.207 14.75 14 13.9844 14 13V11.25C14 10.293 13.207 9.5 12.25 9.5ZM12.0312 12.125C12.0312 11.7695 11.7305 11.4688 11.375 11.4688C10.9922 11.4688 10.7188 11.7695 10.7188 12.125C10.7188 12.5078 10.9922 12.7812 11.375 12.7812C11.7305 12.7812 12.0312 12.5078 12.0312 12.125Z" />
                    </svg>
                    <span>Download brochure</span>
                  </DownloadImage>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 30px 70px 60px;
`
const ImageHeader = styled.img`
  max-height: 550px;
  width: 100%;
  margin-bottom: 60px;
  border-radius: 12px;
`

const Title = styled.h4`
  margin: 0;
  color: #132332;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 35px;
`

const AboutPart = styled.div`
  color: #358feb;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
`

const Purpose = styled.p`
  margin: 0;
  color: #358feb;
  font-size: 17px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
`
const SecondTitle = styled.h5`
  margin: 0;
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 18px;
`

const PurposeText = styled.p`
  margin: 0;
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`

const Sidebar = styled(Stack)`
  border-left: 1px solid #ededed;
  padding-left: 10px;
  object-fit: cover;
`

const Divider = styled.hr`
  height: 2px;
  background: #d3e6fb;
  width: 100%;
  border: 0;
  margin-top: 24px !important;
  margin-bottom: 24px !important;
`

export default AcceleratorView
